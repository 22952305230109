import { Select } from "antd";
import { GlobalExpression } from "../../../api/models";
import { useContext } from "react";
import RulesContext from "../context";

export interface Props {
    name: string;
    onUpdate: (GlobalExpressionDefinition) => void;
}

const GlobalExpressionSelector = (props: Props) => {
    const rulesContext = useContext(RulesContext);

    return (<>
        <Select style={{width: '400px', marginTop: '5px'}} value={props.name} onSelect={(val) => {
            const newExpr = new GlobalExpression();
            newExpr.name = val;
            props.onUpdate(newExpr);
        }}>
            {rulesContext.globalExpressions.map(expr => (
                <Select.Option key={expr.id} value={expr.id}>{expr.definition.name}</Select.Option>
            ))}
        </Select>
    </>);
};

export default GlobalExpressionSelector;
