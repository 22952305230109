import Title from "antd/lib/typography/Title";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Row, TablePaginationConfig, Dropdown, Spin, notification, MenuProps } from "antd";
import { ConfiguratorContext } from "../context";
import axios, { CancelTokenSource } from "axios";
import AssembliesTable from "../components/Table/AssembliesTable";
import { BooleanParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import AssemblyFilter from "../components/Filter/AssemblyFilter";
import { debounce } from "lodash";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { ASSEMBLY_LIST, Assembly, CategoryFilter, FilterOption, Permission, AXIOS_CANCEL_MSG, Category } from "../api/models";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import BatchAssemblyReplacementButtonModal from "../components/BatchAssemblyReplacementButtonModal";
import { AssemblyFilterOptions } from "../api";
import { useIntl } from "react-intl";
import SaveFilter from "../components/widgets/SaveFilter";
import { useCategoryContext } from "../contexts/CategoryContext";
import NewAssemblyModal from "../components/new_assembly_modal";

const DEBOUNCE_MS = 1400;

const EXCLAMATION_DELIMITER = "!!";

interface FilterOptionsRequest {
  obsolete?: boolean
  filterQuery?: string
  categoryId?: number
  sortFields?: string
  sortDirections?: string
  sortColumnValues?: string
  filterFields?: string
  filterValueNumbers?: string
  filterValues?: string
  filterValueColumns?: string
}

const defaultFilterOptionsRequest:FilterOptionsRequest = {
}

const Assemblies = () => {
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const cancelAssemblyTokenSourceRef = useRef<CancelTokenSource>();
  const cancelFilterTokenSourceRef = useRef<CancelTokenSource>();

  //parameters from the query string
  const [dataFilterParam, setDataFilterParam] = useQueryParam<string | null | undefined>("filter", StringParam);
  const [filterCategoryIdParam, setFilterCategoryIdParam] = useQueryParam<number | null | undefined>("category", NumberParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | null | undefined>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | null | undefined>("p", NumberParam);
  const [sortFieldsParam, setSortFieldsParam] = useQueryParam<string | undefined | null>("fields", StringParam);
  const [sortDirectionsParam, setSortDirectionsParam] = useQueryParam<string | undefined | null>("directions", StringParam);
  const [sortColumnValuesParam, setSortColumnValuesParam] = useQueryParam<string | undefined | null>("columnValues", StringParam);
  const [filterFieldsParam, setFilterFieldsParam] = useQueryParam<string | undefined | null>("filterFields", StringParam);
  const [filterValueNumbersParam, setFilterValueNumbersParam] = useQueryParam<string | null | undefined>("fnr", StringParam);
  const [filterValuesParam, setFilterValuesParam] = useQueryParam<string | undefined | null>("filterValues", StringParam);
  const [filterValueColumnsParam, setFilterValueColumnsParam] = useQueryParam<string | undefined | null>("filterValueColumns", StringParam);
  const [obsoleteParam, setObsoleteParam] = useQueryParam<boolean | undefined | null>("obsolete", BooleanParam);

  const [assemblies, assembliesAsync] = useAsyncState<Assembly[]>();
  const [categoryFilter, setCategoryFilter] = useState<CategoryFilter | undefined>({});
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 50 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    pageSizeOptions: [10, 20, 50, 100, 200, 500],
  });

  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);
  const isWorking = false;

  const { categoriesAsync, loadCategories } = useCategoryContext();
  const categories = categoriesAsync?.val;

  const [isExporting, setIsExporting] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<any>();

  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [filterOptionsRequest, setFilterOptionsRequest] = useState<FilterOptionsRequest | undefined>({
      filterQuery: dataFilterParam || defaultFilterOptionsRequest.filterQuery,
      categoryId: filterCategoryIdParam || defaultFilterOptionsRequest.categoryId,
      sortFields: sortFieldsParam || defaultFilterOptionsRequest.sortFields,
      sortDirections: sortDirectionsParam || defaultFilterOptionsRequest.sortDirections,
      sortColumnValues: sortColumnValuesParam || defaultFilterOptionsRequest.sortColumnValues,
      filterFields: filterFieldsParam ||  defaultFilterOptionsRequest.filterFields,
      filterValueNumbers: filterValueNumbersParam ||  defaultFilterOptionsRequest.filterValueNumbers,
      filterValues: filterValuesParam ||  defaultFilterOptionsRequest.filterValues,
      filterValueColumns: filterValueColumnsParam ||  defaultFilterOptionsRequest.filterValueColumns,
      obsolete: obsoleteParam ?? defaultFilterOptionsRequest.obsolete,
  });


  useEffect(() => {
    if (categoriesAsync?.isInitial()) {
      loadCategories?.();
    }
  }, []);

  useEffect(() => {
    if ( categories ) {
      setCategoryFilter( categories.find( c => c.id === filterCategoryIdParam ) );
    }
  }, [categories]);

  useEffect(() => {
    clearDataSource();
    setPagination({ ...pagination, current: 1 });
  }, [filterOptionsRequest, categoryFilter]);

  useEffect(() => {
    loadAssemblies(assembliesAsync, pagination, filterOptionsRequest );
    loadFilterOptions(pagination, filterOptionsRequest )
  }, [pagination.pageSize, pagination.current, filterOptionsRequest, categoryFilter ]);

  const clearDataSource = () => {
    assembliesAsync.setInit();
  };

  const getSortingOrder = (columnKey: string) => {
    if (Array.isArray(sortedInfo)) {
      return sortedInfo?.find(col => col.columnKey === columnKey) ? sortedInfo?.find(col => col.columnKey === columnKey)?.order : null;
    }
    else {
      return sortedInfo?.columnKey === columnKey ? sortedInfo.order : null;
    }
  }

  const tableOnChange = async (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[] | undefined) => { // 
    setSortedInfo(sorter);
    setFilteredInfo(filters);
    if (!Array.isArray(sorter) && sorter?.order == undefined) { sorter = undefined; }
    onChangeTableSetup(pagination, sorter, filters);
  };

  const resetSorting = () => { // this one set the filter sorting to undefined
    setPagination( {...pagination, current: 1} );
    setSortedInfo(undefined);
    setFilteredInfo({})

    const resetFilterOptions = {
      filterFields: undefined,
      filterValueNumbers: undefined,
      filterValues: undefined,
      filterValueColumns: undefined,
      sortFields: undefined,
      sortDirections: undefined,
      sortColumnValues: undefined
    };

    const filterOptions = {
      ...filterOptionsRequest,
      ...resetFilterOptions,
    }

    setFilterOptionsRequest(filterOptions);
    updateFilterParams( filterOptions );
  }

  const getAssemblyQueryFilter = (pagination: TablePaginationConfig, filterOptions?:FilterOptionsRequest): AssemblyFilterOptions => {
    return {
      page: pagination?.current ? pagination.current - 1 : undefined,
      size: pagination?.pageSize,
      imported: true,
      isDashAssembly: false,
      ...filterOptions,
    }
  }

  const loadAssemblies = useCallback(debounce(async (assembliesAsync:AsyncState<Assembly[]>, pagination: TablePaginationConfig, filterOptions?:FilterOptionsRequest | undefined) => {
      assembliesAsync.setLoading();

      try {
        if ( cancelAssemblyTokenSourceRef.current ) {
          cancelAssemblyTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
        }
        const cancelSource = axios.CancelToken.source();
        cancelAssemblyTokenSourceRef.current = cancelSource;

        const assemblyOptionsFilter = getAssemblyQueryFilter(pagination, filterOptions );

        const resp = await configurator.api.getFilteredAssemblies(
          assemblyOptionsFilter,
          cancelSource.token
        );
        cancelAssemblyTokenSourceRef.current = undefined;

        assembliesAsync.setDone(resp.data.content);

        setPagination({ ...pagination, total: resp.data.totalElements });

      } catch (e: any) {
        const id = e.response?.data?.message || e.message ;
        if ( id !== AXIOS_CANCEL_MSG ) {
          const errorMsg = intl.formatMessage({ id });
          assembliesAsync.setFail(errorMsg);
        }
      }
    }, DEBOUNCE_MS), []);

  // Get filter options
  const loadFilterOptions = async (pagination: TablePaginationConfig, filterOptions:FilterOptionsRequest | undefined ) => {
    try {
      if (!categoryFilter?.id) return;

      if ( cancelFilterTokenSourceRef.current ) {
        cancelFilterTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
      }
      const cancelSource = axios.CancelToken.source();
      cancelFilterTokenSourceRef.current = cancelSource;

      const assemblyOptionsFilter = getAssemblyQueryFilter(pagination, filterOptions );

      const metadataIds = categoryFilter?.metadata?.map(m => m.id) || [];

      const resp = await configurator.api.getAssemblyDynamicFilterOptions(assemblyOptionsFilter, categoryFilter.id, metadataIds, cancelSource.token);
      cancelFilterTokenSourceRef.current = undefined;

      const sortingFilterOptions = resp.data.map(fo => { return { ...fo, filterOptions: fo.filterOptions.sort(metadataValueSorting) }; });
      setFilterOptions(sortingFilterOptions);
    }
    catch (e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to fetch filter option value. " + errorMsg });
      }
    }
  }

  const metadataValueSorting = (a, b): number => {
    if (typeof a === "number" && typeof b === "number") {
      return a - b;
    }

    if (!isNaN(a) && !isNaN(b)) {
      return Number(a) - Number(b);
    }

    const [va, vb] = [a, b]
      .map(v => v || "")
      .map(v => (typeof v === "number") ? String(v) : v);

    return va.localeCompare(vb);
  }

  const onChangeAssemblyFilter = (category:Category | undefined, dataFilter:string | undefined, hideObsolete: boolean | undefined) => {

   
    //clear category filters if category changed
    const resetFilterOptions = ( category?.id != categoryFilter?.id ) ? {
      filterFields: undefined,
      filterValueNumbers: undefined,
      filterValues: undefined,
      filterValueColumns: undefined,
      sortFields: undefined,
      sortDirections: undefined,
      sortColumnValues: undefined
    }
    : {};
  
    const filterOptions = {
      ...filterOptionsRequest,
      ...resetFilterOptions,
      filterQuery: dataFilter,
      categoryId: category?.id,
      obsolete: hideObsolete === true ? false : undefined
    }

    setCategoryFilter(category);
    setFilterOptionsRequest(filterOptions);
    updateFilterParams( filterOptions );

  };

  const resetFilterAndCategory = () => {
    setFilterOptionsRequest(defaultFilterOptionsRequest );
    updateFilterParams( defaultFilterOptionsRequest );
  };

  const handleExportWeight = async () => {
    setIsExporting(true);
    try {
      const url = configurator.api.getAssemblyWeightExportUrl();
      await configurator.api.downloadZip(url);
    }
    catch (e) {
    notification["error"]({
      message: "Failed to export.",
    });
    }
    setIsExporting(false);
  };

  const getValidFilters = (filters: Record<string, FilterValue | null> | undefined): Record<string, FilterValue | null> | undefined => {
    if (!filters) return undefined;
    return Object.entries(filters).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, FilterValue>);
  };

  const onChangeTableSetup = (pagination: TablePaginationConfig, sorter: SorterResult<any> | SorterResult<any>[] | undefined, filters: Record<string, FilterValue | null> | undefined) => {

    //save to the query string
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);

    const updatedFilterOptions:any = {};

    if (sorter) {
      updatedFilterOptions.sortFields = Array.isArray(sorter) ? sorter.map(col => transformFieldName(String(col.columnKey))).join(",") :
        [sorter].map(col => transformFieldName(String(col.columnKey))).join(",");
      updatedFilterOptions.sortDirections = Array.isArray(sorter) ? sorter.map(col => transformDirectionString(col.order)).join(",") :
        [sorter].map(col => transformDirectionString(col.order)).join(",");
      updatedFilterOptions.sortColumnValues = Array.isArray(sorter) ? sorter.map(col => transformColumnValue(String(col.columnKey))).join(",") :
        [sorter].map(col => transformColumnValue(String(col.columnKey))).join(",");
    }

    const validFilters = getValidFilters(filters);

    if (validFilters) {

      updatedFilterOptions.filterFields = Object.entries(validFilters).map(entry => transformFieldName(String(entry[0]))).join(",");
      updatedFilterOptions.filterValueNumbers = Object.entries(validFilters).map(entry => entry[1]?.length).join(",");
      updatedFilterOptions.filterValues = Object.values(validFilters).flat().join(EXCLAMATION_DELIMITER);
      updatedFilterOptions.filterValueColumns = Object.entries(validFilters).map(entry => transformColumnValue(String(entry[0]))).join(",");
    }

    setPagination(pagination);
    const filterOptions = {
      ...filterOptionsRequest,
      ...updatedFilterOptions,
    };
    setFilterOptionsRequest(filterOptions );
    updateFilterParams( filterOptions );

  };

  const updateFilterParams = (filterOptions: FilterOptionsRequest) => {
    setDataFilterParam( filterOptions.filterQuery );
    setFilterCategoryIdParam( filterOptions.categoryId );
    setSortFieldsParam(filterOptions.sortFields);
    setSortDirectionsParam(filterOptions.sortDirections);
    setSortColumnValuesParam(filterOptions.sortColumnValues);
    setFilterFieldsParam(filterOptions.filterFields);
    setFilterValueNumbersParam(filterOptions.filterValueNumbers);
    setFilterValuesParam(filterOptions.filterValues);
    setFilterValueColumnsParam(filterOptions.filterValueColumns);
    setObsoleteParam(filterOptions.obsolete);
  }

  const transformDirectionString = (value: string | null | undefined) => {
    return value === "ascend" ? "asc" : "desc";
  };

  const transformFieldName = (value: string | null | undefined) => {
    if (value === 'bom' || value === 'name') { return value; }
    const id = Number(value?.split('md-')[1]);
    let result: string | undefined;
    if (assemblies) {
      assemblies.forEach(asm => {
        const target = asm['metadata'].filter(md => md.categoryMetadata.id === id)[0];
        if (target) {
          result = target.categoryMetadata.name;
        }
      });
    }
    return result;
  }

  const transformColumnValue = (value: string | null | undefined) => {
    if (value === 'bom' || value === 'name') { return value; }
    const id = Number(value?.split('md-')[1]);

    let result: string | undefined;

    if (assemblies) {

      assemblies.forEach(asm => {
        const target = asm['metadata'].filter(md => md.categoryMetadata.id === id)[0];

        if (target) {
          if (target.categoryMetadata.fieldType == "bool") {
            result = 'valueBool';
          } else if (target.categoryMetadata.fieldType == "decimal") {
            result = 'valueDecimal';
          } else if (target.categoryMetadata.fieldType == "numeric") {
            result = 'valueNumeric';
          } else {
            result = 'valueText';
          }
        }
      });
    }

    return result;
  }

  var dropdownItems: MenuProps['items'] = [
    /*
    * deprecated
    {
    key: 1,
    label: <UploadAllianceAssembliesButton type="text" />
    },
    */
    {
      key: 2,
      label: <BatchAssemblyReplacementButtonModal />,
    }
  ];

  if (configurator.isAdmin() || configurator.isEngineering()) {
    dropdownItems.push({
      key: 3,
      label:<Button
        type="text"
        title="Download Weight CSV"
        loading={isExporting}
        onClick={() => handleExportWeight()}
      >
        Export Weight CSV
      </Button>,
    })
  }

  return <div className="site-layout-background">
    <Title level={2}>Assembly List</Title>
    <Spin spinning={isWorking}>
    <Row gutter={12} style={{marginBottom: "1rem"}}>
      <Col>
        <Dropdown.Button trigger={["click"]}
          type="primary"
          menu={{items: dropdownItems}}
          loading={isExporting}
          placement="bottom"
        >
          Options
        </Dropdown.Button>
      </Col>
      {canWrite &&
      <Col>
        <NewAssemblyModal/>
      </Col>}
    </Row>
    <Row style={{marginBottom: "1rem"}} justify={"space-between"} gutter={[8, 16]}>
      <Col>
      <AssemblyFilter
        categoryId={categoryFilter?.id}
        dataFilter={filterOptionsRequest?.filterQuery}
        hideObsolete={filterOptionsRequest?.obsolete === false ? true : undefined }
        updateFilter={onChangeAssemblyFilter}
      />
      </Col>
      <Col>
        {/* <Button type="primary" onClick={resetFilterAndCategory} style={{marginRight: "10px"}} >Reset Category</Button> */}
        <Button type="primary" onClick={resetSorting} style={{marginRight: "10px"}} >Reset Sorting/Column Filter</Button>
        <SaveFilter tableName={ASSEMBLY_LIST}/>
      </Col>
    </Row>

    <AssembliesTable
      dataSource={assemblies}
      tableOnChange={tableOnChange}
      pagination={pagination}
      filterOptions={filterOptions}
      categoryFilter={categoryFilter}
      loading={assembliesAsync.isLoading() || !assembliesAsync.isDone()}
      getSortingOrder={getSortingOrder}
      filteredInfo={filteredInfo}
      metadataValueSorting={metadataValueSorting}
    />
    </Spin>
  </div>
                                                       
};


export default Assemblies;
