import { Select, Spin, notification } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { EpicorCustomer } from "../../api/models";
import { debounce } from "lodash";
import { ConfiguratorContext } from "../../context";

export interface Props {
    onSelect?: (customer: (EpicorCustomer|undefined)) => void;
    initialValue?: EpicorCustomer;
}

const EpicorCustomerFilter = (props: Props) => {
    const configurator = useContext(ConfiguratorContext);
    const [selectedCustomer, setSelectedCustomer] = useState<EpicorCustomer|undefined>(props.initialValue||undefined);
    const [customers, setCustomers] = useState<EpicorCustomer[] | undefined>(props.initialValue ? [props.initialValue] : []);

    useEffect(() => {
        setSelectedCustomer(props.initialValue||undefined);
    }, [props.initialValue]);

    const handleSearch = useCallback(debounce(async (newValue: string) => {
        if (newValue.length < 2) {
            setCustomers([]);
            return;
        }
        try {
            setCustomers(undefined);
            const results = await configurator.api.getEpicorCustomers(newValue);
            setCustomers(results.customers);
        }
        catch (e) {
            notification['error']({
                message: 'Failed to fetch customers',
                description: 'Unable to fetch customers from Epicor at this time.'
            });
            setCustomers([]);
        }
    }, 750), []);

    const handleChange = (newValue: string) => {
        if(props.onSelect) props.onSelect(customers?.find(c => c.custID == newValue));
    };

    const getLabel = (customer: EpicorCustomer) => {
        var label = customer.name;

        if(customer.address1) label += '\n' + customer.address1;
        if(customer.address2) label += '\n' + customer.address2;
        if(customer.address3) label += '\n' + customer.address3;

        if(customer.city?.length > 0 && customer.state?.length > 0 && customer.zip?.length > 0) {
            label += '\n' + (customer.city + ', ' + customer.state + ' ' + customer.zip);
        }

        return label;
    };

    return (
        <Select
            style={{ width: '100%' }}
            showSearch
            value={selectedCustomer?.custID}
            placeholder="Search by Epicor customer name"
            showArrow={false}
            allowClear={true}
            filterOption={false}
            onSearch={handleSearch}
            options={customers?.map((d) => ({
                value: d.custID,
                label: getLabel(d),
              }))}
            notFoundContent={!customers ? <Spin size="small" /> : null}
            onChange={handleChange}
        />
    );
};

export default EpicorCustomerFilter;
