import { Select, notification } from "antd";
import { RuleSet } from "../../api/models";
import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../context";

export interface Props {
    selectedRuleSetId?: number;
    onSelect: (RuleSet) => void;
}


const RuleSetSelector = (props: Props) => {
    const [ruleSets, setRuleSets] = useState<RuleSet[]>();
    const configurator = useContext(ConfiguratorContext);

    useEffect(() => {
        loadRuleSets();
    }, []);

    async function loadRuleSets() {
        try {
            setRuleSets(undefined);
            const resp = await configurator.api.listRuleSets(0, Number.MAX_SAFE_INTEGER);
            setRuleSets(resp.content);
        }
        catch (e) {
            notification.error({ message: 'Failed to load rule sets.' });
        }
    }

    function onChange(val: number) {
        const ruleSet = ruleSets?.find(rs => rs.id == val);
        if (ruleSet) props.onSelect(ruleSet);
    }

    return (
        <>
            <Select style={{ width: '100%' }} value={props.selectedRuleSetId} onChange={onChange} loading={!ruleSets} allowClear showSearch filterOption={(input, option) => {
                return (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase());
            }}>
                {ruleSets?.map((rs, idx) => (
                    <Select.Option key={rs.id} value={rs.id}>{rs.name}</Select.Option>
                ))}
            </Select>
        </>
    );
};

export default RuleSetSelector;
