import { Form, FormInstance } from "antd";
import {ValidateFields} from "rc-field-form/lib/interface";

const useDateTypeAndRangeHook = (form: FormInstance, dateTypeName: string, dateRangeName: string, 
  onFilterChange: (changedValues: Record<string, any>, allValues: any) => void) => {

  const dateFilterTypeWatch = Form.useWatch(dateTypeName, form);
  const dateRangeWatch = Form.useWatch(dateRangeName, form);

  const validateDateFilterType = (_, value) => {
    if ( (dateRangeWatch && !value) || ((dateFilterTypeWatch && !value)) ) {
      return Promise.reject(new Error('Please select date filter type'));
    }
    return Promise.resolve();
  };

  const validateRangePicker = (_, value) => {
    if ( (dateFilterTypeWatch && !value) || (dateRangeWatch && !value)) {
      return Promise.reject(new Error('Please select date range'));
    }
    return Promise.resolve();
  };

  const filterChange = async (changedValues: Record<string, any>, allValues: any) => {
    await handleValidateForm();
    const existBothFilterAndRange = !!allValues["dateRange"] && !!allValues["dateFilterType"];
    const missBothFilterAndRange = !allValues["dateRange"] && !allValues["dateFilterType"];
    if ((allValues && (existBothFilterAndRange || missBothFilterAndRange))) {
      onFilterChange(changedValues, allValues);
    }
  }

  const handleValidateForm = async () : Promise<ValidateFields | undefined> => {
    try {
      await form.validateFields();
    }
    catch(e:any) {

    }
    return;
  }

  return {
    validateDateFilterType,
    validateRangePicker,
    filterChange,
  }

}

export default useDateTypeAndRangeHook;
