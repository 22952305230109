import {
  Button,
  Checkbox,
  Form,
  Input,
  List,
  Popconfirm,
  Select,
  Spin,
  Tabs,
  Upload,
  message,
  Row,
  Image,
  Col,
  notification,
} from "antd";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ModelYearSelector from "../components/model_year_selector";
import { ConfiguratorContext } from "../context";
import Utils from "../util/util";
import {AssemblyInfo, BaseCategory, Category, Dealer, MISSING_IMAGE, ModelWithBasicAssemblyInfo, Permission} from "../api/models";
import ColorPicker from "../components/ColorPicker";
import {useAsyncState} from "../hook/useAsyncState";
import { InboxOutlined } from "@ant-design/icons";
import {useIntl} from "react-intl";


enum UPLOADING_STATUS {
  INIT = "init",
  ERROR = "error",
  DONE = "done",
  UPLOADING = "uploading"
}

interface CategoryIdAssemblyInfoMap {
  [categoryId: string]: AssemblyInfo[];
}

const ViewModel = () => {
  const params = useParams<any>();
  const [model, modelAsync] = useAsyncState<ModelWithBasicAssemblyInfo>();
  const configurator = useContext(ConfiguratorContext);
  const [categories, categoriesAsync] = useAsyncState<BaseCategory[]>();
  const [assemblies, assembliesAsync] = useAsyncState<CategoryIdAssemblyInfoMap>();
  const [uploadFile, setUploadFile] = useState<{status:string, response?:string}>({status: UPLOADING_STATUS.INIT});
  const [form] = Form.useForm();
  const imageUrl = Form.useWatch('imageUrl', form);
  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);
  const [selectedCategories, setSelectedCategories] = useState<BaseCategory[] | undefined>();
  const [selectedModelCategories, setSelectedModelCategories] = useState<BaseCategory[] | undefined>();
  const [dealerLst, dealerLstAsync] = useAsyncState<Dealer[]>();
  const intl = useIntl();

  useEffect(()=> {
      loadDealers();
  }, [] );

  useEffect(()=> {
    setSelectedCategories(undefined);
    setSelectedModelCategories(undefined);
    if (model) {
      const values = toFormValues(model);
      form.setFieldsValue(values);
    }
  }, [model] );

  type ModelFormValues = Omit<ModelWithBasicAssemblyInfo, 'currentModelYear'>  & {
    currentModelYear: number;
  }

  const toFormValues = (model: ModelWithBasicAssemblyInfo): ModelFormValues => {

    const categoryIdAssemblyIdMap = model.assemblies.reduce( (acc, asm) => {
      const key = `assemblies[${asm.categoryId}]`;
      acc[ key  ] =  ( acc[ key ] || [] ).concat( asm.id );
      return acc;
    }, {} );

    const categoryIdBaseAssemblyIdMap = model.baseAssemblies.reduce( (acc, asm) => {
      const key = `base_assemblies[${asm.categoryId}]`;
      acc[ key  ] =  ( acc[ key ] || [] ).concat( asm.id );
      return acc;
    }, {} );

    const formValues = {
      ...model,
      currentModelYear: model?.currentModelYear?.year,
      selectedSupportedCategories: undefined,
      selectedModelCategories: undefined,
      ...categoryIdAssemblyIdMap,
      ...categoryIdBaseAssemblyIdMap, 
    };

    return formValues;
  }

  useEffect(()=> {
    if( uploadFile.status == UPLOADING_STATUS.DONE ) {
          message.success( "Upload complete." );
    }
    else if( uploadFile.status == UPLOADING_STATUS.ERROR ) {
          message.error(  "There was an error uploading the image.");
    }

  }, [ uploadFile.status] )

  const onUploadChange = ({file}) => {
    setUploadFile( file );
    return file.response;
  };

  const isUploading = UPLOADING_STATUS.UPLOADING == uploadFile.status;
  const uploadImageUrl = model?.id ? configurator.api.getUploadModelImageUrl( String( model?.id ) ) : "";

  useEffect(() => {
    loadModel(params.id);
  }, [params.id]);

  const loadModel = (id:string) => {

    modelAsync.setLoading() ;
    configurator.api.getModelWithBasicAssembly(Number(id), false)
    .then( resp => { 
      const model = resp.data
      modelAsync.setDone(model);
    })
    .catch( err => {
      const errorMsg = intl.formatMessage({ id: err.message });
      notification.error( { message: "Failed  to load model. " + errorMsg });
      modelAsync.setFail(err.message);
    });

    assembliesAsync.setLoading() ;
    configurator.api.getImportedAssemblies()
    .then( resp => {

      const asms = resp.data.content;
      const asmBySection = asms.reduce((acc: CategoryIdAssemblyInfoMap, asm: AssemblyInfo) => {
        var categoryId = asm.categoryId;
        acc[ categoryId ] =  ( acc[ categoryId ] || [] ).concat( asm );
        return acc;
      }, {});
      assembliesAsync.setDone( asmBySection ) ;
    })
    .catch( err => {
      const errorMsg = intl.formatMessage({ id: err.message });
      notification.error( { message: "Failed to load assemblies. " + errorMsg });
      assembliesAsync.setFail(err.message);
    });

    loadCategories();

  };

  const loadCategories = () => {
    categoriesAsync.setLoading();
    configurator.api.getBasicCategories().then( resp => {
      const c = resp.data.sort( (a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()) );
      categoriesAsync.setDone( c );
    })
    .catch( err => {
      const errorMsg = intl.formatMessage({ id: err.message });
      notification.error( { message: "Failed  to load categories. " + errorMsg });
      categoriesAsync.setFail(err.message);
    });
  }

  const loadDealers = () => {
    dealerLstAsync.setLoading();
    return configurator.api.getDealers()
      .then( resp => dealerLstAsync.setDone( resp ),
        (e) =>  {
            const errorMsg = intl.formatMessage({ id: e.message });
            notification.error( { message: "Failed load dealers. " + errorMsg });
            dealerLstAsync.setFail(e.message);
          });
  }


  const assemblyFilter = (cat) => {
    return (inputValue: string, option: {key: string, value: number} | undefined) => {
      const query = inputValue.toLowerCase();
      const asm = getAssemblies( cat.id ).filter(a => a.id === option?.value)[0];

      if (
        asm?.bom?.toLowerCase().includes(query) ||
        asm?.bomDescription?.toLowerCase().includes(query)
      ) {
        return true;
      }
      return false;
    };
  };

  const removeExistingAssemblyInCategory = (key: string, assemblyIdList: number[]): number[] => {
    const categoryId = key.match(/\[(.*?)\]/)?.[1];
    if (categoryId) {
      const assembliesToBeRemoved = model?.assemblies?.filter(asm => asm.categoryId.toString() === categoryId).map(asm => asm.id);
      assemblyIdList = assemblyIdList.filter(asm => !assembliesToBeRemoved?.includes(asm))
    }
    return assemblyIdList;
  }

  const onSubmitForm = async (values: any) => {

    let existingAssemblyIds = model?.assemblies.map(asm => asm.id) || [];
    let existingBaseAssemblyIds = model?.baseAssemblies.map(asm => asm.id) || [];

    const [ newAssemblyIdList, newBaseAssemblyIdList ] = Object.keys( values )
      .reduce( (acc:Array<Array<number>>, key) => {
        let [ assemblyIdList, baseAssemblyIdList ] = acc;
        const val = values[ key ];

        if (val && key.startsWith("assemblies[")) {
          assemblyIdList = [...removeExistingAssemblyInCategory(key, assemblyIdList)];
          assemblyIdList.push(val);
        }
        if (val && key.startsWith("base_assemblies[")) {
          baseAssemblyIdList = [...removeExistingAssemblyInCategory(key, baseAssemblyIdList)];
          baseAssemblyIdList.push(val);
        }

        return [ assemblyIdList, baseAssemblyIdList ];
      }, [ existingAssemblyIds, existingBaseAssemblyIds ])
      .map(v => v.flat() )

    if ( model ) {
      try {
        modelAsync.setLoading() ;
        await configurator.api.updateModel(model.id, {
          ...values,
          assemblies: newAssemblyIdList,
          baseModelAssemblies: newBaseAssemblyIdList,
        });
        message.success( "Model successfully updated." );
        loadModel(params.id);
      } catch (e) {
        console.log(e);
        const msg = "Failed to update model at this time.";
        message.error( msg );
        modelAsync.setFail(msg);
      }
    }
  };

  const onClickDelete = async () => {
    try {
      modelAsync.setLoading() ;
      await configurator.api.deleteModel(model?.id);
      window.location.href = "/models";
    } catch (e) {
        const msg = "Failed to delete model at this time.";
        message.error( msg );
        modelAsync.setFail(msg);
    }
  };


  const getAssemblies = (categoryId: number | undefined): AssemblyInfo[] => {

    if (categoryId && assemblies && assemblies[categoryId]) {
      return assemblies[categoryId];
    }
    return [];
  }

  const getItemsToUpdate = (categoryIdList: string[]): BaseCategory[] => {
    const items: BaseCategory[] = [];
    categoryIdList?.forEach(id => {
      const it = categories?.find(c => (c.id.toString() === id));
      if (it) {
        items.push(it);
      }
    });
    return items;
  }

  const onValuesChange = (_value: any, allValues: {selectedSupportedCategories: string[]; selectedModelCategories: string[]}) => {
    setSelectedCategories(getItemsToUpdate(allValues.selectedSupportedCategories));
    setSelectedModelCategories(getItemsToUpdate(allValues.selectedModelCategories));
  }

  const isTabsLoading = !modelAsync.isLoading() && ( assembliesAsync.isLoading() || categoriesAsync.isLoading() );

  return (
    <div className="site-layout-background">
        <Spin spinning={isTabsLoading} size="large" tip="Loading Model">
          <Title level={2}>{model?.name || "Model Name" }</Title>
          <Form form={form} onFinish={onSubmitForm} onValuesChange={onValuesChange} >
            <Row gutter={[48, 8]}>
              <Col span={12}>
                {model?.inheritModel != null && (
                  <div>
                    <strong>Inherits from Model: </strong>
                    <Link to={"/models/" + model?.inheritModel.id}>
                      {model?.inheritModel.name}
                    </Link>
                  </div>
                )}

                <Form.Item
                  name="name"
                  label="Model Name" >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="inactive"
                  label="Inactive"
                  valuePropName="checked" >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="disableRules"
                  label="Disable Rules"
                  valuePropName="checked" >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="erpHasCabPart"
                  label="Export CAB part in Epicor"
                  valuePropName="checked" >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="visibleInWizard"
                  label="Visible in Wizard"
                  valuePropName="checked" >
                  <Checkbox />
                </Form.Item>

              </Col>
              <Col span={12}>
                <Spin spinning={isUploading}>
                  <Form.Item
                    name="imageUrl"
                    getValueFromEvent={onUploadChange}
                    
                    noStyle
                  >
                    <Upload.Dragger 
                      name="image"
                      action={uploadImageUrl}
                      withCredentials={true}
                      showUploadList={false}
                      disabled={!canWrite}
                      maxCount={1}
                      height={250}
                      accept=".gif,.png,.jpg" >
                      { 
                      <>
                        {imageUrl ? 
                          <Image
                            src={imageUrl ||  "error"}
                            fallback={MISSING_IMAGE}
                            style={{paddingBottom: "20px"}}
                            preview={false}
                            height={200}
                          />
                          : 
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined  />
                          </p>
                        }
                        <p className="ant-upload-text">{!canWrite ? "You need permission to upload image" : "Click or drag file to upload"}</p>
                      </>}
                    </Upload.Dragger>
                  </Form.Item>
                </Spin>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Dealer Exclusive"
                  name="dealerExclusives"
                >
                  <Select
                    loading={dealerLstAsync.isLoading()}
                    showSearch={true}
                    mode="multiple"
                    optionFilterProp="label"
                    options={dealerLst?.map(d => ({ label: d.name, value:d.id }))}
                  />
                </Form.Item>

                <Form.Item
                  name="currentModelYear"
                  label="Current Model Year"
                  rules={[{ required: true, message: "Model Year is required" }]} >
                  <ModelYearSelector />
                </Form.Item>

              </Col>

              <Col span={12}>
                <Form.Item
                  name="exportHeaderHexColor"
                  label="Header Color"
                >
                  {/* @ts-ignore */}
                  <ColorPicker placeholder="Type a hex color" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="motorFamily"
                  label="Motor Family">
                  <Select>
                    <Select.Option value="ice">ICE</Select.Option>
                    <Select.Option value="bev">BEV</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}></Col>

              <Col span={12}>
                <Form.Item
                  name="partNumberSuffix"
                  label="Part Number Suffix" >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="operationPrefix"
                  label="Operation ID Prefix (X replacement)" >
                  <Input />
                </Form.Item>

                  <Button type="primary" style={{marginRight: "40px"}}>
                    <a href={configurator.api.baseUrl + '/v1/models/' + model?.id + '/option-prices.csv'}>
                      Download Option Prices CSV
                    </a>
                  </Button>

                  <Popconfirm
                    title="Are you sure you want to delete this model?"
                    onConfirm={onClickDelete}
                  >
                    <Button
                      type="primary"
                      danger
                      disabled={!canWrite}
                      title={!canWrite ? "You need permission to delete model." : ""}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                  &nbsp;
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!canWrite}
                    title={!canWrite ? "You need permission to save model." : ""}
                  >
                    Save
                  </Button>
              </Col>

              <Col span={24}>

              <Tabs defaultActiveKey="assemblies" style={{marginTop: "20px"}}>
                <Tabs.TabPane
                  tab="Supported Assemblies"
                  key="assemblies"
                  forceRender
                >
                  <Form.Item
                    label="Updating Categories"
                    name="selectedSupportedCategories"
                    key={"supported-category"}
                  >
                    <Select
                      mode="tags"
                      showSearch
                      allowClear={true}
                      optionFilterProp="children"
                      placeholder="Please select category to be updated below. "
                    >
                      {categories?.map((c, i) => (
                        <Select.Option key={"category-option-" + i + "-" + c.id} value={c.id.toString()}>
                          {Utils.stripSortingPrefix(c.name)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {selectedCategories?.map(
                    (sc: BaseCategory, id: number) => {
                      return (
                        model?.inheritModel != null ? (
                          <div key={"inherited-supported-div-" + id}>
                            <strong>Inherited: </strong>
                            <List key={"inherited-supported-list" + id}>
                              {model?.inheritModel.assemblies
                                //todo fix me
                                .filter((a) => a.categoryId == sc.id)
                                .map((a) => Utils.getAssemblyLabel(a))
                                .map((l) => (
                                  <List.Item key={"list-item-" + id + "-" + l}>{l}</List.Item>
                                ))}
                            </List>
                          </div>
                        ) : (
                        <Form.Item
                          label={Utils.stripSortingPrefix(sc?.name)}
                          key={id + '-' + sc.id + "-supported"}
                          name={"assemblies[" + sc.id + "]"}
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            allowClear
                            showSearch
                            filterOption={assemblyFilter(sc)}
                            placeholder="Select item"
                            style={{ width: "100%" }}
                            mode="multiple"
                          >
                            {Object.values( getAssemblies( sc.id ) ).map((a: AssemblyInfo, idx) => (
                              <Select.Option key={a.id + "-supported-" + idx} value={a.id}>
                                {`${a.bom} (${Utils.getAssemblyLabel(a)})`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        )
                      );
                    }
                  )}

                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="Model Default"
                  key="base_config"
                  forceRender
                >
                  <Form.Item
                    label="Updating Model Categories"
                    name="selectedModelCategories"
                    key={"model-category"}
                  >
                    <Select
                      mode="tags"
                      showSearch
                      allowClear={true}
                      optionFilterProp="children"
                      placeholder="Please select category to be updated below."
                    >
                      {categories?.map((c, i) => (
                        <Select.Option key={"category-option-model-" + i} value={c.id.toString()}>
                          {Utils.stripSortingPrefix(c.name)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {selectedModelCategories?.map(
                    (sc, id) => {
                      return (
                        <div key={"model-categories-" + sc.id}>
                          {model?.inheritModel != null ? (
                            <div key={"inherited-model-div-" + sc.id}>
                              <strong>Default: </strong>
                              {model?.baseAssemblies
                                .filter((a) => a.categoryId == sc.id)
                                .map((a) => Utils.getAssemblyLabel(a))
                                .join(",")}
                              <br />
                              <br />
                            </div>
                          ) : ("")}
                          <Form.Item
                            label={Utils.stripSortingPrefix(sc?.name)}
                            key={id + '-' + sc.id + "-model"}
                            name={"base_assemblies[" + sc.id + "]"}
                            labelCol={{ span: 24 }}
                          >
                            <Select
                              allowClear
                              showSearch
                              filterOption={assemblyFilter(sc)}
                              placeholder="Select item"
                              style={{ width: "100%" }}
                              mode="multiple"
                            >
                              {Object.values( getAssemblies( sc.id ) ).map((a: AssemblyInfo, idx) => (
                                <Select.Option key={sc.id + "-" + a.id + "-model-" + idx} value={a.id}>
                                  {`${a.bom} (${Utils.getAssemblyLabel(a)})`}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      );
                    }
                  )}

                </Tabs.TabPane>
              </Tabs>
              </Col>
            </Row>
          </Form>
      </Spin>
    </div>
  );
};

export default ViewModel;
