import {Modal, notification, Result} from "antd";
import {useContext} from "react";
import {useIntl} from "react-intl";
import { Quote} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import { useQuoteContext } from "../../contexts/QuoteContext";
import BMButton, {BMButtonProps} from "../BMButton";

const ConvertReservationButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
}) => {

  const { onChange:a, ...btnProps } = props;

  const {quoteAsync} = useQuoteContext();
  const quote = quoteAsync?.val;
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const handleConvertReservation = async (confirm?:boolean) => {

    if (!confirm) {
        Modal.confirm({
          icon:<></>,
          content: <Result 
            status={"warning"}
            title={"Are you sure?"}
            subTitle={"This will convert a production reservation back to a quote for order processing."}
          />,
          onOk() {
            handleConvertReservation(true);
          },
        });
    }
    else {

      const quote = await convertReservation();
      if ( quote ) {
        props.onChange?.(quote);
      }
    }
  }

  const convertReservation = async () : Promise<Quote | undefined> => {
    if ( !quote ) return;

    quoteAsync.setLoading()
    try {
      const resp = await configurator.api.convertReservation(quote.id)
      quoteAsync.setDone(resp.data);
      notification.success({message:"Conversion complete."});

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to convert reservation. " + errorMsg;
      notification.error( { message: msg });
      quoteAsync.setFail(msg);
    }

    return;
  }

  return <>
    <BMButton
      type="primary"
      onClick={() => handleConvertReservation()}
      {...btnProps}
    >
      Convert to Quote
    </BMButton>
  </>
}

export default ConvertReservationButton;
