import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Select, Spin, Table, Tag, notification } from "antd";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useState } from "react";
import { AssemblyInfo, DashTemplate, MISSING_IMAGE, QuoteWithMissingDashComponent, TemplateRelatedQuote } from "../api/models";
import { Link, useHistory } from "react-router-dom";
import { ConfiguratorContext } from "../context";
import { debounce } from "lodash";

const DashTemplateList = (props: any) => {

  const [data, setData] = useState<DashTemplate[]>();
  const history = useHistory();
  const [showNewModal, setShowNewModal] = useState(false);
  const configurator = useContext(ConfiguratorContext);
  const [createDashTemplateForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<undefined | string>();
  const [allDrawingAssemblies, setAllDrawingAssemblies] = useState<AssemblyInfo[]>([]);
  const [descFilter, setDescFilter] = useState<string>('');
  const [dashInstrFilter, setDashInstrFilter] = useState<number[]>();
  const [isExporting, setExporting] = useState(false);

  useEffect(() => {
    getAllDrawings();
  }, [descFilter, dashInstrFilter]);

  useEffect(() => {
    getAllDashTemplates();
  }, [allDrawingAssemblies]);
  
  const getAllDashTemplates = debounce(async () => {
    try {
      setLoading(true);
      const resp = await configurator.api.getDashTemplates(descFilter, dashInstrFilter);
      setData(resp.data.map(template => {
        const assembliesStr = template?.assemblies?.map(asmId => {
          const drawingAssembly = allDrawingAssemblies.find(drw => drw.id === asmId);
          return (drawingAssembly?.bom + ' ' + drawingAssembly?.bomDescription) || '';

        });
        return {...template, assembliesStr: assembliesStr};
      }));
    }
    catch (e) {
      console.log(e);
      notification.error({ message: "Failed to get dash template. " });
    }
    finally {
      setLoading(false);
    }
  }, 800);

  const getAllDrawings = async () => {
    try {
      const resp = await configurator.api.getDashDrawingAssemblies();
      setAllDrawingAssemblies(resp.data);
    }
    catch(e) {
      console.log(e);
    }
  }

  const onClickNew = () => {
    createDashTemplateForm.resetFields();
    setShowNewModal(true);
  };

  const onCancelNewForm = () => {
    setShowNewModal(false);
    setError(undefined);
  }

  const createNewTab = (resp: any) => {
    if (resp?.data?.id) {
      const url = history.createHref({ pathname: `${"/dash-template/" + encodeURIComponent(resp.data.id)}` });
      const newTab = window.open(url, '_blank');
      if (newTab) newTab.focus();
    }
  }

  const onNewDashTemplateSubmit = async (values: DashTemplate) => {
    try {
      setSaving(true);
      const resp = await configurator.api.createDashTemplate(
        {
          id: 0,
          partNumber: values.partNumber,
          description: values.description,
          assemblies: [],
        }
      );

      setSaving(false);
      onCancelNewForm();
      getAllDashTemplates();
      notification.success({ message: " Successfully created new dash template. " });
      createNewTab(resp);
    }
    catch (e) {
      setError(`${e}`);
    }
    finally {
      setSaving(false);
    }

  }

  const exportAllDashTemplates = async () => {

    setExporting( true );
    try {
      const url = configurator.api.getDashTemplateExportUrl();
      await configurator.api.downloadZip(url);
    }
    catch (e) {
      notification["error"]({
        message: "Failed to export templates.",
      });
    }
    setExporting( false );
  };

  const columns = [
    {
      title: "Part Number",
      key: "partNumber",
      width: '20%',
      render: (template: DashTemplate) => (
        <Link to={"/dash-template/" + encodeURIComponent(template.id)}>{template.partNumber}</Link>
      ),
    },
    {
      title: "Description",
      key: "description",
      width: '25%',
      render: (template: DashTemplate) => (
        <span>{template.description}</span>
      ),
    },
    {
      title: "Image",
      key: "image",
      width: '30%',
      render: (template: DashTemplate) => (
        <img
          id='fullImage'
          src={template?.editedImageUrl || template?.imageUrl || MISSING_IMAGE}
          alt=""
          style={{ width: '100%', height: '100%' }}
        />
      ),
    },
    {
      title: 'Dash Instrumentation',
      dataIndex: 'assembliesStr',
      width: '50%',
      render: (_, record: DashTemplate, index: any) => record.assembliesStr?.map((str, idx) => <Tag color={"blue"} key={`${record.id}-${index}-${idx}-tag`}>{str}</Tag>)
    },
  ];

  return (
    <div key='dashTemplate' className="site-layout-background">
      <Title level={2}>Dash Template List</Title>



    <Spin spinning={loading}>
      <Row justify="space-between">
        <Col>
          <Button type="primary" onClick={onClickNew}>Create Dash Template</Button>
        </Col>
        <Row gutter={12}>
          <Col><Button type="primary" loading={isExporting} onClick={() => exportAllDashTemplates()}>Export All Templates</Button></Col>
          <Col><DebugModal/></Col>
        </Row>
      </Row>

      <Row gutter={10} style={{marginTop: "1rem"}}>
        <Col span={12}>
          <Input
            allowClear
            onChange={(e) => {setDescFilter(e.target.value)}}
            value={descFilter}
            placeholder="Search by dash template description..."
          />
        </Col>
        <Col span={12}>
          <Select
              style={{ width: "100%" }}
              placeholder="Search by dash instrumentation..."
              onChange={(value) => setDashInstrFilter(value)}
              mode="multiple"
              allowClear
              optionFilterProp="children"
              showSearch
            >
              {allDrawingAssemblies?.map((asm) => (
                <Select.Option key={asm.id} value={asm.id}>
                  {asm.bom + " - " + asm.bomDescription}
                </Select.Option>
              ))}
            </Select>
          </Col>
      </Row>

      <Divider/>
      <Table
        bordered
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{pageSize: 25}}
      />
    </Spin>
    <Modal
      open={showNewModal}
      title="New Dash Component"
      onOk={() => createDashTemplateForm.submit()}
      onCancel={onCancelNewForm}
      okText={"Submit"}
      width={"30%"}
      confirmLoading={saving}
      closable={!saving}
      cancelButtonProps={{style :{display : 'none'}}}
    >
        <Form
          labelCol={{ span: 6 }}
          form={createDashTemplateForm}
          name="newShippingDestination"
          onFinish={onNewDashTemplateSubmit}
          labelAlign={"right"}
        >
          <Form.Item
            label="Part Number"
            name="partNumber"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Description is required" }]}
          >
            <Input />
          </Form.Item>
        </Form>
        {error && <Alert type="error" message={"Submission Failed"}></Alert>}
    </Modal>
    </div>
  );
}

export default DashTemplateList;


const DebugModal = (props: any) => {

  const [showDebugModal, setShowDebugModel] = useState(false);
  const configurator = useContext(ConfiguratorContext);
  const [templateQuoteData, setTemplateQuoteData] = useState<TemplateRelatedQuote[]>();
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateRelatedQuote>();
  const [debugData, setDebugData] = useState<QuoteWithMissingDashComponent[]>([]);
  const [showDebugTable, setShowdebugTable] = useState(false);
  const [debugLoading, setDebugLoading] = useState(false);

  useEffect(() => {
    if (showDebugModal) {
      getRelatedQuotesOnTemplate();
    }
  }, [showDebugModal]);

  const columns = [
    {
      title: 'Part Number',
      dataIndex: 'partNumber',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: "50%"
    },
    {
      title: 'Related Quote Quantity',
      dataIndex: 'quantity',
    }
  ];

  const debugColumns = [
    {
      title: 'Quote ID',
      key: 'quoteId',
      width: "15%",
      render: (quote: QuoteWithMissingDashComponent) => (
        <span key={quote.id + "-quoteId"}><Link to={"/configurator/" + encodeURI(quote.quoteId)}>{quote.quoteId}</Link></span>
      ),
    },
    {
      title: 'Quote Name',
      key: 'quoteName',
      render: (quote: QuoteWithMissingDashComponent) => (
        <span key={quote.id + "-quoteName"}>{quote.quoteName}</span>
      ),
    },
    {
      title: 'Missing Dash Components Assignment',
      key: 'missingComponents',
      width: "40%",
      render: (quote: QuoteWithMissingDashComponent) => (
        quote.unassignedComponents.map(com =>
          <div key={quote.quoteId + "-" + com.component}>{com.component + " Qty: " + com.quantity + ", "}<br /></div>
        )
      ),
    },
  ];

  const getRelatedQuotesOnTemplate = async () => {
    setLoading(true);
    try {
      const resp = await configurator.api.getDashTemplateRelatedQuotes();
      if (resp) {
        setTemplateQuoteData(resp.data);
      }
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLoading(false);
    }
  }

  const onDebug = async () => {
    setDebugLoading(true);
    try {
      const resp = await configurator.api.getQuoteWithMissingDashComponent(selectedTemplate?.id || '');
      if (resp) {
        setDebugData(resp.data);
        setShowdebugTable(true);
      }
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setDebugLoading(false);
    }
  };

  const rowSelection = {
    onChange: (_: any, b: TemplateRelatedQuote[]) => {
      setSelectedTemplate(b[0]);
    }
  };

  const onCancel = () => {
    setDebugData([]);
    setShowDebugModel(false);
    setShowdebugTable(false);
  };

  const goBack = () => {
    setShowdebugTable(false);
    setSelectedTemplate(templateQuoteData?.[0])
  }

  return (
    <>
      <Button type="primary" onClick={() => setShowDebugModel(true)}>Debug Template</Button>

      <Modal
        open={showDebugModal}
        onCancel={() => onCancel()}
        maskClosable={true}
        width={showDebugTable ? "80rem" : "50rem"}
        okText={"Debug"}
        onOk={onDebug}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: (loading || showDebugTable)? 'none' : ''}}}
        centered
        closable={!showDebugTable}
      >
        {loading ? 
          <Spin/> :
          <div key="debug-section">
            {!showDebugTable &&
            <div key={"select-template"}>
              <p>Please select the template to debug. </p>
              <p style={{color: 'red'}}>Generation process may take long based on the related quote quantity.</p>
              <br/>
              <Table
                rowSelection={{
                  type: 'radio',
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={templateQuoteData?.map(t => {return {...t, key: t.id};})}
                pagination={false}
                bordered
              />
              <br/>
            </div>}
            {debugLoading ? <Spin/> :
              ( showDebugTable &&
              <div key={"debug-table"}>
                <br/>
                <Row justify={"space-between"}>
                  <p style={{fontSize: "20px"}}><strong>Debug result to {selectedTemplate?.partNumber}</strong></p>
                  <Button onClick={goBack}>Go Back</Button>
                </Row>
                <br/>
                <Table
                  columns={debugColumns}
                  dataSource={debugData}
                  pagination={false}
                  bordered
                  rowKey={"id"}
                />
                <br/>
                <Row justify={"end"}>
                  <Button onClick={goBack}>Go Back</Button>
                </Row>
              </div>
              )
            }
          </div>
        }
      </Modal>
    </>
  )
}
