import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext } from "../context";
import { ApproverRole, QuoteStatus, WorkflowDto, WorkflowRequest, WorkflowStepDto } from "../api/models";
import { Alert, Button, Col, Divider, Form, FormListFieldData, Input, Modal, Row, Select, StepProps, Steps, Tooltip, notification } from "antd";
import { useIntl } from "react-intl";
import Utils from "../util/util";
import { useForm } from "antd/es/form/Form";
import {ArrowUpOutlined, PlusCircleOutlined, MenuOutlined, DeleteOutlined, InfoOutlined, LeftOutlined} from "@ant-design/icons";
import {closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import { CancelTokenSource } from "axios";

const Workflow = (props: any) => {

  const configurator = useContext(ConfiguratorContext);
  const [allWorkflows, setAllWorkflows] = useState<WorkflowDto[] | undefined>([]);
  const intl = useIntl();
  const cancelLoadWorkflowTokenSourceRef = useRef<CancelTokenSource>();
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | undefined>();
  const [showAll, setShowAll] = useState<boolean>(false);

  const getAllWorkflows = async () => {
    try {
      const resp = await Utils.executeWithCancelToken(cancelLoadWorkflowTokenSourceRef, (token) =>
        configurator.api.getAllWorkflows(token)
      );
      setAllWorkflows(resp?.data);
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to get workflows. " + errorMsg });
    }
  }

  useEffect(() => {
    getAllWorkflows();
  }, []);

  const getEndStatus = (workflow: WorkflowDto): string | undefined => {
    if (workflow.name === "Quote Approval" || workflow.name === "Concession Quote Approval") {
      return intl.formatMessage({ id: "status.approvedQuote", defaultMessage: "approvedQuote" });
    }
    return workflow.endStatus;
  }

  const getItems = (workflow: WorkflowDto, selectedId: number | undefined, edit: boolean, showButton: boolean) => {
    let items: StepProps[] = workflow.workflowSteps.map((step, idx) => {
      return {
        title: <>
          {showButton && <EditAndInsertModal 
            getAllWorkflows={getAllWorkflows}
            edit={edit}
            selectedId={undefined}
            getItems={getItems}
            workflow={workflow}
            step={step}
            allWorkflows={allWorkflows}
          />}
          <span style={{ fontSize: "13px" }}>{step.name}</span>
        </>,
        status: "finish",
        subTitle: <>
        <span>{"Approver: " + Utils.snakeCaseToFirstLetterCapitalized(step.approverRole)}</span>
        {selectedId && step.id === selectedId && <div><ArrowUpOutlined style={{color: "#1677ff", fontSize: "30px"}} /></div>}
        </>,
      };
    }) || [];
    const triggerStatus = Utils.snakeCaseToFirstLetterCapitalized(workflow.triggerStatus);
    const endStatus = Utils.snakeCaseToFirstLetterCapitalized(getEndStatus(workflow));
    items.unshift({ title: <span style={{ fontSize: "13px" }}>{triggerStatus}</span>, status: "finish" });
    items.push({ title: <span style={{ fontSize: "13px" }}>{endStatus}</span>, status: "finish" });
    return items;
  }

  const handleWorkflowChange = (value: number) => {
    setSelectedWorkflowId(value);
  };

  return (
    <>
      <style>
        {`
          .ant-steps-item-content {
            width: 200px !important;
            margin-left: -45px; 
          }

          .ant-steps-item-subtitle {
            width: 200px !important;
            margin-left: -45px; 
          }
        `}
      </style>
      <div className="site-layout-background">
        <Title level={2}>Workflow</Title>

        <Row justify={"space-between"} style={{ marginBottom: "1rem" }}>
          <Col>
            <Select
              placeholder="Select a Workflow"
              onChange={handleWorkflowChange}
              style={{ width: 300 }}
              options={allWorkflows?.sort(w => w.id).map((workflow) => ({
                label: workflow.name,
                value: workflow.id,
              }))}
              allowClear
            />
          </Col>
          <Col>
            <Row gutter={24}>
              <Col>
                <Button type={"primary"} onClick={() => {setShowAll(!showAll)}}>{showAll ? "Hide All" : "Show All"}</Button>
              </Col>
              <Col>
                <CreateModal getAllWorkflows={getAllWorkflows} />
              </Col>
            </Row>
          </Col>
        </Row>
          
        {allWorkflows?.filter((wf) => wf.id === selectedWorkflowId || showAll)
        .map(wf => 
          <div key={wf.id} style={{marginTop: "6rem"}}>
            <Row justify={"center"}>
              <Title level={4}>{wf.name}</Title>
              <EditWorkflowNotes workflow={wf} reloadWorkflow={getAllWorkflows}/>
            </Row>
            <EditAndInsertModal 
              getAllWorkflows={getAllWorkflows}
              edit={false}
              selectedId={undefined}
              getItems={getItems}
              workflow={wf}
              allWorkflows={allWorkflows}
            />
            <Steps 
              items={
                getItems(wf, undefined, true, true)
              }
              style={{marginTop: "1rem", marginLeft: "1.5rem"}}
              size="default"
              responsive={true}
              labelPlacement="vertical"
            />
            <Divider/>
          </div>)}
      </div>
    </>
    );
}

export default Workflow;


const EditAndInsertModal = (props: {
  getAllWorkflows: () => void
  edit: boolean
  selectedId: number | undefined
  getItems: (workflow: WorkflowDto, selectedId: number | undefined, edit: boolean, showButton: boolean) => StepProps[]
  workflow: WorkflowDto
  step?: WorkflowStepDto | undefined
  allWorkflows: WorkflowDto[] | undefined
}) => {

  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState<string[] | undefined>(undefined);
  const {getAllWorkflows, edit, selectedId, getItems, workflow, step, allWorkflows} = props;
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const isAdminOrEngineeringOrSalesdesk = configurator.isAdmin() || configurator.isEngineering() || configurator.isSalesDesk();
  const [form] = useForm();
  const allApprovers = Object.values(ApproverRole).map(r => String(r)).filter(r => r !== "SALES");
  const [stepId, setStepId] = useState<number | undefined>(undefined);
  const [currentWorkflow, setCurrentWorkflow] = useState<WorkflowDto | undefined>(undefined);

  useEffect(() => {
    if (stepId) {
      setCurrentWorkflow(allWorkflows?.filter(w => w.workflowSteps.map(s => s.id).includes(stepId))[0]);
    }
  }, [stepId]);

  const onEdit = (workflow: WorkflowDto, step: WorkflowStepDto | undefined) => {
    if (!step || !workflow) return;

    setStepId(step.id);
    const allSteps = workflow.workflowSteps;
    const triggerStatus = Utils.snakeCaseToFirstLetterCapitalized(workflow.triggerStatus);
    setSteps([triggerStatus, ...allSteps.map(s => s.name)]);

    const priorStepIndex = step.stepOrder - 1;

    const priorStepName = priorStepIndex ? allSteps.filter(s => s.stepOrder === priorStepIndex)[0].name : triggerStatus;
    const currentStep = {...step, priorStepName}

    form.setFieldsValue(currentStep);
    setOpen(true);
  }

  
  const onInsert = (workflow: WorkflowDto) => {
    const allSteps = workflow.workflowSteps;
    const triggerStatus = Utils.snakeCaseToFirstLetterCapitalized(workflow.triggerStatus);
    setSteps([triggerStatus, ...allSteps.map(s => s.name)]);
    setCurrentWorkflow(workflow);
    form.resetFields();
    setOpen(true);
  }


  const onUpdateStep = async () => {
    if (!stepId) return;
    const object = {...form.getFieldsValue(), id: stepId};
    try {
      await form.validateFields();
      await configurator.api.updateWorkflowStep(stepId, object);
      notification.success({message: "Successfully updated workflow step."})
      getAllWorkflows();
      onCancel();
    }
    catch(e: any) {
      if (! (e.response?.data.message || e.message) ) return;
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to update workflow step. " + errorMsg });
    }
  }

  const onInsertStep = async () => {
    if (stepId || !currentWorkflow) return;
    const object = {...form.getFieldsValue()};
    try {
      await form.validateFields();
      await configurator.api.insertWorkflowStep(currentWorkflow.id, object);
      notification.success({message: "Successfully insert workflow step."})
      getAllWorkflows();
      onCancel();
    }
    catch(e: any) {
      if (! (e.response?.data.message || e.message) ) return;
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to insert workflow step. " + errorMsg });
    }
  }

  const onCancel = () => {
    setStepId(undefined);
    setCurrentWorkflow(undefined);
    form.resetFields();
    setOpen(false);
  }

  const showSelectedStep = (workflow: WorkflowDto | undefined) => {
    if (!workflow) return [];
    return getItems(workflow, stepId, edit, false);
  }

  return (
  <>
    {edit?
      !selectedId && !currentWorkflow && <div key={step?.id + "-edit"}><Button type="primary" size="small" disabled={!isAdminOrEngineeringOrSalesdesk} onClick={() => onEdit(workflow, step)}>Edit Step</Button></div>
      :
      <Row justify={"center"}><Button type="primary" size="small" disabled={!isAdminOrEngineeringOrSalesdesk} onClick={() => onInsert(workflow)}>Insert Step</Button></Row>
    }
    <Modal
      open={open}
      title={!stepId ? "Insert Workflow Step": "Edit Workflow Step"}
      onCancel={onCancel}
      okText={stepId ? "Save" : "Insert"}
      onOk={stepId ? onUpdateStep : onInsertStep}
      cancelButtonProps={{style: {display: "none"}}}
      width={"85rem"}
    >
      <Steps 
        items={
          showSelectedStep(currentWorkflow)
        }
        style={{marginTop: "1rem"}}
        size="default"
        responsive={true}
        labelPlacement="vertical"
      />
      <Divider/>
      <Alert type="warning" style={{marginBottom: ".5rem", fontWeight: "bold", color: "red"}} message={`Updated workflow will be applied in the upcoming approval. Please review change with all related parties before update.`}/>
      <Form
        form={form}
        labelCol={{ flex: '8rem' }}
        labelAlign="left"
        labelWrap
      >
        <Form.Item 
          label="Name"
          name="name"
          rules={[{
            required: true,
            message: `Step name is required.`,
          }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item 
          label="Note"
          name="notificationNote"
          rules={[{
            required: true,
            message: `Note is required. It will be send to the approver in approval notification.`,
          }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item 
          label="Approver"
          name="approverRole"
          rules={[{
            required: true,
            message: `Approver is required.`,
          }]}
        >
          <Select
            options={allApprovers?.map((value) => ({
              label: Utils.snakeCaseToFirstLetterCapitalized(value),
              value: value,
            }))}
          />
        </Form.Item>
        <Form.Item 
          label="Make it after this step"
          name="priorStepName"
          rules={[{
            required: true,
            message: `Prior step is required.`,
          }]}
        >
          <Select
            options={steps?.map((value) => ({
              value: value,
              label: value,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  </>
  );
}

const CreateModal = (props: {
  getAllWorkflows: () => void
}) => {
  const configurator = useContext(ConfiguratorContext);
  const {getAllWorkflows} = props;
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [createForm] = useForm();
  const isAdminOrEngineeringOrSalesdesk = configurator.isAdmin() || configurator.isEngineering() || configurator.isSalesDesk();

  const onCancel = () => {
    setOpen(false)
    createForm.resetFields();
  }

  const onSumbitCreation = async () => {
    try {
      const obj = await createForm.validateFields();
      await configurator.api.createWorkflow(obj)
      getAllWorkflows();
      notification.success({message: "Successfully create new workflow!"});
      onCancel();
    }
    catch(e: any) {
      if (! (e.response?.data.message || e.message) ) return;
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to create workflow. " + errorMsg });
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleDragEnd = (fields:FormListFieldData[], move: (from: number, to: number) => void ) => {

    return (event:DragEndEvent) => {
      const {active, over} = event;

      if ( !over ) return;

      if (active.id === over.id) return;

      const oldIndex = fields.findIndex(f => f.key == active.id);
      const newIndex = fields.findIndex(f => f.key == over.id);
      move( oldIndex, newIndex );
    }
  }


  return (
    <>
      <Row justify={"center"}><Button type="primary" disabled={!isAdminOrEngineeringOrSalesdesk} onClick={() => setOpen(true)}>Create New Workflow</Button></Row>
      <Modal
        open={open}
        title={"Create New Workflow"}
        onCancel={onCancel}
        okText="Create"
        onOk={onSumbitCreation}
        cancelButtonProps={{style: {display: "none"}}}
        width={"60rem"}
      >
        <Alert type="warning" style={{marginBottom: ".5rem", fontWeight: "bold", color: "red"}} 
          message={`Please contact developer for new process triggering situation.`}
        />
        <Form form={createForm} layout={"vertical"}>
          <Form.Item 
            label="Name"
            name="name"
            rules={[{
              required: true,
              message: `Step name is required.`,
            }]}
          >
            <Input/>
          </Form.Item>
          <Form.Item 
            label="Trigger Status"
            name="triggerStatus"
            rules={[{
              required: true,
              message: `Trigger Status is required.`,
            }]}
          >
            <Select
              options={Object.values(QuoteStatus).map((value) => ({
                label: Utils.snakeCaseToFirstLetterCapitalized(value),
                value: value,
              }))}
            />
          </Form.Item>
          <Form.Item 
            label="End Status"
            name="endStatus"
            rules={[{
              required: true,
              message: `End status is required.`,
            }]}
          >
            <Select
              options={Object.values(QuoteStatus).map((value) => ({
                label: Utils.snakeCaseToFirstLetterCapitalized(value),
                value: value,
              }))}
            />
          </Form.Item>
          <Form.Item 
            label="Notes"
            name="notes"
          >
            <Input/>
          </Form.Item>

          <Form.List name="workflowSteps" >
            {(fields, { add, remove, move }) => <>
              <Title level={5} style={{color: "#1890ff"}}>
                Steps
                <PlusCircleOutlined  onClick={(_e) => add({}, fields.length )} style={{paddingLeft: ".5rem", color: "#1890ff"}}  />
              </Title>
              <DndContext 
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd(fields, move)}
              >
                <SortableContext 
                  items={fields.map(f => f.key.toString())}
                  strategy={verticalListSortingStrategy}
                >
                  {fields.map((f, fieldNdx) => 
                  <SortableItem key={f.key.toString()} id={f.key.toString()} remove={() => remove(fieldNdx)}>
                    <>
                      <Form.Item
                        name={[f.name, "id"]}
                        hidden={true}
                      >
                        <Input  style={{ width: 300 }} />
                      </Form.Item>

                      <Form.Item
                        name={[f.name, "stepOrder"]}
                        hidden={true}
                      >
                        <Input  style={{ width: 300 }} />
                      </Form.Item>

                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item
                            rules={[{ required: true, message: "Name is required" }]}
                            label="Step Name"
                            name={[f.name, "name"]}
                          >
                            <Input  style={{ width: 300 }}/>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Approver Role"
                            name={[f.name, "approverRole"]}
                            rules={[{ required: true, message: "Approver role is required" }]}
                          >
                            <Select
                              style={{ width: 300 }}
                              optionFilterProp="label"
                              options={Object.values(ApproverRole)?.map(role => ({ label: role, value: role }))}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Notification Note"
                            name={[f.name, "notificationNote"]}
                            rules={[{ required: true, message: "Notification note is required" }]}
                          >
                            <Input/>
                          </Form.Item>
                        </Col>
                      </Row>

                    </>
                  </SortableItem>)}
                </SortableContext>
              </DndContext>

            </>}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
}

const EditWorkflowNotes = (props: {workflow: WorkflowDto, reloadWorkflow: () => void}) => {
  const [open, setOpen] = useState<boolean>(false);
  const configurator = useContext(ConfiguratorContext);
  const [form] = useForm();
  const isAdmin = configurator.isAdmin();
  const cancelUpdateNotesTokenSourceRef = useRef<CancelTokenSource>();
  const intl = useIntl();

  useEffect(() => {
    form.setFieldsValue(props.workflow);
  }, [props.workflow]);

  const updateWorkflowNotes = async () => {
    try {

      const obj: WorkflowRequest = {
        ...props.workflow,
        workflowId: props.workflow.id,
        workflowSteps: [],
        notes: form.getFieldValue("notes"),
      }

      await Utils.executeWithCancelToken(cancelUpdateNotesTokenSourceRef, (token) =>
        configurator.api.updateWorkflowNotes(obj, token)
      );

      props.reloadWorkflow();

      notification.success({message: "Workflow notes saved."});
      setOpen(false);
      form.resetFields();
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed update workflow notes. " + errorMsg });
    }
  }

  return (
    <>
      <div key={`${props.workflow.id}-note`}>
        <style>
          {`
            .add-working-note-button:hover {
              color: white !important;
              background-color: #1677ff !important;
            }
            .add-working-note-button {
              color: #1677ff !important;
              border: 2px solid #1677ff !important;
              margin-left: 5px !important;
              font-size: 13px !important;
              margin-top: 3px !important;
            }

            .working-note-button:hover {
              color: white !important;
              background-color: orange !important;
              border: 2px solid orange !important;
            }
            .working-note-button {
              color: orange !important;
              border: 2px solid orange !important;
              margin-left: 5px !important;
              font-size: 13px !important;
              margin-top: 3px !important;
            }
          `}
        </style>
        {props.workflow.notes ?
        <Tooltip title={props.workflow.notes}>
          {props.workflow.notes && String(props.workflow.notes).trim() !== '' && <Button className="working-note-button" shape="circle" icon={<InfoOutlined /> } onClick={() => setOpen(!open)} size='small'></Button>}
        </Tooltip>
          :
        <Tooltip title={'Edit working note.'}>
          {<Button className="add-working-note-button" shape="circle" icon={<LeftOutlined />} size='small' onClick={() => setOpen(!open)}></Button>}
        </Tooltip>}
      </div>
      <Modal
        title={isAdmin ? "Edit Workflow Notes" : "Workflow Notes"}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={updateWorkflowNotes}
        okText="Done"
        cancelButtonProps={{style: {display: "none"}}}
        okButtonProps={{style: {display: isAdmin ? "" : "none"}}}
      >
        <Form 
          form={form}
          initialValues={props.workflow}
        >
          <Form.Item
            name="notes"
          >
            <Input.TextArea disabled={!isAdmin}/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}


const SortableItem = ({ id, children, remove }: { id: string; children: React.ReactNode; remove: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void; }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });


  const style = {
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Divider />
      <Row justify={"space-between"} style={{ marginBottom: ".5rem", color: "#1890ff" }}>
        <span className="drag-handle" {...listeners} style={{ cursor: "grab" }}>
          <MenuOutlined />
        </span>
        <DeleteOutlined onClick={remove} style={{ color: "#1890ff" }} />
      </Row>
      <div>{children}</div>
    </div>
  );
};


