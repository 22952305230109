import { Alert, Form, Input, InputNumber, Modal, Row, Switch, Table, Tooltip, notification } from "antd";
import Title from "antd/lib/typography/Title";
import { ConfiguratorContext } from "../context";
import { DAYS_IN_WEEK, NotificationType } from "../api/models";
import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Utils from "../util/util";
import dayjs from "dayjs";
import { useForm, useWatch } from "antd/es/form/Form";
import { InfoCircleTwoTone } from "@ant-design/icons";
import BMButton from "../components/BMButton";

const LifeCycleNotification = (props: any) => {

  const configurator = useContext(ConfiguratorContext);
  const [allNotifications, setAllNotifications] = useState<NotificationType[]>([]);
  const [open, setOpen] = useState(false);
  const [editingId, setEditingId] = useState<number | undefined>(undefined);
  const [form] = useForm();
  const intl = useIntl();

  const start = useWatch("startDaysToProduction", form);
  const end = useWatch("endDaysToProduction", form);
  const isAdminOrSalesdesk = configurator.isAdmin() || configurator.isSalesDesk();

  const getAllNotifications = async () => {
    try {
      const resp = await configurator.api.getAllNotificationType();
      setAllNotifications(resp.data);
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to get notifications. " + errorMsg });
    }
  }

  useEffect(() => {
    getAllNotifications();
  }, []);

  const getWeekAndDay = (day: number) => {
    const second = day % DAYS_IN_WEEK;
    const first = (day - second) / DAYS_IN_WEEK;
    const weeks = first ? first + " week, " : "";
    const days = `${second} days`
    return weeks + days;
  }

  const columns = [
    {
      title: <>
        <span>Message</span>
        <Tooltip title="Message will show up in the notification to dealer and sales reps."><InfoCircleTwoTone style={{marginLeft: "5px"}}/></Tooltip>
      </>,
      dataIndex: "message",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (v: string) => <span>{Utils.snakeCaseToFirstLetterCapitalized(v)}</span>
    },
    {
      title:
        <>
          <span>Sending start to prod.</span>
          <Tooltip title="This is the START day the notificaiton will be sent from the production date. "><InfoCircleTwoTone style={{marginLeft: "5px"}}/></Tooltip>
        </>,
      dataIndex: "startDaysToProduction",
      width: "200px",
      render: (start: number) => <>
        <span>{getWeekAndDay(start)}</span>
        <span style={{color: "darkgrey"}}>{` (${start} days)`}</span>
      </>,
    },
    {
      title:
      <>
        <span>Sending end to prod.</span>
        <Tooltip title="This is the END day the notificaiton will be sent from the production date. "><InfoCircleTwoTone style={{marginLeft: "5px"}}/></Tooltip>
      </>,
      dataIndex: "endDaysToProduction",
      width: "200px",
      render: (end: number) => <>
        <span>{getWeekAndDay(end)}</span>
        <span style={{color: "darkgrey"}}>{` (${end} days)`}</span>
      </>,
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (active: boolean) => <span>{active ? "Active" : "In-active"}</span>
    },
    {
      title: "Last Update",
      render: (row: NotificationType) => <span>{dayjs(row.updatedAt).format('M/D/YYYY') + " by " + row.updatedBy}</span>
    },
    {
      title: "Action",
      render: (row: NotificationType) => 
      <BMButton 
        onClick={() => onEdit(row.id || 0)}
        type="primary"
        disabled={!!getUpdateDisabledMsg()}
        onDisabledClick={() => notifyDisabled(getUpdateDisabledMsg())}
      >
        Edit
      </BMButton>
    },
  ];

  const onNew = () => {
    setEditingId(undefined);
    setOpen(true);
  }

  const onEdit = (id: number) => {
    setEditingId(id);
    setOpen(true);
    form.setFieldsValue(initForm(id));
  }

  const onCancel = () => {
    setOpen(false);
    setEditingId(undefined);
    form.resetFields();
  }

  const initForm = (id: number) => {
    return id ? allNotifications.filter(n => n.id === id)[0] : {};
  }

  const getAddon = (day: number): string => {
    return `days ${!isNaN(day) ? " : " + getWeekAndDay(day) : ""}`;
  }

  const validDate = async (_rule: any, value: string, _cb: any) => {
    if (start == undefined || end == undefined ) {
      return;
    }
    if (Number(value) !== parseInt(value, 10) || Number(start) <= Number(end) ) {
      throw new Error();
    }
  };

  const validateDateChange = async () => {
    try {
      await form.validateFields(['startDaysToProduction', 'endDaysToProduction']);
    } catch (error) {
      console.log('Validation date failed:', error);
    }
  };

  const saveNotification = async () => {
    const obj = {...form.getFieldsValue(), id: editingId};
    try {
      await configurator.api.updateNotification(obj);
      notification.success({message: "Successfully save notification."})
      setOpen(false);
      getAllNotifications();
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to save notification. " + errorMsg });
    }
  }

  const createNotification = async () => {
    const obj = form.getFieldsValue();
    try {
      await configurator.api.createNotification(obj);
      notification.success({message: "Successfully create notification."})
      setOpen(false);
      getAllNotifications();
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to save notification. " + errorMsg });
    }
  }

  const notifyDisabled = (msg:string | undefined) => {
    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  const getUpdateDisabledMsg = () => {
    return !isAdminOrSalesdesk ? "You don't have the access to update notification." 
      : undefined;
  }

  return (
    <>
      <div className="site-layout-background">
        <Title level={2}>Life Cycle Notification</Title>
        <BMButton 
          onClick={onNew}
          style={{marginBottom: "1rem"}}
          type="primary"
          disabled={!!getUpdateDisabledMsg()}
          onDisabledClick={() => notifyDisabled(getUpdateDisabledMsg())}
        >
          New
        </BMButton>
        <Table
          columns={columns}
          dataSource={allNotifications}
          rowKey={"id"}
        />

      </div>
      <Modal
        title={editingId ? "Update Notification" : "Create Notification"}
        open={open}
        onCancel={onCancel}
        okText="Save"
        cancelButtonProps={{style: {display: "none"}}}
        width={"50rem"}
        onOk={editingId ? saveNotification : createNotification}
      >
        <Form
          form={form}
          labelCol={{ flex: '12rem' }}
          labelAlign="left"
          labelWrap
        >
          <Form.Item
            label={<>
              <span>Message</span>
              <Tooltip title="Message will show up in the notification to dealer and sales reps."><InfoCircleTwoTone style={{marginLeft: "5px"}}/></Tooltip>
            </>}
            name="message"
            rules={[
              {
                required: true,
                message: "Type is required",
              },
            ]}
          >
            <Input.TextArea/>
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: "Type is required",
              },
            ]}
          >
            <Input placeholder="Please enter compact notification type name." disabled={!!editingId}/>
          </Form.Item>
          {!editingId && <Alert message="Type can not be changed after notification is created" style={{marginBottom: ".5rem", padding: "5px"}}/>}
          <Form.Item
            label="Active"
            name="active"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Switch checkedChildren="active" unCheckedChildren="in-active"/>
          </Form.Item>
          <Form.Item
            label={<>
              <span>Sending start to prod.</span>
              <Tooltip title="This is the START day the notificaiton will be sent from the production date. "><InfoCircleTwoTone style={{marginLeft: "5px"}}/></Tooltip>
            </>}
            name="startDaysToProduction"
            rules={[
              {
                required: true,
                message: "Start Day from Production is required",
              },
              {
                validator: validDate,
                message: "Start day from prod. should be larger than end day from prod.",
              },
            ]}
          >
            <InputNumber addonAfter={getAddon(start)} onChange={validateDateChange}/>
          </Form.Item>
          <Form.Item
            label={<>
              <span>Sending end to prod.</span>
              <Tooltip title="This is the END day the notificaiton will be sent from the production date. "><InfoCircleTwoTone style={{marginLeft: "5px"}}/></Tooltip>
            </>}
            name="endDaysToProduction"
            rules={[
              {
                required: true,
                message: "End Day from Production is required",
              },
              {
                validator: validDate,
                message: "Start day from prod. should be larger than end day from prod.",
              },
            ]}
          >
            <InputNumber addonAfter={getAddon(end)} onChange={validateDateChange}/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default LifeCycleNotification;
