import { useContext, useEffect } from "react";
import { DealerListContext, DealerListContextType } from "../context";
import { Select, SelectProps } from "antd";

const DealerMultipleSelector = (props: SelectProps) => {

  const {dealerLstAsync, loadDealerList} = useContext<DealerListContextType>(DealerListContext);
  const dealerLst = dealerLstAsync?.val;

  useEffect(() => {
    if ( ( dealerLstAsync?.isInitial() || dealerLstAsync?.isFail() ) && !dealerLstAsync.isLoading() ) {
      loadDealerList?.();
    }
  }, [])

  return <Select
    {...props}
      loading={dealerLstAsync?.isLoading()}
      mode="multiple"
      showSearch
      allowClear
      optionFilterProp="label"
      style={props.style}
      options={dealerLst?.map(d => ({value: d.id, label: d.name}))}
    />
};

export default DealerMultipleSelector;
