import { Alert, Button, Checkbox, Form, Input, Modal, notification, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import Title from "antd/lib/typography/Title";
import { useContext, useRef, useState } from "react";
import { BadRequestError } from "../../api/errors";
import { ExternalUser, User, UserType} from "../../api/models";
import DealerSelector from "../../components/dealer_selector";
import { ConfiguratorContext } from "../../context";
import { useAsyncState } from "../../hook/useAsyncState";
import UserMultiSelector from "../../components/UserMultiSelector";
import Utils from "../../util/util";
import { CancelTokenSource } from "axios";
import { useIntl } from "react-intl";

const EditUserModal = (props:  {
    user: ExternalUser | undefined
    edit: boolean
    loadUsers: () => void
}) => {
    const [form] = useForm();
    const configurator = useContext(ConfiguratorContext);
    const [error, setError] = useState<string>();
    const [saving, setSaving] = useState<boolean>(false);
    const [editUser, editUserAsync] = useAsyncState<ExternalUser>();
    const [showEditUser, setShowEditUser] = useState<boolean>(false);
    const [internalUsers, setInternalUsers] = useState<User[]>();
    const cancelLoadUserTokenSourceRef = useRef<CancelTokenSource>();
    const cancelUpdateUserTokenSourceRef = useRef<CancelTokenSource>();
    const intl = useIntl();

    const handleEditUser = (e?:ExternalUser) => {
        editUserAsync.setDone(e);
        setShowEditUser(true);
        loadInternalUsers(e);
    };

  const handleCancel = async () => {
    editUserAsync.setInit();
    setShowEditUser(false);
  }
  const handleResetPassword = async () => {
    if (!editUser) return;

      try {
        await Utils.executeWithCancelToken(cancelUpdateUserTokenSourceRef, (token) =>
          configurator.api.resetPassword(editUser?.id, token)
        );
        notification.success({ message: "Check your email for a reset notification." });
        setShowEditUser(false);
      }
      catch (e: any) {
        const msg = e.response?.data?.message || e.message;
        const errorMsg = intl.formatMessage({ id: msg });
        notification.error({ message: "Failed to reset password. " + errorMsg });
      }
  }


    const onFinish = async (values: any) => {
        try {
            setSaving(true);
            if ( !values.id ) {
              await configurator.api.createUser(values);
            }
            else {
              await configurator.api.updateUser(values.id, values);
            }
            setError(undefined);

            //wait for auth0 to save
            setTimeout(() => {
              setSaving(false);
              props.loadUsers();
            }, 3000)

        }
        catch (e) {
            if(e instanceof BadRequestError) {
                setError(e.message);
            }
            else {
                setError('Failed to create user at this time.');
            }
            setSaving(false);
        }
    };

    const loadInternalUsers = async (e?:ExternalUser) => {
        try {
    
            const resp = await Utils.executeWithCancelToken(cancelLoadUserTokenSourceRef, (token) =>
                configurator.api.loadInternalUsers(undefined, e?.dealer.id, token)
            );

          setInternalUsers(resp?.data);
        
        }
        catch (e: any) {
    
        }
      };

    return (
    <>
    {props.edit?
        <Button type="primary" loading={editUserAsync.isLoading()} onClick={() => handleEditUser(props.user)}>Edit</Button>
        :
        <Button onClick={() => handleEditUser() } type="primary">New User</Button>
    }
      <Modal open={showEditUser} 
        onCancel={handleCancel}
        confirmLoading={saving}
        afterOpenChange={() => {
          form.resetFields();
        }}
        width={"40rem"}
        footer={<div style={{display:"flex", justifyContent: "space-between"}}>
          <Button danger onClick={handleResetPassword}>Reset Password</Button>
          <div>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()}>OK</Button>
          </div>
        </div>}

        >
            <Title level={3}>{props.user ? 'Edit' : 'New'} User</Title>
            {!!error && <div style={{padding: '10px'}}>
                <Alert type="error" message={error} />
            </div>}
            <Form onFinish={onFinish} form={form}
              initialValues={{
                userType: UserType.Standard,
                enabled: true,
                dealerId: editUser?.dealer.id,
                ...editUser
              }}
            >
                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>
                <Form.Item labelCol={{ span: 4 }} name="email" label="Email" rules={[{ required: true, message: "Email is required" }]}>
                    <Input />
                </Form.Item>
                <Form.Item labelCol={{ span: 4 }} name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
                    <Input />
                </Form.Item>
                <Form.Item labelCol={{ span: 4 }} name="dealerId" label="Dealer" rules={[{ required: true, message: 'Dealer is required' }]}>
                  <DealerSelector style={{width: "300px"}} />
                </Form.Item>
                <Form.Item labelCol={{ span: 4 }} name="userType" label="User Type" >
                    <Select>
                        <Select.Option key={UserType.QuoteOnly} value={UserType.QuoteOnly}>Quote Only</Select.Option>
                        <Select.Option key={UserType.Standard} value={UserType.Standard}>Standard</Select.Option>
                        <Select.Option key={UserType.Management} value={UserType.Management}>Management</Select.Option>
                        <Select.Option key={UserType.Admin} value={UserType.Admin}>Admin</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item labelCol={{ span: 4 }} name="enabled" label="Enabled" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 4 }}
                    name="managers"
                    label="Manager"
                >
                <UserMultiSelector
                    userLst={internalUsers || []}
                />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 4 }}
                    name="subordinates"
                    label="Subordinates"
                >
                <UserMultiSelector
                    userLst={internalUsers || []}
                />
                </Form.Item>
            </Form>
        </Modal>
    </>)
    
};

export default EditUserModal;
