import Title from "antd/lib/typography/Title";
import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Input, TablePaginationConfig } from "antd";
import { ConfiguratorContext } from "../context";
import axios, {CancelTokenSource} from "axios";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import PendingAssembliesTable from "../components/Table/PendingAssembliesTable";
import {Assembly, Page, AXIOS_CANCEL_MSG} from "../api/models";
import {useAsyncState} from "../hook/useAsyncState";
import {debounce} from "lodash";

const DEBOUNCE_MS = 500;
const PendingAssemblies = () => {
  const configurator = useContext(ConfiguratorContext);

  //parameters from the query string
  const [dataFilter, setDataFilter] = useQueryParam<string | null | undefined >("filter", StringParam);
  const [pageSizeQuery, setPageSizeQuery] = useQueryParam<number | null | undefined >("nr", NumberParam);
  const [currentPage, setCurrentPage] = useQueryParam<number | null | undefined >("p", NumberParam);

  const [_assemblies, assembliesAsync] = useAsyncState<Page<Assembly>>();
  const [pagination, setPagination ] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQuery == null || pageSizeQuery > 500 ? 50 : pageSizeQuery,
    current: currentPage == null || currentPage < 1 ? 1 : currentPage,
  });
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  useEffect(() => {
    clearDataSource();
    setPagination({ ...pagination, current: 1 } );
  }, [dataFilter ]);

  useEffect(() => {
    updateAssemblies(assembliesAsync, pagination, dataFilter || undefined );
  }, [pagination.pageSize, pagination.current, dataFilter ]);

  const clearDataSource = () => {
      assembliesAsync.setInit();
  }

  const updateAssemblies = useCallback( debounce( async (assembliesAsync, pagination:TablePaginationConfig, filterQuery:string | undefined) => {
    assembliesAsync.setLoading()

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.getPendingAssemblies(
        pagination?.current ? pagination.current - 1 : undefined,
        pagination?.pageSize,
        filterQuery,
        cancelSource.token
      );
      cancelTokenSourceRef.current = undefined;

      assembliesAsync.setDone( resp.data)
      setPagination({ ...pagination, total: resp.data.totalElements });

    } catch (e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        assembliesAsync.setFail( e.message );
      }
    }
  }, DEBOUNCE_MS), [] );

  const onChangeAssemblyFilter = ( e:ChangeEvent<HTMLInputElement> ) => {
    const dataFilter = e.target.value;
    setDataFilter( dataFilter );
  };

  const onChangePagination = ( pagination:TablePaginationConfig  ) => {

    setPagination(pagination );

    //save to the query string
    setPageSizeQuery( pagination.pageSize );
    setCurrentPage( pagination.current );
  };

  return (
    <div className="site-layout-background">
      <Title level={2}>Assembly List (Pending)</Title>
        <div>
          <div style={{marginTop: "20px" }} >
            <span>Filter: </span>
            <Input
              allowClear
              style={{ width: "300px" }}
              onChange={onChangeAssemblyFilter}
              value={dataFilter || undefined}
              placeholder="Enter text to filter list"
            />
          </div>
          <div style={{marginTop: "20px"}} >
            <PendingAssembliesTable
              dataSource={assembliesAsync}
              updatePagination={onChangePagination}
              pagination={pagination}
            />
          </div>
        </div>
    </div>
  );
};

export default PendingAssemblies;
