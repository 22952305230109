import { Button, Table, TablePaginationConfig } from "antd";
import { Link } from "react-router-dom";
import {Assembly, Page, Permission} from "../../api/models";
import {AsyncState} from "../../hook/useAsyncState";
import { useContext } from "react";
import { ConfiguratorContext } from "../../context";


const PendingAssembliesTable = (props:{
  pagination:TablePaginationConfig
  updatePagination:(pagination:TablePaginationConfig)=>void
  dataSource:AsyncState<Page<Assembly>>
}) => {

  const { pagination, updatePagination,  dataSource } = props;
  const context = useContext(ConfiguratorContext);
  const canWrite = context.hasPermission(Permission.ENGINEERING_WRITE);

  const tableOnChange = async (pagination:TablePaginationConfig) => {
    updatePagination( pagination );
  };

  const getLabel = (assembly:Assembly) => {
    return assembly.label != null && assembly.label.length > 0
      ? assembly.label
      : assembly.bomDescription;
  };

  var columns = [
    {
      title: "BOM",
      key: "bom",
      render: (asm:Assembly) => (
        <span>
          {asm.bom}
          {asm.obsoletedAt != null ? " (Obsolete)" : ""}
        </span>
      ),
    },
    {
      title: "Name",
      key: "name",
      render: (assembly:Assembly) => (
          <div>{getLabel(assembly)}</div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (assembly:Assembly) => (
        <Link to={"/assemblies/import/" + encodeURIComponent(assembly.id)}>
          <Button
            type="primary"
            disabled={!canWrite}
            title={!canWrite ? "You need permission to import." : ""}
          >
            Import
          </Button>
        </Link>
      )
    }
  ];

  return (
    <Table
      id="pending_assembly"
      onChange={tableOnChange}
      pagination={pagination}
      bordered
      rowKey="id"
      columns={columns}
      dataSource={dataSource.val?.content}
      loading={dataSource.isLoading()}
    />
  )
}

export default PendingAssembliesTable;
