import { Alert, Col, Modal, Row, Button, DatePicker, Select, Spin, Table, notification, ButtonProps } from "antd";
import EpicorCustomerFilter from "../Filter/EpicorCustomerFilter";
import EpicorShipToFilter from "../Filter/EpicorShipToFilter";
import { useContext, useEffect, useState } from "react";
import { EpicorCustomer, EpicorExportSalesOrderResponse, EpicorSalesOrder, EpicorShipTo, Quote, Revision } from "../../api/models";
import { ConfiguratorContext } from "../../context";
import { BadRequestError } from "../../api/errors";
import { useIntl } from "react-intl";
import dayjs from 'dayjs'
import Utils from "../../util/util";

const ExportSalesOrder = (props: ButtonProps & {
    quote: Quote | undefined;
    revisionId: number | undefined;
}) => {
    const { quote, revisionId, ...btnProps } = props;
    const [exportSalesOrder, setExportSalesOrder] = useState<boolean>(false);
    return <>
        <Button {...btnProps}
            onClick={() => setExportSalesOrder(true)}
        >
            Epicor Sales Order
        </Button>
        {exportSalesOrder && <ExportSalesOrderModal quote={props.quote} revisionId={props.revisionId} onClose={() => setExportSalesOrder(false)} />}
    </>;
};

const ExportSalesOrderModal = (props: {
    quote: Quote | undefined;
    revisionId: number | undefined;
    onClose: (boolean) => void;
}) => {

    const configurator = useContext(ConfiguratorContext);
    const { revisionId, quote } = props;

    const [selectedRevisionId, setSelectedRevisionId] = useState(revisionId);
    const [billToCustomer, setBillToCustomer] = useState<EpicorCustomer>();
    const [shipToCustomer, setShipToCustomer] = useState<EpicorCustomer>();
    const [shipTo, setShipTo] = useState<EpicorShipTo>();
    const [shipDate, setShipDate] = useState<Date | undefined>(quote?.shippingDate != null ? dayjs(quote?.shippingDate).toDate() : undefined);
    const [errorMessage, setErrorMessage] = useState<string>();
    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSalesOrder, setLoadingSalesOrder] = useState<boolean>(false);

    const [existingSalesOrder, setExistingSalesOrder] = useState<EpicorSalesOrder>();

    const [exportResponse, setExportResponse] = useState<EpicorExportSalesOrderResponse>();

    useEffect(() => {
        setSelectedRevisionId(props.revisionId);
    }, [props.revisionId]);

    useEffect(() => {
        if (quote?.shippingDate) setShipDate(dayjs(quote?.shippingDate).toDate());
        if (quote?.salesOrderNumber) loadExistingSalesOrder(quote.id);
    }, [quote]);

    if (!quote) return <></>;
    if (revisionId === undefined) return <></>;

    const loadExistingSalesOrder = async (quoteId: number) => {
        try {
            setLoadingSalesOrder(true);
            const resp = await configurator.api.getEpicorSalesOrder(quoteId);
            setExistingSalesOrder(resp);
            setBillToCustomer(resp.billToCustomer);
            setShipToCustomer(resp.shipToCustomer);
            setShipTo(resp.shipToCustomer.shipTos.find(st => st.shipToNum == resp.shipToNum));
            setShipDate(resp.shipDate);
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setLoadingSalesOrder(false);
        }
    };

    const onExport = async () => {
        if (!shipToCustomer || !billToCustomer || !shipDate || !selectedRevisionId) {
            setErrorMessage('All fields are required.');
            return;
        }

        try {
            setLoading(true);
            setErrorMessage(undefined);

            const resp = await configurator.api.epicorExportSalesOrder({
                quoteRevisionId: selectedRevisionId,
                billToCustomerId: billToCustomer.custID,
                shipToCustomerId: shipToCustomer.custID,
                shipToNum: shipTo?.shipToNum,
                custNum: billToCustomer.custNum,
                shipDate: dayjs(shipDate).format('YYYY-MM-DD'),
            });

            setErrorMessage(undefined);
            setExportResponse(resp);
        }
        catch (e:any) {
            const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
            setErrorMessage('Failed to export at this time.' + errorMsg);
        }
        finally {
            setLoading(false);
        }
    };

    const serialColumns = [
        {
            key: 'serial',
            dataIndex: 'serial',
            title: 'Unit Number'
        }
    ];

    const partsColumns = [
        {
            key: 'partNum',
            dataIndex: 'partNum',
            title: 'Part Number'
        },
        {
            key: 'quantity',
            dataIndex: 'quantity',
            title: 'Quantity'
        }
    ];

    const partsAdded = exportResponse?.partsAdded ? Object.entries(exportResponse.partsAdded).map((item) => {
        return { partNum: item[0], quantity: item[1] };
    }) : [];

    const partsDeleted = exportResponse?.partsDeleted ? Object.entries(exportResponse.partsDeleted).map((item) => {
        return { partNum: item[0], quantity: item[1] };
    }) : [];

    return <Modal width={"50%"}
        open={true}
        title="Export Epicor Sales Order" cancelText={!exportResponse ? 'Cancel' : 'Close'} okButtonProps={{ disabled: !!exportResponse }}
        confirmLoading={loading}
        onCancel={() => props.onClose(false)}
        okText={quote.salesOrderNumber ? "Update Sales Order" : "Create Sales Order"}
        onOk={onExport}>
        {loadingSalesOrder ? <Spin /> : <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {exportResponse ? <div>
                <p>Sales Order {quote.salesOrderNumber ? 'Updated' : 'Created'}, SO Number {exportResponse?.salesOrderNumber}</p>
                {partsAdded.length > 0 &&
                    <div>
                        <h3>Lines Added</h3>
                        <Table rowKey="partNum" columns={partsColumns} dataSource={partsAdded} />
                    </div>}
                {partsDeleted.length > 0 &&
                    <div>
                        <h3>Lines Deleted</h3>
                        <Table rowKey="partNum" columns={partsColumns} dataSource={partsDeleted} />
                    </div>}
                {exportResponse.closedLinesSkipped && exportResponse.closedLinesSkipped.length > 0 &&
                    <div>
                        <h3>Skipped Lines (Order Line Closed)</h3>
                        <Table rowKey="serial" columns={serialColumns} dataSource={exportResponse.closedLinesSkipped.sort().map(s => { return {serial: s}; })} />
                    </div>}
            </div> :
                <>
                    {quote.salesOrderNumber && <Alert type="info" message={
                        <div>A sales order already exists for this order. If you export, it will update the existing Epicor Sales Order <strong>{quote.salesOrderNumber}</strong></div>} />}
                    {errorMessage && <Alert type="error" message={errorMessage} />}
                    <>
                        <Row gutter={16} align="middle">
                            <Col span={4}>Quote Revision:</Col>
                            <Col span={20}>
                                <Select
                                    value={selectedRevisionId}
                                    style={{ width: '100%' }}
                                    onSelect={(value: number) => setSelectedRevisionId(value)}
                                    options={[...quote.revisions]
                                        .sort((a, b) => b.revision - a.revision)
                                        .map((rev: Revision) => ({
                                            value: rev.id,
                                            label: Utils.formatRevisionLabel(rev, quote?.currentRevisionId, false)
                                        }))}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16} align="middle">
                            <Col span={4}>Bill To Customer:</Col>
                            <Col span={20}>
                                <EpicorCustomerFilter initialValue={billToCustomer} onSelect={(customer) => setBillToCustomer(customer)} />
                            </Col>
                        </Row>
                        <Row gutter={16} align="middle">
                            <Col span={4}>Ship To Customer:</Col>
                            <Col span={20}>
                                <EpicorCustomerFilter initialValue={shipToCustomer} onSelect={(customer) => setShipToCustomer(customer)} />
                            </Col>
                        </Row>
                        <Row gutter={16} align="middle">
                            <Col span={4}>Ship To Destination:</Col>
                            <Col span={20}>
                                <EpicorShipToFilter initialValue={shipTo} onSelect={s => setShipTo(s)}
                                    customer={shipToCustomer} />
                            </Col>
                        </Row>
                        <Row gutter={16} align="middle">
                            <Col span={4}>Shipping Date (Need By):</Col>
                            <Col span={20}>
                                <DatePicker
                                    onChange={(val) => setShipDate(val.toDate())}
                                    defaultValue={quote.shippingDate ? dayjs(quote.shippingDate) : undefined}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                        </Row>
                    </>
                </>}
        </div>}
    </Modal>
};

export default ExportSalesOrder;
