import {  Input, notification, Table, Button } from "antd";
import { useCallback, useContext, useEffect,  useRef,  useState } from "react";
import {useIntl} from "react-intl";
import {AXIOS_CANCEL_MSG, PAGINATION_MAX_PAGE_SIZE, ShippingDestination} from "../api/models";
import { ConfiguratorContext } from "../context";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import BMButton, {BMButtonProps} from "./BMButton";
import {ColumnType} from "antd/es/table";
import {debounce} from "lodash";
import axios, {CancelTokenSource} from "axios";
import ModalWizard from "./ModalWizard";
import { WizardInstance, WizardStep } from "./Wizard";

type ShippingDestinationModalProps =  Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  id?:string
  value?:ShippingDestination
  onChange?:(c:ShippingDestination | undefined)=>void
};

const DEFAULT_PAGE_SIZE = 5;
const NOT_FOUND = -1;

const formatShippingDestination = (sd:ShippingDestination) => `${sd.name} (${sd.miles} mi)`;

const ShippingDestinationModal = (props:ShippingDestinationModalProps) => {
  const { id:a, value:b, onChange:c, ...btnProps } = props;

  const [isOpen, setIsOpen] = useState<boolean>();
  const [wizardInstance, setWizardInstance] = useState<WizardInstance | undefined>();
  const [_activeStep, setActiveStep] = useState<React.Key | undefined>();

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  const handleOk = (shippingDestination:ShippingDestination | undefined) => {
    props.onChange?.( shippingDestination );
    setIsOpen(false);
  }

  const disabled = btnProps.disabled;
  const handleDisabledClick = () => {
      btnProps.onDisabledClick?.();
  };

  const handleStep = (i:WizardInstance | undefined, s:WizardStep | undefined) => {
    setWizardInstance(i);
    setActiveStep(s?.key);
  }

  const btnLbl =  ( props.value ) ? formatShippingDestination(props.value)
    : disabled ? "None"
    : "Add ShippingDestination";

  //hide border when disabled
  const btnStyle = disabled
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  return <>
    <BMButton type="text"
      className="ghostBmButton"
      data-testid="shippingDestination-button"
      disabled={disabled}
      onDisabledClick={handleDisabledClick}
      onClick={handleOpen}
      style={{padding: "0", ...btnProps.style}}
    ><span style={btnStyle}>{btnLbl}</span></BMButton>

    <ModalWizard
      title="Select Shipping Destination"
      open={isOpen}
      onCancel={handleCancel}
      showSteps={false}
      onStep={handleStep}
      steps={[
        useSelectShippingDestinationStep({
          title: "Select Shipping Destination",
          value: props.value,
          onChange: handleOk,
          onCancel: handleCancel
        })
      ]}
      />



  </>
};

export const useSelectShippingDestinationStep = (props: {
  key?: React.Key
  title?: string
  hidden?: boolean
  value: ShippingDestination | undefined
  onChange?:(t:ShippingDestination | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const [shippingDestination, setShippingDestination] = useState<ShippingDestination | undefined>(props.value);

  const handleCancel = () => {
    props.onCancel?.();
  }

  const handleRemove = () => {
    props.onChange?.(undefined);
  }

  const handleDone = () => {
    props.onChange?.(shippingDestination);
  }

  return {
    title: props.title,
    key: props.key || "selectShippingDestination",
    body: () => <div>
      <SelectShippingDestination
        value={shippingDestination}
        onChange={setShippingDestination}
      />
    </div>,
    footer:() => <div style={{display: "flex", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
      <Button key="remove" danger onClick={handleRemove}>Remove</Button>
      <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse"}}>
        <Button key="done" type="primary" onClick={handleDone}>Done</Button>
        <Button key="cancel" onClick={handleCancel}>Cancel</Button>
      </div>
    </div>
  }
}

export const SelectShippingDestination = (props: Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  value?: ShippingDestination
  onChange?:(c:ShippingDestination | undefined)=>void
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [shippingDestinationLst, shippingDestinationLstAsync] = useAsyncState<ShippingDestination[]>();
  const [filter, setFilter] = useState<string | undefined>();
  const [pagination, setPagination] = useState<any>({ pageSize: DEFAULT_PAGE_SIZE, defaultCurrent: 1 });
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  useEffect(() => {
    const ndx = datasource?.map( c => c.id ).findIndex( id => id === props.value?.id) || -1;
    const page = ( ndx === NOT_FOUND ) ? 1 : Math.floor( ( ndx / DEFAULT_PAGE_SIZE) + 1 );

    setPagination( { ...pagination, defaultCurrent: page, current:page } );
  }, [shippingDestinationLst, filter]);

  useEffect(() => {
    loadShippingDestinations(shippingDestinationLstAsync);
  }, []);

  const loadShippingDestinations = useCallback(debounce( async (shippingDestinationLstAsync:AsyncState<ShippingDestination[]> ) => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.getShippingDestinations( {
          page: 0, 
          size: PAGINATION_MAX_PAGE_SIZE, 
          sort: { field: 'name', direction:'asc' }
        }, 
        cancelSource.token );
      cancelTokenSourceRef.current = undefined;

      shippingDestinationLstAsync.setDone(resp.data.content);
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to get shipping destinations. " + errorMsg });
        shippingDestinationLstAsync.setFail(e.message);
      }
    }
  }, 600), []);

  const columns:ColumnType<ShippingDestination>[] = [{
    title: "Name",
    render: formatShippingDestination
  }];

  const handleChangeFilter = useCallback(
    debounce( (e:any) => {
      setFilter( e.target.value )
    }, 400 )
    , [] );

  const datasource = shippingDestinationLst?.filter( m => !filter || formatShippingDestination(m).toLocaleLowerCase().includes(filter.toLocaleLowerCase() ) )

  const handleSelectRow = (record:ShippingDestination) => {

    //toggle previously selected
    if ( props.value?.id === record.id ) {
      props.onChange?.(undefined);
      return;
    }

    props.onChange?.(record);
  }


  return <div>
    <style>
      {`
        /* don't show error border on filter */
        .shippingDestinationFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
        .shippingDestinationFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
        border-color: #d9d9d9;
        }
        .shippingDestinationLst .ant-table-content { padding: 5px; } /* don't clip corners */
        .shippingDestinationLst .ant-table-cell { border: none !important; } /* remove table cell borders */
        /* add error border to table */
        .ant-form-item-has-error .shippingDestinationLst .ant-table-content {
        border: solid 1px #ff4d4f;
        border-radius: 15px;
        }
        `}
    </style>

    <Input className="shippingDestinationFilter" onChange={handleChangeFilter} placeholder="Filter Shipping Destinations" allowClear />

    <Table 
      className="shippingDestinationLst"
      showHeader={false}
      columns={columns}
      rowKey="id"
      loading={shippingDestinationLstAsync.isLoading()}
      dataSource={datasource}
      pagination={pagination}
      onChange={setPagination}
      onRow={(record, _rowIndex) => {
        return {
          onClick: () => handleSelectRow(record)
        };
      }}
      rowSelection={{
        type: "radio",
        onSelect: handleSelectRow,
        selectedRowKeys: props.value?.id ? [props.value?.id] : [],
      }}

    />
  </div>
}

export default ShippingDestinationModal;
