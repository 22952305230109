import { Alert, Button, Divider, Modal, List } from "antd";
import { useEffect, useState } from "react";
import { Performance } from "../api/models";

const PerformanceErrorCategories = (props: {performance: Performance | undefined}) => {

  const {performance} = props;
  const performanceData = performance?.performanceData;
  const performanceWeight = performance?.performanceWeight;
  const performanceDimension = performance?.performanceDimension;

  const noCategoryError = !performanceWeight?.weightsMissing.length 
                              && !performanceWeight?.tareMissing.length 
                              && !performanceWeight?.gvwrMissing.length 
                              && !performanceData?.performanceMissing.length 
                              && !performanceDimension?.dimensionMissing.length;

  const [open, setOpen] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  useEffect(() => {
    setShowErrorAlert(!noCategoryError);
  }, [noCategoryError]);

  return (
    <div key="errorAndListAlert">
      {showErrorAlert && <div  key="errorAlertButton">
        <Alert 
          type="error" 
          closable={true}
          message={"There are missing selections causing calculation errors, please review."}
          action={
            <Button size="small" onClick={() => {setOpen(true)}}>
              Detail
            </Button>
          }
        />
        <Divider/>
      </div>}
      <Modal 
        title="Category with missing selections"
        open={open}
        onOk={() => {setOpen(false)}}
        onCancel={() => {setOpen(false)}}
        cancelButtonProps={{style: {display: 'none'}}}
        width="80%"
        style={{maxWidth: "60rem"}}
      >
        {!!performanceWeight?.weightsMissing.length && 
        <div key="weightsTableAlert">
          <List header="Weights Table">
            {performanceWeight?.weightsMissing.map( (cat, id) => 
              <List.Item key={'WeightsTable_' + id} style={{fontSize: '14px', marginLeft: "1rem"}}>{cat}</List.Item>
            )}
          </List>
        </div>}
        
        {!!performanceWeight?.gvwrMissing.length && 
        <div key="gvwrErrorAlert">
          <List header="GVWR">
            {performanceWeight?.gvwrMissing.map( (cat, id) => 
              <List.Item key={'GVWR_' + id} style={{fontSize: '14px', marginLeft: "1rem"}}>{cat}</List.Item>
            )}
          </List>
        </div>}
        
        {!!performanceWeight?.tareMissing.length &&
        <div key="tareErrorAlert">
          <List header="Tare">
            {performanceWeight?.tareMissing.map( (cat, id) => 
              <List.Item key={'Tare_' + id} style={{fontSize: '14px', marginLeft: "1rem"}}>{cat}</List.Item>
            )}
          </List>
        </div>}

        {!!performanceData?.performanceMissing.length && 
        <div key="performanceErrorAlert">
          <List header="Performance">
            {performanceData?.performanceMissing.map( (cat, id) => 
              <List.Item key={'Performance_' + id} style={{fontSize: '14px', marginLeft: "1rem"}}>{cat}</List.Item>
            )}
          </List>
        </div>}

        {!!performanceDimension?.dimensionMissing.length &&
        <div key="dimensionErrorAlert">
          <List header="Dimension">
            {performanceDimension?.dimensionMissing.map( (cat, id) => 
              <List.Item key={'Dimension_' + id} style={{fontSize: '14px', marginLeft: "1rem"}}>{cat}</List.Item>
            )}
          </List>
        </div>}
      </Modal>
  </div>
  );
}

export default PerformanceErrorCategories;
