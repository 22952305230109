import { Modal, ModalProps, StepsProps} from "antd";
import {useState} from "react";
import Wizard, { getWizardFooter, getWizardTitle, WizardInstance, WizardStep } from "./Wizard";

const ModalWizard = (props:Omit<ModalProps, 'onOk' | 'okText'> & {
  initialStep?: number
  steps?: WizardStep[]
  showSteps?: boolean
  stepsProps?: StepsProps
  viewHeight?: string
  onStep?: (i:WizardInstance | undefined, s:WizardStep | undefined) => void
}) => {

  const { steps, ...modalProps} = props;
  const [activeKey, setActiveKey] = useState<React.Key>();
  const [wizardInstance, setWizardInstance] = useState<WizardInstance>();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    setWizardInstance(instance);

    props.onStep?.(instance, step);
  };


  const activeStep = steps?.find( s => s.key === activeKey );
  const title = props.showSteps === false ? getWizardTitle( wizardInstance, activeStep) : undefined;
  const footer = getWizardFooter( wizardInstance, activeStep);

  const noTitleBodyStyle = !props.showSteps && !title ? {paddingTop: "2rem"} : {};

  return <Modal 
    {...modalProps}
    title={title}
    footer={footer}
    bodyStyle={{...noTitleBodyStyle, ...props.bodyStyle}}
    destroyOnClose={true}
    afterOpenChange={(open) => {
      props.afterOpenChange?.(open);
      if(open) {
        wizardInstance?.resetSteps();
      }
    }}
  >
  <Wizard 
      steps={props.steps || []} 
      showSteps={props.showSteps}
      initialStep={props.initialStep} 
      onStep={handleStep}
      viewHeight={props.viewHeight}
    />
  </Modal>
}


export default ModalWizard;
