import { Button, Form, Input, notification, Row, Space, Table } from "antd";
import { AXIOS_CANCEL_MSG, SalesTeam, User } from "../api/models";
import BMButton, { BMButtonProps } from "./BMButton";
import { PlusOutlined } from "@ant-design/icons";
import Wizard, { getWizardFooter, getWizardTitle, WizardInstance, WizardStep } from "./Wizard";
import { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from "react";
import { FilterValue, SorterResult, SortOrder, TablePaginationConfig } from "antd/es/table/interface";
import { useIntl } from "react-intl";
import { ConfiguratorContext } from "../context";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { debounce } from "lodash";
import axios, {CancelTokenSource} from "axios";
import ModalWizard from "./ModalWizard";
import CopyContact from "./CopyContact";
import SalesTeamForm, { SalesTeamFormValues } from "./Quote/SalesTeamForm";

type SortResult<T> = SorterResult<T> | SorterResult<T>[]
export type SalesTeamSort = SorterResult<SalesTeam> | SorterResult<SalesTeam>[]

export interface SalesTeamFilter {
  search?: string
  isUserSalesTeam?: boolean
  dealerId?: string,
}

const DEFAULT_PAGE_SIZE = 5;

const btnStyle = {borderBottom: "1px solid black"};

const OpenBtn = (props:BMButtonProps) => <BMButton type="text"
  className="ghostBmButton"
  {...props}
  style={{padding: 0, ...props.style}}
><span style={btnStyle}>{props.children || "None Assigned"}</span></BMButton>

export const SalesTeamDescription = (props: BMButtonProps & {
  salesTeam:SalesTeam | undefined 
  role?:string | undefined
  readOnly?:boolean
  listStyle?: CSSProperties
  listClassName?: string
}) => {

  const { salesTeam, role, readOnly, listStyle, listClassName, ...btnProps } = props;

  const lst = new Array<User[] | (User | undefined)[] | undefined>();

  if ( role === undefined || role === "sales" ) {
    lst.push(salesTeam?.sales );
  }
  if ( role === undefined || role === "engineer" ) {
    lst.push(salesTeam?.engineers );
  }
  if ( role === undefined || role === "support" ) {
    lst.push(salesTeam?.support );
  }

  const userLst = [lst].flat().flat().filter(v=>v) as User[];

  if (!userLst.length) {
    return readOnly 
      ? <div>None</div>
      : <OpenBtn {...btnProps}>None</OpenBtn>
  }
 
  //default to a single line list
  const listProps = ( listStyle || listClassName )
    ? { style: listStyle, className: listClassName }
    : { className: "csl" };

  return <ul {...listProps}  >
    {userLst
      .flat().filter(v=>v)
      .map( (user:User | undefined, ndx) => <li key={"open-" + ndx} >
        <div style={{display: "inline-flex", alignItems: "baseline"}}>
          {readOnly 
            ? <div>{user?.name}</div>
            : <OpenBtn {...btnProps}>{user?.name}</OpenBtn>
          }
          <CopyContact emailToCopy={user?.email} phoneToCopy={user?.phoneNumber}/>
        </div>
      </li>)}
  </ul>;


}

export const SelectSalesTeamButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  value?:SalesTeam | undefined
  onChange?:(s:SalesTeam | undefined) => void
  filter?: SalesTeamFilter
  role?: string
}) => {

  const {value:a, onChange:b, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [_activeKey, setActiveKey] = useState<React.Key>();
  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  const handleCancel = () => {
    setIsOpen(false);
  }

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleSalesTeamSelected = (salesTeam:SalesTeam | undefined) => {
    props.onChange?.(salesTeam);
    setIsOpen(false);
  }

  const btn = props.children ? <span style={btnStyle}>{props.children}</span>
      : <SalesTeamDescription 
        listStyle={{listStyle: "none", padding:0, margin: 0}}
        salesTeam={props.value} 
        role={props.role}
        {...{ ...btnProps, onClick: () => setIsOpen(true) }} 
      />

  return <>
    {btn}
    <ModalWizard
      open={isOpen}
      width={"50rem"}
      onCancel={handleCancel}
      showSteps={false}
      onStep={handleStep}
      steps={[
        useSelectSalesTeamStep( {
          key:2,
          value: props.value,
          filter: props.filter,
          onChange: handleSalesTeamSelected,
          onCancel: handleCancel,
        }),
      ]}
    />

  </>;

}

const SelectSalesTeamButton = (props: Omit<BMButtonProps, "id" | "value" | "onClick" > & {
  value?:SalesTeam | undefined
  onClick:(s:SalesTeam | undefined) => void
  onAdd?:() => void
  role?:string
}) => {

  const {value:a, onClick:b, onAdd:c, ...btnProps} = props;

  const btn = props.children ? <span style={btnStyle}>{props.children}</span>
      : <SalesTeamDescription 
        salesTeam={props.value} 
        role={props.role}
        listClassName="csl"
        {...{ ...btnProps, onClick: () => props.onClick(props.value) }} 
      />;

  return <Row>
    {btn}
  {!!props.onAdd && <Button style={{marginLeft: "1rem"}} icon={<PlusOutlined/>} type="primary" shape="circle" size="small" onClick={props.onAdd} />}
  </Row>;
}


export const useSelectSalesTeamStep = (props: {
  key: React.Key
  hidden?: boolean
  value: SalesTeam | undefined
  filter?: SalesTeamFilter
  onChange?:(t:SalesTeam | undefined) => void
  onCancel?:() => void
}) : WizardStep => {

  const configurator = useContext(ConfiguratorContext);
  const [selectedSalesTeam, setSelectedSalesTeam] = useState<SalesTeam | undefined>();
  const [form] = Form.useForm();

  const [activeKey, setActiveKey] = useState<React.Key>();
  //note: reference used because steps change visibility
  const wizardInstance = useRef<WizardInstance>();

  useEffect(() => {
    setSelectedSalesTeam(props.value);
    wizardInstance.current?.resetSteps();
  }, [props.value] );

  const isDealer = configurator.isDealerSales();

  const handleStep = (instance:WizardInstance | undefined, step:WizardStep | undefined) => {
    setActiveKey(step?.key);
    wizardInstance.current = instance;
  };

  const handleCancel = () => {
    props.onCancel?.();
  }

  const handleSelectSalesTeam = (salesTeam:SalesTeam | undefined) => {
    setSelectedSalesTeam( salesTeam );
  }

  const handleNew = async () => {
    setSelectedSalesTeam( undefined );
    wizardInstance.current?.nextStep();
  }

  const handleSelected = async () => {
    props.onChange?.(selectedSalesTeam);
  }

  const handleEditSelected = async () => {

    try {
      const values = await form.validateFields() as SalesTeamFormValues;
      const team:SalesTeam = {
        ...values
      };

      props.onChange?.(team);
    }
    catch(e:any) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const handleDeleteSalesTeam = () => {
    props.onChange?.(undefined);
  }

  const steps:WizardStep[] = [
    {
      key: "selectSalesTeam",
      title: "Select Sales Team",
      hidden: !!props.value && !isDealer,
      body: (nav) => <>
        {(configurator.isAdmin() || configurator.isSalesDesk()) &&
        <div style={{width: "100%", display: "flex", flexDirection:"row-reverse", gap: ".4rem", marginBottom: ".4rem", padding: "0rem .3rem 0rem .3rem" }}>
          <Button type="primary" onClick={handleNew} >New</Button>
          <Button type="primary" disabled={!selectedSalesTeam} onClick={nav.nextStep} >Edit</Button>
        </div>}
        <SelectSalesTeam value={selectedSalesTeam} onChange={handleSelectSalesTeam} filter={props.filter} />
      </>,
      footer: (_nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
        <Space>
          <Button key="cancel" onClick={handleCancel}>Cancel</Button>
          <Button key="next" type="primary" onClick={handleSelected} >Done</Button>
        </Space>
      {(props.value && !isDealer) &&
      <Button key="reset" onClick={() => handleDeleteSalesTeam()}>Remove</Button>}
      </div>
    },
   {
    key: "editSalesTeam",
    title: "Edit Sales Team",
    hidden: isDealer,
    body: (_nav) => <SalesTeamForm form={form} initialValues={selectedSalesTeam} />,
    footer: (nav) => <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: "1rem .3rem .3rem .3rem" }}>
      <Space>
          {props.value 
            ? <Button key="cancel" onClick={handleCancel}>Cancel</Button>
            : <Button key="cancel" onClick={nav.prevStep}>Back</Button>}
          <Button key="done" type="primary" onClick={ handleEditSelected} >Done</Button>
      </Space>
      {props.value &&
        <Button key="reset" onClick={() => handleDeleteSalesTeam()}>Remove</Button>}
    </div>
  }

  ];


  const activeStep = steps?.find( s => s.key === activeKey );
  const title = getWizardTitle( wizardInstance.current, activeStep);
  const footer = getWizardFooter( wizardInstance.current, activeStep);

  return {
    key: props.key,
    hidden: props.hidden,
    body: () => 
      <Wizard 
        key="selectSalesTeamWizard"
        steps={steps} 
        showSteps={false}
        onStep={handleStep}
      />,
    title: () => title,
    footer: () => footer,
  }
}

const defaultSort = {
  columnKey: 'user.name',
  order: 'ascend' as SortOrder
};
export const SelectSalesTeam = (props:{
  value?:SalesTeam | undefined
  onChange?: (s:SalesTeam | undefined) => void
  filter?: SalesTeamFilter
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [selectedRow, setSelectedRow] = useState<number | undefined>();
  const [salesTeamLst, salesTeamLstAsync] = useAsyncState<SalesTeam[]>([]);
  const [filter, setFilter] = useState<SalesTeamFilter | undefined>();
  const [sort, setSort] = useState<SortResult<SalesTeam>>(defaultSort);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    current: 1,
  });
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  useEffect(() => {
    setSelectedRow(props.value?.id);
  }, [props.value])

  useEffect(() => {
    reloadSalesTeams();
  }, [sort, pagination.current ])

  useEffect(() => {
    const nextFilter = {
      ...filter,
      ...props.filter
    };
    setFilter(nextFilter);
    loadSalesTeams( salesTeamLstAsync, pagination, nextFilter, sort );
  }, [props.filter])


  const reloadSalesTeams = async () : Promise<SalesTeam[] | undefined> => {
    return loadSalesTeams( salesTeamLstAsync, pagination, filter , sort );
  }
  const loadSalesTeams = useCallback(debounce( async (salesTeamLstAsync:AsyncState<SalesTeam[]>, pagination: TablePaginationConfig, filter: SalesTeamFilter | undefined, sorter:SortResult<SalesTeam> ) : Promise<SalesTeam[] | undefined> => {
    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    var sort = [ sorter ].flat()[0];
    salesTeamLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchSalesTeamList({
        ...filter,
        page: ( pagination.current || 1 ) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort: {
          field: sort.columnKey?.toString() ||  defaultSort.columnKey,
          direction: sort.order == 'descend' ? 'desc' : 'asc',
        },
      }, 
        cancelSource.token );
      cancelTokenSourceRef.current = undefined;

      salesTeamLstAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
      return resp.data.content;
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error({message:"Sales Teams failed to load. " + errorMsg});
      salesTeamLstAsync.setFail( e.message );
    }

  }, 600 ), [] );

  const handleSelectRow = (record:SalesTeam) => {
    setSelectedRow( record.id );
    props.onChange?.(record);

  }

  const tableOnChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: SalesTeamSort) => {
    setPagination(pagination);

    setSort(sorter);
  };

  const handleChangeSearch = (search:string | undefined) => {
    const nextFilter:SalesTeamFilter = {
      ...props.filter,
      ...filter,
      search
    };
    setFilter(nextFilter);
    loadSalesTeams( salesTeamLstAsync, pagination, nextFilter, sort );
  }


  return <div key="salesTeamSelection">
    <div style={{width: "100%"}}>
      <Input onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search teams" allowClear />
    </div>

    <style>
      {`
      .dialog-salesTeamLst .ant-table-content { padding: 5px; } /* don't clip corners */
      .dialog-salesTeamLst .ant-table-cell { border: none !important; } /* remove table cell borders */
      /* add error border to table */
      .ant-form-item-has-error .dialog-salesTeamLst .ant-table-content {
      border: solid 1px #ff4d4f;
      border-radius: 15px;
      }
      `}
    </style>
    <Table 
      className="dialog-salesTeamLst"
      columns={ [ 
        {
          title: "Sales",
          render: (st:SalesTeam) => st?.sales?.map(u => u.name ).join(", ") || "None"
        },
        {
          title: "Engineering",
          render: (st:SalesTeam) => st?.engineers?.map(u => u.name ).join(", ") || "None"
        },
        {
          title: "Support",
          render: (st:SalesTeam) => st?.support?.map(u => u.name ).join(", ") || "None"
        },
      ]}
      rowKey="id"
      size="small"
      pagination={pagination}
      onChange={tableOnChange}
      loading={salesTeamLstAsync?.isLoading()}
      dataSource={salesTeamLst}
      rowSelection={{
        type:  "radio",
        onSelect: handleSelectRow,
        hideSelectAll: true,
        selectedRowKeys: selectedRow ? [ selectedRow ] : []
      }}
    />
  </div>;

}





export default SelectSalesTeamButton;


