import { Button, Col, Divider, Form, Input, InputNumber, Modal, ModalProps, notification, Result, Row, Select, Spin, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ConfiguratorContext } from "../context";
import { BaseQuote, ShippingDestination } from "../api/models";
import {useAsyncState} from "../hook/useAsyncState";

const ShippingDestinationView = () => {
  const params = useParams<{id?:string}>();
  const configurator = useContext(ConfiguratorContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [shippingDestination, shippingDestinationAsync] = useAsyncState<ShippingDestination>();
  const [quoteLst, quoteLstAsync] = useAsyncState<Array<BaseQuote>>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
 
  useEffect(() => {
    loadShippingDestination(Number(params.id));
  }, [params.id]);

  useEffect(() => {
    form.resetFields();
  }, [shippingDestination]);


  const isNew = ( id?:number ) => {
    if ( id !== undefined ) return id === 0;

    return Number( params.id ) === 0;
  }

  const loadShippingDestination = async ( id:number) : Promise<ShippingDestination | undefined> => {
    //bail on new
    if( isNew(id) ) return;

    try {
      shippingDestinationAsync.setLoading();
      const shippingDestination = (await configurator.api.getShippingDestination( id ) ).data;

      shippingDestinationAsync.setDone( shippingDestination );

      return shippingDestination;
    }
    catch(e:any) {
      shippingDestinationAsync.setFail( e.message );
      notification.error({message: "Failed to load at this time. " + e.message});
    }

    return;
  };

  const previewSaveDestination = async ( id:number) : Promise<Array<BaseQuote> | undefined> => {

    //bail on new
    if( isNew(id) ) return;

    try {
      quoteLstAsync.setLoading();
      const quoteLst = (await configurator.api.previewUpdateShippingDestination( id )).data;

      quoteLstAsync.setDone( quoteLst );

      return quoteLst;
    }
    catch(e:any) {
      shippingDestinationAsync.setFail( e.message );
      notification.error({message: "Failed to preview at this time. " + e.message});
    }

    return;
  };

  const onSaveForm = async (values: any) => {

    var hasCostChanged = values.centsPerMile != shippingDestination?.centsPerMile ||
      values.miles != shippingDestination?.miles;

    const quoteLst = hasCostChanged 
        ? await previewSaveDestination(Number(params.id))
        : [];

    if ( !quoteLst?.length ) {
      await saveDestination( values );
      return;
    }
    else {
      setShowConfirmModal(true);
    }
  }

  const handleConfirm = async () => {
      setShowConfirmModal(false);

    const values = form.getFieldsValue(true);
    await saveDestination( values );
  }

  const handleConfirmCancel = async () => {
      setShowConfirmModal(false);

      quoteLstAsync.setInit();
  }

  const saveDestination = async (values: any) => {

    //if no shipping destination but an id given then not loaded, bail
    if (!shippingDestination && Number(params.id) ) return;

    try {

      shippingDestinationAsync.setLoading();
      const resp = isNew()
        ? await configurator.api.createShippingDestination(values)
        : await configurator.api.updateShippingDestination(shippingDestination?.id || 0, values);

      shippingDestinationAsync.setDone(resp.data);
      notification.success({message: "Saved successfully." });

      if ( isNew() ) history.push( "/shipping-destination/" + resp.data.id );

    } catch (e:any) {
      shippingDestinationAsync.setFail(e.message);
      notification.error({message: "Failed to save at this time. " + e.message});
    }
  };

  const handleDelete = async () => {
    Modal.confirm( {
      title: "Confirm Delete",
      content: `Are you sure you want to delete ${shippingDestination?.name}?`,
      onOk: deleteShippingDestination
    })
  };

  const deleteShippingDestination = async () => {
    if (! shippingDestination ) return;

    try {
      shippingDestinationAsync.setLoading();
      await configurator.api.deleteShippingDestination(shippingDestination.id);
      shippingDestinationAsync.setDone(undefined);

      notification.success({message: "Deleted successfully."});
    } catch (e:any) {
      shippingDestinationAsync.setFail(e.message);
      notification.error({message: "Failed to delete at this time. " + e.message});
    }
  };


  //detect delete
  if ( shippingDestinationAsync.isDone() && !shippingDestination ) {
    return <div className="site-layout-background">
      <Result
        status="info"
        title={"Shipping Destination Deleted!"}
        extra={ <Button onClick={history.goBack} type="primary" key="console"> Back </Button>
        }
      />
    </div>
  }

  return <div className="site-layout-background">
    <Spin spinning={shippingDestinationAsync.isLoading()} >
      <div style={{width: "40rem"}} >
        <Form 
          form={form}
          name="shippingDestination"
          labelCol={{ span: 8 }} 
          onFinish={onSaveForm} 
          labelAlign="right" 
          initialValues={shippingDestination}
        >
          <Row gutter={12} style={{marginBottom: "2rem"}} >
            <Col span={14}>
                <Title level={2}>Shipping Destination</Title>
            </Col>
            <Col>
              <Button onClick={handleDelete} danger disabled={!shippingDestination}>Delete</Button>
            </Col>
            <Col>
              <Button onClick={history.goBack} >Cancel</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">Save</Button>
            </Col>
          </Row>
          <Form.Item
            rules={[{ required: true, message: "Name is required" }]}
            label="Shipping Destination Name"
            name="name"
          >
            <Input placeholder="Location, STATE(two-letter abbreviation)"/>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Miles is required" }]}
            label="Miles"
            name="miles"
          >
            <InputNumber min={0} max={1000000000} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Cents Per Mile is required" }]}
            label="Cents Per Mile"
            name="centsPerMile"
          >
            <InputNumber min={0} max={1000000000}  step="0.01"/>
          </Form.Item>

          <Divider orientation="left" style={{marginTop: "3rem"}} >Address</Divider>
          <Form.Item
            label="Line 1"
            name={["customer", "addressLine1"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Line 2"
            name={["customer", "addressLine2"]}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            label="City" 
            name={["customer", "city"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="State"
            name={["customer", "stateProvince"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Postal Code"
            name={["customer", "postalCode"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Country"
            name={["customer", "countryCode"]}
          >
            <Select 
              showSearch
              options={[
                {label: "United States", value: "US" },
                {label: "Canada", value: "CA" },
              ]} />
          </Form.Item>

          <Divider orientation="left" style={{marginTop: "3rem"}} >Contact</Divider>

          <Form.Item
            label="Title"
            name={["customer", "contactTitle"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name={["customer", "contactName"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Role"
            name={["customer", "contactRole"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name={["customer", "contactPhone"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name={["customer", "contactEmail"]}
          >
            <Input />
          </Form.Item>

        </Form>
      </div>
    </Spin>
    <ConfirmModal 
      open={showConfirmModal}
      quoteLst={quoteLst}
      onOk={handleConfirm}
      onCancel={handleConfirmCancel}
    />
  </div>;
};


const ConfirmModal = (props:ModalProps & {
  quoteLst: Array<BaseQuote> | undefined
}) => {

  const { quoteLst, ...modalProps } = props;

  return <Modal
    {...modalProps}
    style={{maxWidth: "60rem"}}
  >
    <style>
      {`
        .alert-update-destination td {
          padding-top: .1rem !important;
          padding-bottom: .1rem !important;
          border: none !important;
        }
        `}
    </style>
    <Result
      className="alert-update-destination"
      status="warning"
      title="Are you sure you want to update the destination?"
      extra={<>
        {!!quoteLst?.length && <>
        <div style={{marginBottom: ".4rem"}}>
          The pricing on the following {quoteLst?.length} quotes will be updated:
        </div>
        <div style={{display:"flex", alignItems: "center", flexFlow: "column", width: "100%"}}>
          <div>
            <Table 
              showHeader={false}
              columns={[
                {
                  title: "Quote",
                  dataIndex: "quoteId",
                  render: (v) => <Link to={"/configurator/" + encodeURIComponent(v) }>{v}</Link>
                }
              ]}
              dataSource={quoteLst?.sort((a,b) => b.quoteId.localeCompare(a.quoteId) )?.map(q => ({quoteId:q.quoteId}))}
              pagination={{
                pageSize: 10,
                position: ["bottomCenter"]
              }}
              rowKey="quoteId" 
              style={{backgroundColor: "transparent"}}
            />
          </div>
        </div>
        </>}
      </>}
    />
  </Modal>

}


export default ShippingDestinationView;
