import { Col, InputNumber, Modal, Row, Table, TableColumnsType, TablePaginationConfig, notification, Form, ModalProps, Result, Checkbox } from "antd";
import { Assembly, PricingConfig, PricingSnapshotAssembly } from "../api/models";
import Utils from "../util/util";
import { ReactNode, useContext, useState } from "react";
import { ConfiguratorContext } from "../context";
import BMButton from "./BMButton";
import {Link} from "react-router-dom";
import {useAsyncState} from "../hook/useAsyncState";


const AssemblyCostHistory = (props: {
  assembly?: Assembly;
  latestPricing?: PricingConfig;
  onCostsUpdated?: () => void;
}) => {
    const configurator = useContext(ConfiguratorContext);

    const [snapshotsSelected, setSnapshotsSelected] = useState<PricingSnapshotAssembly[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        pageSize: 10,
        pageSizeOptions: [5, 10, 25, 50, 100],
        showSizeChanger: true,
    });
    const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
    const [updateMaterialCost, setUpdateMaterialCost] = useState<number>();
    const [updateLaborHours, setUpdateLaborHours] = useState<number>();
    const [updatingSnapshotCosts, updatingSnapshotCostsAsync] = useAsyncState<boolean>(false);
    const [snapshotAssemblyQuotes, snapshotAssemblyQuotesAsync] = useAsyncState<string[]>();

    const updateSnapshotCosts = async (assemblyId: number, pricingSnapshotAssemblyIds: number[], standardMaterialCost?: number, laborHours?: number ) : Promise<boolean> => {
      if(!props.assembly) return false;
      try {
        updatingSnapshotCostsAsync.setLoading();
        await configurator.api.updateSnapshotCosts(assemblyId, {
          pricingSnapshotAssemblyIds,
          standardMaterialCost,
          laborHours,
        })
        notification['success']({
          message: 'Updated',
          description: 'The snapshot costs have been updated successfully.'
        });
        updatingSnapshotCostsAsync.setDone(true);
        return true;
      }
      catch (e:any) {
        notification['error']({
          message: 'Failed to update',
          description: 'Unable to update price snapshot data at this time.'
        });
        updatingSnapshotCostsAsync.setFail('Unable to update price snapshot data at this time.');
      }

      return false;
    };

    const handleUpdateSnapshotCosts = async () => {
      if( !props.assembly ) return;

      var pricingSnapshotAssemblyIds = snapshotsSelected.map(s => s.id);
      var rslt = await updateSnapshotCosts( props.assembly.id, pricingSnapshotAssemblyIds, updateMaterialCost, updateLaborHours  );

      setShowUpdateConfirmation(false);
      if ( rslt ) {
        props.onCostsUpdated?.()
      }
    }

    const getQuotesByAssemblyIdAndPricingSnapshotId = async (assemblyId: number, pricingSnapshotAssemblyIds: number[] ) : Promise<string[] | undefined> => {
      snapshotAssemblyQuotesAsync.setLoading();
      try {
        const approved = false;
        var resp = await configurator.api.getQuotesByAssemblyIdAndPricingSnapshotId( assemblyId, pricingSnapshotAssemblyIds, approved );
        snapshotAssemblyQuotesAsync.setDone(resp.data);
        return resp.data;
      }
      catch (e:any) {
        notification['error']({
          message: 'Failed to update',
          description: 'Unable to fetch affected quotes at this time.'
        });
        snapshotAssemblyQuotesAsync.setFail('Unable to fetch affected quotes at this time.');
      }

      return;
    }

    const handleConfirmUpdatePricing = async () => {
      if( !props.assembly ) return;

      var pricingSnapshotIds = snapshotsSelected.map(s => s.pricingSnapshot.id);
      getQuotesByAssemblyIdAndPricingSnapshotId( props.assembly.id, pricingSnapshotIds );
      setShowUpdateConfirmation(true);
    }

    const selectAllPage = () => {
        setSelectAllChecked(!selectAllChecked);
        if (props.assembly?.pricingSnapshots && pagination.pageSize) {
            const currentPage = pagination.current || 1;
            const startIndex = (currentPage - 1) * pagination.pageSize;

            const items = props.assembly?.pricingSnapshots.slice(startIndex, startIndex + pagination.pageSize);

            if (!selectAllChecked) {
                setSnapshotsSelected(snapshotsSelected.concat(items.filter(a => snapshotsSelected.indexOf(a) == -1)));
            }
            else {
                setSnapshotsSelected(snapshotsSelected.filter(a => items.indexOf(a) == -1));
            }
        }
    };

    const selectSnapshot = (s: PricingSnapshotAssembly) => {
        if (!snapshotsSelected?.includes(s)) {
            setSnapshotsSelected([...snapshotsSelected, s]);
        }
        else {
            setSnapshotsSelected(snapshotsSelected.filter(a => a != s));
        }
    };

    const onTableChange = (paginationConfig: TablePaginationConfig) => {
        setPagination(paginationConfig);
        setSelectAllChecked(false);
    };

    const columns: TableColumnsType<PricingSnapshotAssembly> = [
        {
            title: <Checkbox onClick={selectAllPage} checked={selectAllChecked} />,
            width: 25,
            render: (a: PricingSnapshotAssembly) => {
                return <Checkbox onClick={() => selectSnapshot(a)} checked={snapshotsSelected?.includes(a)} />
            },
        },
        {
            title: 'Snapshot',
            render: (a: PricingSnapshotAssembly) => {
                if (a.pricingSnapshot.id == props.latestPricing?.pricingSnapshot?.id ) {
                    return (
                        <strong>{a.pricingSnapshot.name} (Active)</strong>
                    );
                }
                else {
                    return a.pricingSnapshot.name;
                }
            }
        },
        {
            title: 'Material Cost',
            dataIndex: 'standardMaterialCost',
            render: (val, _rec, _ndx) => Utils.formatMoney(val),
        },
        {
            title: 'Labor Cost',
            dataIndex: 'laborCost',
            render: (val, _rec, _ndx) => Utils.formatMoney(val),
        }
    ];


  const notifyDisabled = (msg:string | undefined) => {
    if ( !!msg ) notification.warning({message: msg });
  }
  const getDisabledMsg = () => {
    return !snapshotsSelected.length ? "A snapshot must be selected."
      : ( updateMaterialCost === undefined && updateLaborHours === undefined ) ? "A new material cost or labor hours must be provided."
      : undefined;
  }

  const UpdateSnapshotMsg = (props: {
    snapshot:PricingSnapshotAssembly 
  }) => {
    const { snapshot } = props;

      let msg = new Array<ReactNode>();
      if ( updateMaterialCost !== undefined ) {
        msg.push( <>material cost from <strong>{Utils.formatMoney(snapshot.standardMaterialCost)}</strong> -&gt; &nbsp;
            <strong>{Utils.formatMoney(updateMaterialCost)}</strong></> );
      }
      if ( updateLaborHours !== undefined ) {
        msg.push( <>labor hours from <strong>{snapshot.laborHours}</strong> -&gt; &nbsp;
            <strong>{updateLaborHours}</strong></> );
      }

      return <li key={snapshot.id}>{snapshot.pricingSnapshot.name}, will update { Utils.joinNodes( msg,<> and </>)}.</li>
    }


    return (
        <>
          <Row align="middle" gutter={16} >
            <Col flex="3">
              <Form.Item
                label="Material Cost Adjustment"
              >
                <InputNumber 
                  controls={false}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '100%' }} 
                  value={updateMaterialCost !== undefined ? String(updateMaterialCost) : undefined} 
                  onChange={(e) => setUpdateMaterialCost( e !== undefined ? Number(e) : undefined)} 
                  placeholder="New Cost" />
              </Form.Item>
            </Col>
            <Col flex="3">
              <Form.Item
                label="Labor Hours Adjustment"
              >
                <InputNumber 
                  controls={false}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                  style={{ width: '100%' }} 
                  value={updateLaborHours !== undefined ? String(updateLaborHours) : undefined} 
                  onChange={(e) => setUpdateLaborHours( e !== undefined ? Number(e) : undefined)} 
                  placeholder="New Hours" />
              </Form.Item>
            </Col>
            <Col flex="1" style={{ marginBottom: '25px' }}>
              <div style={{display: "flex", flexDirection: "row-reverse", width: "100%" }}>
                <BMButton type="primary" 
                  onClick={handleConfirmUpdatePricing} 
                  disabled={!!getDisabledMsg()}
                  onDisabledClick={() => notifyDisabled(getDisabledMsg())}
                >Update ({snapshotsSelected.length})</BMButton>
              </div>
            </Col>
          </Row>
          <Table 
            pagination={pagination} 
            onChange={onTableChange} 
            bordered 
            columns={columns} 
            dataSource={props.assembly?.pricingSnapshots?.sort((a, b) => b.pricingSnapshot.id - a.pricingSnapshot.id)}
            rowKey="id" />

          <ConfirmModal
            open={showUpdateConfirmation} 
            okText="Update" 
            onOk={handleUpdateSnapshotCosts} 
            onCancel={() => setShowUpdateConfirmation(false)}
            confirmLoading={updatingSnapshotCostsAsync.isLoading()} 

            snapshotAssemblyQuotes={snapshotAssemblyQuotes}
            snapshotsSelected={snapshotsSelected}
            updateMaterialCost={updateMaterialCost}
            updateLaborHours={updateLaborHours}
          />
        </>
    );
}

const ConfirmModal = (props:ModalProps & {
  snapshotAssemblyQuotes: string[] | undefined
  snapshotsSelected: PricingSnapshotAssembly[] | undefined
  updateMaterialCost: number | undefined
  updateLaborHours: number | undefined
}) => {

  const { snapshotAssemblyQuotes, snapshotsSelected, updateLaborHours, updateMaterialCost, ...modalProps } = props;

  const UpdateSnapshotMsg = (props: {
    snapshot:PricingSnapshotAssembly 
  }) => {
    const { snapshot } = props;

      let msg = new Array<ReactNode>();
      if ( updateMaterialCost !== undefined ) {
        msg.push( <>material cost from <strong>{Utils.formatMoney(snapshot.standardMaterialCost)}</strong> -&gt; &nbsp;
            <strong>{Utils.formatMoney(updateMaterialCost)}</strong></> );
      }
      if ( updateLaborHours !== undefined ) {
        msg.push( <>labor hours from <strong>{snapshot.laborHours}</strong> -&gt; &nbsp;
            <strong>{updateLaborHours}</strong></> );
      }

      return <li key={snapshot.id}>{snapshot.pricingSnapshot.name}, will update { Utils.joinNodes( msg,<> and </>)}.</li>
    }


  return <Modal
    {...modalProps}
    style={{maxWidth: "60rem"}}
  >
    <style>
      {`
        .alert-update-snapshot-costs td {
          padding-top: .1rem !important;
          padding-bottom: .1rem !important;
          border: none !important;
        }
        `}
    </style>
    <Result
      className="alert-update-snapshot-costs"
      status="warning"
      title="Are you sure you want to update the snapshot cost?"
      extra={<>
        {!!snapshotAssemblyQuotes?.length && <>
        <div style={{marginBottom: ".4rem"}}>
          The pricing on the following {snapshotAssemblyQuotes?.length} quotes will be updated:
        </div>
        <div style={{display:"flex", alignItems: "center", flexFlow: "column", width: "100%"}}>
          <div>
            <Table 
              showHeader={false}
              columns={[
                {
                  title: "Quote",
                  dataIndex: "quoteId",
                  render: (v) => <Link to={"/configurator/" + encodeURIComponent(v) }>{v}</Link>
                }
              ]}
              dataSource={snapshotAssemblyQuotes?.sort((a,b) => b.localeCompare(a) )?.map(q => ({quoteId:q}))}
              pagination={{
                pageSize: 10,
                position: ["bottomCenter"]
              }}
              rowKey="quoteId" 
              style={{backgroundColor: "transparent"}}
            />
          </div>
        </div>
        </>}
        <Row style={{ marginTop: '25px' }}>
          <p>The following snapshots will be updated: </p>
        </Row>
        <Row>
          <ul style={{textAlign: "left"}}>
            {snapshotsSelected?.map(snapshot => <UpdateSnapshotMsg key={snapshot.id} snapshot={snapshot} />)}
          </ul>
        </Row>
      </>}
    />
  </Modal>

}



export default AssemblyCostHistory;
