import {Button, Checkbox, Form, notification, Result } from "antd";
import Title from "antd/lib/typography/Title";
import {useForm} from "antd/es/form/Form";
import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import { OrderSubmissionLegalPhrase,  Quote, QuoteReview} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import BMButton, {BMButtonProps} from "../BMButton";
import ModalWizard from "../ModalWizard";
import QuoteReviewDetail from "../QuoteReviewDetail";
import {useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import StatesSelector from "../StatesSelector";
import { buildPoNumberForm, buildPoNumberRequest, PoNumberForm, PoNumberFormValues } from "./PoNumberEditor";
import { WizardInstance } from "../Wizard";
import { PoNumberRequest } from "../../api";
import { useQuoteContext } from "../../contexts/QuoteContext";


const SubmitSalesChangeButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
}) => {

  const { onChange:a, ...btnProps } = props;

  const {quoteAsync, quotePricingDetailsAsync, setQuoteFormValues, validateQuoteForm } = useQuoteContext();
  const quote = quoteAsync?.val;
  const configurator = useContext(ConfiguratorContext);
  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmForm] = useForm();
  const [poNumberForm] = useForm();
  const confirmSubmit = Form.useWatch<boolean>('confirmSubmit', confirmForm);
  const [selectedStateRegistration, setSelectedStateRegistration] = useState<string>();
  const [selectedPoNumber, setSelectedPoNumber] = useState<PoNumberFormValues>();
  const [isQuoteValid, setIsQuoteValid] = useState<boolean>();

  useEffect(() => {
    poNumberForm.resetFields();
  }, [selectedPoNumber])

  useEffect(() => {
    if ( isOpen ) { 
      validateQuoteForm?.()
        .then( validForm => {
          const validState = isValidStateRegistration(quote?.stateRegistration);
          setIsQuoteValid( !!validForm && validState)
        });
    }
  }, [quote, isOpen])

  const isValidStateRegistration = (v:string | undefined) : boolean => {
    return !!v && v !== 'TBD';
  }

  const loadReview = async (quoteRevisionId:number | undefined) : Promise<QuoteReview | undefined> => {
    if ( !quoteRevisionId ) return;

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, {dealerView: false});
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }

  const savePoNumber = async (quoteRevisionId:number | undefined, req:PoNumberRequest) :Promise<Quote | undefined> => {
    if ( !quoteRevisionId) return;

    try {
      quoteAsync?.setLoading();
      const resp = await configurator.api.savePoNumber(quoteRevisionId, req );
      await setQuoteFormValues?.({
        ...resp.data,
      });
      quoteAsync?.setDone(resp.data);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update Po Number. " + errorMsg });
    }
    return;
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  const handleOpen = async () => {
    if (!quote) return;

    await loadReview(quote?.displayRevisionId);

    setSelectedStateRegistration(quote?.stateRegistration);

    const poValues = buildPoNumberForm(quote?.poNumber);
    setSelectedPoNumber({
      ...poValues,
      amount: poValues?.amount || quotePricingDetailsAsync?.val?.totalPrice
    });

    confirmForm.resetFields();

    setIsOpen( true );
  }

  const handleSelectStateRegistration = async (nav:WizardInstance) => {
    if (!quote) return;

    if ( quote.stateRegistration !== selectedStateRegistration ) {
      await setQuoteFormValues?.({
        ...quote,
        stateRegistration: selectedStateRegistration
      });
    }

    nav.nextStep();
  }

  const handleSelectPoNumber = async (nav:WizardInstance) => {

    const formValues = poNumberForm.getFieldsValue(true) as PoNumberFormValues;
    const req = buildPoNumberRequest(formValues);

    await savePoNumber(quote?.displayRevisionId, req);

    nav.nextStep();
  }

  const handleConfirmSubmitBtn = async (nav:WizardInstance) => {

    if ( !isQuoteValid ) {
      nav.resetSteps();
      return;
    }

    const quote = await submit();
    if ( quote ) {
      props.onChange?.(quote);

      setIsOpen(false);
    }
  }

  const submit = async () : Promise<Quote|undefined> => {
    if (!quote) return;

    quoteAsync.setLoading();
    try {
      const resp = await configurator.api.submitOrder(quote.displayRevisionId);
      quoteAsync.setDone(resp.data);

      notification["success"]({
        message: "Submitted",
        description: "Your order has been submitted for approval",
      });

      return resp.data;
    } catch (e: any) {

      const errorMsg = intl.formatMessage({ id: e.message });
      const msg = "Failed to submit order. " + errorMsg
      notification.error( { message: msg, duration: 0 });
    }

    return;
  };
 
  const isWorking = props.loading || quoteAsync?.isLoading() || reviewAsync.isLoading()

  return <>
    <BMButton
      type="primary"
      onClick={handleOpen}
      {...btnProps}
      loading={isWorking}
    >
      Submit Order
    </BMButton>
    <ModalWizard
      showSteps={false}
      centered
      open={isOpen}
      onCancel={handleCancel}
      width={'50rem'}
      steps={[
        {
          key:"review",
          hidden: !Utils.reviewHasErrors(review),
          body: (_nav) => <Result status="warning" 
            title={"The following issues with the order have been identified."}
            subTitle={<Title level={5}>Continue to submit for approval?</Title> }
          >
            <QuoteReviewDetail 
              review={review} 
              ignoreDashDrawing={true} 
            />
          </Result>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
            <Button key="back" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"stateRegistration",
          body:(_nav) => <div>
            <div style={{display:"flex", flexDirection: "column", marginTop: "2rem", alignItems: "center", gap: "1rem"}} >
            <Title level={5}>Is the state of registration correct?</Title>
              <StatesSelector data-testid="submitStateRegistrationSelector" includeTbd={false} allowClear={false} value={selectedStateRegistration} 
                onChange={setSelectedStateRegistration} 
                style={{width: "10rem"}}
              />
            </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" disabled={!isValidStateRegistration(selectedStateRegistration)} loading={isWorking} onClick={() => handleSelectStateRegistration(nav)} >Next</Button>
            <Button key="cancel" type="primary" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"poNumber",
          body:(_nav) => <div>
            <div style={{display:"flex", flexDirection: "column", marginTop: "2rem", alignItems: "center", gap: "1rem"}} >
              <Title level={5}>Is the Product Order (PO) information accurate?</Title>
              <PoNumberForm 
                form={poNumberForm} 
                initialValues={selectedPoNumber}
              />
            </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" loading={isWorking} onClick={() => handleSelectPoNumber(nav)} >Next</Button>
            <Button key="cancel" type="primary" onClick={handleCancel} >Cancel</Button>
          </div>,
        },
        {
          key:"confirm",
          body: (_nav) => 
              <Result status="info">
              <Form form={confirmForm}>
                <Form.Item name="confirmSubmit" valuePropName="checked" style={{marginBottom: "0px"}}>
                  <Checkbox data-testid="confirmSubmitOrder" />
                </Form.Item>
                <div dangerouslySetInnerHTML={{ __html: OrderSubmissionLegalPhrase || '' }}></div>
              </Form>
              </Result>,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" disabled={!confirmSubmit} loading={isWorking} onClick={() => handleConfirmSubmitBtn(nav)} >Submit</Button>
              <Button key="back" onClick={handleCancel} >Cancel</Button>
            </div>,
        }
      ]}    
    />
  </>
}

export default SubmitSalesChangeButton;
