import {Button, ButtonProps, message} from "antd";
import { FunctionComponent, ReactNode, ReactElement} from "react";

//borrowed from stack overflow
//https://stackoverflow.com/a/60565322
const getNodeText = (node:ReactNode):string|undefined => {
  if (['string', 'number'].includes(typeof node)) return String(node);
    if (node instanceof Array) return node.map(getNodeText).join('')
      if (typeof node === 'object' && node) return getNodeText((node as ReactElement).props.children)
}

export interface BMButtonProps extends ButtonProps {
  onDisabledClick?: ()=>void
}
const BMButton: FunctionComponent<BMButtonProps> = (props) => {  

  const { onDisabledClick:a, ...btnProps } = props;

  const isDisabled = ( props.disabled  );
  if ( isDisabled ) {

    const backgroundColor = props.type === "text" ? undefined : "rgba(0, 0, 0, 0.04)";
    return <Button {...btnProps} 
      disabled={false}
      className={ btnProps.className || "disabledBmButton"}
      style={{
        cursor: "not-allowed",
        color: "rgba(0, 0, 0, 0.25)",
        ...(btnProps.style),
        backgroundColor
      }}
      onClick={props.onDisabledClick}
      onContextMenu={(e) => {
        const txt = getNodeText(props.children);
        if( txt ) {
          e.preventDefault();
          navigator.clipboard.writeText(txt).then( () => {
            message.info("copied!");
          }) ;
        }
      }}
    />
  }

  return <Button {...btnProps} />
};
export default BMButton;
