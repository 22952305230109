import Title from "antd/lib/typography/Title";
import { Table, Input, Popover, Button, TablePaginationConfig, notification, Space, } from "antd";
import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../context";
import dayjs from 'dayjs'
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { SyncStatus } from "../api/models";
import { ColumnType, FilterValue, SorterResult, TableCurrentDataSource } from "antd/es/table/interface";
import { Link } from "react-router-dom";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { debounce } from "lodash";
import { useIntl } from "react-intl";

const DEFAULT_PAGE_SIZE = 20;

const gotoQuote = (quoteId:string) => window.open("/configurator/" + encodeURI(quoteId), "_blank");

const EpicorSyncStatus = () => {
  const intl = useIntl();

  const [syncStatusLst, syncStatusLstAsync] = useAsyncState<SyncStatus[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [dataFilter, setDataFilter] = useQueryParam<any>("filter", StringParam);
  const [pageSizeQuery, setPageSizeQuery] = useQueryParam("nr", NumberParam);
  const [currentPage, setCurrentPage] = useQueryParam("p", NumberParam);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["bottomLeft"],
    pageSize: pageSizeQuery == null || pageSizeQuery > 500 ? DEFAULT_PAGE_SIZE : pageSizeQuery,
    current: currentPage == null || currentPage < 1 ? 1 : currentPage,
  });

  useEffect(() => {
      reloadSyncStatus();
  }, [dataFilter, pagination.pageSize, pagination.current]);

  useEffect(() => {
    setPageSizeQuery(pagination.pageSize);
    setCurrentPage(pagination.current);
  }, [pagination]);

  const reloadSyncStatus = () => {
      loadSyncStatus( syncStatusLstAsync, pagination, dataFilter );
  }

  const loadSyncStatus = useCallback(debounce( async (syncLstAsync:AsyncState<SyncStatus[]>, pagination: TablePaginationConfig, filter: string ) : Promise<SyncStatus[] | undefined> => {

    syncLstAsync.setLoading()
    try {
      const resp = await configurator.api.epicorSyncStatus({
        page: ( pagination.current || 1 ) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        filter: filter,
      });
      const lst = resp.data.content;
      syncLstAsync.setDone(lst);
      setPagination({ ...pagination, total: resp.data.totalElements });

      return lst
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error({message:"Epicor Sync Status failed to load. " + errorMsg});
      syncLstAsync.setFail( e.message );
    }
    return;
  }, 400 ), [] );

  const columns:ColumnType<SyncStatus>[] = [
    {
      title: "Quote ID",
      key: "quoteId",
      render: (s:SyncStatus) => <><Link to={"/configurator/" + encodeURI(s.quoteId)}
        onClick={(e) => {
          gotoQuote(s.quoteId);
          e.preventDefault();
        }}
      >{s.quoteId}</Link></>,
    },
    {
      title: "Part Number",
      key: "partNumber",
      render: (s: SyncStatus) => {
        return s.partNumber + ' (Rev ' + s.revision + ')';
      }
    },
    {
      title: "Sync Status",
      dataIndex: 'erpSyncStatus',
      key: "erpSyncStatus",
    },
    {
      title: "Sync Message",
      key: "erpSyncMessage",
      render: (s: SyncStatus) => {
        return (
          <Popover content={(<pre>{s.erpSyncMessage}</pre>)}>
            <div style={{ width: '300px', whiteSpace: 'nowrap', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis' }}>{s.erpSyncMessage}</div>
          </Popover>
        );
      }
    },
    {
      title: "Last Sync Update",
      key: "erpSyncLastUpdatedAt",
      render: (q) => (
        <span>{dayjs(q.erpSyncLastUpdatedAt).format("MMMM Do YYYY, h:mm:ss a")}</span>
      ),
    },
  ];

  const tableOnChange = async (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, _sorter: SorterResult<SyncStatus> | SorterResult<SyncStatus>[], _extra: TableCurrentDataSource<SyncStatus>) => {
    setPagination(pagination);
  };

  const onFilterChange = (e:ChangeEvent<HTMLInputElement>) => {
    setDataFilter(e.target.value);
    setPagination({ ...pagination, current: 1 });
  };

  return <div className="site-layout-background">
    <Space direction="vertical" size="small" style={{ display: 'flex' }}>

      <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
        <Title level={2}>Epicor Sync Status</Title>
        <Button type="primary" href={configurator.api.baseUrl + '/v1/epicor/sync-status.csv'}>Export CSV</Button>
      </div>

      <div style={{width: "100%"}}>
        <Input
          allowClear
          onChange={onFilterChange}
          value={dataFilter}
          placeholder="Search by Part Number or Quote ID"
        />
      </div>

      <Table
        loading={syncStatusLstAsync.isLoading()}
        onChange={tableOnChange}
        bordered
        pagination={pagination}
        dataSource={syncStatusLst}
        columns={columns}
        rowKey={(record) => record.quoteId + "-" + record.revision + "-" + record.erpSyncMessage + "-" + record.erpSyncLastUpdatedAt}
      />

    </Space>
  </div>
};

export default EpicorSyncStatus;
