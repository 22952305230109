import { useContext, useEffect, useState } from "react";
import { Dealer } from "../api/models";
import { ConfiguratorContext } from "../context";
import { Select, Spin } from "antd";

const DealerSelector = (props: {
  value?: string;
  onChange?: (dealer?: string) => void;
  placeholder?:string
  style?:any
}) => {

  const configurator = useContext(ConfiguratorContext);
  const [dealers, setDealers] = useState<Dealer[] | undefined>(
    undefined
  );

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const resp = await configurator.api.getDealers();
    setDealers(resp);
  }

  if (!dealers) {
    return <Spin />;
  }

  return (
    <Select
      onChange={props.onChange}
      placeholder={props.placeholder}
      defaultValue={props.value}
      showSearch={true}
      optionFilterProp="children"
      allowClear={true}
      style={props.style}
      filterOption={(input, option) => {
        if (option && option.children) {
          return option.children
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase());
        } else {
          return false;
        }
      }}
    >
      {dealers.map((dealer) => (
        <Select.Option key={dealer.id} value={dealer.id}>
          {dealer.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DealerSelector;
