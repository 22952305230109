import { Modal, Result } from 'antd';
import { useEffect, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';

const UnsavedChangesWarning = (props: {
  isDirty:boolean, 
}) => {
  let { isDirty } = props;

  const history = useHistory();
  const go = useRef<boolean>(false);

  useEffect(() => {
    const lastLocation = window.location;
    const unblock = history.block((location: Location) => {

      if ( go.current ) {
        return;
      }
      
      const hasPathChanged = location.pathname !== lastLocation.pathname 
      if (isDirty && hasPathChanged ) {
        Modal.confirm({
          icon:<></>,
          content: <Result 
            status={"warning"}
            title={"Are you sure you want to leave?"}
            subTitle={"If you leave this page, changes will be lost."}
          />,
          onOk() {
            go.current = true;
            unblock();
            history.push(location);
          },
        });
        return false;
      }
      return;
    });

    return () => {
      unblock();
    };
  }, [isDirty]);


  return <></>
}

export default UnsavedChangesWarning;
