import { Button, DatePicker, Divider, Form, Input, Modal, Tooltip, notification } from "antd";
import { useState } from "react";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";
import BMButton, {BMButtonProps} from "../BMButton";
import {RangePickerProps} from "antd/es/date-picker";
import { TruckRequestedShippingDto } from "../../api/models";

export interface RequestedShipping {
  customerShippingDate: Date
  cadence: string
  truckCustomerShippingDateList: TruckRequestedShippingDto[]
}
const CustomerShippingInfo = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  id?:string
  value?: RequestedShipping
  onChange?: (v: RequestedShipping | undefined) => void
}) => {
  const { id, value, onChange, ...buttonProps} = props;

  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleCancel = () => {
    setOpen(false);
  }

  const handleDone = async () => {
    try {
      var values = {...await form.validateFields()};

      const obj = {
        customerShippingDate: values.customerShippingDate,
        cadence: values.cadence,
        truckCustomerShippingDateList: values.truckCustomerShippingDateList.map(t => {
          if (!!t.truckCustomerShippingDate && dayjs.isDayjs(t.truckCustomerShippingDate)) {
            t.truckCustomerShippingDate = t.truckCustomerShippingDate.format('YYYY-MM-DD')
          }
          return t;
        }),
      };
      onChange?.( obj );
      setOpen(false);

    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const resetTargetValue = () => {
    form.resetFields();
    form.validateFields();
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    var endOfDay = dayjs().endOf('day');
    var eighteenMonths = dayjs().add(18, "month");
    return current < endOfDay || current > eighteenMonths;
  };

  const btnLbl = value?.customerShippingDate ? dayjs(value?.customerShippingDate).format('M/D/YYYY')
    : "Request Shipping Date"

    const info = value?.cadence || "Specify contract dates and per truck requested ship dates.";

  //hide border when disabled
  const btnStyle =  ( props.disabled )
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

    return <>
  <style>
      {`
        .shipping-info-icon {
          margin-top: 2px !important;
        }
      `}
    </style>
      <div style={{display:"flex", gap: ".4rem"}}>
          <BMButton type="text"
            className="ghostBmButton"

            {...buttonProps}
            onClick={handleOpen}
            style={{padding: "0"}}
          ><span style={btnStyle}>{btnLbl}</span></BMButton>
          <Tooltip title={info} data-testid="requested-shipping-tooltip" className="shipping-info-icon">
            <ExclamationCircleTwoTone data-testid="requested-shipping-icon"/>
          </Tooltip>
      </div>
      <Modal
        open={open}
        onCancel={handleCancel}
        onOk={handleDone}
        okText="Done"
        title="Requested Shipping Details"
        afterOpenChange={resetTargetValue}
      >
        <Form form={form} 
          initialValues={value}
          layout="vertical"
        >
          <Form.Item
            label="Order Requested Shipping Date:"
            name="customerShippingDate"
          >
            <DatePicker
              style={{ width: "100%" }}
              disabledDate={disabledDate}
              allowClear={true}
              data-testid="request-date-picker"
            />
          </Form.Item>
          <Form.Item
            label="Schedule Notes:"
            name="cadence"
          >
            <Input.TextArea allowClear />
          </Form.Item>

          <Divider/>

          <Form.List
            name="truckCustomerShippingDateList"
          >
            {(fields) => (
              <>
                {fields.map((truckDate, idx) => {
                  return (
                    <div key={idx}>
                      <Form.Item
                        name={[truckDate.name, "truckCustomerShippingDate"]}
                        label={`Unit ${value?.truckCustomerShippingDateList[truckDate.key]?.serialNumber}`}
                        getValueProps={(i) => ({value: !!i ? dayjs(i) : undefined})}
                      >
                        <DatePicker style={{width: "50%"}} placeholder=" Specify customer shipping date to this truck."/>
                      </Form.Item>
                    </div>
                  );
                })}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
}

export default CustomerShippingInfo;
