import { Button, Col, Input, Modal, notification } from "antd";
import { GlobalExpressionDefinition, Permission, RuleExpression, RuleSet } from "../../api/models";
import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../context";
import RuleExpressionEditor from "./expression";
import { useAsyncState } from "../../hook/useAsyncState";

const EditGlobalExpressionModal = (props: {
    open: boolean | undefined,
    ruleSet: RuleSet | undefined,
    definition: GlobalExpressionDefinition | undefined,
    id?: string;
    onFinished?: (arg0: GlobalExpressionDefinition | undefined) => void;
    onCancel?: () => void;
}) => {
    const [definition, definitionAsync] = useAsyncState<GlobalExpressionDefinition>();
    const configurator = useContext(ConfiguratorContext);
    const [readOnly, setReadOnly] = useState(true);

    const [history, setHistory] = useState<GlobalExpressionDefinition[]>([]);

    useEffect(() => {
      setHistory(props.definition ? [ props.definition ] : []);
      definitionAsync.setDone(props.definition);
    }, [props.definition]);


    function onUpdate(expr: RuleExpression) {
        const newDef = {...definition} as GlobalExpressionDefinition;
        newDef.expression = expr;
        Object.setPrototypeOf(newDef, GlobalExpressionDefinition.prototype);
        definitionAsync.setDone(newDef);
        setHistory([...history, newDef]);
    }

    function onUpdateName(name: string) {
        const newDef = {...definition} as GlobalExpressionDefinition;
        newDef.name = name;
        Object.setPrototypeOf(newDef, GlobalExpressionDefinition.prototype);
        definitionAsync.setDone(newDef);
    }

    async function onOk() {
        if(!definition?.name) return;
        if(!props.ruleSet) return;

        try {

            definitionAsync.setLoading();

            if( !props.id?.length ) {
                await configurator.api.createGlobalExpression(props.ruleSet.id, {
                    definition
                });
            }
            else {
                await configurator.api.updateGlobalExpression(props.ruleSet.id, {
                    id: props.id, definition,
                });
            }

            definitionAsync.setDone(definition);

            props.onFinished?.(definition);
        }
        catch (e:any) {
            const message = 'Expression failed to update: ' + e;
            definitionAsync.setFail(message);
            notification.error({ message });
        }
    }

    function onUndo() {
        definitionAsync.setDone(history[history.length - 2]);
        setHistory(history.slice(0, history.length - 1));
    }

    return <Modal 
      width="75%" 
      open={props.open} 
      confirmLoading={definitionAsync.isLoading()}
      okText="Save" 
      onOk={onOk} 
      onCancel={props.onCancel} 
      okButtonProps={{style:{ display: configurator.hasPermission(Permission.RULES_WRITE) ? "" : "none" }}}
      title={
        <div>
          Global Expression Editor &nbsp;
          {configurator.hasPermission(Permission.RULES_WRITE) &&  <>
            <Button onClick={onUndo} size="small" style={{ marginBottom: '5px' }} type="primary" disabled={history.length <= 1}>Undo</Button>
            <Button onClick={() => setReadOnly(!readOnly)} size="small" style={{ marginLeft: '5px', marginBottom: '5px' }} type="primary">{!readOnly ? 'View Mode' : 'Edit Mode'}</Button>
          </>}
        </div>
      } 
      >
      <strong>Name: </strong>
      <Col span={24}>
        {configurator.hasPermission(Permission.RULES_WRITE) 
          ? <Input value={definition?.name} style={{width: '100%'}} onChange={(e) => onUpdateName(e.target.value)}/>
          :<span>{definition?.name}</span> }
      </Col>
      <RuleExpressionEditor readOnly={readOnly} expression={definition?.expression} onUpdate={onUpdate} />
    </Modal>;
};

export default EditGlobalExpressionModal;
