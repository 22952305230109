import { Alert } from "antd";
import PerformanceDetails from "../PerformanceDetails";
import { AsyncState } from "../../hook/useAsyncState";
import { Performance } from "../../api/models";


const QuotePerformanceTab = (props: {performanceAsync: AsyncState<Performance>}) => {

  const {performanceAsync} = props;
  const performance = performanceAsync.val;

  return (<>
    { performanceAsync.isFail() &&
    <div key="performance-alert">
      <Alert
        type="error"
        showIcon
        closable={true}
        onClose={() => performanceAsync.setDone()}
        message={performanceAsync.err}
      />
      <br/>
    </div>
    }
    <PerformanceDetails performance={performance}/>
  </>);

}

export default QuotePerformanceTab;

