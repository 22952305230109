import { Button, Checkbox, Divider, Form, Input, Modal, notification, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext } from "../context";
import { PermissionKey, User, InternalUser, Permission } from "../api/models";
import UserMultiSelector from "../components/UserMultiSelector";
import { useForm } from "antd/es/form/Form";
import { CancelTokenSource } from "axios";
import { useIntl } from "react-intl";
import Utils from "../util/util";
import DealerSelector from "../components/dealer_selector";

const InternalUserPage = (props: any) => {

  const configurator = useContext(ConfiguratorContext);
  const [users, setUsers] = useState<User[] | undefined>([]);
  const [allUsers, setAllUsers] = useState<User[] | undefined>([]);
  const [internalUser, setInternalUser] = useState<InternalUser>();
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>(" ");
  const [form] = useForm();
  const cancelUpdateUserTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadUserTokenSourceRef = useRef<CancelTokenSource>();
  const intl = useIntl();
  const approvalRolePermission = configurator.hasPermission(Permission.APPROVAL_ROLE_WRITE)
  const [dealerId, setDealerId] = useState<string | undefined>();
  const isAdminOrSalesDesk = configurator.isAdmin() || configurator.isSalesDesk();

  useEffect(() => {
    loadInternalUsers();
  }, []);

  useEffect(() => {
    loadInternalUsers();
  }, [filter, dealerId]);

  const loadInternalUsers = async () => {
    try {

      const resp = await Utils.executeWithCancelToken(cancelLoadUserTokenSourceRef, (token) =>
        configurator.api.loadInternalUsers(filter || " ", dealerId, token)
      );

      setUsers(resp?.data);
      if (!filter?.trim()) {
        setAllUsers(resp?.data);
      }
    }
    catch (e: any) {

    }
  };

  const getInternalUser = async (userId: string) => {
    try {
      const resp = await configurator.api.getInternalUser(userId);
      setInternalUser(resp.data);
      let updated = {...resp.data};

      resp.data.userRolePermissions.forEach(p => {
        if (Object.keys(PermissionKey).map(k => String(k)).includes(p)) {
          updated[p] = true;
        }
      })

      form.setFieldsValue(updated)
      setOpen(true);
    }
    catch (e: any) {

    }
  }

  const onEdit = (user: User) => {
    getInternalUser(user.id);
  }

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const updateInternalUser = async () => {
    if (internalUser) {
      const userRolePermissions = Object.entries(form.getFieldsValue()).filter(entry => Object.keys(PermissionKey).map(k => String(k)).includes(entry[0]) && entry[1]).map(e => e[0]);
      const updatedInternalUser = { ...form.getFieldsValue(), userRolePermissions, userId: internalUser.userId };
      try {
        await Utils.executeWithCancelToken(cancelUpdateUserTokenSourceRef, (token) =>
          configurator.api.updateInternalUser(updatedInternalUser, token)
        );
        notification.success({ message: "Successfully update internal user." });
        handleCancel();
      }
      catch (e: any) {
        const msg = e.response?.data?.message || e.message;
        const errorMsg = intl.formatMessage({ id: msg });
        notification.error({ message: "Failed to update internal user " + errorMsg });
      }
    }
  }

  const handleResetPassword = async () => {
    if ( !internalUser ) return;

    try {
      await Utils.executeWithCancelToken(cancelUpdateUserTokenSourceRef, (token) =>
        configurator.api.resetPassword(internalUser.userId, token)
      );
      notification.success({ message: "Check your email for a reset notification." });
      handleCancel();
    }
    catch (e: any) {
      const msg = e.response?.data?.message || e.message;
      const errorMsg = intl.formatMessage({ id: msg });
      notification.error({ message: "Failed to reset password. " + errorMsg });
    }
  }


  const columns = [
    {
      key: "name",
      title: "Name",
      render: (u: User) => <p>{u.name}</p>
    },
    {
      key: "actions",
      title: "Actions",
      render: (u: User) => <Button type="primary" onClick={() => onEdit(u)}>Edit</Button>
    }
  ];

  return (
    <div className="site-layout-background">
      <Title level={2}>Internal Users</Title>

      {isAdminOrSalesDesk && <DealerSelector onChange={setDealerId} style={{width: "100%", marginBottom: "10px"}} placeholder="Searched by dealer"/>}

      <Input allowClear onChange={(v) => setFilter(v.target.value)} placeholder="Filtered by name"/>

      <Table bordered
        dataSource={users}
        columns={columns}
        rowKey={"id"}
        style={{marginTop: "1rem"}}
      />
      <Modal
        title={allUsers?.find(u => u.id === internalUser?.userId)?.name}
        open={open}
        onCancel={handleCancel}
        footer={<div style={{display:"flex", justifyContent: "space-between"}}>
          <Button danger onClick={handleResetPassword}>Reset Password</Button>
          <div>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={updateInternalUser}>OK</Button>
          </div>
        </div>}
        closeIcon={false}
      >

        <Form 
          layout="vertical"
          form={form}
        >

          {Object.entries(PermissionKey).filter(e => approvalRolePermission).map((entry, idx) => 

            <Form.Item
              name={entry[1]}
              label={Utils.snakeCaseToFirstLetterCapitalized(entry[1])}
              valuePropName="checked"
              key={"checkbox-" + idx}
            >
              <Checkbox disabled={!approvalRolePermission}/>
            </Form.Item>
            )}

          <Form.Item
            name="managers"
            label="Managers"
          >
            <UserMultiSelector
              userLst={allUsers || []}
            />
          </Form.Item>

          <Divider/>

          <Form.Item
            name="subordinates" 
            label="Subordinates"
          >
            <UserMultiSelector
              userLst={allUsers || []}
            />
          </Form.Item>
        </Form>

      </Modal>
    </div>
  );
}

export default InternalUserPage;
