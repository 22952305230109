import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, notification, Row, Select, Space } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import {Assembly, AssemblyMetadata, Category, CategoryMetadata, Dealer, Operation} from "../api/models";
import { ConfiguratorContext } from "../context";
import { useIntl } from "react-intl";
import { ProFormInstance, ProFormText, StepsForm } from "@ant-design/pro-components";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { AssemblyRequest } from "../api";
import { NotFoundError } from "../api/errors";
import NotFoundPage from "../pages/not_found";
import { MetadataFieldList } from "./metadata_field";
import Title from "antd/lib/typography/Title";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CancelTokenSource } from "axios";
import Utils from "../util/util";
import ModelMultipleSelector from "./ModelMultipleSelector";

const NewAssemblyModal = (props: {refAsm?: Assembly}) => {
  const configurator = useContext(ConfiguratorContext);
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const {refAsm} = props;
  const cancelLoadCategoriesTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadOperationsTokenSourceRef = useRef<CancelTokenSource>();
  const cancelLoadDealersTokenSourceRef = useRef<CancelTokenSource>();

  const [operations, operationsAsync] = useAsyncState<Operation[]>();
  const [assembly, assemblyAsync] = useAsyncState<Assembly>();
  const [categoryLst, categoryLstAsync] = useAsyncState<Category[]>([]);
  const [categoryId, setCategoryId] = useState<number | undefined>(refAsm?.categoryId || undefined);
  const [dealerLst, dealerLstAsync] = useAsyncState<Dealer[] | undefined>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<AssemblyMetadata[]>();

  const formRef = useRef<ProFormInstance>();


  const onSubmitForm = async (values: any) => {
    try {
      assemblyAsync.setLoading();
      const resp = await configurator.api.createAssembly(
        {
          ...values,
        } as AssemblyRequest,
      );

      assemblyAsync.setDone( resp.data );
      notification.success({message: "Assembly created."});

      onCancel();
      createNewTab(resp);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to import assembly. " + errorMsg });

      assemblyAsync.setDone( assembly );
    }

  };

  const createNewTab = (resp: any) => {
    if (resp?.data?.id) {
      const url = history.createHref({ pathname: `${"/assemblies/" + encodeURIComponent(resp.data.id)}` });
      const newTab = window.open(url, '_blank');
      if (newTab) newTab.focus();
    }
  }


  const buildFormMetadataOnNewAssembly = (category:Category | undefined, assembly: Assembly | undefined) : AssemblyMetadata[] =>  {

    const assemblyMetadata: AssemblyMetadataFormValues[] = !!assembly?.id ? assembly?.metadata
      ?.filter( md => category?.metadata.some( cm => cm.id === md.categoryMetadata.id  )).map(md => {return {...md, categoryMetadataId: md.categoryMetadata.id}}) || [] : [];

    const categoryMetadata:AssemblyMetadataFormValues[] = category?.metadata
      .filter( cm => !assemblyMetadata.length || !assemblyMetadata.some( md  => cm.id === md.categoryMetadata.id  ) ) 
      .map( cm => ({categoryMetadata:cm, categoryMetadataId: cm.id}) ) || [];

    return [
    ...assemblyMetadata,
    ...categoryMetadata
    ];
  }

  useEffect(() => {
    loadOperations();
    loadCategories();
    loadDealers();
  }, []);

  useEffect(() => {
    if (refAsm) setCategoryId(refAsm.categoryId);
  }, [refAsm]);

  useEffect( () => {
    const category = categoryLst?.find( c => c.id === categoryId );

    const asmId = refAsm?.id;

    if ( asmId ) {
      loadAssembly(asmId).then(asm => {
        const metadata = buildFormMetadataOnNewAssembly( asm?.category, asm )
        setMetadata(metadata);
      })
    }
    else if (!!categoryId) {
      const metadata = buildFormMetadataOnNewAssembly( category, undefined )
      setMetadata(metadata);
    }
  }, [categoryId, refAsm])

  
  const loadAssembly = (id:number) : Promise<Assembly | undefined> => { //
    if (id === undefined) {
      return Promise.resolve(undefined);
    }

    assemblyAsync.setLoading();
    return configurator.api.getAssembly(id)
      .then( resp => {
        assemblyAsync.setDone(resp.data)
        return resp.data;
      },
        (e) => {
          if (e instanceof NotFoundError) {
            setNotFound(true);
          } else {
            const errorMsg = intl.formatMessage({ id: e.message });
            notification.error( { message: "Failed load assembly. " + errorMsg });
            assemblyAsync.setFail(e.message);
          }

          return undefined;
        });
  };

  const loadCategories = async () => {
    categoryLstAsync.setLoading()
    try {
      const resp = await Utils.executeWithCancelToken(cancelLoadCategoriesTokenSourceRef, (token) =>
        configurator.api.getCategories(token)
      );

      categoryLstAsync.setDone(resp?.data?.sort((a: Category, b: Category) => a.name.toLowerCase().localeCompare( b.name.toLowerCase() ) ))
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed load categories. " + errorMsg });
      categoryLstAsync.setFail(e.message);
    }
  }

  const loadOperations = async () => {
    operationsAsync.setLoading()
    try {
      const resp = await Utils.executeWithCancelToken(cancelLoadOperationsTokenSourceRef, (token) =>
          configurator.api.fetchAssemblyOperations(token)
      );

      operationsAsync.setDone(resp?.data?.sort((a: Operation, b: Operation) => a.operationId.localeCompare(b.operationId) ));
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed load operations. " + errorMsg });
      operationsAsync.setFail(e.message);
    }
  }

  const loadDealers = async () => {
    dealerLstAsync.setLoading();
    try {
      const resp = await Utils.executeWithCancelToken(cancelLoadDealersTokenSourceRef, (token) =>
        configurator.api.getDealers(token)
      );

      dealerLstAsync.setDone( resp );
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed load dealers. " + errorMsg });
      dealerLstAsync.setFail(e.message);
    }
  }

  if ( notFound ) {
    return <div className="site-layout-background"><NotFoundPage /></div>
  }

  const onCancel = () => {
    setOpen(false); 
    formRef.current?.resetFields();
  }

  return (
    <>
      <style>
        {`
          .ant-pro-steps-form-container .ant-space-horizontal.ant-space-align-center {
            width: 100%  !important;
            display: flex  !important;
            justify-content: flex-end  !important;
            margin-top: 50px !important;
          }

          .fixed-height-modal .ant-modal-content {
            height: 900px;
            max-height: 800px;
          }

          .fixed-height-modal .ant-modal-body {
            height: inherit;
            max-height: 680px;
            overflow-y: auto !important;
            overflow-x: hidden;
          }

        `}
      </style>
      {!!refAsm ? <Button
        type="primary"
        onClick={() => setOpen(true)}
      >
        Copy
      </Button>
      :
      <Button
        type="primary"
        onClick={() => setOpen(true)}
      >
        New
      </Button>
      }

      <StepsForm
        formRef={formRef}
        onFinish={onSubmitForm}
        containerStyle={{width: "80rem"}}
        stepsProps={{size: "small", labelPlacement: "vertical"}}
        stepsFormRender={(dom, submitter) => {
          return (
            <Modal
              title={!!refAsm ? "Copy Assembly" : "Create Assembly"}
              open={open}
              onCancel={onCancel}
              footer={submitter}
              width={"80rem"}
              className="fixed-height-modal"
            >
              {dom}
            </Modal>
          )
        }}
      >
        <StepsForm.StepForm name="detail`" title='Detail' style={{width: "90%", textAlign: "center", margin: "0 auto"}}>
          {<DetailsSection
            dealerLst={dealerLst}
            dealerLstAsync={dealerLstAsync}
            categoryLstAsync={categoryLstAsync}
            categoryLst={categoryLst}
            setCategoryId={setCategoryId}
            categoryId={categoryId}
            assembly={refAsm}
          />}
        </StepsForm.StepForm>
        <StepsForm.StepForm name="metadata" title='Metadata' style={{width: "90%", textAlign: "center", margin: "0 auto"}}>
          <MetadataFieldList metadata={metadata}/>
        </StepsForm.StepForm>
        <StepsForm.StepForm name="operation" title='Operation' style={{width: "90%", textAlign: "center", margin: "0 auto"}}>
          <OperationsSection
            operations={operations}
            copyOperations={refAsm?.operations}
          />
        </StepsForm.StepForm>
      </StepsForm>
    </>
  );
};

export default NewAssemblyModal;

export interface NewAssemblyOperation {
  id?: number
  operationId?: string
  hours?:number
  group?:string
}

export interface AssemblyMetadataFormValues {
  id?:number
  categoryMetadata:CategoryMetadata
  valueText?:string
  valueBool?: boolean
  valueNumeric?: number
  valueDecimal?: number
}

export const OperationsSection = (props: {
  operations: Operation[] | undefined
  copyOperations?: Operation[] | undefined
}) => {

  const {operations, copyOperations} = props;

  return (
  <>
    <style>
      {`
        .custom-form-item .ant-form-item {
          margin-bottom: 60px !important;
        }
      `}
    </style>
    <Form.List name="operations" initialValue={copyOperations}>
      {(fields, { add, remove }) => (
        <>
          <Title level={4} style={{color: "#1677FF"}}>
            Operations
            <Button onClick={(_e) => add()} icon={<PlusOutlined/>} style={{backgroundColor: "#1677ff", color: "white", marginLeft: ".5rem"}} shape="circle" size="small"/>
          </Title>
          {fields.map((ao, idx) => (
            <div key={idx}>
              <Form.Item
                name={[ao.name, "id"]}
                noStyle
                hidden
              >
                <Input hidden />
              </Form.Item>
              <Space.Compact>
                <Form.Item
                  name={[ao.name, "operationId"]}
                  label="Operation"
                  rules={[
                    {
                      required: true,
                      message: "Operation is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: 300 }}
                  >
                    {operations
                      // ?.filter( o => isCurrentOrSelected(formAssembly?.operations[ao.name], o) )
                      ?.map((o, idx) => 
                        <Select.Option value={o.operationId} key={o.operationId + "-" + idx}>
                          {!o.current 
                            ?  <span style={{color: "red"}}>{o.operationId}</span>
                            :  <>{o.operationId}</>
                          }
                        </Select.Option>
                          )}
                  </Select>
                </Form.Item>
                &nbsp;
                <Form.Item
                  name={[ao.name, "group"]}
                  label="Group"
                  rules={[
                    {
                      required: true,
                      message: "Group is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: 150 }}
                    optionFilterProp="label"
                    options={[
                      {label:"CAB", value:"CAB" },
                      {label:"Chassis", value:"CHASSIS" }
                    ]}
                  />
                </Form.Item>
                &nbsp;
                <Form.Item
                  name={[ao.name, "hours"]}
                  label="Hours"
                  rules={[
                    {
                      required: true,
                      message: "Hours are required",
                    },
                  ]}
                >
                  <Input style={{ width: 100 }} />
                </Form.Item>
                <DeleteOutlined onClick={() => remove(ao.name)} style={{paddingLeft: "1rem"}} />
              </Space.Compact>
            </div>
          ))}
        </>)}
    </Form.List>
  </>);
}

export const DetailsSection = (props: {
  dealerLst: Dealer[] | undefined
  dealerLstAsync: AsyncState<Dealer[] | undefined>
  categoryLst: Category[] | undefined
  categoryLstAsync: AsyncState<Category[]>
  setCategoryId: (key: number) => void
  categoryId: number | undefined
  assembly?: Assembly | undefined
}) => {

  const configurator = useContext(ConfiguratorContext);
  const {dealerLst, dealerLstAsync, categoryLst, categoryLstAsync, setCategoryId, categoryId, assembly} = props;
  const intl = useIntl();

  const validateBomExistance = async (_rule: any, value: string, _cb: any) => {
    if (!value) return;
    try {
      const resp =  await configurator.api.checkBomAvailablility(value);
      if (resp.data === false) {
        throw new Error();
      }
      return;
    } catch (e) { }
    throw new Error("validation failed");
  };


  return (
    <>
      <ProFormText
        label="Name"
        name='bomDescription'
        placeholder={''}
        rules={[{ required: true, message: "Name is required" }]}
      />
      <ProFormText
        label="BOM (Optional)"
        name='bom'
        placeholder={''}
        rules={[{
          message: `${intl.formatMessage({id: "assembly.bomExisted"})}`,
          validator: validateBomExistance
        }]}
      />
      <Form.Item
        label="Category"
        name="categoryId"
        rules={[
          {
            required: true,
            message: "Category is required",
          },
        ]}
        initialValue={categoryId}
      >
        <Select 
          showSearch
          optionFilterProp="label"
          loading={categoryLstAsync.isLoading()}
          options={categoryLst?.map( c => ({
            label: c.name,
            value: c.id
          }))}
          onChange={(key) => setCategoryId(key)}
          style={{textAlign: "left"}}
        />
      </Form.Item>

      <Form.Item
        label="Dealer Exclusive"
        name="dealerExclusives"
      >
        <Select
          loading={dealerLstAsync.isLoading()}
          showSearch={true}
          mode="multiple"
          optionFilterProp="label"
          options={dealerLst?.map(d => ({ label: d.name, value:d.id }))}
        />
      </Form.Item>

      <Form.Item
        label="Material Cost"
        name="standardMaterialCost"
        rules={[
          {
            required: true,
            message: "Material Cost is required",
          },
        ]}
        initialValue={assembly?.standardMaterialCost}
      >
        <InputNumber
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value!.replace(/\$\s?|(,*)/g, '')}
          placeholder="Type dollar amount"
          controls={false}
          style={{width: "100%"}}
        />
      </Form.Item>

      <Form.Item
        label="Model(s)"
        name="modelIds"
        rules={[{ required: true, message: "Model is required" }]}
      >
        <ModelMultipleSelector />
      </Form.Item>

      <Row gutter={[20,20]}>

        <Col >
          <Form.Item
            name="noOption"
            valuePropName="checked"
          >
            <Checkbox >"NO OPTION"</Checkbox>
          </Form.Item>
        </Col>

        <Col >
          <Form.Item
            name="selectionRequiresUserInput"
            valuePropName="checked"
          >
            <Checkbox >Requires User Input</Checkbox>
          </Form.Item>
        </Col>

      </Row>
    </>
  );
}


