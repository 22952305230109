import { Button, Card } from "antd";
import { FilterAssembliesIfNoneMatchAction, FilterAssembliesIfNoneMatchActionDetails, FilterAssembliesIfNoneMatchActionFilter, RuleValueTypes } from "../../api/models";
import RuleExpressionEditor from "./expression";
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import CategorySelector2 from "../category_selector2";

export interface Props {
    rule: FilterAssembliesIfNoneMatchAction;
    onUpdate: (a:FilterAssembliesIfNoneMatchAction) => void;
    readOnly?: boolean;
}

interface detailsProps {
    details: FilterAssembliesIfNoneMatchActionDetails
    onUpdate: (a:FilterAssembliesIfNoneMatchActionDetails) => void;
    readOnly?: boolean;
}

const FilterDetails = (props: detailsProps) => {
    function onAddFilter() {
        const filter = { ...props.details } as FilterAssembliesIfNoneMatchActionDetails;
        Object.setPrototypeOf(filter, FilterAssembliesIfNoneMatchActionDetails.prototype);
        filter.filters = [...filter.filters, new FilterAssembliesIfNoneMatchActionFilter()];
        props.onUpdate(filter);
    }

    function onRemove(idx: number) {
        const filter = { ...props.details } as FilterAssembliesIfNoneMatchActionDetails;
        Object.setPrototypeOf(filter, FilterAssembliesIfNoneMatchActionDetails.prototype);
        filter.filters = [...filter.filters.slice(0, idx), ...filter.filters.slice(idx + 1)];
        props.onUpdate(filter);
    }

    return (<div>
        <div><strong>Condition: </strong><br />
      <RuleExpressionEditor disabledExpressionTypes={['filter-category']} 
          readOnly={props.readOnly} 
          expression={props.details.condition} 
          disabledRuleValueTypes={[RuleValueTypes.AssemblyAttribute]}
          onUpdate={(condition) => {
            const newDetails = { ...props.details };
            Object.setPrototypeOf(newDetails, FilterAssembliesIfNoneMatchActionDetails.prototype);
            newDetails.condition = condition;;
            props.onUpdate(newDetails);
          }} /></div>
        <br />
        <div><strong>Filters: </strong>
            <Button hidden={props.readOnly} style={{ margin: '5px' }} size="small" type="primary" icon={<PlusCircleOutlined />} onClick={onAddFilter}>Add</Button>
            {props.details.filters.map((filter: FilterAssembliesIfNoneMatchActionFilter, idx) => (
                <Card key={idx}>
                    <Button hidden={props.readOnly} icon={<DeleteOutlined />} style={{ marginBottom: '5px' }} onClick={() => {
                        onRemove(idx);
                    }}>Remove</Button>
                    {props.readOnly ?
                        <><strong>Category:</strong> {filter.categoryId}</> :
                        <div style={{ marginBottom: '5px', marginTop: '5px' }}>

                            Category to Filter: <CategorySelector2 style={{ width: '400px' }} selectedCategory={filter.categoryId} onSelectCategory={(cat) => {
                                const newFilter = { ...filter };
                                Object.setPrototypeOf(newFilter, FilterAssembliesIfNoneMatchActionFilter.prototype);
                                newFilter.categoryId = cat.categoryId;

                                const newDetails = { ...props.details };
                                Object.setPrototypeOf(newDetails, FilterAssembliesIfNoneMatchActionDetails.prototype);
                                newDetails.filters = [...props.details.filters.slice(0, idx), newFilter, ...props.details.filters.slice(idx + 1)];
                                props.onUpdate(newDetails);
                            }} />
                        </div>
                    }
                    <br />
                    <RuleExpressionEditor 
                      readOnly={props.readOnly} 
                      expression={filter.expression} 
                      onUpdate={(expr) => {
                        const newFilter = { ...filter };
                        Object.setPrototypeOf(newFilter, FilterAssembliesIfNoneMatchActionFilter.prototype);
                        newFilter.expression = expr;

                        const newDetails = { ...props.details };
                        Object.setPrototypeOf(newDetails, FilterAssembliesIfNoneMatchActionDetails.prototype);
                        newDetails.filters = [...props.details.filters.slice(0, idx), newFilter, ...props.details.filters.slice(idx + 1)];
                        props.onUpdate(newDetails);
                    }} />
                </Card>
            ))}
        </div>
    </div>);
}

const FilterIfNoMatch = (props: Props) => {
    const readOnly = props.readOnly;

    function onUpdate(idx: number, detail: FilterAssembliesIfNoneMatchActionDetails) {
        const newAction = { ...props.rule } as FilterAssembliesIfNoneMatchAction;
        Object.setPrototypeOf(newAction, FilterAssembliesIfNoneMatchAction.prototype);
        newAction.details = [...props.rule.details.slice(0, idx), detail, ...props.rule.details.slice(idx + 1)];
        props.onUpdate(newAction);
    }

    function onAdd() {
        const newAction = { ...props.rule } as FilterAssembliesIfNoneMatchAction;
        Object.setPrototypeOf(newAction, FilterAssembliesIfNoneMatchAction.prototype);
        newAction.details = [...props.rule.details, new FilterAssembliesIfNoneMatchActionDetails()];
        props.onUpdate(newAction);
    }

    function onRemove(idx: number) {
        const newAction = { ...props.rule } as FilterAssembliesIfNoneMatchAction;
        Object.setPrototypeOf(newAction, FilterAssembliesIfNoneMatchAction.prototype);
        newAction.details = [...props.rule.details.slice(0, idx), ...props.rule.details.slice(idx + 1)];
        props.onUpdate(newAction);
    }

    return (
        <div>
            <p>The <strong>Filter If No Match</strong> rule does the following:</p>
            <ul>
                <li>For each <strong>Filter in the list</strong></li>
                <ul>
                    <li>Checks to see if the <strong>Condition</strong> clause matches</li>
                    <li>If it does, include assemblies that match the <strong>Filter</strong>.</li>
                </ul>
                <li>If an assembly was included in ANY of the filters, it will be included in the list of selections.</li>
            </ul>
            <Button hidden={readOnly} style={{ margin: '5px' }} size="small" type="primary" icon={<PlusCircleOutlined />} onClick={onAdd}>Add</Button>
            {props.rule.details?.map((details, idx) => (
                <div key={idx}>
                    <Card style={{ marginTop: '10px' }}>
                        <Button hidden={readOnly} icon={<DeleteOutlined />} style={{ marginBottom: '5px' }} onClick={() => {
                            onRemove(idx);
                        }}>Remove Filter Details</Button>
                        <FilterDetails readOnly={readOnly} details={details} onUpdate={(detail) => onUpdate(idx, detail)} />
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default FilterIfNoMatch;
