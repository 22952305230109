
import { useState } from "react";
import { EqualsExpression, RuleExpression, RuleValueTypes } from "../../../api/models";
import RuleValueEditor from "../Values/rule_value";

export interface Props {
    expression: EqualsExpression;
    onUpdate: (e:EqualsExpression) => void;
    disabledRuleValueTypes?: RuleValueTypes[] | undefined
}

const EqualsExpressionEditor = (props: Props) => {
    const [lhs, setLhs] = useState(props.expression.lhs);
    const [rhs, setRhs] = useState(props.expression.rhs);

    function onUpdateLhs(lhs: RuleExpression) {
        const expr = new EqualsExpression();
        expr.lhs = lhs;
        expr.rhs = rhs;
        props.onUpdate(expr);
        setLhs(lhs);
    }

    function onUpdateRhs(rhs: RuleExpression) {
        const expr = new EqualsExpression();
        expr.lhs = lhs;
        expr.rhs = rhs;
        props.onUpdate(expr);
        setRhs(rhs);
    }

    return (
        <div>
            <RuleValueEditor value={lhs} onUpdate={onUpdateLhs}  disabledRuleValueTypes={props.disabledRuleValueTypes} />
            &nbsp; == &nbsp;
            <RuleValueEditor value={rhs} onUpdate={onUpdateRhs} disabledRuleValueTypes={props.disabledRuleValueTypes} />
        </div>
    );
};

export default EqualsExpressionEditor;
