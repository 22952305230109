import { useContext, useEffect } from "react";
import { ConfiguratorContext } from "../context";
import { notification, Select } from "antd";
import { PAGINATION_MAX_PAGE_SIZE, ShippingDestination } from "../api/models";
import {useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";

const QuoteShippingDestinationSelector = (props: {
  id?:string
  value?: number[] 
  onChange?: (shippingDestinations: number[]) => void;
  style?: any;
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [shippingDestinationLst, shippingDestinationLstAsync] = useAsyncState<ShippingDestination[] | undefined>();

  useEffect(() => {
    loadShippingDestinations();
  }, []);


  const loadShippingDestinations = async () => {
    try {
      const resp = await configurator.api.getShippingDestinations( {
          page: 0, 
          size: PAGINATION_MAX_PAGE_SIZE, 
          sort: { field: 'name', direction:'asc' }
        })
      shippingDestinationLstAsync.setDone(resp.data.content);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get shipping destinations. " + errorMsg });
      shippingDestinationLstAsync.setFail(e.message);
    }
  };

  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      options={ shippingDestinationLst?.map(d => ({
        value: d.id, label:d.name
      }))}
      mode="multiple"
    >
    </Select>
  );
};

export default QuoteShippingDestinationSelector;
