import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { DashTemplate, MISSING_IMAGE, TemplateLocation, DashComponent, ComponentMap, AssemblyInfo, DRAWING_WIDTH, DRAWING_HEIGHT, ComponentType } from "../api/models";
import { ConfiguratorContext } from "../context";
import { Button, Col, notification, Form, Input, Spin, Image, Radio, Select, Alert, Row, Divider, Tooltip, SelectProps, Modal } from "antd";
import Title from "antd/lib/typography/Title";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined, LeftCircleTwoTone, PlusOutlined, RightCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import styles from "./dash_template_view.module.css";
import { MinusCircleTwoTone, UpCircleTwoTone, DownCircleTwoTone } from "@ant-design/icons";
import { throttle } from "lodash";
import { useForm, useWatch } from "antd/es/form/Form";
import { useIntl } from "react-intl";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

const DashTemplateView = () => {
  const params = useParams<any>();
  const configurator = useContext(ConfiguratorContext);
  const [loading, setLoading] = useState(false);
  const [editDashTemplateForm] = Form.useForm();
  const [data, setData] = useState<DashTemplate>();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [imageDimension, setImageDimension] = useState<{w: number, h: number}>({w: 1, h: 1});
  const [allDashComponents, setAllDashComponents] = useState<DashComponent[]>([]);
  const [componentTypes, setComponentTypes] = useState<ComponentType[]>([]);
  const [allDrawingAssemblies, setAllDrawingAssemblies] = useState<AssemblyInfo[]>([]);
  const [isLegend, setLegend] = useState(false);
  const [isOptionDescription, setOptionDescription] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState<number>(0);
  const [cursorType, setCursorType] = useState('cursor');
  const [clickedImage, setClickedImage] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const resolutionAlert = window.innerWidth <= 2560 && window.innerWidth >= 1900 ? "You are with perfect resolution" : 
                        `The ideal monitor resolution for this tool is about (2400 x 1246), other resolutions may not place items correctly on the template. Adjusting browser zoom may clear this error`;

  const selectedLocationRef = useRef(selectedLocation);
  const setSelectedLocationRef = (data: number) => {
    selectedLocationRef.current = data;
    setSelectedLocation(data);
  }

  const setCoordinateCallback = useCallback(
    throttle( (event: any) => {
      setClickedImage(true);

      const relativeX = Math.round(event.offsetX / imageDimension.w * DRAWING_WIDTH);
      const relativeY = Math.round(event.offsetY / imageDimension.h * DRAWING_HEIGHT);
      captureCoordinate(relativeX, relativeY, isLegend);
    }, 500 )
  , [imageDimension, isLegend] );

  const fullImage = document.getElementById("fullImage");

  const setDimensionCallback = useCallback(
    throttle( (width: number, height: number) => {
      setImageDimension({w: width, h: height});
    }, 1000 )
  , [] );

  if (fullImage) {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        if (imageDimension.w !== width || imageDimension.h !== height) {
          setDimensionCallback(width, height);
        }
      }
    });
    resizeObserver.observe(fullImage || new Element());
  }

  useEffect(() => {
    fullImage?.addEventListener('click', setCoordinateCallback);
    return () => {
      fullImage?.removeEventListener('click', setCoordinateCallback);
    }
  }, [setCoordinateCallback])

  const mouseLeave = useCallback(
    () => {setClickedImage(false)}
  , [clickedImage] );

  useEffect(() => {
    fullImage?.addEventListener('mouseleave', mouseLeave);
    return () => {
      fullImage?.removeEventListener('mouseleave', mouseLeave);
    }
  }, [mouseLeave])

  const nullLocationLastSorting = (a: TemplateLocation, b: TemplateLocation) => {
    if (a.location == undefined && b.location == undefined) {
      return 1;
    }
    else if (a.location == undefined) {
      return 1;
    }
    else if (b.location == undefined) {
      return -1;
    }
    else {
      return a.locationOrder - b.locationOrder;
    }
  }

  useEffect(() => {
    getDashTemplate(params.id);
  }, [params.id]);

  const getDashTemplate = async (id: number) => {
    try {
      setLoading(true);
      const resp = await configurator.api.getSingleDashTemplate(id);
      if (resp) {
        editDashTemplateForm.setFieldsValue(
          {...resp.data, locations: resp.data.locations?.sort(nullLocationLastSorting), assemblies: resp.data.assemblies.map(asm => asm.toString())}
        );

        editDashTemplateForm.setFieldValue("locations", resp.data?.locations?.map((loc, idx) => {return {...loc, selected: idx === 0}}).sort(nullLocationLastSorting) || []);

        const firstLocation = (resp.data?.locations || [])[0];
        setCursorType(ComponentMap[(firstLocation && firstLocation.type) || 'cursor']);

        setData(resp.data);
        setUploadSuccess(resp.data.imageUrl != undefined);
        setDisabled(false);
      }
    }
    catch(e) {
      notification.error( { message: "Failed to get template. " + e });
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllDashComponents(undefined);
    getAllComponentTypes();
    getAllDrawings();
  }, []);

  const getAllDashComponents = async (type: string | undefined) => {
    try {
      const resp = await configurator.api.getDashComponents(type);
      setAllDashComponents(resp.data);
    }
    catch(e) {
      notification.error( { message: "Failed to get dash components. " + e });
    }
  }

  const getAllComponentTypes = async () => {
    try {
      const resp = await configurator.api.getComponentTypes();
      const types = resp.data.filter(str => str.toLowerCase() !== ComponentMap.COVER.toLowerCase());
      setComponentTypes(types);
    }
    catch(e) {
      console.log(e);
    }
  }

  const getAllDrawings = async () => {
    try {
      const resp = await configurator.api.getDashDrawingAssemblies();
      setAllDrawingAssemblies(resp.data);
    }
    catch(e) {
      console.log(e);
    }
  }

  const onSaveDashTemplateSubmit = async (value: DashTemplate) => {
    setDisabled(true);
    value.locations?.forEach((loc) => {loc.coordinateX = String(loc.coordinateX); loc.coordinateY = String(loc.coordinateY)})
    var assemblies = editDashTemplateForm.getFieldValue('assemblies');
    try {
      await configurator.api.updateSingleDashTemplate({...value, id: data?.id || 0, assemblies: assemblies});
      getDashTemplate(params.id);
      notification.success({
        message: "Saved successfully.",
      });
    }
    catch(e) {
      console.log(e)
      notification.error({
        message: "Saved failed.",
      });
    }
  }

  const captureCoordinate = (x: number, y: number, isLegend: boolean) => {

    if (isNaN(x) || isNaN(y) || x > 3500 || x < 0 || y > 3500 || y < 0) {
      return 
    }

    if (isOptionDescription) {
      editDashTemplateForm.setFieldValue('optionStartingX', String(Math.round(x)));
      editDashTemplateForm.setFieldValue('optionStartingY', String(Math.round(y)));
    }
    else {
      const newLocations = editDashTemplateForm.getFieldValue('locations').map( (row: TemplateLocation, id: number) => {
        let newRow = row;
        if (id === selectedLocationRef.current) {
          if (!isLegend) {
            newRow.coordinateX = String(Math.round(x));
            newRow.coordinateY = String(Math.round(y));
          }
          else {
            newRow.legendCoordinateX = String(Math.round(x));
            newRow.legendCoordinateY = String(Math.round(y));
          }
          newRow.selected = true;
        }
        else {
          newRow.selected = false;
        }
        return newRow;
      }).sort(nullLocationLastSorting);

      editDashTemplateForm.setFieldValue('locations', newLocations);
    }
  }
  
  const getUploadImageUrl = () => {
    const resp = configurator.api.baseUrl + "/v1/dash-component/template/" + encodeURIComponent(data?.id || 0) + "/image";
    return resp;
  }

  const imageUploaded = () => {
    getDashTemplate(params.id);
  }

  const selectLocation = (name: number, isLegend: boolean) => {
    setLegend(isLegend);
    const existingLocation = editDashTemplateForm.getFieldValue('locations').map( (row, id) => {
      let newRow = {...row};
      newRow.selected = (id === name);
      return newRow;
    }).sort(nullLocationLastSorting);

    const previousComponentType = existingLocation[selectedLocationRef.current].type;

    Object.keys(existingLocation[name]).includes('type') && existingLocation[name].type != undefined ? 
      (isLegend ? setCursorType(ComponentMap[existingLocation[name]?.type + "_LARGE"]) : setCursorType(ComponentMap[existingLocation[name]?.type]) )
      : setCursorType("cursor");

    if (name !== selectedLocationRef.current) {
      
      if (previousComponentType !== existingLocation[name].type) {
        getAllDashComponents(existingLocation[name].type);
      }


      editDashTemplateForm.setFieldValue('locations', existingLocation);
      setSelectedLocationRef(name);
    }

  }

  const onComponentTypeChange = (value: string, name: number) => {
    getAllDashComponents(value);
    let existingLocations = editDashTemplateForm.getFieldValue('locations');
    if (existingLocations) {
      existingLocations[name].components = [];
      editDashTemplateForm.setFieldValue('components', existingLocations.map((loc, id: number) => (id === name ? { ...loc, components: [] } : loc)));
    }
  };

  const moveRow = (up: boolean, name: number) => {

    let currentLocations = [...editDashTemplateForm.getFieldValue('locations')];

    const location = { ...currentLocations[name], selected: true };

    if ((up && name == 0) || (!up && name >= (currentLocations.length || 0) - 1)) return;

    const direction = up ? -1 : 1;
    const newPosition = name + direction;
    currentLocations.splice(name, 1);
    currentLocations = currentLocations.map(loc => { return { ...loc, selected: false }; });

    currentLocations.splice(newPosition, 0, location);
    currentLocations = currentLocations.map((loc: TemplateLocation, idx: number) => { return { ...loc, locationOrder: idx + 1 }; });

    editDashTemplateForm.setFieldValue('locations', [...currentLocations]);

    setSelectedLocationRef(newPosition);
  }

  const onSetOptionStartingPoint = () => {
    setLegend(false);
    setCursorType("cursor");
    setOptionDescription(true);
  }

  const options: SelectProps['options'] = allDrawingAssemblies?.map((asm) => {return {
    label: asm.bom + " - " + asm.bomDescription,
    value: asm.id.toString(),
  }})

  return <div key="dashTemplateView" className="site-layout-background">
    <Title level={2}>
      Dash Template Edit
    </Title>
    <br />
    <br />
    <Spin spinning={loading}>
      <Col style={{ width: "100%" }}>
        <Form
          labelCol={{ span: 4 }}
          form={editDashTemplateForm}
          name="editShippingDestination"
          onFinish={onSaveDashTemplateSubmit}
          labelAlign={"right"}
          style={{ border: '8px solid #1890ff', padding: '50px 30px 50px 90px', borderRadius: '10px' }}
        >
          <Form.Item
            label="Part Number"
            name="partNumber"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input style={{ width: "40rem" }} />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Description is required" }]}
          >
            <Input style={{ width: "40rem" }} />
          </Form.Item>

          <Form.Item
            label="Drawing Assemblies"
            name={'assemblies'}
          >
            <Select
              mode="multiple"
              style={{ width: "40rem" }}
              showSearch
              allowClear={true}
              optionFilterProp="label"
              options={options}
            >
            </Select>
          </Form.Item>

          <Form.Item label="Upload Image">
            <Dragger style={{ width: "40rem" }}
              maxCount={1}
              action={getUploadImageUrl}
              withCredentials={true}
              name="image"
              showUploadList={false}
              accept=".jpg,.jpeg,.png,.gif,.tiff"
              listType="picture-card"
              onChange={imageUploaded}
            >
              {uploadSuccess ?
                <Image
                  src={data?.imageUrl || MISSING_IMAGE}
                  fallback={MISSING_IMAGE}
                  width={500}
                  preview={false}
                >
                </Image>
                : <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </>}
            </Dragger>
          </Form.Item>

          <Row justify={"end"} gutter={24}>
            <Col>
              <CloneModal template={data}/>
            </Col>
            <Col>
            {editDashTemplateForm.getFieldValue("locations") && editDashTemplateForm.getFieldValue("locations").length > 3 && <Form.Item>
              <Button type="primary" htmlType="submit" disabled={disabled} loading={disabled}>
                Save Template
              </Button>
            </Form.Item>}
            </Col>
          </Row>

          <TemplateLocationModule
              selectLocation={selectLocation}
              selectedLocation={selectedLocation}
              clickedImage={clickedImage}
              onComponentTypeChange={onComponentTypeChange}
              componentTypes={componentTypes}
              allDashComponents={allDashComponents}
              isLegend={isLegend}
              moveRow={moveRow}
          />

          <Divider style={{marginLeft: "-60px"}}/>

          <br/>
          <Row style={{width: "1200px"}}>
          <Form.Item
            label="Option Description Starting X"
            name="optionStartingX"
            style={{ display: 'inline-block', width: '33%', marginLeft: "-30px" }}
            labelCol={{span: 12}}
          >
            <Input style={{ width: "10rem" }} onFocus={() => onSetOptionStartingPoint()}/>
          </Form.Item>

          <Form.Item
            label="Option Description Starting Y"
            name="optionStartingY"
            style={{ display: 'inline-block', width: '33%' }}
            labelCol={{span: 12}}
          >
            <Input style={{ width: "10rem" }}  onFocus={() => onSetOptionStartingPoint()}/>
          </Form.Item>

          <Form.Item
              label="Option Column Width"
              name="optionColumnWidth"
              style={{ display: 'inline-block', width: '33%' }}
              labelCol={{span: 10}}
            >
              <Input style={{ width: "10rem" }} placeholder="Default column width is 610"/>
          </Form.Item>
          </Row>
          <Form.Item wrapperCol={{ offset: 20 }}>
            <Button type="primary" htmlType="submit" disabled={disabled} loading={disabled}>
              Save Template
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <br />
      {data?.imageUrl &&
        <div>
          <Title level={2}>
            Template Canvas
          </Title>

          <Row gutter={30}>
            <Col>
              <p style={{ fontSize: '18px', fontWeight: "bold"}}>{`Current Resolution: ${window.innerWidth} x ${window.innerHeight}`}</p>
            </Col>
            <Col>
              <p style={{ fontSize: '18px', color: window.innerWidth <= 2560 && window.innerWidth >= 1900 ? 'green' : "red", fontWeight: 'bolder' }}>
                {resolutionAlert}
              </p>
            </Col>
            <Col>
              {clickedImage && !!editDashTemplateForm.getFieldValue('locations')?.length && <Alert message="Location selected!" style={{marginTop: "-.3rem"}}></Alert>}
            </Col>
          </Row>

          <MouseTracking imageDimension = {imageDimension} DRAWING_WIDTH = {DRAWING_WIDTH} DRAWING_HEIGHT = {DRAWING_HEIGHT} fullImage = {fullImage}/>

          <img
            id='fullImage'
            src={data?.editedImageUrl || data?.imageUrl || MISSING_IMAGE}
            alt=""
            className={`${styles[cursorType]}`} // Input the enum for the key here in styles
            style={{ width: '100%', height: '100%', boxShadow: "6px 6px 24px rgba(0, 0, 0, 0.25)" }}
          />
          <div style={{marginTop: "1rem"}}>
            <MouseTracking imageDimension = {imageDimension} DRAWING_WIDTH = {DRAWING_WIDTH} DRAWING_HEIGHT = {DRAWING_HEIGHT} fullImage = {fullImage}/>
          </div>
        </div>}
    </Spin>
  </div>;
}

export default DashTemplateView;



interface MouseTrackingProps {
  imageDimension: {w: number, h: number}
  DRAWING_WIDTH: number;
  DRAWING_HEIGHT: number;
  fullImage: HTMLElement | null;
}

const MouseTracking = (props: MouseTrackingProps) => {
  const [coordinate, setCoordinate] = useState<{x: number, y: number}>({x: 0, y: 0});
  const {imageDimension, DRAWING_WIDTH, DRAWING_HEIGHT, fullImage} = props;

  const moveCoordinateCallback = useCallback(
    throttle( (event: any) => {
      const relativeX = Math.round(event.offsetX / imageDimension.w * DRAWING_WIDTH);
      const relativeY = Math.round(event.offsetY / imageDimension.h * DRAWING_HEIGHT);
      setCoordinate({x: relativeX, y: relativeY});
    }, 100 )
  , [imageDimension] );

  useEffect(() => {
    fullImage?.addEventListener('mousemove', moveCoordinateCallback);
    return () => {
      fullImage?.removeEventListener('mousemove', moveCoordinateCallback);
    }
  }, [moveCoordinateCallback])

  return (
    <p className={`${styles["mouse_coordinate"]}`}>x: {coordinate.x}, y: {coordinate.y}</p>
  )
}

interface LocationProps {
  selectLocation: (name: number, isLegend: boolean) => void;
  allDashComponents: DashComponent[];
  selectedLocation: number;
  clickedImage: boolean;
  onComponentTypeChange: (value, name: number) => void;
  componentTypes: string[];
  isLegend: boolean;
  moveRow: (value: boolean, name: number) => void;
}

const TemplateLocationModule = (props: LocationProps) => {
  const {selectLocation, selectedLocation, clickedImage, onComponentTypeChange, componentTypes, allDashComponents, isLegend, moveRow} = props;
  const editDashTemplateForm = useFormInstance(); 
  const currentLocations = useWatch("locations", editDashTemplateForm);

  const isUndefinedOrEmpty = (value: undefined | string | null ) => {
    return (value ?? '') === '';
  } 

  const isEmptyCoordinate = (name: number) => {

    const existingLocation = editDashTemplateForm.getFieldValue('locations');

    if (
      editDashTemplateForm.getFieldValue('locations')[name] != undefined 
      && ['coordinateX', 'coordinateY', 'legendCoordinateX', 'legendCoordinateY'].every( e => Object.keys(existingLocation[Number(name)]).includes(e))
      && !isUndefinedOrEmpty(existingLocation[Number(name)].coordinateX)
      && !isUndefinedOrEmpty(existingLocation[Number(name)].coordinateY)
      && !isUndefinedOrEmpty(existingLocation[Number(name)].legendCoordinateX)
      && !isUndefinedOrEmpty(existingLocation[Number(name)].legendCoordinateY)
     ) {
      return false;
    }
    return true;
  };

  const moveComponent = (name: number, componentId: number, up: boolean) => {
    const existingLocations = [...editDashTemplateForm.getFieldValue('locations')];
    const comIndex = existingLocations[name].components.findIndex((com: number) => componentId === com);
    const movable = (comIndex > 0 && up) || (comIndex < existingLocations[name].components.length - 1 && !up);
    const updatedLocations = existingLocations.map((loc: TemplateLocation, idx: number) => {
      let temp = loc;
      if (idx === name && movable) {
        const direction = up ? -1 : 1;
        const newPosition = comIndex + direction;
        temp.components?.splice(comIndex, 1);
        temp.components?.splice(newPosition, 0, componentId);
      }
      return temp;
    });
    editDashTemplateForm.setFieldValue('locations', updatedLocations);
  }

  const buildNewRow = (): TemplateLocation => {
    const allLocationOrders = editDashTemplateForm.getFieldValue('locations').map((loc: TemplateLocation) => {return loc?.locationOrder || 0});
    let locationOrder = 1;
    if (allLocationOrders.length !== 0) {
      locationOrder = Math.max(...allLocationOrders) + 1;
    }
    return {
      selected: false, 
      coordinateX: undefined, 
      coordinateY: undefined, 
      location: undefined, 
      type: undefined, 
      components: [], 
      legendCoordinateX: undefined, 
      legendCoordinateY: undefined, 
      locationOrder: locationOrder};
  }

  return (
    <div style={{ width: "120rem", marginLeft: "-4rem" }}>
      <Title level={5}>
        Location(s):
      </Title>
      <Form.List name="locations" >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => ( // name is the absolute index to the rows !
              <div key={"row_" + name}>
                  <Row gutter={12}>
                    <Col>
                      <Form.Item
                        name={[name, 'selected']}
                      >
                        <Radio
                          checked={(currentLocations || [])[name]?.selected || false}
                          onChange={() => selectLocation(name, false)}>
                        </Radio>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'location']}
                        rules={[{ required: true, message: 'Missing location' }]}
                        label="location"
                        labelCol={{ span: 6 }}
                        style={{marginLeft: "28px"}}
                      >
                        <Input placeholder="location name" onClick={() => selectLocation(name, false)} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={[name, 'type']}
                        rules={[{ required: true, message: 'Missing component type' }]}
                        label="type"
                      >
                        <Select style={{ width: "16.5rem" }} onClick={() => selectLocation(name, false)}
                          options={componentTypes.map((value) => ({
                            value: value,
                            key: value,
                          }))}
                          onChange={(value) => onComponentTypeChange(value, name)}
                        >
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        {...restField}
                        name={[name, 'coordinateX']}
                        rules={[{ required: true, message: 'Missing x coordinate' }]}
                        label="x"
                      >
                        <Input placeholder="x coordinate" onClick={() => selectLocation(name, false)} className={name === selectedLocation && clickedImage && !isLegend ? styles['click'] : ''} />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        {...restField}
                        name={[name, 'coordinateY']}
                        rules={[{ required: true, message: 'Missing y coordinate' }]}
                        label="y"
                      >
                        <Input placeholder="y coordinate" onClick={() => selectLocation(name, false)} className={name === selectedLocation && clickedImage && !isLegend ? styles['click'] : ''} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        name={[name, 'components']}
                        rules={[{ required: true, message: 'Missing component' }]}
                        label="components"
                      >
                        <CustomSelect 
                          alldashcomponents={allDashComponents} 
                          name={name}
                          selectlocation={selectLocation} 
                          movecomponent={(componetId, up) => moveComponent(name, componetId, up)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={1} style={{marginLeft: "55px"}}>
                    <Col span={3}>
                      <Form.Item
                        {...restField}
                        name={[name, 'legendCoordinateX']}
                        rules={[{ required: true, message: 'Missing x coordinate' }]}
                        label="legend x"
                        labelCol={{ span: 8 }}
                      >
                        <Input style={{width: "11rem"}} placeholder="legend x coordinate" onClick={() => selectLocation(name, true)} className={name === selectedLocation && clickedImage && isLegend ? styles['click'] : ''} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'legendCoordinateY']}
                        rules={[{ required: true, message: 'Missing y coordinate' }]}
                        label="legend y"
                        labelCol={{ span: 8 }}
                      >
                        <Input style={{width: "11rem"}} placeholder="legend y coordinate" onClick={() => selectLocation(name, true)} className={name === selectedLocation && clickedImage && isLegend ? styles['click'] : ''} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Tooltip title="Move Row Up">
                        <UpCircleTwoTone onClick={() => moveRow(true, name)} className={styles['upIcon']} />
                      </Tooltip>
                      <Tooltip title="Move Row Down">
                        <DownCircleTwoTone  onClick={() => moveRow(false, name)} className={styles['downIcon']} label="Move Row Down" />
                      </Tooltip>
                      <Tooltip title="Remove Location">
                        <MinusCircleTwoTone onClick={() => remove(name)} className={styles['removeIcon']} />
                      </Tooltip>
                    </Col>
                  </Row>
                {isEmptyCoordinate(name) && <Alert style={{ height: "2rem", width: "50rem", marginBottom: "1rem" }} type="warning" message="Please select the coordinate in the template graph"></Alert>}
                <Divider style={{marginTop: "-.3rem", borderWidth: '2px', minWidth: "1200px", width: "1200px", color: "blue"}}/>
              </div>
            ))}
            {editDashTemplateForm.getFieldValue('locations') && editDashTemplateForm.getFieldValue('locations').length <= 50 && <Form.Item>
              <Button type="text" onClick={() => add(buildNewRow())} block icon={<PlusOutlined />} style={{ width: "70rem", marginLeft: "2rem", marginTop: "1.5rem", fontWeight: "bold", border: "1px dashed #1677ff", color: "#1677ff"}}>
                Add location 
              </Button>
            </Form.Item>}
          </>
        )}
      </Form.List>
    </div>
  )
}


interface CustomSelectProps {
  alldashcomponents: DashComponent[];
  name: number;
  selectlocation: (name: number, isLegend: boolean) => void; 
  movecomponent: (componentId: number, up: boolean) => void;
}

const CustomSelect = ({alldashcomponents, movecomponent, selectlocation, name, ...restProps}: CustomSelectProps) => {

  const tagRenderMethod = (tagProps: any) => {

    const { label, value, closable, onClose } = tagProps;

    return (
      <div className={styles['component_tag']}>
        {label}
         {/* ({value}) */}
        {closable && 
        <CloseCircleTwoTone 
          style={{ marginLeft: '10px', marginRight: '5px', cursor: 'pointer', fontSize: "16px" }} 
           onClick={onClose} 
          onMouseDown={e => {e.stopPropagation()}}
        />}
        <LeftCircleTwoTone 
           
          onClick={() => {movecomponent(value, true)}} 
          onMouseDown={e => {e.stopPropagation()}} 
          style={{fontSize: "16px"}}
        />
        <RightCircleTwoTone 
           
          style={{marginLeft: "5px", fontSize: "16px"}} 
          onClick={() => {movecomponent(value, false)}} 
          onMouseDown={e => {e.stopPropagation()}} 
        />
      </div>
    );
  };

  return (
  <Select
    style={{ width: "63.5rem" }}
    mode="multiple"
    onClick={() => selectlocation(name, false)}
    showSearch
    allowClear={true}
    optionFilterProp="children"
    tagRender={tagRenderMethod}
    {...restProps}
  >
    {alldashcomponents?.map((dc, i) => (
      <Select.Option key={"components-" + i} value={dc.id}>
        {dc.partNumber + ' - ' + dc.description}
      </Select.Option>
    ))}
  </Select>)
}

const CloneModal = (props: {template: DashTemplate | undefined}) => {

  const [showClone, setShowClone] = useState(false);
  const configurator = useContext(ConfiguratorContext);
  const [form] = useForm();
  const [enabled, setEnabled] = useState(true);
  const intl = useIntl();

  const onOpen = () => {
    setShowClone(true);
    setEnabled(true);
  }

  const onCancel = () => {
    setShowClone(false);
    form.resetFields();
  }

  const cloneTemplate = async () => {
    try {
      form.validateFields();
      setEnabled(false);
      const clone = {...props.template, ...form.getFieldsValue(), locations: []};
      const templateId = (await configurator.api.cloneTemplate(clone)).data;
      notification.success({message: "Sucessfully clone dash template."});
      window.location.href =
        "/dash-template/" + templateId;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to clone dash template. " + errorMsg;
      notification.error( { message: msg });
      setEnabled(false);
    }
  }

  return (<>
    <Button type="primary" onClick={onOpen}>
      Clone
    </Button>
    <Modal
      title={`Clone Dash Template ${props.template?.partNumber}`}
      open={showClone}
      onCancel={onCancel}
      onOk={cloneTemplate}
      okText="Clone"
      okButtonProps={{disabled: !enabled}}
    >
      <Form
        form={form}
      >
        <Form.Item
          name="partNumber"
          label="Part Number"
          rules={[{
            required: true,
            message: `Part Number is required.`,
          }]}
        >
          <Input/>
        </Form.Item>
      </Form>
    </Modal>
  </>);
}
