import { Select } from "antd";
import { Category, MetadataValue } from "../../../api/models";
import { useContext, useState } from "react";
import RulesContext from "../context";

export interface Props {
    value: MetadataValue;
    onUpdate: (MetadataValue) => void;
}

interface CategorySelectProps {
    categoryId?: string;
    categories: Category[];
    onSelectCategory: (Category) => void;
}

interface MetadataSelectProps {
    category: Category | undefined;
    field?: string;
    onSelectField: (string) => void;
}

const MetdataFieldSelect = (props: MetadataSelectProps) => {
    return (
        <Select showSearch value={props.field} style={{ width: '300px' }} onChange={(val) => props.onSelectField(val)}
            filterOption={(input, option) =>
                (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }>
            {props.category?.metadata.map(md => (
                <Select.Option key={md.name} value={md.name}>{md.name}</Select.Option>
            ))}
        </Select>
    );
};

const CategorySelect = (props: CategorySelectProps) => {
    return (
        <Select showSearch value={props.categoryId} style={{ width: '300px' }} onSelect={(val) => props.onSelectCategory(props.categories.find(cat => cat.categoryId == val))}
            filterOption={(input, option) => {
                return (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase());
            }
            }>
            {props.categories.map(cat => (
                <Select.Option key={cat.categoryId} value={cat.categoryId}>{cat.name}</Select.Option>
            ))}
        </Select>
    );
};

const MetadataValueEditor = (props: Props) => {
    const rulesContext = useContext(RulesContext);
    const [currentCategory, setCurrentCategory] = useState(rulesContext.categories.find(c => c.categoryId == props.value.category));
    const [currentField, setCurrentField] = useState<string | undefined>(props.value.field);

    function onSelectCategory(category: Category) {
        if (category == currentCategory) return;

        const val = new MetadataValue();
        val.category = category.categoryId;
        props.onUpdate(val);

        setCurrentCategory(category);
        setCurrentField('');
    }

    function onSelectField(field: string) {
        setCurrentField(field);

        if (!currentCategory) return;
        const val = new MetadataValue();
        val.category = currentCategory.categoryId;
        val.field = field;
        props.onUpdate(val);
    }

    return (
        <div>
            <CategorySelect categories={rulesContext.categories} categoryId={currentCategory?.categoryId} onSelectCategory={onSelectCategory} />
            <MetdataFieldSelect onSelectField={onSelectField} field={currentField} category={currentCategory} />
        </div>
    );
};

export default MetadataValueEditor;
