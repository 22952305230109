import { useContext, useRef, useState } from "react";
import { Button, Row, Modal, Result, Table, Carousel, Spin, Checkbox, Tooltip, ButtonProps, Col } from "antd";
import { ConfiguratorContext } from "../context";
import {useAsyncState} from "../hook/useAsyncState";
import { ColumnType } from "antd/lib/table/interface";
import UploadButton from "../components/UploadButton";
import {useIntl} from "react-intl";
import Utils from "../util/util";
import {Link} from "react-router-dom";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import BMButton from "../components/BMButton";
import {UploadFailure} from "../components/UploadButton";
import {AssemblyBase, BaseQuote} from "../api/models";

interface QuoteAssemblyReplacement {
  quote: BaseQuote
  removeAssembly: AssemblyBase
  addAssemblyLst: AssemblyBase[]
  notes: string
}

interface AssemblyReplacementStatisics {
  available?: QuoteAssemblyReplacement[]
  unavailable?: QuoteAssemblyReplacement[]
  failures?: UploadFailure[]
  reason?:string
}
interface QuoteAssemblyReplacementResult  {
  replacement:QuoteAssemblyReplacement
  errorMsg?:string
}

const BatchAssemblyReplacementButtonModal  = (props: ButtonProps) => {

  const [updateAll, setUpdateAll] = useState<boolean>(true);
  const [forcePriceMatch, setSilentPriceMatch] = useState<boolean>(true);
  const [selectedQuoteLst, setSelectedQuoteLst] = useState<QuoteAssemblyReplacement[]>();
  const [replaceAssemblyResult, replaceAssemblyResultAsync] = useAsyncState<QuoteAssemblyReplacementResult[]>()

  const carouselRef = useRef<any>(null);
  const [carouselNdx, setCarouselNdx] = useState<number>(0);
  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [assemblyReplacementStatistics, setAssemblyReplacementStatistics] = useState<AssemblyReplacementStatisics>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);


  const handleDone = () => {
    setIsOpen(false);
  }

  const handleReplaceAssemblies = async () => {
    if ( !selectedQuoteLst?.length ) return;

    replaceAssemblyResultAsync?.setLoading();

    var rslt = new Array<QuoteAssemblyReplacementResult>();
    for ( var ndx in [...selectedQuoteLst] ) {
      var replacement = selectedQuoteLst[ndx];

      try {
        const req = {
          removeAssemblyId: replacement.removeAssembly.id,
          addAssemblyIdLst: replacement.addAssemblyLst.map( r => r.id ),
          reason: assemblyReplacementStatistics?.reason,
          forcePriceMatch,
        };
        var resp = await configurator.api.replaceAssembly(replacement.quote.id, req);
        replacement.quote = resp.data;
        rslt.push({ replacement });
      } catch (e:any) {
        const errorMsg = intl.formatMessage({ id: e.message });
        rslt.push({ replacement, errorMsg});
      }
    }

    replaceAssemblyResultAsync.setDone( rslt.sort( (a,b) => a.replacement.quote.quoteId.localeCompare(b.replacement.quote.quoteId) ) );
    setSelectedQuoteLst(rslt.filter( f => f.errorMsg ).map(f => f.replacement));

    nextSlide();
  }

  const nextSlide = () => {
    carouselRef.current?.next();
    setCarouselNdx( ( carouselNdx + 1 ) % getSlideLst().length );
  }
  const prevSlide = ()  =>{
    carouselRef.current?.prev();
    setCarouselNdx( carouselNdx- 1 );
  }
  const resetSlides = () => {
    const ndx = 0;
    setCarouselNdx(ndx);
    carouselRef.current?.goTo(ndx);
  }
  const getSlide = () : { title?:string, footer?: React.ReactNode, hidden?:boolean } | undefined => {
    return getSlideLst()[carouselNdx];
  };

  const getSlideLst = () => {
    return [
      { title: "Upload file:",
        footer: <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
          <Button key="next" type="primary" onClick={nextSlide} disabled={!assemblyReplacementStatistics || isUploading} >Next</Button>
          <Button key="cancel" onClick={handleDone}>Cancel</Button>
        </div>,
      },
      { title: "The uploaded file had the following issues:",
        footer: <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
          {!!assemblyReplacementStatistics?.available?.length && <Button key="next" type="primary" onClick={nextSlide}>Next</Button> }
          <Button key="back" onClick={prevSlide}>Back</Button>
        </div>,
        hidden: !assemblyReplacementStatistics?.failures?.length
      },
      { title: "The following quotes will be affected by the assembly replacments:",
        footer: <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
          <Button key="next" type="primary" onClick={handleReplaceAssemblies} loading={replaceAssemblyResultAsync.isLoading()} >Next</Button>
          <Button key="back" onClick={prevSlide}>Back</Button>
        </div>,
      },
      {
        footer: <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
          <Button key="done" type="primary" onClick={handleDone} >Done</Button>
          <Button key="back" onClick={prevSlide}>Back</Button>
        </div>,
      }].filter( s => !s.hidden );
  }

  const handleUpdateAll = (v:CheckboxChangeEvent) => setUpdateAll(v.target.checked);
  const handleSilentPriceMatch = (v:CheckboxChangeEvent) => setSilentPriceMatch(v.target.checked);

  const handleSelectAllRows = (selected: boolean, _selectedRows: QuoteAssemblyReplacement[], _changeRows: QuoteAssemblyReplacement[]) => {
    if (selected) {
      setSelectedQuoteLst(assemblyReplacementStatistics?.available);
      return;
    }
    else {
    
      setSelectedQuoteLst([]);
    }
  }
  const handleSelectRow = (record:QuoteAssemblyReplacement) => {
    //toggle previously selected
    const updated = selectedQuoteLst?.filter( ae => ae.quote.id !== record.quote.id );
    if ( updated?.length === selectedQuoteLst?.length  ) {
      setSelectedQuoteLst( (updated || []).concat(record) );
      return;
    }

    setSelectedQuoteLst(updated);
  }

  const columns:ColumnType<QuoteAssemblyReplacement>[] = [ 
    {
      title: "Quote",
      render: (record:QuoteAssemblyReplacement) => <Link to={"/configurator/" + encodeURI(record.quote.quoteId)} >{record.quote.quoteId}</Link>
    }, 
    {
      title: "Status",
      render: (record:QuoteAssemblyReplacement) => Utils.formatQuoteStatus(record.quote)
    }, 
    {
      title: "Remove",
      render: (record:QuoteAssemblyReplacement) => record.removeAssembly.bom
    },
    {
      title: "Add",
      render: (record:QuoteAssemblyReplacement) => record.addAssemblyLst.map(a => a.bom).join(", ")
    } ];

    const dataSource = (assemblyReplacementStatistics?.available || []).concat((assemblyReplacementStatistics?.unavailable || [] )).sort( (a,b) => a.quote.quoteId.localeCompare(b.quote.quoteId) );

    return <>
    <BMButton
      className="ghostBmButton"
      type="text"
      {...props}
      onClick={() => setIsOpen(true)}
    >Batch Assembly Replacement</BMButton>

    <Modal 
      title={getSlide()?.title}
      footer={getSlide()?.footer}
      open={isOpen}
      //onOk={props.onDone}
      onCancel={handleDone}
      afterOpenChange={() => {
        //select all available by default
        setSelectedQuoteLst(assemblyReplacementStatistics?.available);
        setAssemblyReplacementStatistics(undefined);
        resetSlides();
      }}
      style={{maxWidth: "50rem", minWidth: "50rem"}}
    >
      <Carousel dots={false} ref={carouselRef}>
        <div>
          <Spin spinning={isUploading}>
            <Row style={{marginTop: "2rem", marginBottom: "1rem", justifyContent: "center"}}>
              <Checkbox checked={updateAll} onChange={handleUpdateAll} >Update all quotes - including already built trucks.</Checkbox>
            </Row>
            <Row style={{marginTop: "3rem", marginBottom: "1rem", justifyContent: "center"}}>
              <UploadButton type="primary"
                showResults={false}
                action={configurator.api.getStageAssemblyReplacementCSVUrl()}
                data={{updateAll}}
                onDone={(v) => {
                  setIsUploading(false);
                  setAssemblyReplacementStatistics({...v.response, 
                                                   reason: `${v.name} batch update` });
                  nextSlide();
                }}
                onUploading={() => setIsUploading(true) }
              >Upload File</UploadButton>
            </Row>
          </Spin>
        </div>

        {assemblyReplacementStatistics?.failures?.length && <div>
          <Result
            status={"warning"}
            subTitle={<div style={{textAlign: "left"}}>
              <div>The following records had errors:</div>
              <ul>
                {assemblyReplacementStatistics?.failures?.map( (err,ndx) => <li key={`upload-msg-${ndx}`}>{err.message}</li>)}
              </ul>
            </div> }
          />
        </div>}

        <div>

          <style>
            {`
              .quoteAssemblyReplacementLst .ant-table-content { padding: 5px; } /* don't clip corners */
                .quoteAssemblyReplacementLst .ant-table-cell { border: none !important; } /* remove table cell borders */
                /* add error border to table */
                .ant-form-item-has-error .quoteAssemblyReplacementLst .ant-table-content {
                border: solid 1px #ff4d4f;
                border-radius: 15px;
              }
              `}
          </style>

          <Row style={{marginBottom: ".5rem", paddingRight: ".5rem", justifyContent: "flex-end" }}>
            <Tooltip title="After assembly changes, this will price match quotes without an engineering change or reverting from quote approved." >
              <Checkbox checked={forcePriceMatch} onChange={handleSilentPriceMatch} >Force Price Match</Checkbox>
            </Tooltip>
          </Row>

          <Table 
            className="quoteAssemblyReplacementLst"
            columns={columns}
            rowKey={(r) =>  r.quote.id}
            dataSource={dataSource}
            onRow={(record, _rowIndex) => {
              return {
                onClick: () => {
                  if( ! assemblyReplacementStatistics?.unavailable?.includes(record) ) {
                  handleSelectRow(record)
                }
              }};
            }}
            rowSelection={{
              type: "checkbox",
              onSelectAll: handleSelectAllRows,
              onSelect: handleSelectRow,
              selectedRowKeys: (selectedQuoteLst?.map( ae => ae.quote.id ) || [] ) as number[],
              getCheckboxProps: (record) => {
                return { 
                  disabled: assemblyReplacementStatistics?.unavailable?.includes(record)
                };
              }
            }}
            loading={replaceAssemblyResultAsync.isLoading()}
          />
        </div>

        <div>
          <Result
            status={!replaceAssemblyResult?.filter(v => v.errorMsg).length ? "success" : "error"}
            subTitle={ <>
              {!!replaceAssemblyResult?.filter(v => v.errorMsg).length && <div style={{textAlign: "left"}}>
                <div>The following updates had errors:</div>
                <ul>
                  {replaceAssemblyResult?.filter(v => v.errorMsg).map( (rslt,ndx) => <li key={`upload-error-msg-${ndx}`}>{rslt.replacement.quote.quoteId} - {rslt.errorMsg}</li>)}
                </ul>
              </div> }
              {!!replaceAssemblyResult?.filter(v => !v.errorMsg).length && <div style={{textAlign: "left"}}>
                <div style={{ marginBottom: "1rem" }}>The following updates were successful:</div>
                <ul style={{padding: 0, listStyle: "none"}}>
                  {replaceAssemblyResult?.filter(v => !v.errorMsg)
                    .map( rslt => rslt.replacement.quote )
                    .map( (quote,ndx) => <li style={{ display: "inline-block", width: "10rem", marginBottom: ".4rem" }} key={`upload-success-msg-${ndx}`}>
                      <Link to={"/configurator/" + encodeURI(quote.quoteId)} >{quote.quoteId}</Link>
                    </li>)}
                </ul>
              </div> }
            </>}
          />
        </div>
      </Carousel>

    </Modal>
    </>;
}

export default BatchAssemblyReplacementButtonModal;

