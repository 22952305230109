import Title from "antd/lib/typography/Title";
import React from "react";
import { ReactElement } from "react";
import { AssemblyBase, QuoteReview } from "../api/models";
import Utils from "../util/util";

const QuoteReviewDetail = (props:{ 
  review:QuoteReview | undefined 
  ignoreDashDrawing?: boolean
}) => {

  const { review } = props;


  const perferrs = Utils.getPerformanceErrors(review?.performance);
  const getAssemblyLabel = (asm:AssemblyBase) =>  <span style={{fontSize: ".8rem"}}><span style={{fontWeight: 600}}>{asm.bom}</span> - <span>{asm.bomDescription}</span></span>

  const sections:Array<ReactElement> = [];

  !!review?.customOptions.length && sections.push(<React.Fragment key="coReview">
    <Title level={5}>The following custom options need review:</Title>
    <ul>
      {review.customOptions
        .sort( (a,b) => a.content?.localeCompare(b.content || "") || 0 )
        //.map( co => `${co.content} - ${co.approved === undefined ? "Pending" : co.approved ? "Approved" : "Rejected"}`)
        .map( co => <div>{co.category ? Utils.stripSortingPrefix(co.category?.name) : "Missing Category" } - {co.content}</div>)
        .map( (v,ndx) => <li style={{marginBottom: ".4rem"}} key={`co-${ndx}`}>{v}</li>)}
    </ul>
  </React.Fragment>);

  !!review?.obsoleteAssemblyLst.length && sections.push(<React.Fragment key="obsoleteReview">
    <Title level={5}>The following selections are obsolete:</Title>
    <ul>
      {review.obsoleteAssemblyLst
        .sort( (a,b) => a.bom.localeCompare(b.bom) )
        .map( getAssemblyLabel )
        .map( (v,ndx) => <li style={{marginBottom: ".4rem"}}  key={`asm-${ndx}`}>{v}</li>)}
    </ul>
  </React.Fragment>);

  !!review?.invalidAssemblyLst.length && sections.push(<React.Fragment key="incompatibleReview">
    <Title level={5}>The following selections are incompatible:</Title>
    <ul>
      {review.invalidAssemblyLst
        .sort( (a,b) => a.bom.localeCompare(b.bom) )
        .map( getAssemblyLabel )
        .map( (v,ndx) => <li style={{marginBottom: ".4rem"}}  key={`asm-${ndx}`}>{v}</li>)}
    </ul>
  </React.Fragment>);

  !!review?.missingCategoryLst.length && sections.push(<React.Fragment key="missingReview">
    <Title level={5}>The following categories are missing selections:</Title>
    <ul>
      {review?.missingCategoryLst
        .map( cat => `${cat?.configuratorSection}: ${Utils.stripSortingPrefix(cat?.name)}` )
        .sort( (a,b) => a.localeCompare(b) )
        .map( (v,ndx) => <li style={{marginBottom: ".4rem"}}  key={`missingcat-${ndx}`}>{v}</li>)}
    </ul>
  </React.Fragment>);

  !!perferrs?.length && sections.push(<React.Fragment key="performanceReview">
    <Title level={5}>There are performance errors:</Title>
    <ul>
      {perferrs?.map( (v,ndx) => <li style={{marginBottom: ".4rem"}}  key={`perferr-${ndx}`}>{v}</li> )}
    </ul>
  </React.Fragment>);

  (!props.ignoreDashDrawing && !review?.dashImageUrl?.length) && sections.push(<React.Fragment key="dashReview">
    <Title level={5}>The dash drawing is missing.</Title>
  </React.Fragment>);

  return sections.length 
    ? <>{sections}</>
    : <>There are no issues.</>

}

export default QuoteReviewDetail;

