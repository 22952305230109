import { EqualsExpression, GtExpression, GteExpression, LhsRhsExpression, LtExpression, LteExpression, NotEqualsExpression, NotExpression, RuleExpression, RuleValueTypes } from "../../../api/models";
import RuleValueEditor from "../Values/rule_value";

export interface Props {
    expression: LhsRhsExpression;
    onUpdate: (e:LhsRhsExpression) => void;
    disabledRuleValueTypes?: RuleValueTypes[] | undefined
}

const LhsRhsExpressionEditor = (props: Props) => {
    function getOperator() {
        if(props.expression instanceof NotEqualsExpression) {
            return '!=';
        }
        else if(props.expression instanceof EqualsExpression) {
            return '==';
        }
        else if(props.expression instanceof GteExpression) {
            return '>=';
        }
        else if(props.expression instanceof GtExpression) {
            return '>';
        }
        else if(props.expression instanceof LtExpression) {
            return '<';
        }
        else if(props.expression instanceof LteExpression) {
            return '<=';
        }
        else {
            return 'Unknown Operator';
        }
    }

    return (
        <div>
            <RuleValueEditor value={props.expression.lhs} 
              onUpdate={(lhs) => {
                      const expr = {...props.expression, lhs: lhs};
                      Object.setPrototypeOf(expr, Object.getPrototypeOf(props.expression));
                      props.onUpdate(expr);
              }} 
              disabledRuleValueTypes={props.disabledRuleValueTypes}
            />
            &nbsp; <strong>is {getOperator()}</strong> &nbsp;
            <RuleValueEditor value={props.expression.rhs} 
                onUpdate={(rhs) => {
                        const expr = {...props.expression, rhs: rhs};
                        Object.setPrototypeOf(expr, Object.getPrototypeOf(props.expression));
                        props.onUpdate(expr);
                }}
                disabledRuleValueTypes={props.disabledRuleValueTypes}
            />
        </div>
    );
};

export default LhsRhsExpressionEditor;
