import { useCallback, useContext, useEffect, useRef } from "react";
import { ConfiguratorContext } from "../context";
import { notification, Select, SelectProps } from "antd";
import { Assembly, SortDirection, AXIOS_CANCEL_MSG } from "../api/models";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import {debounce} from "lodash";
import {AssemblyFilterOptions} from "../api";
import {SorterResult, TablePaginationConfig} from "antd/es/table/interface";
import axios, {CancelTokenSource} from "axios";

type BaseAssemblySort = SorterResult<Assembly> | SorterResult<Assembly>[]

const AssemblySelector = (props: SelectProps<number[]> & {
  categoryId?: number | undefined
}) => {

  const {categoryId:a, ...selectProps } = props;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [assemblyLst, assemblyLstAsync] = useAsyncState<Assembly[]>();
  const [selectedLst, selectedLstAsync] = useAsyncState<Assembly[]>();
  const isLoading = assemblyLstAsync.isLoading() || selectedLstAsync.isLoading()
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  useEffect(() => {

    if ( !selectedLstAsync.isLoading() ) {
      if ( props.value?.length ) {
        selectedLstAsync.setLoading();
        Promise.all( props.value.map( id => configurator.api.getAssembly(id) ) )
        .then( selected => {
          selectedLstAsync.setDone( selected.map( r => r.data ) );
        })
        .catch((e:any) => {
          const errorMsg = intl.formatMessage({ id: e.message });
          notification.error( { message: "Failed to get selected assemblies. " + errorMsg });
          selectedLstAsync.setFail(e.message);
        });
      }
    }
  }, [props.value]);


  const handleFocus = () => {
    if( assemblyLstAsync.isInitial() ) {
      handleSearch();
    }
  }

  const handleSearch = async (bom?:string) => {
    const filter = {
      filterQuery: bom,
      categoryId: props.categoryId
    };
    const pagination = {
      current: 1,
    };
    const sort = {
      columnKey: 'name',
      order: 'ascend'
    } as BaseAssemblySort;

    loadAssemblyLst( assemblyLstAsync, pagination, filter, sort );
  };

  const loadAssemblyLst = useCallback(debounce( async (assemblyLstAsync:AsyncState<Assembly[]>, pagination: TablePaginationConfig, filter: AssemblyFilterOptions | undefined, sorter:BaseAssemblySort) : Promise<Assembly[] | undefined> => {

    const sort = [sorter].flat().map( sorter => ({
      sortFields: sorter.columnKey?.toString() || "name",
      sortDirections: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    assemblyLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchFilteredAssemblies({
        ...filter,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || 20,
        ...sort,
      },
        cancelSource.token,
      );
      cancelTokenSourceRef.current = undefined;

      assemblyLstAsync.setDone(resp.data.content);
      return resp.data.content;
    } catch (e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id: e.message });
        notification.error( { message: "Failed to get assemblies. " + errorMsg });
        assemblyLstAsync.setFail(e.message);
      }
    }

    return;
  }, 400), []);

  const distinct = (selectedLst || []).concat( assemblyLst || [] ).reduce( (acc, v) => {
    acc[v.bom] = v
    return acc;
  }, {} );
  const datasource = Object.values( distinct ) as Assembly[];

  return <Select
    mode="multiple"
    showSearch
    allowClear
    {...selectProps}
    onFocus={handleFocus}
    optionFilterProp="label"
    onSearch={handleSearch}
    loading={isLoading}
    options={datasource?.map(asm => {
      const lbl = asm.label || asm.bomDescription
      return {
        label: `${asm.bom} (${lbl})`,
        value: asm.id
      };
    }
    )}
  />

};

export default AssemblySelector;
