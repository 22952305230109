import { Select, SelectProps } from "antd";

const OrderProbability = (props: Omit<SelectProps, 'value' | 'onChange'> & {
  id?: string;
  value?: string;
  onChange?: (v: string | undefined) => void;
}) => {
  const {...selectProps } = props;

  const probabilityStyle = {
    width: "10px",
    height: "10px",
    display: "inline-block",
    marginLeft: "12px",
  };
  
  const labelStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const getOrderProbabilityOptions = () => {
    return [
      {
        value: "HIGH",
        label: (
          <span
            style={{...labelStyle}}
          >
            HIGH
            <span
              style={{
                ...probabilityStyle,
                backgroundColor: "green",
              }}
            />
          </span>
        ),
      },
      {
        value: "MEDIUM",
        label: (
          <span
            style={{...labelStyle}}
          >
            MEDIUM
            <span
              style={{
                ...probabilityStyle,
                backgroundColor: "yellow",
              }}
            />
          </span>
        ),
      },
      {
        value: "LOW",
        label: (
          <span
            style={{...labelStyle}}
          >
            LOW
            <span
              style={{
                ...probabilityStyle,
                backgroundColor: "red",
              }}
            />
          </span>
        ),
      },
    ];
  };



  return (
    <Select
      placeholder="Select Order Probability"
      style={{ width: "120px" }}
      {...selectProps}
      options={getOrderProbabilityOptions()}
    />
  );
};

export default OrderProbability;
