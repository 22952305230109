import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { DashComponent, MISSING_IMAGE } from "../api/models";
import { ConfiguratorContext } from "../context";
import { Button, Col, notification, Form, Input,  Spin, Image, Select } from "antd";
import Title from "antd/lib/typography/Title";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";


const DashComponentView = () => {
  const params = useParams<any>();
  const configurator = useContext(ConfiguratorContext);
  const [loading, setLoading] = useState(false);
  const [editDashComponentForm] = Form.useForm();
  const [data, setData] = useState<DashComponent>();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [componentTypes, setComponentTypes] = useState<String[]>([]);

  useEffect(() => {
    getDashComponent(params.id);
  }, [params.id]);

  useEffect(() => {
    getAllComponentTypes();
  }, [])

  const getDashComponent = async (id: number) => {
    try {
      setLoading(false);
      const resp = await configurator.api.getSingleDashComponent(id);
      if (resp) {
        editDashComponentForm.setFieldsValue(
          resp.data
        )
        setUploadSuccess(true)
        setData(resp.data);
      }
    }
    catch(e) {
      console.log(e);
    }
    finally {
      setLoading(false);
    }
  }

  const getAllComponentTypes = async () => {
    try {
      setLoading(true);
      const resp = await configurator.api.getComponentTypes();
      setComponentTypes(resp.data);
    }
    catch(e) {
      console.log(e);
    }
    finally{
      setLoading(false);
    }
  }

  const onSaveDashComponentSubmit = async (value: DashComponent) => {
    try {
      const resp = await configurator.api.updateSingleDashComponent({...value, id: data?.id || 0});
      if (resp) {
        editDashComponentForm.setFieldsValue(
          resp.data
        )
        setData(resp.data);
        notification.success({
          message: "Saved successfully.",
        });
      }
    }
    catch(e) {
      console.log(e)
      notification.error({
        message: "Saved failed.",
      });
    }
  }

  const imageUploaded = () => {
    getDashComponent(params.id);
  }
  
  const getUploadImageUrl = () => {
    return ( configurator.api.baseUrl + "/v1/dash-component/" + encodeURIComponent(data?.id || 0) + "/image" );
  }

  return <div key="dashComponentView" className="site-layout-background">
    <Title level={2}>
      Dash Component Edit
    </Title>
    <br/>
    <br/>
    <Spin spinning={loading}>
      <Col style={{width: "70rem"}}>
      <Form
        labelCol={{ span: 4 }}
        form={editDashComponentForm}
        name="editShippingDestination"
        onFinish={onSaveDashComponentSubmit}
        labelAlign={"right"}
        style={{border: '3px solid #1890ff', padding: '50px 30px 50px 90px', borderRadius: '10px'}}
      >
        <Form.Item
          label="Part Number"
          name="partNumber"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input style={{ width: "36rem" }}/>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Description is required" }]}
        >
          <Input style={{ width: "36rem" }}/>
        </Form.Item>
        <Form.Item
            label="Type"
            name={'type'}
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Select
              style={{ width: "36rem" }}
              options={ componentTypes.map((value) => ({
                value: value,
                key: value,
              }))}
            >
            </Select>
          </Form.Item>

        <Form.Item label="Upload Image">
          <Dragger style={{ width: "36rem" }}
            maxCount={1}
            action={getUploadImageUrl()}
            withCredentials={true}
            name="image"
            accept=".jpg,.jpeg,.png"
            listType="picture-card"
            showUploadList={false}
            onChange={imageUploaded}
          >
            {uploadSuccess ?
              <Image
                src={data?.imageUrl || MISSING_IMAGE}
                fallback={MISSING_IMAGE}
                style={{ paddingBottom: "10px" }}
                width={100}
                preview={false}
              >
              </Image>
              :
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined  />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </>}
          </Dragger>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button type="primary" htmlType="submit">
            Save Dash Component
          </Button>
        </Form.Item>
      </Form>
      </Col>
      <br/>
      {data?.imageUrl &&
        <>
          <Image
            id='image'
            src={data?.imageUrl || MISSING_IMAGE}
            width={"100px"}
            alt=""
            preview={false}
          />
          &emsp;
          &emsp;
          <Image
            id='image'
            src={data?.iconImageUrl || MISSING_IMAGE}
            width={"32px"}
            alt=""
            preview={false}
          />
        </>
      }

    </Spin>
  </div>
}

export default DashComponentView;
