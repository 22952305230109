import React, {useContext, useState} from "react";
import { ConfiguratorContext } from "../context";
import dayjs, {Dayjs} from "dayjs";

export interface SourcewellCntContextType {
  cnt:number 
}
export const SourcewellCntContext = React.createContext<SourcewellCntContextType>({
  cnt: 0
});

export const useSourcewellContext = () : SourcewellCntContextType => {
    return useContext(SourcewellCntContext);
}

// Data provider component
const SourcewellCntProvider = ({ children }) => {

  const [nextUpdate, setNextUpdate] = useState<Dayjs>();
  const [sourceWellCnt, setSourcewellCnt] = useState<number>(0);
  const context = useContext(ConfiguratorContext);

  //don't bother if not sourcewell manager
  if ( context.isSourcewellManager() ) {

    const now = dayjs();

    const fetchSourcewellCnt = async () : Promise<number> => {

      const filter = {
        myQuotes: false,
        incentivePrograms: ['Sourcewell'],
        dateFilterType: 'Created',
        dateFilterStart: now.startOf('week'),
        dateFilterEnd: now.endOf('week'),
        page: 0,
        size: 1,
      };

      const resp = await context.api.listQuotes(filter)
      return resp.data.totalElements;
    };

    //only update hourly
    if ( !nextUpdate || now.isAfter(nextUpdate) ) {
      setNextUpdate(now.add(1, 'hour') );
      fetchSourcewellCnt().then(setSourcewellCnt);
    }
  }

  return <SourcewellCntContext.Provider value={{cnt:sourceWellCnt}}>{children}</SourcewellCntContext.Provider>;
};
  
export default SourcewellCntProvider;

