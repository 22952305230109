import Title from "antd/lib/typography/Title";
import { Table, Space, notification,  Input } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { ConfiguratorContext, } from "../context";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import {  EngineeringTeam } from "../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import {debounce} from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import {SortOrder} from "antd/es/table/interface";
import EditEngineeringTeamButtonModal from "../components/Quote/EditEngineeringTeamButtonModal";
import { Link } from "react-router-dom";
import Utils from "../util/util";

type  SortResult<T> = SorterResult<T> | SorterResult<T>[]

interface EngineeringTeamFilter {
  search?: string
}

const DEFAULT_PAGE_SIZE = 20;
const EngineeringTeamsPage = () => {
  const isMobile = useCheckMobileScreen();
  const intl = useIntl();

  const [_engineeringTeamLst, engineeringTeamLstAsync] = useAsyncState<EngineeringTeam[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("search", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [filter, setFilter] = useState<EngineeringTeamFilter>({
    search: searchFilterParam || undefined, //silly fix for null
  });

  const defaultSort = {
    columnKey: 'name',
    order: 'ascend' as SortOrder
  };
  const [sort, setSort] = useState<SortResult<EngineeringTeam>>({
    columnKey: sortFieldQueryParam || defaultSort.columnKey,
    order: sortDirectionQueryParam as ( SortOrder | undefined ) || defaultSort.order
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  //change in filters should trigger refetch
  useEffect(() => {
    reloadEngineeringTeams();
  }, [ filter, pagination.pageSize, pagination.current ]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  const reloadEngineeringTeams = () => {
      loadEngineeringTeams( engineeringTeamLstAsync, pagination, filter , sort );
  }

  const loadEngineeringTeams = useCallback(debounce( async (engineeringTeamLstAsync:AsyncState<EngineeringTeam[]>, pagination: TablePaginationConfig, filter: EngineeringTeamFilter, sorter:SortResult<EngineeringTeam> ) => {

    var sort = [ sorter ].flat()[0];
    engineeringTeamLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchEngineeringTeamList({
        ...filter,
        page: ( pagination.current || 1 ) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort: {
          field: sort.columnKey?.toString() ||  defaultSort.columnKey,
          direction: sort.order == 'ascend' ? 'asc' : 'desc',
        }
      })
      engineeringTeamLstAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error({message:"Engineering Teams failed to load. " + errorMsg});
      engineeringTeamLstAsync.setFail( e.message );
    }

  }, 400 ), [] );


  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SortResult<EngineeringTeam>) => {
    setPagination(pagination);

    const firstSort = [ sort ].flat()[0];
    setSortFieldQueryParam( firstSort.columnKey?.toString() );
    setSortDirectionQueryParam( firstSort.order );
    setSort(sorter);
  };

  const handleChangeSearch = (search:string) => {
    setSearchFilterParam(search);
    setFilter({
      search
    });
  }

  const handleAddEngineeringTeam = () => {
    reloadEngineeringTeams();
  }

  let columns:ColumnType<EngineeringTeam>[] = [
    {
      title: "Name",
      key: "name",
      render: (et:EngineeringTeam) => <EditEngineeringTeamButtonModal type="text" className="ghostBmButton" 
        value={et}
        onChange={handleAddEngineeringTeam}
      >
        <span style={{textDecoration: "underline"}}>{et.name || "None"}</span>
      </EditEngineeringTeamButtonModal>
    },
    {
      title: "Members",
      key: "members",
      render: (et:EngineeringTeam) => et.members?.map(u => u.name).join(", ")
    },
    {
      title: "Categories",
      key: "categories",
      render: (et:EngineeringTeam) => <ul className="csl">{et.categories?.map(c => (<li><Link to={`/categories/${c.id}`} target="_blank" >{Utils.stripSortingPrefix( c.name)}</Link></li>))}</ul> 
    },
  ];

  return (
    <div className="site-layout-background">
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>

        <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
          <Title level={2}>Engineering Teams</Title>
          <EditEngineeringTeamButtonModal type="primary" onChange={handleAddEngineeringTeam} >New</EditEngineeringTeamButtonModal>
        </div>

        <div style={{width: "100%"}}>
        <Input onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search teams" />
        </div>

        <Table
          loading={engineeringTeamLstAsync.isLoading()}
          onChange={tableOnChange}
          bordered
          pagination={pagination}
          dataSource={engineeringTeamLstAsync.val}
          columns={columns}
          rowKey="id"
        />

      </Space>
    </div>
  );
};

export default EngineeringTeamsPage;
