import { Alert, Button, notification } from "antd";
import { ConfiguratorContext } from "../../context";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { useQuoteContext } from "../../contexts/QuoteContext";

const SubmitSplitChangeAlert = (props: any) => {

  const { quoteAsync } = useQuoteContext();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const quote = quoteAsync?.val;
  const showSplitButton = (quote?.quoteId === quote?.pendingSplitChange?.self && !quote?.pendingSplitChange?.selfSubmitted)
                            || (quote?.pendingSplitChange?.partners.some(p => p.quoteId === quote?.quoteId && !p.partnerSubmitted)); 

  const submitSplitChangeWithNoChange = async () => {
    if (quote) {
      try {
        await configurator.api.submitSplitWithoutChange(quote?.id || 0);
        notification.success({ message: "Split change is successfully submitted ." });
        setTimeout(() => window.location.reload(), 400);
      }
      catch (e: any) {
        const errorMsg = intl.formatMessage({ id: e.message });
        notification.error({ message: "Failed to submit split change. " + errorMsg });
        quoteAsync?.setFail(e.message);
      }
    }
  };

  const parentQuoteId = quote?.pendingSplitChange?.partners?.find(q => !!q.isParent)?.quoteId;

  const alertMessage = `${!parentQuoteId ? "This quote is in pending split change!" : `Split approvals of this quote is under ${parentQuoteId}!`}
                        The split also includes quote: ${quote?.pendingSplitChange?.partners?.map(p => p.quoteId).join(", ")}. 
                        ${showSplitButton ? "Submit change order or click submit split to trigger the process." : ""}`;

  return (
    <Alert
      type="warning"
      message={alertMessage}
      action={showSplitButton && <Button size="small" type="primary" data-testid="submit-split" onClick={() => submitSplitChangeWithNoChange()}>Submit Split</Button>}
    />
  );
}

export default SubmitSplitChangeAlert;
