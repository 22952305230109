import { Segmented } from "antd";
import { SegmentedValue } from "antd/es/segmented";

const PriceViewSwitch = (props: {
  options: string[],
  defaultValue: SegmentedValue,
  setPriceView: (val: SegmentedValue) => void,
}) => {

  const {options, defaultValue, setPriceView} = props;

  return (
    <>
      <style>
      {`
        .custom-segmented .ant-segmented-item-selected {
          background-color: #1677ff !important;
          border-radius: 4px !important;
          font-size: 12px !important;
        }

        .custom-segmented .ant-segmented-item-selected .ant-segmented-item-label {
          color: white !important;
        }

        .custom-segmented .ant-segmented-item-label {
          color: darkgrey;
        }

      `}
      </style>
      <Segmented
        style={{marginLeft: "10px"}}
        size="small"
        options={options}
        onChange={setPriceView}
        defaultValue={defaultValue}
        className="custom-segmented"
      />
    </>
  );
}

export default PriceViewSwitch;
