import {
  Alert,
  Button,
  Checkbox,
  Drawer,
  DrawerProps,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Table,
} from "antd";
import Title from "antd/lib/typography/Title";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BadRequestError } from "../api/errors";
import ModelYearSelector from "../components/model_year_selector";
import { ConfiguratorContext } from "../context";
import { BaseModel, Permission, SortDirection } from "../api/models";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import axios, {CancelTokenSource} from "axios";
import {useIntl} from "react-intl";
import BMButton from "../components/BMButton";
import { ColumnType } from 'antd/lib/table';
import {useForm} from "antd/es/form/Form";
import AssemblySelector from "../components/assembly_selector";
import {debounce} from "lodash";
import {FilterValue, SorterResult, SortOrder, TablePaginationConfig} from "antd/es/table/interface";
import IncentiveProgramSelector from "../components/incentive_program_selector";
import dayjs from "dayjs";

const UNLOAD = "UNLOAD";

interface ModelFilter {
  search?: string
  incentivePrograms?: string[]
  assemblies?: number[]
  cabStyles?: string[]
  fuelTypes?: string[]
  inactive?: boolean
}

interface CreateModelState {
  isSaving?: boolean
  saveError?: string
  visible?: boolean
}
const DEFAULT_PAGE_SIZE = 10;
const defaultSort = {
  columnKey: 'updatedAt',
  order: 'descend' as SortOrder
};
type BaseModelSort = SorterResult<BaseModel> | SorterResult<BaseModel>[]

const ModelList = () => {
  const [modelLst, modelLstAsync] = useAsyncState<BaseModel[]>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<ModelFilter>({
    inactive: true,
  });
  const [sort, setSort] = useState<BaseModelSort>(defaultSort);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: DEFAULT_PAGE_SIZE,
    current: 1,
  });

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    loadModels(modelLstAsync, pagination, filter, sort, cancelToken);
    return () => {
      cancelToken.cancel( UNLOAD );
    };
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    loadModels(modelLstAsync, pagination, filter, sort, cancelToken);
    return () => {
      cancelToken.cancel( UNLOAD );
    };
  }, [pagination.pageSize, pagination.current, filter, sort]);


  const loadModels = useCallback(debounce( async (modelLstAsync:AsyncState<BaseModel[]>, pagination: TablePaginationConfig, filter: ModelFilter | undefined, sorter:BaseModelSort, cancelToken:CancelTokenSource) => {
    modelLstAsync.setLoading();

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "name",
      direction: ( sorter.order === 'descend' ? 'desc' : 'asc') as SortDirection,
    }));

    try {

      //todo - replace with AbortController
      //https://axios-http.com/docs/cancellation
      const resp = await configurator.api.listModels({
        ...filter,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize,
        sort,
        cancelToken,
      });
      modelLstAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });
      return resp.data.content;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to load models. " + errorMsg });
      modelLstAsync.setFail(e.message);
    };
    
    return;
  }, 400), []);


  const handleChangeSearch = (search:string) => {
    setFilter({
      search
    });
  }

  const handleClickFilter = () => {
    setIsFilterOpen(true);
  }

  const handleFilterChange = (filter:ModelFilter) => {
    setFilter(filter);
  }

  const handleTableChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: BaseModelSort) => {
    setPagination(pagination);
    setSort(sorter);
  };

  const columns:ColumnType<BaseModel>[] = [
    {
      title: "Name",
      key: "name",
      render: (model) => {
        return <Link to={"/models/" + model.id}>{model.name}</Link>;
      },
      sorter: true,
    },
    {
      title: "Last Updated",
      key: "updatedAt",
      dataIndex: "updatedAt",
      render: (updatedAt) => (
        <span>{dayjs(updatedAt).format("MMMM Do YYYY, h:mm:ss a")}</span>
      ),
      sorter: true,
      defaultSortOrder: "descend",
    },
  ];
  return <div className="site-layout-background">
    <Space direction="vertical" size="small" style={{ display: 'flex' }}>

      <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
        <Title level={2}>Models</Title>
        <Space>
          <Button
            type="primary"
            onClick={handleClickFilter}
          >
            Filter
          </Button>
          <NewModelModalButton modelLst={modelLst} />
        </Space>
      </div>

      <div style={{width: "100%"}}>
        <Input value={filter.search} onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search teams" />
      </div>

      <Table 
        bordered 
        rowKey="id" 
        columns={columns} 
        onChange={handleTableChange}
        pagination={pagination}
        loading={modelLstAsync.isLoading()}
        dataSource={modelLst} 
      />
      <ModelFilterDrawer 
        open={isFilterOpen} 
        filter={filter}
        onFilterChange={handleFilterChange}
        onClose={() => setIsFilterOpen(false)}
      />
    </Space>
  </div>
};


const NewModelModalButton = (props:{
  modelLst:BaseModel[] | undefined
}) => {

  const {modelLst} = props;

  const [createModelState, setCreateModelState] = useState<CreateModelState>();
  
  const configurator = useContext(ConfiguratorContext);

  const [createModelForm] = Form.useForm();
  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);

  const onClickNew = () => {
    createModelForm.resetFields();
    setCreateModelState({ visible: true });
  };

  const getDisabledNewMsg = () : string | undefined => {
    return !canWrite ?  "You need permission to create model."
      :undefined;
  }

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  const onNewModelFinish = async (values) => {
    setCreateModelState({
      isSaving: true,
      saveError: undefined,
      ...createModelState,
    });
    try {
      const resp = await configurator.api.createModel({
        copyModelId: values.copyModel,
        inheritModelId: values.inheritModel,
        name: values.name,
        currentModelYear: values.currentModelYear
      });
      window.location.href = "/models/" + encodeURIComponent(resp.data.id);
      setCreateModelState({
        isSaving: false,
        saveError: undefined,
        ...createModelState,
      });
    } catch (e: any) {
      if (
        e instanceof BadRequestError &&
        e.message != null &&
        e.message.length > 0
      ) {
        setCreateModelState({
          isSaving: false,
          saveError: "Error: " + e.message,
          ...createModelState,
        });
      } else {
        setCreateModelState({
          isSaving: false,
          saveError: "Failed to save at this time: " + e.message,
          ...createModelState,
        });
      }
    }
  };


  return <>
    <BMButton
      type="primary"
      onClick={onClickNew}
      disabled={!!getDisabledNewMsg()}
      onDisabledClick={() => notifyDisabled(getDisabledNewMsg())}
    >
      New
    </BMButton>
    <Modal
      onCancel={() => setCreateModelState({})}
      okButtonProps={{ disabled: createModelState?.isSaving }}
      title="New Model"
      open={createModelState?.visible}
      onOk={() => createModelForm.submit()}
    >
      <div>
        <Form
          labelCol={{ span: 8 }}
          form={createModelForm}
          name="newModel"
          onFinish={onNewModelFinish}
        >
          <p>
            Choose a model to inherit from, or ignore if this is a new
            base model.
          </p>
          <Form.Item
            label="Model Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="currentModelYear"
            label="Current Model Year"
            rules={[{ required: true, message: "Model Year is required" }]}
          >
            <ModelYearSelector />
          </Form.Item>
          <Form.Item label="Copy from model" name="copyModel">
            <Select allowClear>
              {modelLst?.map((m) => (
                <Select.Option key={m.id} value={m.id}>
                  {m.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Inherit from model" name="inheritModel">
            <Select allowClear>
              {modelLst?.map((m) => (
                <Select.Option key={m.id} value={m.id}>
                  {m.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {createModelState?.saveError &&
            <Alert message={createModelState.saveError} type="error" />
          }
        </Form>
      </div>
    </Modal>
  </>
}


const ModelFilterDrawer = (props:DrawerProps & {
  filter?: ModelFilter
  onFilterChange: (filter:ModelFilter) => void
}) => {

  const { filter:a, onFilterChange:b, ...drawerProps } = props;
  const [filterForm] = useForm();

  return <Drawer
    title="Model Filter"
    maskStyle={{
      background: "rgb(0,0,0,0)", 
    }}
    afterOpenChange={(open) => {
      if( open ) {
        filterForm.resetFields();
      }
    }}
    {...drawerProps}> 
    <Form 
      form={filterForm} 
      initialValues={props.filter}
      onValuesChange={(_values: Record<string, any>, filter:ModelFilter) => {
        props.onFilterChange(filter)}
      }
      layout="vertical"
    >
      <Form.Item 
        name="search" 
      >
        <Input
          allowClear
          placeholder="Search by model name and more."
        />
      </Form.Item>
      <Form.Item
        name="assemblies"
        label="BOM Filter"
      >
        <AssemblySelector />
      </Form.Item>
      <Form.Item
        label="Incentive Program"
        name="incentivePrograms"
      >
        <IncentiveProgramSelector />
      </Form.Item>
      <Form.Item
        name="inactive"
        valuePropName="checked"
      >
        <Checkbox>Include Inactive</Checkbox>
      </Form.Item>
    </Form>
  </Drawer>;

}

export default ModelList;
