import { Checkbox, Empty, Form, Input, InputNumber, Select, Table } from "antd";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { AssemblyMetadata, CategoryMetadata } from "../api/models";
import { useEffect } from "react";

export const MetadataFieldList = (props:{ 
  pageSize?: number
  scroll?: {
    x?: string | number | undefined
    y?: string | number | undefined
  }
  metadata?: AssemblyMetadata[]
}) => {
  const form = useFormInstance();
  useEffect(() => {
    if (props.metadata) {
      form.setFieldValue("metadata", props.metadata)
    }
  }, [props.metadata]);
  return <Form.List name="metadata" >
    {(fields, _operations, _meta) => <>
      <style>
        {`
          .metadataFieldLst .ant-table-content { padding: 5px; } /* don't clip corners */
          .metadataFieldLst .ant-table-cell { border: none !important; } /* remove table cell borders */
          .metadataFieldLst .ant-form-item { margin-bottom: 0px; } 
        `}
      </style>
      <Table
        className="metadataFieldLst"
        size="small"
        scroll={props.scroll}
        pagination={{
          pageSize: props.pageSize || 500,
          hideOnSinglePage: true
        }}
        columns={[
          {
            render: ({fld, cm}) => <MetadataField key={`metadata-${fld.name}`} categoryMetadata={cm} ndx={fld.name} />,
          }
        ]}
        locale={{emptyText: 
          <div style={{height: "30rem", display: "flex", justifyContent: "center"}}>
            <Empty style={{position: "absolute", top: "30%", scale: "1.5"}} image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data"/>
          </div>,
        }}
        dataSource={fields.map(fld => {
          const md = form.getFieldValue(["metadata", fld.name]);
          return {
            key: [md.categoryMetadata.name, fld.name].join("-"),
            fld,
            cm: md.categoryMetadata
          }})
          .sort((a,b) => (a.cm?.sortOrder || 0 ) - ( b.cm?.sortOrder || 0 ) )}
        />
    </>}
  </Form.List>
}


const MetadataField = (props:{ 
  categoryMetadata:CategoryMetadata | undefined
  ndx:number
}) => {
  const {categoryMetadata:cm, ndx} = props;
  return <>
    {cm?.fieldType == "text" && (
      <Form.Item
        label={cm.name}
        name={[ndx, "valueText"]}
      >
        <Input  />
      </Form.Item>
    )}
    {cm?.fieldType == "numeric" && (
      <Form.Item
        label={cm.name}
        name={[ndx, "valueNumeric"]}
      >
        <InputNumber  />
      </Form.Item>
    )}
    {cm?.fieldType == "decimal" && (
      <Form.Item
        label={cm.name}
        name={[ndx, "valueDecimal"]}
      >
        <InputNumber precision={4} />
      </Form.Item>
    )}
    {cm?.fieldType == "bool" && (
      <Form.Item
        valuePropName="checked"
        label={cm.name}
        name={[ndx, "valueBool"]}
      >
        <Checkbox />
      </Form.Item>
    )}
    {cm?.fieldType == "select" && (
      <Form.Item
        label={cm.name}
        name={[ndx, "valueText"]}
      >
        <Select
          showSearch
          options={cm?.selectOptions?.map((opt, i) => ({
            value: opt, 
            label: opt,
            key: `opt-${ndx}-${i}`
          }))}
          allowClear
        />
      </Form.Item>
    )}
    <Form.Item
      name={[ndx, "id"]}
      hidden
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={[ndx, "categoryMetadata"]}
      hidden
    >
      <Input type="hidden" />
    </Form.Item>
    <Form.Item
      name={[ndx, "categoryMetadataId"]}
      hidden
    >
      <Input type="hidden" />
    </Form.Item>
  </>
}

export default MetadataField;
