import { debounce } from "lodash";
import { ConfiguratorContext } from "../../context";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AsyncState, useAsyncState } from "../../hook/useAsyncState";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { useIntl } from "react-intl";
import axios, { CancelTokenSource } from "axios";
import { ApprovalDiff, SortDirection, QuoteAuditInfo, QuoteAuditData } from "../../api/models";
import { FilterValue, SorterResult, SortOrder, TablePaginationConfig } from "antd/es/table/interface";
import { Button, ButtonProps, Modal, notification, Spin, Table } from "antd";
import ApprovalDiffTable from "../Table/ApprovalDiffTable";
import dayjs from "dayjs";
import { AsyncData } from "../../api/async_data";
import Utils from "../../util/util";

const AXIOS_CANCEL_MSG = "axios.cancel";
type  QuoteAuditSort = SorterResult<QuoteAuditInfo> | SorterResult<QuoteAuditInfo>[]
const DEFAULT_PAGE_SIZE = 20;

const PREVIOUS_AUDIT_ID = -1;

const QuoteAuditView = () => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;

  const [quoteAuditLst, quoteAuditLstAsync] = useAsyncState<QuoteAuditInfo[]>();
  const quoteAuditCancelTokenSourceRef = useRef<CancelTokenSource>();
  const [quoteAuditDiff, setQuoteAuditDiff] = useState<Record<number,AsyncData<ApprovalDiff>>>();

  const defaultSort = {
    columnKey: "id",
    order: 'descend' as SortOrder
  };
  const [sort, setSort] = useState<QuoteAuditSort>( defaultSort);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: DEFAULT_PAGE_SIZE,
    current: 1
  });

  useEffect(() => {
    if ( !quote?.quoteId ) return;

    loadQuoteAudits(quoteAuditLstAsync, pagination, quote?.quoteId, sort);
  }, [quote, pagination.pageSize, pagination.current, sort]);


  const { isReadOnly } = Utils.getQuoteState(configurator, quoteAsync);

  const loadQuoteAudits = useCallback(debounce( async ( quoteAuditAsync:AsyncState<QuoteAuditInfo[]>, pagination: TablePaginationConfig, quoteId:string, sorter:QuoteAuditSort ) => {

    if ( quoteAuditCancelTokenSourceRef.current ) {
      quoteAuditCancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    quoteAuditCancelTokenSourceRef.current = cancelSource;

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || defaultSort.columnKey,
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));


    quoteAuditAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteAudit({
        quoteId,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort,
      },
        cancelSource.token
      )

      quoteAuditCancelTokenSourceRef.current = undefined;

      quoteAuditAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Report failed to load. " + errorMsg });
        quoteAuditAsync.setFail(e.message);
      }
    }

  }, 600, {leading:true} ), [] );


  const loadQuoteAuditDiff = async (quoteAuditDiffAsync:AsyncData<ApprovalDiff>, auditIdA:number | undefined, auditIdB?:number | undefined ) : Promise<AsyncData<ApprovalDiff> | undefined> => {
    if ( !auditIdA ) return;

    quoteAuditDiffAsync.loading();
    setQuoteAuditDiff( {...quoteAuditDiff, [auditIdA]:quoteAuditDiffAsync.loading() } );

    try {
      const resp = await configurator.api.fetchQuoteAuditDiff(auditIdA, auditIdB)
      return quoteAuditDiffAsync.done(resp.data);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch quote differences. " + errorMsg });
      quoteAuditDiffAsync.fail(e.message);
    }

    return quoteAuditDiffAsync;
  }


  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: QuoteAuditSort) => {
    setPagination(pagination);
    setSort(sorter);
  };

  const handleExpand = (expanded: boolean, record: QuoteAuditInfo) => {
    if ( expanded ) {
      if ( !quote?.quoteId ) return;

      const quoteAuditDiffAsync = quoteAuditDiff?.[ record.id ] || new AsyncData<ApprovalDiff>();
      loadQuoteAuditDiff(quoteAuditDiffAsync, record.id, PREVIOUS_AUDIT_ID)
        .then( rslt => {
          if ( rslt ) {
            setQuoteAuditDiff( {...quoteAuditDiff, [record.id]:rslt } ) 
          }
        });
    }
  }

  const datasource = quoteAuditLst?.filter(v=>!!v);

  return <Table
    rowKey="id"
    scroll={{ x: true }}
    style={{ width: "100%" }}
    bordered={false}
    loading={quoteAuditLstAsync.isLoading()}
    onChange={tableOnChange}
    pagination={pagination}
    dataSource={datasource}
    expandable={{ 
      onExpand: handleExpand,
      expandedRowRender: (tm:QuoteAuditInfo) => {
        const quoteAuditDiffAsync = quoteAuditDiff?.[ tm.id ];
        return <Spin spinning={quoteAuditDiffAsync?.isLoading()} >
          {configurator.isAdmin() &&
          <div style={{display: "flex", flexDirection: "row-reverse"}}>
          <RestoreButtonModal disabled={isReadOnly} type="primary" quoteAudit={tm} />
          </div>}
          <ApprovalDiffTable diff={quoteAuditDiffAsync?.val} />
        </Spin>
    }}}
    columns={ [
      {
        dataIndex: "id"
      },
      {
        key: "timestamp",
        render: u => dayjs( u.timestamp ).format("M/DD/YY h:mm:ss A")
      },
      {
        key: "user",
        render: u => u.user.name
      },
      {
        dataIndex: "auditType"
      },
    ] }
  />

}

const RestoreButtonModal = (props: ButtonProps & {
  quoteAudit: QuoteAuditInfo
}) => {
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const {quoteAudit, ...btnProps} = props;

  const {setQuoteFormValues} = useQuoteContext();
  const [_quoteUndo, quoteUndoAsync] = useAsyncState<QuoteAuditData>();
  const [_quoteAuditDiff, quoteAuditDiffAsync] = useAsyncState<ApprovalDiff>();
  const [isOpen, setIsOpen] = useState<boolean>();


  const loadQuoteUndoByAuditId = async (auditId:number ) : Promise<QuoteAuditData | undefined> => {

    quoteUndoAsync?.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteByAudit(auditId)
      quoteUndoAsync?.setDone(resp.data);
      return resp.data;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch restore. " + errorMsg });
      quoteUndoAsync?.setFail(errorMsg);
    }

    return;
  }

  const loadQuoteAuditDiff = async (quoteAuditDiffAsync:AsyncState<ApprovalDiff>, auditIdA:number | undefined, auditIdB?:number | undefined) : Promise<ApprovalDiff | undefined> => {

    quoteAuditDiffAsync.setLoading();

    try {
      const resp = await configurator.api.fetchQuoteAuditDiff(auditIdA, auditIdB )
      quoteAuditDiffAsync.setDone(resp.data);
      return resp.data;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch differences. " + errorMsg });
      quoteAuditDiffAsync.setFail(e.message);
    }

    return;
  }

  const handleUndo = () => {
    if( !quoteAudit.id ) return;

    loadQuoteUndoByAuditId(quoteAudit.id)
      .then( q => {
        setQuoteFormValues?.( q?.quote, true )
        setIsOpen(false);
      });
  }

  return <>
    <Button
      {...btnProps}
      onClick={() => setIsOpen(true)}
    >
      Restore 
    </Button>
    <Modal title='Restore the following changes:'
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      width={'90rem'}
      onOk={handleUndo}
      afterOpenChange={(open:boolean) => {
        if(open) {
            loadQuoteAuditDiff(quoteAuditDiffAsync, quoteAudit.id );
        }
      }}
    >
      <Spin spinning={quoteAuditDiffAsync?.isLoading()} >
        <ApprovalDiffTable diff={quoteAuditDiffAsync?.val} />
      </Spin>
    </Modal>
  </>

}

export default QuoteAuditView;

