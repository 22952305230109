import { Input, notification, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import dayjs from 'dayjs'
import {debounce} from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import {useIntl} from "react-intl";
import { SystemConfig } from "../api/models";
import SystemConfigValueEditor from "../components/system_config_value_editor";
import { ConfiguratorContext } from "../context";
import {useAsyncState} from "../hook/useAsyncState";

const SystemConfigPage = () => {
    const intl = useIntl();
    const configurator = useContext(ConfiguratorContext);
    const [systemConfigs, systemConfigsAsync] = useAsyncState<SystemConfig[]>();
    const [filter, setFilter] = useState<string | undefined>();

    useEffect(() => {
        loadSystemConfig();
    }, [])

    async function loadSystemConfig() {
      systemConfigsAsync.setLoading();
      try {
        const resp = await configurator.api.getSystemConfig();
        systemConfigsAsync.setDone(resp.data);
      }
      catch (e:any) {

        const errorMsg = intl.formatMessage({ id: e.message || e.response.data.message });
        const msg = "System configurations failed to load." + errorMsg;
        notification.error( { message: msg });
        systemConfigsAsync.setFail(errorMsg);
      }
    }

    function onConfigUpdated(config: SystemConfig) {
        var configs = [...systemConfigs || []];
        configs[configs.findIndex(item => item.property == config.property)] = config;
        systemConfigsAsync.setDone(configs);
    }

    const handleChangeFilter = useCallback(
      debounce( (e:any) => {
        setFilter( e.target.value )
      }, 400 )
    , [] );

    const configSorting = (a,b) => {
      const preportyComparison = a.propertyType.localeCompare(b.propertyType);
      if (preportyComparison != 0) {
        return preportyComparison;
      }
      return a.name.localeCompare(b.name);
    }
    
    const datasource = [...(systemConfigs || [])]?.filter( m => !filter || m.name?.toString().toLocaleLowerCase().includes(filter.toLocaleLowerCase() ) )
    .sort(configSorting);

    const columns: ColumnsType<SystemConfig> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: SystemConfig, b: SystemConfig) => a.name.localeCompare(b.name),
        },
        {
            title: 'Value',
            key: 'value',
            render: (config) => (
                <SystemConfigValueEditor config={config} onConfigUpdated={onConfigUpdated} />
            )
        },
        {
            title: 'Last Updated',
            key: 'updatedAt',
            render: (config: SystemConfig) => (
                <span>{dayjs(config.updatedAt).toLocaleString()}</span>
            ),
            sorter: (a: SystemConfig, b: SystemConfig) => a.updatedAt.getTime() - b.updatedAt.getTime(),
        },
    ];


  return <div className="site-layout-background">
            <Title level={2}>System Configuration</Title>
            <p><strong>NOTE:</strong> After updating configuration, it can take a few minutes to propagate.</p>
            <style>
              {`
                /* don't show error border on filter */
                  .systemConfigFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
                .systemConfigFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
                  border-color: #d9d9d9;
                }
                `}
            </style>

            <Row style={{marginBottom: "1rem"}}>
              <Input className="systemConfigFilter" onChange={handleChangeFilter} placeholder="Filter Configurations" allowClear />
            </Row>
            <Table loading={systemConfigsAsync.isLoading()} rowKey="property" dataSource={datasource} columns={columns} pagination={{ pageSize: 15}}/>
        </div>
}

export default SystemConfigPage;
