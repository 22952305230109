import Title from "antd/lib/typography/Title";
import { RepresentativeSalesSupport, RepresentativeSalesSupportRequest } from "../api/models";
import Table, { ColumnType } from "antd/es/table";
import { Alert, Button, Form, Input, Modal, Row, notification } from "antd";
import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../context";
import { useIntl } from "react-intl";
import { useForm } from "antd/es/form/Form";
import UserSingleSelector from "../components/widgets/UserSingleSelector";

const SalesSupport = () => {

  const [supports, setSupports] = useState<RepresentativeSalesSupport[]>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const isSalesDeskOrAdmin = configurator.isSalesDesk() || configurator.isAdmin();

  useEffect(() => {
    loadSalesSupport();
  }, []);

  const loadSalesSupport = async () => {
    try {
      const resp = await configurator.api.getSalesSupports();
      const s = resp.data.sort((a,b) => (a.salesRep.name || "").toLowerCase().localeCompare( (b.salesRep.name || "").toLowerCase() ))
      setSupports(s);
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get sales supports. " + errorMsg });
    }
  }



  let columns: ColumnType<RepresentativeSalesSupport>[] = [
    {
      title: "BM Sales Representative",
      dataIndex: "salesRep",
      width: "30%",
      sorter: (a,b) => (a.salesRep.name || "").toLowerCase().localeCompare( (b.salesRep.name || "").toLowerCase() ),
      render: (_, support: RepresentativeSalesSupport) => (
        <span>{support.salesRep.name}</span>
      ),
    },
    {
      title: "Sales Support",
      dataIndex: "salesSupport",
      width: "30%",
      sorter: (a,b) => (a.salesSupport.name || "").toLowerCase().localeCompare( (b.salesSupport.name || "").toLowerCase() ),
      render: (_, support: RepresentativeSalesSupport) => (
        <span>{support.salesSupport.name}</span>
      ),
    },
  ];

  if (isSalesDeskOrAdmin) {
    columns = columns.concat({
      title: "Action",
      dataIndex: "action",
      render: (_, support: RepresentativeSalesSupport) => (
        isSalesDeskOrAdmin ? <EditSalesSupport support={support} loadSalesSupport={loadSalesSupport} /> : <></>
      ),
    });
  }

  return (
  <div className="site-layout-background" key="sales-support-list">
    <Title level={2}>Sales Support</Title>
    {isSalesDeskOrAdmin && <EditSalesSupport loadSalesSupport={loadSalesSupport}/>}
    {isSalesDeskOrAdmin && <Alert style={{marginBottom: ".5rem"}} message="This is the default assignment of the sales suppot. Default assignment will be applied during the first BM reps assignment.  It won't change the assignment on the existing quote."/>}
    <Table
      columns={columns}
      dataSource={supports}
      rowKey={"id"}
      pagination={false}
    />
  </div>);
}

export default SalesSupport;


const EditSalesSupport = (props: {
  support?: RepresentativeSalesSupport
  loadSalesSupport: () => void
}) => {

  const {support, loadSalesSupport} = props;
  const [open, setOpen] = useState(false);
  const configurator = useContext(ConfiguratorContext);
  const [form] = useForm();
  const intl = useIntl();
  const isUpdate = !!support;

  const update = async () => {

    const formObj = form.getFieldsValue();

    const obj: RepresentativeSalesSupportRequest = {
      id: formObj.id,
      salesRepId: formObj.salesRep.id,
      salesSupportId: formObj.salesSupport.id,
    }

    try {
      await configurator.api.updateSalesSupport(obj);
      notification.success({message: "Successfully update sales support on this representative. "});
      setOpen(false);
      loadSalesSupport();
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to update sales support. " + errorMsg });
    }
  }

  const create = async () => {

    const formObj = form.getFieldsValue();

    const obj: RepresentativeSalesSupportRequest = {
      id: formObj.id,
      salesRepId: formObj.salesRep.id,
      salesSupportId: formObj.salesSupport.id,
    }

    try {
      await configurator.api.createSalesSupport(obj);
      notification.success({message: "Successfully create sales support. "});
      setOpen(false);
      loadSalesSupport();
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to create sales support. " + errorMsg });
    }
  }

  return (<>
    {isUpdate ? 
    <Button type="primary" onClick={() => {setOpen(true)}}>Edit</Button>
    : 
    <Row justify={"end"} style={{marginBottom: "1rem"}}>
      <Button type="primary" onClick={() => {setOpen(true)}}>New</Button>
    </Row>
    }
    <Modal
      open={open}
      onCancel={() => {setOpen(false); form.resetFields()}}
      onOk={isUpdate ? update : create}
      title={!!support ? `Edit sales support to ${support.salesRep.name}` : `Add sales support`}
      cancelButtonProps={{style: {display: "none"}}}
      okText={isUpdate ? "Update" : "Create"}
      destroyOnClose
    >
      <Form
        initialValues={support}
        layout="vertical"
        form={form}
      >
        <Form.Item name="id" hidden ><Input/></Form.Item>
        <Form.Item name="salesRep" label="Sales Representative" hidden={isUpdate}>
          <UserSingleSelector/>
        </Form.Item>
        <Form.Item label="Sales Representative" hidden={!isUpdate}>
          <Input value={support?.salesRep.name} readOnly/>
        </Form.Item>
        <Form.Item name={'salesSupport'} label="Sales Support">
          <UserSingleSelector/>
        </Form.Item>
      </Form>
    </Modal>
  </>);
}
