import { Quote} from "../../../api/models";
import {ConfiguratorContext } from "../../../context";
import { notification } from "antd";
import {useContext, useEffect, useState} from "react";
import Utils from "../../../util/util";
import BMButton, {BMButtonProps} from "../../BMButton";

const ExportLinesetButton = (props: BMButtonProps &  {
  quote:Quote | undefined,
  onExporting?:(b:boolean)=>void
  pendingChanges?:boolean
  allSelected?:boolean
}) => {
  const { quote, onExporting, pendingChanges, allSelected, ...btnProps } = props;

  const configurator = useContext(ConfiguratorContext);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  useEffect(() => {
    onExporting?.( isExporting );
  }, [isExporting]);

  const handleDisabledClick = () => {

    const disabledMsg = !Utils.isOrder( quote ) ? 'Only orders can be exported.' 
      : !allSelected ? 'Unable to export unless all selections are made' 
      : pendingChanges ? "There are pending changes. Please save the quote first."
      : undefined;

    if ( disabledMsg ) {
      notification.warning({message:disabledMsg});
    }
  }

  const handleExportLineset = async () => {
    if ( !quote ) return;

    setIsExporting( true );
    try {
      const url = configurator.api.getLinesetTicketPdfUrl(quote.quoteId, quote.displayRevisionId);
      await configurator.api.downloadPdf(url);
    }
    catch (e) {
      notification["error"]({
        message: "Failed to export at this time.",
      });
    }
    setIsExporting( false );
  };

  return <BMButton {...btnProps}
    disabled={pendingChanges || !quote || !Utils.isOrder(quote)} 
    onDisabledClick={handleDisabledClick} 
    onClick={handleExportLineset} 
    loading={isExporting}
  >Lineset Ticket</BMButton>
}

export default ExportLinesetButton;
