import { useContext, useEffect, useState } from "react";
import { Operation } from "../api/models";
import { ConfiguratorContext } from "../context";
import { Select, Spin } from "antd";

export interface Props {
  onSelectOperation?: (operation: Operation) => void;
  value?: any;
  selectedOperation?: number;
  onChange?: (operationId: number) => void;
  allowMultiple?: boolean;
  currentOnly?: boolean;
}

const OperationSelector = (props: Props) => {
  const configurator = useContext(ConfiguratorContext);
  const [operations, setOperations] = useState<Operation[] | undefined>(undefined);

  useEffect(() => {
    load();
  }, []);

  const operationSorting = (a: Operation, b: Operation) => a.operationId.toLowerCase().localeCompare(b.operationId.toLowerCase() );

  async function load() {
    const resp = await configurator.api.getAllOperations();
    var operations = resp.data.sort(operationSorting).filter(op => op.current || !props.currentOnly);
    setOperations(operations);
  }

  if (!operations) {
    return <Spin />;
  }

  const options = operations.map((op) => ({
    key: op.operationId, 
    value: op.operationId, 
    label: `${op.operationId} (CabSeq: ${op.cabSequence}, ChassisSeq: ${op.chassisSequence}) `,
  }));

  return (
    <Select
      style={{ width: "100%" }}
      onChange={props.onChange}
      value={props.value}
      defaultValue={props.selectedOperation}
      showSearch={true}
      optionFilterProp="children"
      allowClear={true}
      mode={props.allowMultiple ? "multiple" : undefined}
      filterOption={(input, option) => {
        if (option) {
          return option.label
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase());
        } else {
          return false;
        }
      }}
      options={options}
    />
  );
};

export default OperationSelector;
