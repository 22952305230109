import {Form, InputNumber, Modal, notification} from "antd";
import {useState} from "react";
import {PoNumber, PricingBreakdown} from "../../api/models";
import BMButton, {BMButtonProps} from "../BMButton";
import {  CalculatorOutlined } from "@ant-design/icons";

const CalculatePercentDiscountButtonModal = (props: Omit<BMButtonProps, 'onChange'> & {
  breakdown:PricingBreakdown | undefined
  poNumber?:PoNumber
  minDiscountPercent: number
  maxDiscountPercent: number
  validateDiscount: (r: any, v: string, cb: any) => void
  onChange: (v:number) => void
}) => {

  const [isOpen, setIsOpen] = useState<boolean>();

  const [form] = Form.useForm();
  const targetPrice = Form.useWatch('targetPrice', form);
  const { breakdown, poNumber, minDiscountPercent, maxDiscountPercent, validateDiscount, onChange:_a, ...buttonProps} = props;

  const calculatePercentDiscount = (target:number):number | undefined => {
    if ( !breakdown ) return;

    const dealerPrice = breakdown.dealerPrice || 0;
    const additionalDiscount = dealerPrice - target;

    const discount = breakdown.dealerNet * breakdown.percentDiscount / 100;
    const newPercentDiscount = ( discount + additionalDiscount ) / breakdown.dealerNet * 100;
    const value = Math.round( newPercentDiscount * 10000 ) / 10000; //four decimal places precision

    return value;
  }

  const targetPercentDiscount = calculatePercentDiscount( targetPrice );
  const handleOk = async () => {

    try {
      await form.validateFields();

      if ( targetPercentDiscount !== undefined ) {
        props.onChange?.(targetPercentDiscount);
        setIsOpen(false);
      }

    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }

  }


  const resetTargetValue = () => {
    form.resetFields();
    form.validateFields();
  }

  const validateTargetPrice = async (rule: any, value: string, cb: any) => {
    const percentDiscount = Number( value );
    if ( isNaN(percentDiscount) ) {
      return false;
    }

    const target = String( calculatePercentDiscount( percentDiscount ) );
    return props.validateDiscount( rule, target, cb );
  };


  const handleDisabledClick = () => {
    if ( !breakdown ) notification.warning({message:"Please wait for pricing to load."});
    props.onDisabledClick?.();
  }

  
  return <>
    <BMButton
      className="ghostBmButton"
      type="text"
      {...buttonProps}
      disabled={!breakdown || buttonProps.disabled}
      onDisabledClick={handleDisabledClick}
      onClick={() => setIsOpen(true)}
      icon={<CalculatorOutlined />}
    />
    <Modal 
      title={"Calculate Percent Discount"}
      open={isOpen}
      onOk={() => handleOk()}
      onCancel={() => setIsOpen(false)}
      afterOpenChange={resetTargetValue}
    >
      <Form form={form} 
        initialValues={{targetPrice: poNumber?.amount || breakdown?.dealerPrice || 0 }}
      >
        <Form.Item label="Target Dealer Price" name="targetPrice"
          rules={[
            {
              message: `Calculated percent discount must be between ${minDiscountPercent} and ${maxDiscountPercent}`,
              validator: validateTargetPrice,
            },
          ]} >
          <InputNumber 
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            placeholder="Type dollar amount." 
            controls={true} 
            style={{width:"100%"}}
          />
        </Form.Item>
        {targetPercentDiscount !== undefined &&
        <div>
          Calculated Percent Discount: {targetPercentDiscount}%
        </div>
        }
      </Form>
    </Modal>
  </>
}

export default CalculatePercentDiscountButtonModal;
