import Title from "antd/lib/typography/Title";
import React from "react";

const NotFoundPage = (props) => {
  return (
    <div className="site-layout-background">
      <Title level={2}>Not Found</Title>
      <p>
        {props.message
          ? props.message
          : "The page you are looking for was not found."}
      </p>
    </div>
  );
};

export default NotFoundPage;
