import { useContext, useEffect, useState } from "react";
import {  Button, Input, Table, Row, Col, Upload, message, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { ConfiguratorContext, DealerListContext, DealerListContextType } from "../context";
import { Customer } from "../api/models";
import { debounce } from "lodash";
import {ColumnType} from "antd/lib/table";
import { useEditCustomerStep } from "../components/customer_entry";
import Utils from "../util/util";
import DealerMultipleSelector from "../components/DealerMultipleSelector";
import { UploadOutlined, DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import ModalWizard from "../components/ModalWizard";

enum UPLOADING_STATUS {
  INIT = "init",
  ERROR = "error",
  DONE = "done",
  UPLOADING = "uploading"
}

const CustomerList = () => {

  const [customers, setCustomers] = useState<Customer[]>();
  const configurator = useContext(ConfiguratorContext);
  const [filter, setFilter] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState<Customer>();
  const [filterDealers, setFilterDealers] = useState<string[]>([]);
  const [uploadStatus, setUploadStatus] = useState(UPLOADING_STATUS.INIT);

  const {dealerLstAsync, loadDealerList} = useContext<DealerListContextType>(DealerListContext);
  const dealerLst = dealerLstAsync?.val;

  useEffect(() => {
    if ( ( dealerLstAsync?.isInitial() || dealerLstAsync?.isFail() ) && !dealerLstAsync.isLoading() ) {
      loadDealerList?.();
    }
  }, [])

  useEffect(() => {
    if (dealerLstAsync?.isDone()) {
      loadCustomers();
    }
  }, [dealerLst]);

  const loadCustomers = async () => {
    try {
      const resp = await configurator.api.getCustomers();
      setCustomers(resp.data);
    } catch (e: any) {
      console.log(e);
    }
  };

  const debounceSetFilter = debounce(setFilter, 500);

  const columns: ColumnType<Customer>[] = [

    {
      title: "Name",
      dataIndex: "name",
      sorter: (a,b) => (a.name || "").toLowerCase().localeCompare( (b.name || "").toLowerCase() ),
      defaultSortOrder: "ascend", 
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (_, customer: Customer) => (
        <span>{Utils.getAddress(customer)}</span>
      ),
    },
    {
      title: "Contact Name",
      dataIndex: "contactName",
    },
    {
      title: "Phone",
      dataIndex: "contactPhone",
    },
    {
      title: "E-mail",
      dataIndex: "contactEmail",
    },
    {
      title: "Dealer",
      dataIndex: "dealer",
      render: (_, customer: Customer) => (
        <span>{dealerLst?.find(d => d.id === customer.dealerId)?.name}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, customer: Customer) => (
        <Button type="primary" onClick={() => {onEdit(customer)}}>Edit</Button>
      ),
    },
  ];

  const filterDealerName = (customer: Customer) => {
    const dealerId = dealerLst?.filter(d => d.name.toLocaleLowerCase().includes(filter || "")).map(d => d.id);
    return customer.dealerId && dealerId?.includes(customer.dealerId);
  }

  const filterCustomerName = (customer: Customer) => {
    return customer.name?.toLowerCase().includes(filter?.toLowerCase() || "");
  }

  let filteredCustomers = customers?.filter(co => 
    filterCustomerName(co)
    || filterDealerName(co)).filter(co => filterDealers.length === 0 || filterDealers.includes(co.dealerId || ""));

  const onEdit = (customer: Customer) => {
    setEditingCustomer(customer);
    setOpen(true);
  }

  const onCancelEdit = () => {
    setOpen(false);
    setEditingCustomer(undefined);
  }

  const onSave = (customer: Customer | undefined) => {
    setEditingCustomer(undefined);
    setOpen(false);
    loadCustomers();
  }

  const createCustomer = () => {
    setEditingCustomer(undefined);
    setOpen(true);
  }

  const onExport = async () => {
    const url = configurator.api.getCustomerExportUrl();
    try {
      await configurator.api.downloadPdf( url )
    }
    catch(e:any) {
      message.error( e.message );
    }
  };

  const isUploading = UPLOADING_STATUS.UPLOADING === uploadStatus;
  const importUrl = configurator.api.getCustomerImportUrl();

  const onUploadChange = ({file}) => {
    setUploadStatus( file.status );
    if (file.status === UPLOADING_STATUS.ERROR) {
      message.error( "Failed to import customers" );
    }
    else if (file.status === UPLOADING_STATUS.DONE) {
      message.success("Successfully import customers");
    }
  };

  return (<div className="site-layout-background" key="customer-list">
    <Title level={2}>Customers</Title>

    <Row gutter={12}>
      <Col span={9}>
      <Input
        allowClear
        onChange={(value) => debounceSetFilter(value.target.value)}
        addonBefore="Filter"
        style={{marginBottom: "1rem"}}
      />
      </Col>
      <Col span={9}>
        <DealerMultipleSelector
          style={{width: "100%"}}
          onChange={(value) => {setFilterDealers(value || [])}}
          placeholder="Please select dealer..."
        />
      </Col>
      <Col>
        <Button type="primary" onClick={() => createCustomer()}>New</Button>
      </Col>
      <Col>
        <Button
          onClick={onExport}
          type="primary"
          icon={<DownloadOutlined />}
        >
          Export
        </Button>
      </Col>

      <Col>
        <Upload
          name="file"
          action={importUrl }
          withCredentials={true}
          onChange={onUploadChange}
          showUploadList={false}
          accept=".csv"
        >
          <Spin spinning={isUploading} indicator={<SyncOutlined spin style={{fontSize: "20px", color: "darkgrey"}}/>}>
            <Button
              type="primary"
              icon={<UploadOutlined />}
            >
              Import
            </Button>
          </Spin>
        </Upload>
      </Col>
    </Row>
    <Table
      bordered
      rowKey="id"
      loading={!customers}
      columns={columns}
      dataSource={filteredCustomers}
    />

    <ModalWizard
      showSteps={false}
      open={open}
      onCancel={() => {setOpen(false)}}
      steps={[
        useEditCustomerStep({
          value: editingCustomer,
          onChange: onSave,
          onCancel: onCancelEdit,
        })
      ]}
    />

  </div>);
}

export default CustomerList;

