import { Select, SelectProps } from "antd";
import { useContext, useEffect } from "react";
import { ModelYear, PAGINATION_MAX_PAGE_SIZE } from "../api/models";
import { ConfiguratorContext } from "../context";
import {useAsyncState} from "../hook/useAsyncState";

export interface Props {
  disabled? : boolean
  style?: {}
}

const ModelYearSelector = (props: Props & SelectProps) => {
  const [modelYears, modelYearsAsync] = useAsyncState<ModelYear[]>();
  const configurator = useContext(ConfiguratorContext);

  useEffect(() => {
    loadModelYears();
  }, []);

  const loadModelYears = async () : Promise<ModelYear[] | undefined> => {
    try {
      modelYearsAsync.setLoading();
      const resp = await configurator.api.getModelYears(0, PAGINATION_MAX_PAGE_SIZE);

      modelYearsAsync.setDone( resp.data.content );

      return resp.data.content;
    }
    catch(e:any) {
      modelYearsAsync.setFail( e.message );
    }

    return;
  };

  return <Select
    {...props}
    loading={props.loading || modelYearsAsync.isLoading()}
    placeholder="Choose a Model Year"
    allowClear
    options={modelYears?.map((f) => ({
      label:f.year, value:f.year
    }))}
  />

};

export default ModelYearSelector;
