import {useCallback, useEffect, useState} from "react";
import Table, {ColumnType} from "antd/es/table";
import {debounce} from "lodash";
import Utils from "../util/util";
import {Input} from "antd";
import {User} from "../api/models";

const DEFAULT_PAGE_SIZE = 5;
const NOT_FOUND = -1;

const UserSelectionMulitple = (props:{
  value?:string[]
  loading?:boolean
  options:User[]
  onChange?:(c:string[] | undefined)=>void
}) => {

  const userLst = props.options;
  const [filter, setFilter] = useState<string | undefined>();
  const [pagination, setPagination] = useState<any>({ pageSize: DEFAULT_PAGE_SIZE, defaultCurrent: 1 });
  const [selections, setSelections] = useState<string[]>(props.value || []);

  useEffect(() => {
    const ndx = datasource.map( c => c.id ).findIndex( id => props.value?.includes(id));
    const page = ( ndx === NOT_FOUND ) ? 1 : Math.floor( ( ndx / DEFAULT_PAGE_SIZE) + 1 );

    setPagination( { ...pagination, defaultCurrent: page, current:page } );
  }, [userLst, filter]);


  const columns:ColumnType<any>[] = [{
    dataIndex: "name",
    title: "Name",
    render: (_n,u) => Utils.formatUsername(u),
    defaultSortOrder: "ascend",
  }];

  const handleChangeFilter = useCallback(
    debounce((e: any) => {
      setFilter(e.target.value);
    }, 400)
    , []);

  const datasource = [...userLst]?.filter(m => !filter || m.name?.toString().toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleSelectRow = (record: User) => {
    if (!selections.includes(record.id)) {
      const newSelections = selections.concat(record.id);
      setSelections(newSelections);
      props.onChange?.(newSelections);
    }
    else {
      const newSelections = selections.filter(s => s !== record.id);
      setSelections(newSelections);
      props.onChange?.(newSelections);
    }
  }


  return <>
    <style>
      {`
        /* don't show error border on filter */
          .userFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
        .userFilter.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
          border-color: #d9d9d9;
        }
        .userLst .ant-table-content { padding: 5px; } /* don't clip corners */
          .userLst .ant-table-cell { border: none !important; } /* remove table cell borders */
          /* add error border to table */
          .ant-form-item-has-error .userLst .ant-table-content {
          border: solid 1px #ff4d4f;
          border-radius: 15px;
        }
        `}
    </style>

    <Input className="userFilter" onChange={handleChangeFilter} placeholder="Filter" allowClear />

    <Table 
      className="userLst"
      showHeader={false}
      columns={columns}
      rowKey="id"
      dataSource={datasource}
      pagination={pagination}
      onChange={(p) => setPagination(p)}
      loading={props.loading}
      onRow={(record, _rowIndex) => {
        return {
          onClick: () => handleSelectRow(record)
        };
      }}
      rowSelection={{
        type: "checkbox",
        onSelect: (record) => handleSelectRow(record),
        selectedRowKeys: props.value || [],
      }}
    />

  </>
};

export default UserSelectionMulitple;

