import { Table, TableColumnType, TablePaginationConfig, TableProps } from "antd";
import { Link } from "react-router-dom";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import {Assembly, AssemblyCost, Page} from "../../api/models";

const formatMoney = (() => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (val:number | undefined) :string | undefined => {
    if ( val === undefined || isNaN(val) ) return;

    return formatter.format( val );
  }
})();


const AssemblyCostsTable = (props:TableProps<AssemblyCost>) => {


  const getLabel = (assembly:Assembly) => {
    return assembly.label != null && assembly.label.length > 0
      ? assembly.label
      : assembly.bomDescription;
  };

  const columns:TableColumnType<AssemblyCost>[] = [
    {
      title: "BOM",
      key: "bom",
      render: (pending:AssemblyCost) => (
        <span>
          {pending.assembly.bom}
          {pending.assembly.obsoletedAt != null ? " (Obsolete)" : ""}
        </span>
      ),
    },
    {
      title: "Name",
      key: "name",
      render: (pending:AssemblyCost) => (
        <div>
          {pending.assembly.imported ? (
            <Link to={"/assemblies/" + encodeURIComponent(pending.assembly.id)}>
              {getLabel(pending.assembly)}
            </Link>
          ) : (
            getLabel(pending.assembly)
          )}
        </div>
      ),
    },
    {
      title: "Source",
      key: "createdBy",
      render: (pending:AssemblyCost) => {

        const dealerName = pending.user?.dealerName && `(${pending.user.dealerName})`;
        const displayName = [ pending.user?.name, dealerName ].filter( v => v ).join( " " );

        return <div>{displayName || pending.createdBy || "Unknown"}</div>
      },
    },
    {
      title: "Current Cost",
      key: "standardMaterialCost",
      render: (pending:AssemblyCost) => {
        let v1 = pending.assembly.standardMaterialCost || 0;
        let v2 = pending.standardMaterialCost;
        const diff = v2 - v1;
        if ( diff === 0 ) return <span></span>;

        return <span>{formatMoney( pending.assembly.standardMaterialCost ) }</span>;
        
      },
    },
    {
      title: "New Cost",
      key: "pendingMaterialCost",
      render: (pending:AssemblyCost) => (<span>{formatMoney( pending.standardMaterialCost)}</span>),
    },
    {
      title: "Difference",
      key: "materialCostDifference",
      render: (pending:AssemblyCost) => {
        let v1 = pending.assembly.standardMaterialCost || 0;
        let v2 = pending.standardMaterialCost;
        const diff = v2 - v1;

        if (diff > 0) { 
          return ( <span>{formatMoney(diff)}<ArrowUpOutlined style={{ color: "green" }}  /></span> );
        }
        else if (diff < 0) {
          return ( <span>{formatMoney( -1 * diff)}<ArrowDownOutlined style={{ color: "red" }}  /></span> );
        }
      },
    },
  ];

  return (
    <>
    <Table
      {...props}
      data-testid="assemblyCostsTable"
      bordered
      rowKey="id"
      columns={columns}
    />
    </>
  )
}

export default AssemblyCostsTable;
