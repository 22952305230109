import {useContext, useEffect, useState} from "react"
import {Quote} from "../api/models";
import {ConfiguratorContext} from "../context";
import {AsyncState} from "./useAsyncState";

export enum LockState {
    UNLOCKED,
    LOCKED,
    WARNING, //unlocked
    EXCEEDED, //locked
}

const LOCK_POLL_SECONDS = 60 * 1000 // 1 minute
const MAX_LOCK_TIME_SECONDS = 10 * 60 * 1000 //10 minutes
const WARNING_LOCK_TIME_SECONDS = MAX_LOCK_TIME_SECONDS * 0.8;

function getLockState( lockedCnt:number ) : LockState {

    const isLocked = lockedCnt === 0;
    if ( isLocked ) return LockState.LOCKED;

    const isLockExceeded = ( LOCK_POLL_SECONDS * lockedCnt ) > MAX_LOCK_TIME_SECONDS
    if ( isLockExceeded ) return LockState.EXCEEDED;

    const isLockWarning = ( LOCK_POLL_SECONDS * lockedCnt ) > WARNING_LOCK_TIME_SECONDS //warn of lockout after 80% of max lockout time
    if ( isLockWarning ) return LockState.WARNING;

    return LockState.UNLOCKED;
}

export const useQuoteLock = ( quoteAsync:AsyncState<Quote>, isNewQuote:boolean, isFormDirty:boolean ) : [ LockState, ()=>void, ()=>void  ] => {

    const configurator = useContext(ConfiguratorContext);
    const [lockedCnt, setLockedCnt] = useState<number>(1);
    const lockState = getLockState(lockedCnt);
    const quote = quoteAsync.val;

    //required because setInterval closes over old lockedCnt (all parameters)
    useEffect(() => {
        const interval = setInterval( () => { updateIsLocked( quote?.id, lockedCnt, lockState, isNewQuote, isFormDirty ) }, LOCK_POLL_SECONDS );
        return () => clearInterval(interval); //remove old interval on update
    }, [quote?.id, lockedCnt, isFormDirty ]);

    //upon loading a new quote, check the lock
    useEffect(() => {
        updateIsLocked( quote?.id, 0, LockState.UNLOCKED, isNewQuote, isFormDirty );
    }, [quote?.id, isFormDirty ]);

    const updateIsLocked = ( quoteId:React.Key | undefined, lockedCnt:number, lockState:LockState, isNewQuote:boolean, isFormDirty:boolean, skipPoll?:boolean ) : void => {

        if ( !isFormDirty ) return;

        if ( isNewQuote ) {
            setLockedCnt( 1 ); //keep lock count at 1 for new quotes
            return;
        }

        //bail if quote isn't loaded or isLockExceeded time limit
        if ( !quoteId || (lockState === LockState.EXCEEDED) ) return;

        //if not locked, and skipping the poll, keep the lockCnt at 1
        //note: this effectively resets the lock exceeded timeout
        if ( (lockState !== LockState.LOCKED) && skipPoll ) {
            setLockedCnt( 1 );
            return;
        }

        configurator.api.fetchIsLocked( Number( quoteId ) ).then( resp => {
            const locked = resp.data as boolean;
            const cnt = locked ? 0 : ( lockedCnt + 1 )
            setLockedCnt( cnt );
        });
    };

    const resetLockCnt = (skipPoll?:boolean) => {
        updateIsLocked( quote?.id, 0, LockState.UNLOCKED, isNewQuote, isFormDirty, skipPoll );
    }

    const userActivity = () => {
        resetLockCnt(true);
    }

    const retryLock = () => {
        resetLockCnt();
    }
    /* ************************************
     **************************************/




    return [ lockState, userActivity, retryLock ];
}
