import { createContext } from "react";
import { Category, GlobalExpressionDetails, BaseModel, RuleExpression, RuleSetDefinition } from "../../api/models";

export interface RulesContextInterface {
    categories: Category[];
    models: BaseModel[];
    ruleSet: RuleSetDefinition;
    globalExpressions: GlobalExpressionDetails[];
}

const initial: RulesContextInterface = {
    categories: [],
    models: [],
    ruleSet: new RuleSetDefinition(),
    globalExpressions: [],
};

const RulesContext = createContext(initial);

export default RulesContext;
