import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../context";
import { useIntl } from "react-intl";
import { Button, Col, Dropdown, Form, Input, MenuProps, Modal, Row, notification } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { OptionFilter } from "../../api/models";
import { FormInstance } from "antd/lib";

const SaveOption = (props: {
  moduleName: string,
  size?: SizeType,
  includeSelectionSummary?: boolean,
  form?: FormInstance,
}) => {

  const [optionName, setOptionName] = useState<string | undefined>(undefined);
  const [showOptionName, setShowOptionName] = useState(false);
  const [optionFilters, setOptionFilters] = useState<OptionFilter[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const getOptionFilters = async () => {
    try {
      const resp = await configurator.api.getOptionFilters();
      setOptionFilters(resp.data.filter(q => q.moduleName === props.moduleName));
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to get options. " + errorMsg });
    }
  }

  useEffect(() => {
    getOptionFilters();
  }, []);

  const saveOptionFilter = async () => {
    const obj: OptionFilter = {
      moduleName: props.moduleName,
      optionName: optionName,
      selectionCategories: props.form?.getFieldsValue().categoryIds,
      rowPerUnit: props.form?.getFieldsValue().rowPerUnit,
      includeSelectionSummary: props.includeSelectionSummary,
    };

    try {
      await configurator.api.saveOptionFilter(obj);
      getOptionFilters();
      onCancel();
      notification.success({message: "Successfully save option."})
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to save option. " + errorMsg });
    }
  }

  const onCancel = () => {
    setShowOptionName(false);
    setOptionName(undefined);
  }

  const onDelete = async (f: OptionFilter) => {

    if (!f.id) return;

    try {
      await configurator.api.deleteOptionFilter(f.id);
      notification.success({message: "Successfully delete filter."})
      getOptionFilters();
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to delete filter. " + errorMsg });
    }
  }

  const applyOption = async (f: OptionFilter) => {
    props.form?.setFieldsValue({
      includeSelectionSummary: true,
      categoryIds: f.selectionCategories,
      rowPerUnit: f.rowPerUnit,
    })
  }


  const dropdownItems: MenuProps['items'] = optionFilters.map((f: OptionFilter, i: number) => {return {
    key: f.optionName + "-" + i,
    label: 
    <Row justify={"space-between"}>
      <Button style={{border: "none"}}
        type="text"
        onClick={() => applyOption(f)}
        size={props.size}
      >
        {f.optionName}
      </Button>
      <Button 
        type="text"
        shape="circle"
        className="cancel-button"
        size={props.size}
        icon={<CloseOutlined />}
        onClick={() => {onDelete(f)}}
      />
    </Row>,
  }});


  return (
    <Row gutter={12}>
      <style>
        {`
          .custom-dropdown {
            display: inline !important;
          }

          .custom-dropdown .ant-btn {
            border: 1px solid #1677ff !important;
            color: #1677ff !important;
          }

          .custom-dropdown .ant-btn:hover {
            color: white !important;
            background-color: #1677ff !important;
          }

          .custom-dropdown .ant-btn:last-child {
            border-left: none;
          }

          .custom-dropdown .ant-dropdown-menu-item-active {
            background-color: white !important;
          }

          .cancel-button:hover {
            color: #1677ff !important;
          }

          .cancel-button {
            color: red !important;
          }

          .save-filter-button {
            border: 1px solid #1677ff !important;
            color: #1677ff !important;
          }

          .save-filter-button:hover {
            color: white !important;
            background-color: #1677ff !important;
          }
        `}
      </style>
      <Col>
      {!optionFilters.length ?
      <Button onClick={() => setShowOptionName(true)} size={props.size} className="save-filter-button">Save Option</Button>
      :
      <Dropdown.Button
        menu={{items:dropdownItems}}
        size={props.size}
        onClick={() => setShowOptionName(true)}
        rootClassName="custom-dropdown"
      >
        Save Option
      </Dropdown.Button>
      }
      </Col>
      <Col span={12}>
        {showOptionName && <Input size="small"
          placeholder="Please enter save option name"
          style={{width: "100%"}}
          onChange={(e) => setOptionName(e.target.value)}
        />}

      </Col>
      {optionName && <Col span={2}>
        <Button icon={<CheckOutlined />} type="text" shape="circle" size="small" onClick={saveOptionFilter} style={{color: "green"}}/>
      </Col>}
      {showOptionName && <Col span={2}>
        <Button icon={<CloseOutlined />} type="text" shape="circle" size="small" onClick={onCancel} style={{color: "red"}}/>
      </Col>}
    </Row>
  );

}

export default SaveOption;
