import {Button, Result, notification, Form, Input, Space, } from "antd";
import Table from "antd/es/table";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {AXIOS_CANCEL_MSG, FgiTransporter, PAGINATION_MAX_PAGE_SIZE, SortDirection } from "../../api/models";
import {ConfiguratorContext } from "../../context";
import BMButton, {BMButtonProps} from "../BMButton";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import { useForm } from "antd/es/form/Form";
import axios, { CancelTokenSource } from "axios";
import { debounce } from "lodash";
import ModalWizard from "../ModalWizard";
import { WizardInstance, WizardStep } from "../Wizard";

  function isNew(obj: Record<string, any> | undefined) : boolean {
    if ( !obj ) return true;
    return !('id' in obj && !!obj.id);
  }

  interface FgiTransporterFormValues {
    id?:number
    key?:string
    name?:string
  }

const FgiTransportersButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  onChange?: (lst:FgiTransporter[] | undefined) => void
}) => {

  const {onChange:b, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const [selectedFgiTransporter, selectedFgiTransporterAsync] = useAsyncState<FgiTransporter>();
  const [fgiTransporterLst, fgiTransporterLstAsync] = useAsyncState<FgiTransporter[]>([]);

  const [form] = useForm();

  const fgiTransporterLstCancelTokenSourceRef = useRef<CancelTokenSource>();

  useEffect(() => {
    form.resetFields();
  }, [selectedFgiTransporter])


  const handleNew = (nav:WizardInstance | undefined) => {
    selectedFgiTransporterAsync.setInit();
    form.resetFields();

    nav?.nextStep();
  }

  const handleEdit = (nav:WizardInstance | undefined, t:FgiTransporter) => {

    selectedFgiTransporterAsync.setDone(t);
    form.resetFields();

    nav?.nextStep();
  }

  const handleDelete = (nav:WizardInstance | undefined) => {
    nav?.nextStep();
  }

  const handleConfirmDelete = async (nav:WizardInstance | undefined) => {
    deleteFgiTransporters( selectedFgiTransporter?.id ).then(nav?.nextStep);
    await reloadFgiTransporters();
    nav?.nextStep();
  }

  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleCancel = () => {
    if ( selectedFgiTransporterAsync.isDone() ) props.onChange?.(fgiTransporterLst);

    setIsOpen(false);
  }

  const reloadFgiTransporters = async () => loadFgiTransporters(fgiTransporterLstAsync);
  const loadFgiTransporters = useCallback(debounce( async ( fgiTransporterLstAsync:AsyncState<FgiTransporter[]>)  : Promise<FgiTransporter[] | undefined>=> {

    if ( fgiTransporterLstCancelTokenSourceRef.current ) {
      fgiTransporterLstCancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    fgiTransporterLstCancelTokenSourceRef.current = cancelSource;

    const sort = {
      field: "name",
      direction: 'asc' as SortDirection,
    }

    fgiTransporterLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchFgiTransporters({
          page: 0,
          size: PAGINATION_MAX_PAGE_SIZE,
          sort,
        },
        cancelSource.token
      );

      fgiTransporterLstCancelTokenSourceRef.current = undefined;

      fgiTransporterLstAsync.setDone( resp.data.content );
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Transporters failed to load. " + errorMsg });
        fgiTransporterLstAsync.setFail(e.message);
      }
    }

    return;
  }, 1000 ), [] );

  const deleteFgiTransporters = async (fgiTransporterId:number | undefined) : Promise<boolean> => {
    if( !fgiTransporterId ) return false;

    try {
      await configurator.api.deleteFgiTransporter(fgiTransporterId)
      return true;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to delete transporter. " + errorMsg;
      notification.error( { message: msg });
    }

    return false;
  }
  const createFgiTransporters = async (name:string | undefined) : Promise<FgiTransporter | undefined> => {
    if( !name ) return;

    try {
      selectedFgiTransporterAsync.setLoading();
      const resp = await configurator.api.createFgiTransporter(name)
      selectedFgiTransporterAsync.setDone(resp.data);

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create transporter. " + errorMsg;
      notification.error( { message: msg });
    }

    return;
  }

  const updateFgiTransporters = async (fgiTransporterId:number | undefined, name:string | undefined) : Promise<FgiTransporter | undefined> => {
    if( !fgiTransporterId ) return;
    if( !name ) return;

    try {
      selectedFgiTransporterAsync.setLoading();
      const resp = await configurator.api.updateFgiTransporter(fgiTransporterId, name)
      selectedFgiTransporterAsync.setDone(resp.data);

      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to update transporter. " + errorMsg;
      notification.error( { message: msg });
    }

    return;
  }

  const handleSave = async (nav:WizardInstance) => {

    try {
      const values = await form.validateFields() as FgiTransporterFormValues;

      const saved =  isNew( values )
        ? await createFgiTransporters(values.name)
        : await updateFgiTransporters(values.id, values.name)

      if ( saved ) {

        await reloadFgiTransporters();
        nav.prevStep();
      }
    }
    catch(e:any) {
      notification.error({message: "Please fix validation errors." });
    }

  }

  //hide border when disabled
  const btnStyle = {borderBottom: "1px solid black"};


  const steps:WizardStep[] = [
    {
      key: 1,
      body: (nav) => 
        <div key="fgiTransporterSelection">
          <div style={{display: "flex", flexDirection: "row-reverse", marginBottom: "1rem", gap: ".4rem"}}>
            <Button type="primary" onClick={() => handleNew(nav)} >New</Button>
          </div>

          <style>
            {`
              .dialog-fgiTransporterLst .ant-table-content { padding: 5px; } /* don't clip corners */
              .dialog-fgiTransporterLst .ant-table-cell { border: none !important; } /* remove table cell borders */
              /* add error border to table */
              .ant-form-item-has-error .dialog-fgiTransporterLst .ant-table-content {
              border: solid 1px #ff4d4f;
              border-radius: 15px;
              }
            `}
          </style>
          <Table 
            pagination={{
              hideOnSinglePage:true,
              pageSize: 5,
            }}
            className="dialog-fgiTransporterLst"
            columns={ [ 
              {
                title: "Transporter",
                render: (co:FgiTransporter) =>
                  <BMButton type="text"
                  onClick={() => handleEdit(nav, co)}
                  className="ghostBmButton"
                  style={{padding:0 }}
                ><span style={{...btnStyle, whiteSpace:"pre-wrap"}}>{co.name}</span></BMButton> 
              },
            ]}
            rowKey="id"
            size="small"
            loading={fgiTransporterLstAsync?.isLoading()}
            dataSource={fgiTransporterLst}
          />
        </div>,
      footer:() => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button key="done" type="primary" onClick={handleCancel}>Done</Button>
      </div>
    },
    {
      key: 2,
      body: () => <Form
        form={form}
        initialValues={selectedFgiTransporter}
      >
        <Form.Item
          hidden
          name="id"
        >
          <Input hidden />
        </Form.Item>
        <Form.Item
          hidden
          name="key"
        >
          <Input hidden />
        </Form.Item>
        <Form.Item
          name="name"
        >
          <Input placeholder="Transporter Name" />
        </Form.Item>
      </Form>,
      title: () => isNew(selectedFgiTransporter) ? "Add Transporter" :  "Edit Transporter",
      footer:(nav) => <div style={{display: "flex", justifyContent: "space-between"}}>
        <Button type="primary" danger onClick={() => handleDelete(nav)}>Delete</Button>
        <Space>
        <Button onClick={() => nav?.prevStep()}>Back</Button>
        <Button type="primary" onClick={() => handleSave(nav)}>Save</Button>
        </Space>
      </div>
    },
    {
      key: 3,
      body: () =>  
        <Result
          key="deleteWarningStep"
          status="warning"
          title={`'${selectedFgiTransporter?.name}' will be deleted.`}
          style={{padding: "0"}}
        />,
      footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
        <Button type="primary" danger onClick={() => handleConfirmDelete(nav)}>Confirm</Button>
        <Button onClick={() => nav?.prevStep()}>Back</Button>
      </div>
    }
  ];


  return <>
    <BMButton {...btnProps}
      onClick={handleOpen} 
    >Transporters</BMButton>
    <ModalWizard 
      open={isOpen}
      onCancel={handleCancel}
      afterOpenChange={(open) => {
        if (open) {
          if (fgiTransporterLstAsync?.isInitial() && !fgiTransporterLstAsync.isLoading() ) {
            loadFgiTransporters(fgiTransporterLstAsync);
          }
        }
      } }
      showSteps={false}
      steps={steps}
    />
  </>;
}

export default FgiTransportersButtonModal;
