
export enum AsyncDataState {
    Initial = "initial" //use strings for easy debug
    ,Loading = "loading"
    ,Done = "done"
    ,Fail = "fail"
}

interface AnyAsyncData<T> {
    readonly val?:T
    readonly lastModified:number
    readonly state:AsyncDataState
    readonly err?:string
}

export class AsyncData<T> implements AnyAsyncData<T> {
    readonly val?:T
    readonly lastModified:number
    readonly state:AsyncDataState
    readonly err?:string

    constructor ( val?:T, state?:AsyncDataState, lastModified?:number | null, err?:string) {
        this.val = val
        this.lastModified = lastModified || Date.now()
        this.state = state || AsyncDataState.Initial
        this.err = err
    }

    init(val?:T) : AsyncData<T> {
        return new AsyncData(val);
    }

    loading() : AsyncData<T> {
        return new AsyncData( this.val, AsyncDataState.Loading );
    }

    fail(err?:string) : AsyncData<T> {
        return new AsyncData( this.val, AsyncDataState.Fail, null, err );
    }

    done(val?:T) : AsyncData<T> {
        return new AsyncData( val, AsyncDataState.Done );
    }

    loaded(obj:AsyncData<T>) : AsyncData<T> {
        return new AsyncData( obj.val, obj.state, obj.lastModified, obj.err );
    }

    isInitial() : boolean {
      return this.state == AsyncDataState.Initial
    }

    isLoading() : boolean {
      return this.state == AsyncDataState.Loading
    }

    isDone() : boolean {
      return this.state == AsyncDataState.Done
    }

    isFail() : boolean {
      return this.state == AsyncDataState.Fail
    }

}
