import styles from './InventoryDetail.module.css'
import { Badge, Button, Col, Divider, Drawer, DrawerProps, Dropdown, notification, Row, Space } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { NumberParam, useQueryParam } from "use-query-params";
import { CommentTopic, PricingBreakdown, Quote, QuoteComment, Performance } from "../api/models";
import { ConfiguratorContext } from "../context";
import { useAsyncState } from "../hook/useAsyncState";
import NotFoundPage from "./not_found";
import { ConfigurationTabContents, DetailsTabContents, PerformanceContents, QuoteHeader } from '../components/Quote/QuoteQuickView';
import QuoteCommentList from '../components/Quote/QuoteCommentList';
import QuoteContextProvider from '../contexts/QuoteContext';
import ExportQuotePdf from '../components/Quote/ExportQuotePdf';
import {MoreOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import dayjs from "dayjs";
import Utils from '../util/util';


const InventoryDetail = () => {

  const params = useParams<Record<string, string>>();
  const [revisionParam, _setRevisionParam] = useQueryParam<number | undefined | null >("rev", NumberParam);
  const quoteId = params.quoteId;
  const revision = revisionParam || undefined;


  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [_pricing, pricingAsync] = useAsyncState<PricingBreakdown>();
  const [performance, performanceAsync] = useAsyncState<Performance>();
  const [quote, quoteAsync] = useAsyncState<Quote>();
  const exportViewRef = useRef(null);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [quoteCommentCnt, setQuoteCommentCnt] = useState<number | undefined>();
  const topic = [ CommentTopic.UserComment, CommentTopic.SystemActivity ]
  const history = useHistory();

  //load data one showing modal and data is init/fail
  useEffect(() => {

    Promise.all([
      loadQuote(quoteId, revision),
      loadPricing(quoteId),
      loadQuoteComments(quoteId),
      loadPerformance( quoteId )
    ]).then( ([_q, _p, commentLst]) => {
        setQuoteCommentCnt( commentLst?.length );
    });
  }, [quoteId]);

  const {
    hasWritePermission
  } = Utils.getQuoteState(configurator, quoteAsync, false);

  const loadQuoteComments = async (quoteId:string) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    try {
      const resp = await configurator.api.fetchQuoteComments(quoteId, {topic});
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch comments " + errorMsg });
    }
    return;
  }

  const loadPricing = async (quoteId:string | undefined) => {
    if ( !quoteId ) return;

    pricingAsync.setLoading()

    try {
      const resp = await configurator.api.fetchFullPricingBreakdownByQuote( quoteId )
      pricingAsync.setDone(resp.data);
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to load pricing. " + errorMsg });
      pricingAsync.setFail(e.message);
    }
  }

  const loadQuote = async (quoteId:string | undefined, revision:number | undefined) : Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    try {
      quoteAsync.setLoading();

      const resp = await configurator.api.getQuoteByRevision(quoteId, revision);
      const quote = resp.data;
      quoteAsync.setDone(quote);

      return quote;
    } 
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to get quote details. " + errorMsg

      notification.error( { message: msg, duration: 0 });
      quoteAsync.setFail(msg);
    }

    return;
  };

  const loadPerformance = async (quoteId:string | undefined, rev?:number | undefined) : Promise<Performance | undefined> => {
    if ( !quoteId?.length ) return;

    try {
      const resp = await configurator.api.fetchQuotePerformance(quoteId, rev);
      performanceAsync.setDone(resp.data);
      return resp.data;
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get performance statistics. " + errorMsg });
      performanceAsync.setFail( e.message );
    }

    return;
  }

  if ( quoteAsync.isFail() ) return <NotFoundPage message="Quote not found" />;

  const CommentsDrawer = (props:DrawerProps ) => {

    return <>
      <Drawer
        {...props}
        title={<>
          <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
            <div>{quote?.partNumberString} Comment(s)</div>
          </div>
        </>}
      >
        <QuoteCommentList topics={topic} />
      </Drawer>
    </>
  }

  const handleOnePageExport = () => {

    const element = exportViewRef.current;
    if (!element ) return;


    const ts = dayjs().format('YYYY-MM-DD.SSS');

    const doc = new jsPDF();
    doc.html(element, {
      callback: (doc) => {
        doc.save( `${quote?.quoteId}-onepage-${ts}.pdf` );
      },
      html2canvas:  { scale: .12 },
      margin: 10,
    });
  }

  const gotoQuote = (q:Quote | undefined) => q && history.push("/configurator/" + encodeURI(q.quoteId));

  return <div className="site-layout-background">
    <QuoteContextProvider value={{ quoteAsync, quotePricingDetailsAsync:pricingAsync }}>
      <Space direction='vertical' >
        <div style={{display: "flex", flexDirection: "row-reverse", gap: ".5rem"}}>

          <Dropdown trigger={["click"]}
            menu={{items:[
              {key: "specPdf", label: <ExportQuotePdf type="text" inventory={true} /> },
              {key: "save", label: <Button type="text" onClick={ () => handleOnePageExport()}>Download</Button> }
            ]}}
            destroyPopupOnHide={true}
            forceRender={true}
          >
            {/* this div is to avoid a warning with strict mode */}
            <div>
              <Button 
                type="primary" 
                icon={<MoreOutlined />} 
              >Export</Button>
            </div>
          </Dropdown>

          {configurator.isInternalSales() &&
            <Button type="primary" onClick={() => setShowComments(true)} >
              Comments<Badge count={quoteCommentCnt} size="small" >&nbsp;</Badge>
            </Button>}

          {hasWritePermission &&
            <Button type='primary' onClick={() => gotoQuote(quote)}>Edit</Button>}

        </div>

        <div ref={exportViewRef}>
          <Space direction='vertical' >
            <div className={styles['quote-quick-view-header']}>
              <QuoteHeader  />
            </div>
            <Row gutter={[20, 20]}>
              <Col span={15}>
                <DetailsTabContents  /> 
              </Col>
              {performance && <Col >
                <PerformanceContents performance={performance} /> 
              </Col> }
            </Row>
            <ConfigurationTabContents  hidePricing={quoteAsync.val?.stock}/>
          </Space>
        </div>
      </Space>
      <CommentsDrawer 
        open={showComments} 
        onClose={() => setShowComments(false)} />
    </QuoteContextProvider>
  </div>
}

export default InventoryDetail;

