import { ExclamationOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";

interface Props {
  title: string;
  formItemName: string;
  showAlertIcon: boolean | undefined;
  showInfoIcon: boolean | undefined;
  inputFieldsStyle: any;
  alertContent: string;
  infoContent: string;
}

const LabelTooptip = (props: Props) => {

  const {title, showAlertIcon, showInfoIcon, alertContent, infoContent} = props

  return (
    <span>
      {showAlertIcon &&
        <Tooltip title={alertContent} style={{marginRight: "5px"}}>
          <ExclamationOutlined style={{color: "red", fontSize: "16px"}} />
        </Tooltip>
      }
      <span style={{marginRight: "5px"}}>{title}</span>
      {showInfoIcon && <Tooltip color="blue" title={infoContent}>
        <QuestionCircleTwoTone style={{color: "blue"}} />
      </Tooltip>}
    </span>
  );
}

export default LabelTooptip;
