import styles from "./VersionChecker.module.css";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Result } from 'antd';

// Periodic check every 15 minutes
const CHECK_VERSION_INTERVAL = 15 * 60 * 1000; //in ms

export const VersionChecker = () => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {

    let currentVersion:string
    const checkVersion = async () => {
      try {
        const response = await axios.get('/manifest.json', {
          // query URL without using browser cache
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        });

        const latestVersion = response.data.version;
        console.log( "latestVersion", latestVersion, "currentVersion", currentVersion );
        if (!currentVersion?.length) {
          currentVersion = latestVersion;
        }
        else if (latestVersion !== currentVersion) {
          setIsOpen(true);
        }
      } catch (error) {
        console.error('Failed to check for updates:', error);
      }
    };

    // Initial check
    checkVersion();

    const intervalId = setInterval(checkVersion, CHECK_VERSION_INTERVAL);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return <Modal
    open={isOpen}
    className={styles["versionCheckerModal"]}
    okText="Refresh"
    onOk={() => window.location.reload()}
    onCancel={() => setIsOpen(false)}
  >
    <Result 
      status={"info"}
      title={"New version available!"}
      subTitle={"Refresh for the best experience."}
    />
  </Modal>
};

export default VersionChecker;

