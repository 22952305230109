import {Button, Input, Modal, notification, Form, Spin} from "antd";
import {useForm} from "antd/es/form/Form";
import _ from "lodash";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {Quote, RevisionChangeDto} from "../../api/models";
import {ConfiguratorContext } from "../../context";
import { useQuoteContext } from "../../contexts/QuoteContext";
import {useAsyncState} from "../../hook/useAsyncState";
import BMButton, {BMButtonProps} from "../BMButton";
import ApprovalDiffTable from "../Table/ApprovalDiffTable";

const AbandonEngineeringChangeButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void
}) => {

  const { onChange:a, ...btnProps } = props;

  const {quoteAsync} = useQuoteContext();
  const quote = quoteAsync?.val;
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = useForm();
  const [diff, diffAsync] = useAsyncState<RevisionChangeDto>();
  const changeSummary = Form.useWatch<string>('changeSummary', form);

  const abandonChangeOrder = async () : Promise<Quote|undefined> => {
    if ( !quote ) return;

    try {

      quoteAsync.setLoading();
      const resp = await configurator.api.abandonEngineeringChange(quote.displayRevisionId, changeSummary );
      quoteAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      const msg = "Failed to abandon change order. " + errorMsg;

      notification.error( { message: msg });
      quoteAsync.setFail(e.message);
    }
  };

  const loadDiff = async () : Promise<RevisionChangeDto | undefined> => {
    if ( !quote ) return;

    try{
      diffAsync.setLoading();
      const resp = await configurator.api.verifyRevisionChange(
        {
          quoteId: quote.id,
          verifyingRevisionId: quote.displayRevisionId,
          compareCurrentRevision: true,
        }
      );
      diffAsync.setDone(resp.data);

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response.data.message });
      const msg = "Failed to get change difference. " + errorMsg;

        notification.error( { message: msg });
        diffAsync?.setFail(e.message);
    }     
  }

  function isEmptyDeep(obj:any) {
    if(_.isObject(obj)) {
      if(Object.keys(obj).length === 0) return true
        return _.every(_.map(obj, v => isEmptyDeep(v)))
    } else if(_.isString(obj)) {
      return !obj.length
    }
    return false
  }

  const handleAbandonClick = async () => {

    const diff = await loadDiff();
    if ( !isEmptyDeep( diff?.diffDto ) ) {
      setIsOpen(true);
    }
    else {
      handleAbandon();
    }
  }



  const handleAbandon = async () => {
    const quote = await abandonChangeOrder();
    if ( quote ) {
      props.onChange?.(quote);
    }
  }

  return <>
    <BMButton
      onClick={handleAbandonClick}
      {...btnProps}
    >
      Abandon
    </BMButton>
    <Modal title={'Abandon Engineering Change'}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      width={'90rem'}
      footer={[
        <Button key="b3" 
          onClick={() => setIsOpen(false)} >
          Cancel
        </Button>,  
        <Button key="b1" type="primary" 
          disabled={changeSummary?.trim() === ''} 
          onClick={handleAbandon} >
          Abandon
        </Button>
      ]}
    >
      <Spin spinning={diffAsync.isLoading() || quoteAsync?.isLoading()}>
        <ApprovalDiffTable diff={diff?.diffDto} />
        <Form
          form={form}
        >
          <Form.Item
            name="changeSummary"
            label="Change Summary"
            rules={[{ required: true, message: "Abandon reason is required." }]}
          >
            <Input placeholder={'Please enter the abandon reason.'} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  </>
}

export default AbandonEngineeringChangeButton;

