import React from "react"
import {ReactElement} from "react"
import BMButton from "./BMButton"

const BMReadOnly = <T,>(props:{
  readOnly: boolean | undefined
  value?: T
  renderValue?: (v?:T | undefined) => ReactElement | string | undefined
  placeholder?:string
  style?:React.CSSProperties
  btnStyle?:React.CSSProperties
  dataTestId?: string
  children:ReactElement
  onClick?: ()=>void
}) : ReactElement => {

  const {children, style, readOnly, renderValue, placeholder, dataTestId, btnStyle, ...childProps } = props;

  const v:ReactElement | string | undefined = renderValue?.(props.value) || 
    ( ( props.value !== undefined ) && String(props.value) ) || 
    placeholder;

  //hide border when disabled
  const btnTxtStyle =  {borderBottom: "none", color: "black"};

  if ( readOnly ) return <BMButton type="text"  
    className="ghostBmButton"
    disabled={true}
    onDisabledClick={props.onClick}
    style={{paddingLeft:0, ...btnStyle}}
    data-testid={dataTestId}
  ><span style={{...btnTxtStyle, ...style}}>{v}</span></BMButton> 

  const newElement = React.cloneElement(children, childProps );

  return newElement;
}

export default BMReadOnly;

