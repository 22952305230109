import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../context";
import { Select } from "antd";
import { useIntl } from "react-intl";

export interface Props {
  onSelectQuoteStatus?: (status: string) => void;
  value?: string;
  onChange?: (statuses: string) => void;
  style?: any;
  inludingCancelledOrder?: boolean;
}

const HEAD_STATUS = [ 'QUOTE', 'Quote (Approved)', 'ORDER', 'SHIPPED', 'CANCELLED', 'Quote (Expired)' ]
const QuoteStatusSelector = (props: Props) => {
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [quoteStatuses, setQuoteStatuses] = useState<string[] | undefined>(
    props.value ? [props.value] : undefined
  );

  useEffect(() => {
    load();
  }, [props.inludingCancelledOrder]);

  async function load() {
    const resp = await configurator.api.listStatuses(props.inludingCancelledOrder || false);
    setQuoteStatuses(resp?.data);
  }

  const head = HEAD_STATUS
  ?.filter( s => quoteStatuses?.includes(s) );

  const tail = quoteStatuses
  ?.filter( s => !HEAD_STATUS.includes(s) )
  ?.sort((a,b) => {
    return intl.formatMessage({id: 'status.' + a, defaultMessage: a})
      .localeCompare(intl.formatMessage({id: 'status.' + b, defaultMessage: b}));
  }) || [];

  const options = [...head, ...tail]
  .map(status => ({
    value: status, 
    label: intl.formatMessage({id: 'status.' + status, defaultMessage: status})
  }));

  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      mode="multiple"
      options={options}
    />
  );
};

export default QuoteStatusSelector;
