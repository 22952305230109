import { Select, Spin } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { EpicorCustomer, EpicorShipTo } from "../../api/models";
import { debounce } from "lodash";
import { ConfiguratorContext } from "../../context";

export interface Props {
    customer?: EpicorCustomer;
    onSelect?: (shipTo: (EpicorShipTo|undefined)) => void;
    initialValue?: EpicorShipTo;
}

const EpicorShipToFilter = (props: Props) => {
    const [selected, setSelected] = useState<EpicorShipTo|undefined>(props.initialValue||undefined);
    const [items, setItems] = useState<EpicorShipTo[] | undefined>(props.initialValue ? [props.initialValue] : []);
    const [customer, setCustomer] = useState<EpicorCustomer|undefined>(props.customer);

    useEffect(() => {
        setSelected(props.initialValue);
        setItems(props.customer?.shipTos);
        setCustomer(props.customer);
    }, [props.customer]);

    const handleChange = (newValue: string) => {
        const item = items?.find(s => s.shipToNum == newValue); 
        setSelected(item);
        props.onSelect?.(item);
    };

    const getLabel = (customer: EpicorShipTo) => {
        var label = customer.name;

        if(customer.address1) label += '\n' + customer.address1;
        if(customer.address2) label += '\n' + customer.address2;
        if(customer.address3) label += '\n' + customer.address3;

        if(customer.city?.length > 0 && customer.state?.length > 0 && customer.zip?.length > 0) {
            label += '\n' + (customer.city + ', ' + customer.state + ' ' + customer.zip);
        }

        return label;
    };

    return (
        <Select
            style={{ width: '100%' }}
            showSearch
            value={selected ? selected.shipToNum : null}
            placeholder=""
            allowClear={true}
            showArrow={false}
            filterOption={true}
            optionFilterProp="label"
            options={items?.filter(item => item.shipToNum?.length > 0).map((d) => ({
                value: d.shipToNum,
                label: getLabel(d),
              }))}
            onChange={handleChange}
        />
    );
};

export default EpicorShipToFilter;
