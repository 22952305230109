import "./App.css";

import { IntlProvider } from "react-intl";

import { BrowserRouter as Router, Route } from "react-router-dom";

import Admin from "./admin";
import { QueryParamProvider } from "use-query-params";
import messages_en from "./translations/en.json";
import Utils from "./util/util";

//add advanced format to dayjs for entire app
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat);

const messages = {
  en: messages_en,
};
const language = navigator.language.split(/[-_]/)[0];

function App() {

  Utils.restoreNavigation();

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Admin />
        </QueryParamProvider>
      </Router>
    </IntlProvider>
  );
}

export default App;
