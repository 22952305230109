import { Quote} from "../../../api/models";
import {ConfiguratorContext } from "../../../context";
import { notification } from "antd";
import {useContext, useEffect, useState} from "react";
import BMButton from "../../BMButton";

const ExportBOMButton = (props: {
  quote:Quote | undefined,
  onExporting?:(b:boolean)=>void
  pendingChanges?:boolean
}) => {
  const { quote, onExporting, pendingChanges } = props;

  const configurator = useContext(ConfiguratorContext);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  useEffect(() => {
    onExporting?.( isExporting );
  }, [isExporting]);

  const handleDisabledClick = () => {
      notification.warning({message:"There are pending changes. Please save the quote first."});
  }

  const handleExportBOM = async () => {
    if ( !quote ) return;

    setIsExporting( true );
    try {
      const url = configurator.api.getEpicorExportUrl(quote.displayRevisionId);
      await configurator.api.downloadZip(url);
    }
    catch (e) {
      notification["error"]({
        message: "Failed to export at this time.",
      });
    }
    setIsExporting( false );
  };

  return <BMButton type="text"
    disabled={pendingChanges || !quote} 
    onDisabledClick={handleDisabledClick} 
    onClick={handleExportBOM} 
    loading={isExporting}
  >Export BOM</BMButton>
}

export default ExportBOMButton;
