import { Alert, Button, Col, Descriptions, Divider, Form, Input, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import Title from "antd/lib/typography/Title";
import { useContext, useState } from "react";
import { BadRequestError } from "../api/errors";
import {
  DestinationOperationSpec,
  OperationsRemapResponse,
  Permission,
  RemapOperationRequest,
} from "../api/models";
import OperationSelector from "../components/operation_selector";
import { ConfiguratorContext } from "../context";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";

const OperationsPage = () => {
  const [remapForm] = useForm();
  const configurator = useContext(ConfiguratorContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dryRun, setDryRun] = useState<boolean>(true);
  const [dryRunResults, setDryRunResults] = useState<OperationsRemapResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);

  async function doFinish(values: any) {
    const destinations: DestinationOperationSpec[] = values.destinations.map(
      (dest: any) => {
        return {
          operationName: dest.operation,
          hoursRatio: parseFloat(dest.hours),
        };
      }
    );

    const request: RemapOperationRequest = {
      sourceOperations: values.sourceOperations,
      destinationCategoryOperation: values.destinationCategoryOperation,
      cabSequence: values.destinationOperationCabSequence,
      chassisSequence: values.destinationOperationChassisSequence,
      dryRun: dryRun,
      destinationOperations: destinations,
    };

    setErrorMessage(undefined);
    setDryRunResults(undefined);
    setLoading(true);
    try {
      const resp = await configurator.api.remapOperations(request);
      if (dryRun) {
        setDryRun(false);
      } else {
        setDryRun(true);
      }
      setDryRunResults(resp.data);
    } catch (e) {
      console.log(e);
      setDryRun(true);
      if (e instanceof BadRequestError) {
        setErrorMessage(e.toString());
      } else {
        setErrorMessage("Request failed, please try again later");
      }
    } finally {
      setLoading(false);
    }
  }

  async function onFinish(values: any) {
    if (!values.destinations?.length) {
      return;
    }

    if (!dryRun) {
      confirm({
        title: "Are you sure want to run this remap?",
        icon: <ExclamationCircleOutlined />,
        content:
          "Only run this operation if you are sure the dry run output matches your expecations.",
        okText: "Remap",
        okType: "danger",
        cancelText: "Cancel",
        onOk() {
          doFinish(values);
        },
        onCancel() {},
      });
    } else {
      await doFinish(values);
    }
  }

  return (
    <div className="site-layout-background">
      <Title>Operations</Title>
      <Title level={2}>Remap Operations</Title>
      <Form
        onChange={() => setDryRun(true)}
        onFinish={onFinish}
        form={remapForm}
        layout="vertical"
      >
        <Row gutter={80}>
          <Col span={10}>
            <Form.Item
              name="sourceOperations"
              label="Source Operation"
              rules={[{ required: true, message: "Source Operation is required" }]}
            >
              <OperationSelector allowMultiple={true} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="destinationCategoryOperation"
              label="Category(BOO) Destination Operation"
              rules={[
                {
                  required: true,
                  message: "Destination Category Operation is required",
                },
              ]}
            >
              <OperationSelector currentOnly={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={80}>
          <Col span={10}>
            <Form.Item
              initialValue=""
              name={"destinationOperationCabSequence"}
              label="Cab Sequence"
              rules={[{ required: true, message: "Cab Sequence is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              initialValue=""
              name={"destinationOperationChassisSequence"}
              label="Chassis Sequence"
              rules={[{ required: true, message: "Chassis Sequence is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Title level={4}>Destination Operations</Title>
        <Form.List name="destinations">
          {(fields, { add, remove }) => (
            <div>
              <Form.Item>
                <Button
                  type="primary"
                  shape="circle"
                  size="small"
                  onClick={() => {
                    setDryRun(true);
                    add();
                  }}
                >
                  <PlusOutlined/>
                </Button>
              </Form.Item>
              <Divider />

              {fields.map((field, idx) => (
                <div key={idx}>
                  <Row justify={"end"}>
                    <Button
                      shape="circle"
                      size="small"
                      type="primary"
                      onClick={() => {
                        setDryRun(true);
                        remove(field.name);
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Row>
                  <Row gutter={80}>
                    <Col span={10}>
                      <Form.Item
                        label="Operation"
                        name={[field.name, "operation"]}
                        rules={[
                          { required: true, message: "Operation is required" },
                        ]}
                      >
                        <OperationSelector currentOnly={true} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        label="Hours (Ratio 0.0-0.1)"
                        name={[field.name, "hours"]}
                        rules={[{ required: true, message: "Hours is required" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{marginTop: "5px", marginBottom: "5px"}}/>
                </div>
              ))}
            </div>
          )}
        </Form.List>

        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!canWrite}
            title={!canWrite ? `You need permission to ${dryRun ? "test" : "update"}` : ""}
          >
            {dryRun ? "Test" : "Update"}
          </Button>
        </Form.Item>
      </Form>
      {errorMessage ? (
        <div>
          <Alert type="error" message={"Error: " + errorMessage} />
        </div>
      ) : (
        ""
      )}

      {dryRunResults ? (
        <div>
          <Title level={5}>{dryRun ? "Dry Run" : ""} Results</Title>
          <Descriptions
            layout="vertical"
            labelStyle={{fontWeight: "bold", color: "black"}}
            items={[
              {
                key: "cabSequence",
                label: "Cab Sequence",
                children: dryRunResults.cabSequence,
                span: 1,
              },
              {
                key: "chassisSequence",
                label: "Chassis Sequence",
                children: dryRunResults.chassisSequence,
                span: 2,
              },
              {
                key: "updatedCategories",
                label: "Update Categories",
                children: <>
                  {dryRunResults.updatedCategories.map(u => <>
                    {`${u.name} (operation: ${u.operationId})`}
                    <br/>
                  </>)}
                </>,
                span: 3,
              },
              {
                key: "updatedAssemblies",
                label: "Update Assemblies",
                children: <>
                  {dryRunResults.updatedAssemblies.map(r => <>
                    {`${r.bom}`}
                    <br/>
                    {`Operation Update: ${r.operationUpdates.map(u => `operation: ${u.operationId} hours: ${u.hours}`).join(", ")}`}
                    <br/>
                    {`Operation Delete: ${r.operationDeletes.join(", ")}`}
                    <br/>
                    <br/>
                  </>)}
                </>,
                span: 2,
              },
            ]}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default OperationsPage;
