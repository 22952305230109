import { Input, Select } from "antd";
import { Category, CategoryMetadata, MetadataSelectOption, MetadataValue } from "../../../api/models";
import { useContext, useEffect, useState } from "react";
import RulesContext from "../context";

export interface MetadataSelectOptionInfo {
    categoryId: string;
    field: string;
    value: string;
}

export interface Props {
    value: MetadataSelectOptionInfo;
    onUpdate: (MetadataSelectOptionInfo) => void;
}

interface CategorySelectProps {
    categoryId: string;
    categories: Category[];
    onCategoryUpdated: (Category) => void;
}

interface MetadataSelectProps {
    category: Category | undefined;
    field: string;
    onFieldUpdated: (string) => void;
}

const MetdataFieldSelect = (props: MetadataSelectProps) => {
    return (
        <Select showSearch value={props.field} style={{ width: '500px' }} onChange={props.onFieldUpdated}
            filterOption={(input, option) =>
                (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }>
            {props.category?.metadata.filter((md: CategoryMetadata) => md.fieldType == 'select').map(md => (
                <Select.Option key={md.id} value={md.name}>{md.name}</Select.Option>
            ))}
        </Select>
    );
};

const CategorySelect = (props: CategorySelectProps) => {
    function onChange(categoryId: string) {
        if (props.onCategoryUpdated) {
            props.onCategoryUpdated(props.categories.find(cat => cat.categoryId == categoryId));
        }
    }

    return (
        <Select showSearch value={props.categoryId} onChange={onChange} style={{ width: '500px' }}
            filterOption={(input, option) =>
                (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }>
            {props.categories.map(cat => (
                <Select.Option key={cat.categoryId} value={cat.categoryId}>{cat.name}</Select.Option>
            ))}
        </Select>
    );
};

const MetadataSelectOptionEditor = (props: Props) => {
    const rulesContext = useContext(RulesContext);

    const category = rulesContext.categories.find(cat => cat.categoryId == props.value.categoryId);
    const currentFieldMetadata = category?.metadata.filter(md => md.fieldType == 'select').find(md => md.name == props.value.field);

    return (
        <div>
            <div style={{ display: 'flex', flex: '1' }}>
                <CategorySelect categories={rulesContext.categories} categoryId={props.value.categoryId} onCategoryUpdated={(cat) => {
                    const val = new MetadataSelectOption();
                    val.categoryId = cat.categoryId;
                    props.onUpdate(val);
                }} />
                {props.value.categoryId &&
                    <>
                        <MetdataFieldSelect field={props.value.field} onFieldUpdated={(val) => {
                            const opt = new MetadataSelectOption();
                            opt.categoryId = props.value.categoryId;
                            opt.field = val;
                            props.onUpdate(opt);
                        }} category={category} />
                        <Select showSearch value={props.value.value} onChange={(val) => {
                            const opt = new MetadataSelectOption();
                            opt.categoryId = props.value.categoryId;
                            opt.field = props.value.field;
                            opt.value = val;
                            props.onUpdate(opt);
                        }} style={{ width: '500px' }}
                            filterOption={(input, option) =>
                                (option?.children?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                            }>
                            {currentFieldMetadata?.selectOptions?.map((opt, i) => {
                                return (<Select.Option key={i} value={opt}>{opt}</Select.Option>)
                            })}
                        </Select>
                    </>
                }
            </div>
        </div>
    );
};

export default MetadataSelectOptionEditor;
