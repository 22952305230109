import { Alert, Button,  Modal, Result,  } from "antd";
import { Approval, ApprovalAction } from "../api/models";
import Utils from "../util/util";
import { useHistory } from "react-router-dom";

export const ApprovalResult = (props:{
  approval:Approval | undefined
  quoteStatus:string | undefined
  showApprovalResults:boolean
  setShowApprovalResults:(b:boolean)=>void
}) => {
  const { approval, showApprovalResults, setShowApprovalResults, quoteStatus } = props;
  const action = approval?.action === ApprovalAction.APPROVED ? "Approved" : "Rejected";
  const title = `Approval ${action}!`;
  const history = useHistory();

  if ( !approval?.action ) return <></>;

  const handleDone = () => {
    setShowApprovalResults(false);

    if ( history.length > 1 ) history.goBack();
      else history.push("/approvals");

  }

  return <>
    <Alert type="info" message={`This quote has already been ${action.toLowerCase()}.`} />
    <Modal
      open={showApprovalResults}
      onCancel={() => setShowApprovalResults(false)}
      footer={null}
    >
      <Result
        status="info"
        title={title}
        subTitle={<>The quote status is now {Utils.snakeCaseToFirstLetterCapitalized(quoteStatus)}.</> }
        extra={ <Button type="primary" key="console" onClick={handleDone}>Done</Button> }
      />
    </Modal>
  </>

}

export default ApprovalResult;

