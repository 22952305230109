import {Alert, Button, Modal, Result, Space} from "antd";
import {LockState} from "../../hook/useQuoteLock";

interface Properties { 
    style?: any
    lockState:LockState
    retryLock:()=>void
    userActivity:()=>void
}
const QuoteLock = (props: Properties) => {
    const { style, lockState, retryLock, userActivity } = props;

    const isLocked = lockState === LockState.LOCKED;
    const isLockWarning = lockState === LockState.WARNING;
    const isLockExceeded = lockState === LockState.EXCEEDED;

    return (
        <>
            <div style={style}>
            {isLockExceeded &&
            <Alert type="error" message="The lock has been released due to exceeding max lock time."
                action={ <Button onClick={retryLock}>Retry</Button> } />
            }

            {isLocked && 
                <Alert type="error" message="The form has been locked while another user makes changes. It will reload when available." />
            }
            </div>

            <Modal open={isLockWarning} 
                onOk={userActivity}
                onCancel={userActivity}
                cancelButtonProps={{ style: { display: 'none' } }} >
                <Result
                    status="warning"
                    title="The lock is about to expire and there are unsaved changes.  Please save the changes or they will be lost."
                />
            </Modal>

        </>
    );
}

export default QuoteLock;



