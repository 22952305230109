import "./AssemblySelectionTable.css"
import { Alert, Button, Checkbox, Col, Dropdown, Input, notification, Row, Space, Table, TablePaginationConfig} from "antd";
import {CategoryIdAssembliesIdMap, CategoryMetadata, Category, CustomOptionType, BaseCategory, AXIOS_CANCEL_MSG, AssemblyOption, AssemblyBase, PAGINATION_MAX_PAGE_SIZE, QuoteAssemblyExceptionType } from "../../api/models";
import {
  MoreOutlined,
  EditOutlined 
} from "@ant-design/icons";
import Utils from "../../util/util";
import {ColumnType} from "antd/lib/table";
import {useMemo, useContext, useEffect, CSSProperties, useState, ChangeEvent, useRef, useCallback } from "react";
import {ConfiguratorContext, CustomOptionsContext, CustomOptionsContextType} from "../../context";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import axios, {CancelTokenSource} from "axios";
import {useIntl} from "react-intl";
import Title from "antd/lib/typography/Title";
import RuleDebugOutputModal from "../RuleDebugOutputModal";
import BMButton from "../BMButton";
import {QuoteAssemblyExceptionContext, QuoteAssemblyExceptionContextType} from "../../pages/configurator";
import AssemblyExceptionButtonModal from "./AssemblyExceptionButtonModal";
import EditCustomOptionButtonModal from "../EditCustomOptionButtonModal";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { debounce } from "lodash";
import { FilterValue, SorterResult, SortOrder } from "antd/es/table/interface";
import { CategoryOptionsFilter } from "../../api";
import { MenuItemType } from "antd/es/menu/interface";
import _ from "lodash";

type SelectionSort = SorterResult<SelectionInfo> | SorterResult<SelectionInfo>[];

const DEFAULT_SORT = {
  columnKey:  'name',
  order: 'ascend' as SortOrder,
}

const DEFAULT_FILTER = {
  imported: true,
  showObsolete: false,
  showIncompatible: false,
}

export interface SelectionInfo {
  key:string,
  option:AssemblyOption | CustomOptionType
}

interface CategoryOptions {
  selectedOptions:CategoryIdAssembliesIdMap | undefined
  selectedCustomOptions:CustomOptionType[] | undefined
  percentDiscount?:number | undefined
  revisionId?:number | undefined
}

export function isAssemblyOption(option: AssemblyOption | CustomOptionType | undefined) : boolean {
  if ( !option ) return false;
  return 'bom' in option;
}

export function asAssemblyOption(option: AssemblyOption | CustomOptionType | undefined) : AssemblyOption | undefined {
  return isAssemblyOption(option) ? option as AssemblyOption : undefined;
}
export function asCustomOption(option: AssemblyOption | CustomOptionType | undefined) : CustomOptionType | undefined {
  return !isAssemblyOption(option) ? option as CustomOptionType : undefined;
}

const warningTextStyle = {color: "red", fontWeight: 'bold'};
const infoTextStyle = {color: "blue", fontWeight: 'bold'};

const DEFAULT_PAGE_SIZE = 5;
const AssemblySelectionTable = (props: {
  readOnly?: boolean
  loading?: boolean
  disabled?: boolean
  selectedCategory: Category | undefined
  onSelectOption: (c:BaseCategory, o:AssemblyBase | CustomOptionType)=>void
  onClearSelections: (c:BaseCategory)=>void
  filterOptionsQuery: string | undefined
  optionNotes:Record<number,string>
  onUpdateOptionNotes:(a:number | undefined, note:string | undefined)=>void
  percentDiscount: number | undefined
}) => {
  const { selectedCategory } = props;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const isEngineering = configurator.isEngineering();
  const isAdmin = configurator.isAdmin();

  const [optionLst, optionLstAsync] = useAsyncState<AssemblyOption[]>();
  const [columnFilterOptionLst, columnFilterOptionLstAsync] = useAsyncState<AssemblyOption[]>();

  const { quoteAsync, loadQuoteOnly, adminView, selectedOptions, selectedCustomOptions, selectedModel} = useQuoteContext();
  const quote = quoteAsync?.val;
  const revisionId = quote?.displayRevisionId;
  const modelId = selectedModel?.modelInfo.id;

  const { quoteAssemblyExceptionLstAsync, loadQuoteAssemblyExceptions } = useContext<QuoteAssemblyExceptionContextType>(QuoteAssemblyExceptionContext);
  const quoteAssemblyExceptionLst = quoteAssemblyExceptionLstAsync?.val;

  const { customOptionLstAsync, loadCustomOptions } = useContext<CustomOptionsContextType>(CustomOptionsContext);
  const customOptionLst = customOptionLstAsync?.val?.filter( co => co.category?.id === selectedCategory?.id );
  const cancelCategoryOptionsTokenSourceRef = useRef<CancelTokenSource | undefined>();
  const cancelColumnFilterValuesTokenSourceRef = useRef<CancelTokenSource | undefined>();

  const [categoryOptionsFilter, setCategoryOptionsFilter] = useState<CategoryOptionsFilter>(DEFAULT_FILTER);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [sort, setSort] = useState<SelectionSort>(DEFAULT_SORT);


  //if menu sort filter changes, update the selection filter
  useEffect(() => {
    const filter = {
      ...categoryOptionsFilter,
      metadataSearch: props.filterOptionsQuery,
    };
    setCategoryOptionsFilter(filter);
  }, [props.filterOptionsQuery]);

  useEffect(() => {
    //console.log("reset ", selectedCategory );
    setPagination({
      current: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    setSort({...DEFAULT_SORT});
    reloadColumnFilterValues(categoryOptionsFilter);
  }, [categoryOptionsFilter])


  //on initial conditions changing, reset the pagination
  useEffect(() => {
    setCategoryOptionsFilter({
      ...DEFAULT_FILTER,
      metadataSearch: props.filterOptionsQuery,
    });
  }, [selectedCategory, modelId, quoteAssemblyExceptionLst, selectedOptions, selectedCustomOptions])

  //if pagingation changes or sort, refetch options
  useEffect(() => {
    reloadCategoryOptions();
  }, [pagination.current, pagination.pageSize, sort ]);

  const {
    isOrder,
    isPendingApproval,
  } = Utils.getQuoteState(configurator, quoteAsync);

  const loadCategoryOptions = useCallback( debounce( async (lstAsync:AsyncState<AssemblyOption[]>, modelId:number, categoryId:number, pagination?:TablePaginationConfig, sorter?:SelectionSort, filter?:CategoryOptionsFilter, opts?:CategoryOptions) : Promise<AssemblyOption[] | undefined> => {
//console.log("loadCategoryOptions start");

    if ( cancelCategoryOptionsTokenSourceRef.current ) {
      cancelCategoryOptionsTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelCategoryOptionsTokenSourceRef.current = cancelSource;

    const sort = [ sorter ].flatMap(v => v)
    .filter( v => v?.columnKey !== undefined )
    .map( v => ([
      v?.columnKey?.toString(),
      v?.order === 'descend' ? 'desc' : 'asc',
    ].join(',')))

    try {
      lstAsync.setLoading();

      const resp = await configurator.api.fetchCategoryOptionsPage(modelId, categoryId, { 
        options: {
          currentSelections: Object.values(opts?.selectedOptions || {}).flat(),
          customOptions: opts?.selectedCustomOptions?.map(co => co.id),
          quoteRevisionId:opts?.revisionId, 
          percentDiscount: opts?.percentDiscount,
        },
        filter: {
          ...filter,
          includePricing: true,
        },
        page: {
          page: ( pagination?.current || 1 ) - 1,
          size: pagination?.pageSize || DEFAULT_PAGE_SIZE,
          sort
        },
      },  cancelSource.token );
      cancelCategoryOptionsTokenSourceRef.current = undefined;

      const options = resp.data.content;
      lstAsync.setDone(options);

      const p = {
        ...pagination,
        total: resp?.data.totalElements,
      };
      setPagination(p);

      return options;

    } catch (e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to load category options. " + errorMsg });
        lstAsync.setFail(e.message);
      }
    }

    return;
  }, 750 ), []);

  const reloadCategoryOptions = async () : Promise<AssemblyOption[] | undefined> => {
//console.log("reloadCategoryOptions start");
    if ( !selectedCategory || !modelId )  return;

    const categoryOptions:CategoryOptions = {
      selectedOptions, 
      selectedCustomOptions,
      revisionId,
      percentDiscount: props.percentDiscount,
    }
    const filter = {
      ...categoryOptionsFilter,
    };
    const resp = await loadCategoryOptions(optionLstAsync, modelId, selectedCategory.id, pagination, sort, filter, categoryOptions);
    //console.log("reloadCategoryOptions", resp);
    return resp;

  }

  const loadColumnFilterValues = useCallback( debounce( async (lstAsync:AsyncState<AssemblyOption[]>, modelId:number, categoryId:number, pagination?:TablePaginationConfig, sorter?:SelectionSort, filter?:CategoryOptionsFilter, opts?:CategoryOptions) : Promise<AssemblyOption[] | undefined> => {
//console.log("loadCategoryOptions start");

    if ( cancelColumnFilterValuesTokenSourceRef.current ) {
      cancelColumnFilterValuesTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelColumnFilterValuesTokenSourceRef.current = cancelSource;

    const sort = [ sorter ].flatMap(v => v)
    .filter( v => v?.columnKey !== undefined )
    .map( v => ([
      v?.columnKey?.toString(),
      v?.order === 'descend' ? 'desc' : 'asc',
    ].join(',')))

    try {
      lstAsync.setLoading();

      const resp = await configurator.api.fetchCategoryOptionsPage(modelId, categoryId, { 
        options: {
          currentSelections: Object.values(opts?.selectedOptions || {}).flat(),
          customOptions: opts?.selectedCustomOptions?.map(co => co.id),
          quoteRevisionId:opts?.revisionId, 
          percentDiscount: opts?.percentDiscount,
        },
        filter,
        page: {
          page: ( pagination?.current || 1 ) - 1,
          size: pagination?.pageSize || DEFAULT_PAGE_SIZE,
          sort
        },
      },  cancelSource.token );
      cancelColumnFilterValuesTokenSourceRef.current = undefined;

      const options = resp.data.content;
//console.log("loadCategoryOptions", options);
      lstAsync.setDone(options);
      return options;

    } catch (e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to load category options. " + errorMsg });
        lstAsync.setFail(e.message);
      }
    }

    return;
  }, 750 ), []);

  const reloadColumnFilterValues = async (categoryOptionsFilter?:CategoryOptionsFilter) : Promise<AssemblyOption[] | undefined> => {
//console.log("reloadColumnFilterValues start");
    if ( !selectedCategory || !modelId )  return;

    const categoryOptions:CategoryOptions = {
      selectedOptions, 
      selectedCustomOptions,
      revisionId,
      percentDiscount: props.percentDiscount,
    }
    const filter = {
      ...categoryOptionsFilter,
      columnFilterValues: undefined,
    };
    const page = {
      page: 0,
      pageSize: PAGINATION_MAX_PAGE_SIZE
    }
    const resp = await loadColumnFilterValues(columnFilterOptionLstAsync, modelId, selectedCategory.id, page, DEFAULT_SORT, filter, categoryOptions);
    //console.log("reloadColumnFilterValues", resp);
    return resp;
  }

  const getAssemblyLabel = (a:AssemblyOption) => !!a.label?.length ? a.label : a.bomDescription;

  const columnFilterValues = useMemo( () => 

    columnFilterOptionLst?.map( a => {

      const asm = {'name': getAssemblyLabel(a)};

      const metadata = a.metadata
      .map( md => {
        const v = Utils.getMetadataValue(md);
        return v ? ({ [md.name!]: String(v) }) : undefined;
      })
      .filter(v => !!v)
      .reduce( (acc, val) => Object.assign(acc, val), {})

      //console.log( asm, metadata );
      return Object.assign(asm, metadata);
    })
    .reduce( (acc, val) => {
      //merge array of objects into one object with values as sets (to remove duplicates)
      //eg [ {a, b}, {a, c}, {a, c}, {d, e} ] => {a: [a, c], d: [e]}
      Object.keys(val).forEach( k => {
      //console.log( "reduce", k );
        if ( !acc[k] ) acc[k] = new Set<string>();
        acc[k].add(val[k]);
      });
      return acc;
    }, {}), 
  [columnFilterOptionLst]);

  const selectedOptionLst = ( selectedCategory && selectedOptions?.[ selectedCategory.id ] ) || [];
  const selectedCustomOptionLst = selectedCustomOptions?.filter(co => co.category?.id === selectedCategory?.id ) || [];

  const isValidLeadTime = !Utils.isWithinLeadTime(quote?.productionDate, selectedCategory?.leadTimeDays);
  const overrideLeadTime = isAdmin || isEngineering;

  const saveUserSelection = async (quoteRevisionId:number | undefined, selection:SelectionInfo | undefined) : Promise<void> => {
    if ( !quoteRevisionId ) return;
    if ( !selection ) return;

    try {

      await configurator.api.saveUserSelection(quoteRevisionId, {
        assemblyId: asAssemblyOption(selection.option)?.id,
        customOptionId: asCustomOption(selection.option)?.id,
      });

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      console.log( "Failed to load category options. " + errorMsg );
    }

    return;
  }

  const getLeadTimeAlertMsg = () : string | undefined => {
    if ( isValidLeadTime ) return;

    if ( overrideLeadTime ) {
      return `Warning! Changes are within ${selectedCategory?.leadTimeDays} days of the Production Date.`;
    }
    else {
      const rslt = new Array<string>();
      rslt.push( `Selections in this category cannot be changed within ${selectedCategory?.leadTimeDays} days of the Production Date.` );
      const primaryEngineer = quote?.salesTeam?.engineers?.find(v=>v);
      if ( primaryEngineer ) {
        rslt.push(`Please reach out to ${primaryEngineer.name} (${primaryEngineer.email}) if you need additional assistance.`);
      }
      return rslt.join( " " );
    }
  };

  const getAssemblyExceptionDisabledMsg = () : string | undefined => {

    return !quote?.displayRevisionId ? "The quote is still loading."
      : !selectedCategory ? "A category must be selected."
      : undefined;

  }

  const getCategoryDisabledMsg = () : string | undefined => {

    return !quote?.displayRevisionId ? "The quote is still loading."
      : !selectedCategory ? "A category must be selected."
      : ( !(overrideLeadTime || isValidLeadTime) ) ? getLeadTimeAlertMsg()
      : ( isPendingApproval && props.readOnly ) ? "Assemblies cannot be modified while approval is pending."
      : ( isOrder && props.readOnly ) ? "A change order is required to modify assemblies."
      : props.readOnly ? "The quote is currently read-only" 
      : undefined;

  }

  const getDebugDisabledMsg = () : string | undefined => {
    const disabledMessage = getCategoryDisabledMsg();
    return !!disabledMessage ? disabledMessage
            : !(isAdmin || isEngineering) ? "Only engineering can clear"
            : undefined;
  }

  const isCategoryDisabled = () : boolean => {
    return !!getCategoryDisabledMsg()
  }

  const getCategoryWarningMsg = () : string | undefined => {
    if ( overrideLeadTime && !isValidLeadTime ) return getLeadTimeAlertMsg();

    return;
  }

  const isCategoryWarning = () : boolean => {
    return !!getCategoryWarningMsg()
  }

  const isRowDisabled = (s:SelectionInfo) :boolean => {

    if( isCategoryDisabled() ) return true;

    const asm = asAssemblyOption(s.option);
    if ( !(isEngineering || isAdmin ) && asm?.obsoletedAt ) return true;
    if ( asm?.incompatible ) return true;

    const co = asCustomOption(s.option);
    if ( co?.approved === false && !co.included ) return true;

    return false;
  }

  const isSelected = (s:SelectionInfo):boolean => {
    const asm = asAssemblyOption(s.option);
    const co = asCustomOption(s.option);

    const isSelectedAssembly = asm && selectedOptionLst.includes(asm.id)
    const isSelectedCustomOption = co && selectedCustomOptionLst.map( co => co.id).includes(co.id);
    return isSelectedAssembly || isSelectedCustomOption || false;
  }

  const buildSelectionInfo = (o:CustomOptionType | AssemblyOption ) :SelectionInfo => {
    const asm = asAssemblyOption(o);
    if( asm ) {
      return {key: `assembly-${asm.id}`, option:asm};
    }

    const co = o as CustomOptionType;
    return {key: `customOption-${co.id}`, option:co};
  }

  const customSelections:SelectionInfo[] | undefined = customOptionLst?.map(buildSelectionInfo);

  const assemblySelections:SelectionInfo[] | undefined = optionLst?.map(buildSelectionInfo);

  const dataSource:SelectionInfo[] | undefined = [ ...assemblySelections || [], ...customSelections || [] ];

  const obsoletedOptions: SelectionInfo[] = dataSource.filter(s => asAssemblyOption(s.option)?.obsoletedAt);

  const selectedRows = dataSource?.filter( isSelected );
  const disabledSelectedRows = selectedRows?.filter( isRowDisabled );

  const handleSelectRow = async (s:SelectionInfo) => {
    if (!selectedCategory) return;
    if (!(overrideLeadTime || isValidLeadTime)) return;
    if (isRowDisabled(s)) return;

    saveUserSelection(quote?.displayRevisionId, s );

    props.onSelectOption(selectedCategory, s.option);
  };

  //if category allows multiple or there are multiple selected, allow multiple selection
  const showMultiple = selectedCategory?.allowMultiple || selectedRows.length > 1;

  const showMultipleWarning = showMultiple && !selectedCategory?.allowMultiple

  const handleClearDisabled = () => {
    if (!selectedCategory) return;

    selectedRows?.filter( isRowDisabled ).forEach( s => {
       props.onSelectOption(selectedCategory, s.option );
    });
  }

  const handleAddCustomOption = async (customOption:CustomOptionType)  => {
    await loadCustomOptions?.();
    await loadQuoteOnly?.();

    if (!selectedCategory) return;
    props.onSelectOption(selectedCategory, customOption);
  }

  const handleEditCustomOption = async (_customOption:CustomOptionType)  => {
    await loadCustomOptions?.();
    await loadQuoteOnly?.();
  }

  const handleUpdatedAssemblyException = () => {
    loadQuoteAssemblyExceptions?.(quote?.quoteId);
  }

  const handleClear = () => {
    if (!selectedCategory) return;
    props.onClearSelections(selectedCategory);
  }

  const handleChangeSearchFilter = (e:ChangeEvent<HTMLInputElement>) => {
    setCategoryOptionsFilter({
      ...categoryOptionsFilter,
      metadataSearch: e.target.value,
    });
  }

  const handleTableChange =  (pagination:TablePaginationConfig, filters:Record<string, FilterValue | null>, sorter: SelectionSort) => {

    if ( !_.isEqual( categoryOptionsFilter.columnFilterValues, filters) )  {
      //console.log("filters=", categoryOptionsFilter.columnFilterValues, filters);
      setCategoryOptionsFilter({
        ...categoryOptionsFilter,
        columnFilterValues: filters,
      });
      reloadColumnFilterValues();
    }

    //console.log(sorter);
    setSort(sorter);
    setPagination(pagination);
  };

  const optionActionItems = new Array<MenuItemType>();
  optionActionItems.push( {
    key: "clearBtn",
    label: <BMButton type="text" className="ghostBmButton"
      disabled={!!getCategoryDisabledMsg()}
      onDisabledClick={() => notifyDisabled(getCategoryDisabledMsg())}
      onClick={handleClear}>Clear</BMButton>
  } );
  optionActionItems.push( {
    key: "debugBtn",
    label:
        <RuleDebugOutputModal
          category={selectedCategory}
          type="text"
          obsoletedOptions={obsoletedOptions}
        />
  } );


  const getColumnFilterProperties = (columnName:string | undefined) : ColumnType<SelectionInfo> | undefined => {
    if ( !columnName?.length ) return;

    //console.log( "column filter values", columnName, columnFilterValues );
    const values = columnFilterValues?.[ columnName ];
    const filters = values ? [...values]
      .sort( (a,b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map( v => ({ id:columnName, text: v, value: v })) : undefined;

    return {
      filters,
      sorter: {
        multiple: 1
      },
      filterSearch: true,
      filterMultiple: true,
    }
  }

  const getColumnForMetadata = ( md:CategoryMetadata ) : ColumnType<SelectionInfo> => ({
      ...getColumnFilterProperties(md.name),
      key: md.name, 
      title: md.name,
      render: (s:SelectionInfo) => {
        const m = s.option?.metadata?.find( md1 => md1.categoryMetadata.id == md.id);
        return m == null ? "" : Utils.getMetadataValue(m);
      },
    });

  const metadataColumns:ColumnType<SelectionInfo>[] = 
    selectedCategory?.metadata?.filter( md => adminView || md.visibleInConfigurator )
    .sort((a,b) => (a.sortOrder || 0 ) - ( b.sortOrder || 0 ) )
    .map( md  => getColumnForMetadata( md ) ) || [];


  const labelStyle =  { wordWrap: "break-word", wordBreak: "break-word", width: "30rem" } as CSSProperties;

  const columns:ColumnType<SelectionInfo>[] = [
    {
      key: "name",
      title: "Name",
      fixed: "left",
      sorter: {
        multiple: 1
      },
      render: (s: SelectionInfo) => {
        const asm = asAssemblyOption(s.option);
        const co = asCustomOption(s.option);
        if ( asm ) {
          return <AssemblyDetail 
            labelStyle={labelStyle}
            assembly={asm} 
            note={props.optionNotes[asm.id]} 
            onUpdateNote={(note) => props.onUpdateOptionNotes(asm.id, note) } />
        }
        else if(co) {
          return <CustomOptionDetail 
            category={selectedCategory}
            labelStyle={labelStyle}
            disabled={isRowDisabled(buildSelectionInfo(co))}
            customOption={co} 
            onChange={handleEditCustomOption} />
        }
      },
    },
    ...metadataColumns
  ];

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }


  const handleToggleShowIncompatible = (checked:boolean) => {
    setCategoryOptionsFilter({  ...categoryOptionsFilter, showIncompatible: checked });
  }

  const handleToggleShowObsolete = (checked:boolean) => {
    setCategoryOptionsFilter({  ...categoryOptionsFilter, showObsolete: checked });
  }

  const filterActionItems = new Array<MenuItemType>();

  filterActionItems.push( {
    key: "filterIncompatible",
    label:
    <div >
      <label>
        <Checkbox
          onChange={(e) => handleToggleShowIncompatible(e.target.checked)}
          checked={categoryOptionsFilter.showIncompatible}
        />
        <span style={{marginLeft: "0.5rem"}}>Show Incompatible</span>
      </label>
    </div>
  } );

  filterActionItems.push( {
    key: "filterObsolete",
    label:
    <div >
      <label>
        <Checkbox
          onChange={(e) => handleToggleShowObsolete(e.target.checked)}
          checked={categoryOptionsFilter.showObsolete}
        />
        <span style={{marginLeft: "0.5rem"}}>Show Obsolete</span>
      </label>
    </div>
  } );

  return <>

    <style>
      {`
        .ant-table-cell {
          background-color: white !important;
        }
      `}
    </style>

    <Title level={5}>{Utils.stripSortingPrefix(selectedCategory?.name)} Options</Title>
    <Row justify={"space-between"} align="middle" style={{width:"100%", marginBottom: ".5rem"}}>
      <Col>
        <Space direction="horizontal">
          <Space.Compact>
            <Input value={categoryOptionsFilter.metadataSearch} 
              onChange={handleChangeSearchFilter} 
              placeholder="Filter options" 
              allowClear style={{minWidth: "20rem" }} />
            <Dropdown trigger={["click"]}
              placement="bottomRight"
              menu={{
                items:filterActionItems,
              }} >
              {/* this div is to avoid a warning with strict mode */}
              <div>
                <Button icon={<MoreOutlined/>} />
              </div>
            </Dropdown>
          </Space.Compact>
        </Space>
      </Col>
      <Col>
        <Space direction="horizontal">

          {(isEngineering || isAdmin ) &&
          <AssemblyExceptionButtonModal type="primary" 
            onAdd={handleUpdatedAssemblyException} 
            onDelete={handleUpdatedAssemblyException} 
            disabled={!!getAssemblyExceptionDisabledMsg()}
            onDisabledClick={() => notifyDisabled(getAssemblyExceptionDisabledMsg())}
            selectedCategory={selectedCategory}
          >
            Assembly Exceptions
            </AssemblyExceptionButtonModal>
          }


          <EditCustomOptionButtonModal type="primary" 
            onChange={handleAddCustomOption} 
            disabled={!!getCategoryDisabledMsg()}
            onDisabledClick={() => notifyDisabled(getCategoryDisabledMsg())}
            categoryId={selectedCategory?.id}
          >
            Add Custom Option
            </EditCustomOptionButtonModal>
          <BMButton type="primary" key="clearBtn"
            disabled={!!getDebugDisabledMsg()}
            onDisabledClick={() => notifyDisabled(getDebugDisabledMsg())}
            onClick={handleClear}>Clear</BMButton>
          <RuleDebugOutputModal
            category={selectedCategory}
            type="primary"
            key="debugBtn"
            obsoletedOptions={obsoletedOptions}
          />
        </Space>
      </Col>
    </Row>
    {isCategoryDisabled() &&
    <Alert type="info" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={getCategoryDisabledMsg()} />
    }
    {isCategoryWarning() &&
    <Alert type="warning" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={getCategoryWarningMsg()} />
    }
    {(!isCategoryDisabled() && !!disabledSelectedRows?.length ) &&
    <Alert type="error" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={"Some of the selections are no longer valid."}
      action={<><Button onClick={handleClearDisabled}>Clear </Button></>} />
    }
    {showMultipleWarning &&
    <Alert type="error" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={"Multiple selections, including custom options, are not valid for this category."}
       />
    }


    <Table
      data-testid="assemblySelectionTable"
      bordered
      loading={props.loading || optionLstAsync.isLoading()}
      style={{ width: "100%" }}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      onChange={handleTableChange}
      onRow={(record, _rowIndex) => {
        return {
          onDoubleClick: () => handleSelectRow(record)
        };
      }}
      rowKey="key"
      rowSelection={{
        type: showMultiple ? "checkbox" : "radio",
        onSelect: handleSelectRow,
        selectedRowKeys: selectedRows.map(s => s.key ),
        hideSelectAll: true,
        getCheckboxProps: (record) => {
          return { 
            disabled: isRowDisabled(record)
          };
        }
      }}
      scroll={{ x: true }}
    />
  </>
}


const AssemblyDetail = (props:{ 
  assembly:AssemblyOption,
  note:string
  onUpdateNote:(n:string | undefined) => void
  labelStyle:CSSProperties
}) => {
  const {assembly:a} = props;
  const asmLbl = (!!a.label?.length ? a.label : a.bomDescription);

  const { quoteAssemblyExceptionLstAsync } = useContext<QuoteAssemblyExceptionContextType>(QuoteAssemblyExceptionContext);
  const isObsoleteException = quoteAssemblyExceptionLstAsync?.val?.filter(ae => ae.type === QuoteAssemblyExceptionType.OBSOLETE ).filter( ae => ae.assembly.id === props.assembly.id ).length;
  const isIncompatibleException = quoteAssemblyExceptionLstAsync?.val?.filter(ae => ae.type === QuoteAssemblyExceptionType.RULE_OVERRIDE ).filter( ae => ae.assembly.id === props.assembly.id ).length;

  const promptOptionNotes = () => {
    const note = prompt("Enter option notes", props.note || "");
    props.onUpdateNote(note || undefined);
  }

  return <>
    {(!!isObsoleteException || !!isIncompatibleException ) &&
    <div style={infoTextStyle}>(Exception)</div>
    }
    {!isObsoleteException &&
    <div style={warningTextStyle}>
      {a.obsoletedAt && '(Obsolete)'}
    </div>}
    {!isIncompatibleException &&
    <div style={warningTextStyle}>
      {a.incompatible && '(Incompatible)'}
    </div> }
    <div style={{ ...props.labelStyle }} >
      <div>
        {asmLbl}, <span style={{whiteSpace:"nowrap"}}>{a.bom}</span>
        {(a.priceDifference || 0) != 0 && 
          <span style={{fontWeight: "600"}}>&nbsp;{Utils.formatMoneyWithSign(a.priceDifference)}</span>
        }
      </div>
      {a.selectionRequiresUserInput && (
        <div style={{ marginTop: "5px" }}>
          <strong>Option Notes: </strong>{" "}
          <span>{props.note || "None"}</span>{" "}
          <EditOutlined onClick={promptOptionNotes} />
        </div>
      )}
    </div>
  </>;
}

const CustomOptionDetail = (props:{ 
  category: Category | undefined
  customOption:CustomOptionType 
  disabled?:boolean
  onChange:(co:CustomOptionType)=>void
  labelStyle:CSSProperties
}) => {

  const { customOption, disabled } = props;
  const configurator = useContext(ConfiguratorContext);

  const btnStyle = disabled
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

    return <>
      <div style={infoTextStyle}>(Custom Option)</div>
      <div style={{ ...props.labelStyle,}}>

        <EditCustomOptionButtonModal 
          type="text" className="ghostBmButton"
          style={{padding:0}}
          onChange={props.onChange}
          disabled={disabled}
          value={customOption}
          categoryId={props.category?.id}
        >
          <span style={{...btnStyle}}>{customOption.content}</span>
        {(customOption?.upgradePrice || 0 ) != 0 && 
        <span style={{fontWeight: "600"}}>&nbsp;{Utils.formatMoneyWithSign(customOption?.upgradePrice)}</span>
        }
        </EditCustomOptionButtonModal>
        {!!customOption.note?.length &&
          <div style={{fontStyle:"italic"}}>Note: {customOption?.note}</div>
        }
        {!!props.category &&
        <ul className="csl" >
          <li style={{fontStyle:"italic"}}>Lead Time (days): {props.category.leadTimeDays}</li>
          {configurator.isEngineering() &&
            <li style={{fontStyle:"italic"}}>Design Time (weeks): {props.category.designTimeWeeks}</li>}
        </ul>
        }
      </div>
    </>;
}



export default AssemblySelectionTable


