import "./ReplaceCostsModal.css";
import { Alert, Form, FormInstance, InputNumber, Modal, Table } from "antd";
import { Link } from "react-router-dom";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import {Assembly, AssemblyCost} from "../../api/models";
import {useContext, useEffect, useRef, useState} from "react";
import React from "react";
import {ColumnType} from "antd/lib/table";

const formatMoney = (() => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (val:number | undefined) :string => {
    if ( val === undefined || isNaN(val) ) return 'N/A';

    return formatter.format( val );
  }
})();


type Item = AssemblyCost;

type EditableTableProps = Parameters<typeof Table>[0];
type DefaultColumnTypes = ColumnType<AssemblyCost> & { editable?: boolean; dataIndex: string }
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
type DataType = AssemblyCost & {
  dataIndex: React.Key;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <InputNumber onPressEnter={save} onBlur={save}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value!.replace(/\$\s?|(,*)/g, '')}
          placeholder="Type dollar amount." 
          ref={inputRef}
          controls={false}
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const ReplaceCostsModal = (props:{
  dataSource:AssemblyCost[] | undefined,
  onOk:(d:AssemblyCost[] | undefined) => void,
  onCancel:() => void,
}) => {

  const [ dataSource, setDataSource ] = useState<AssemblyCost[]>();

  const getLabel = (assembly:Assembly) => {
    return assembly.label != null && assembly.label.length > 0
      ? assembly.label
      : assembly.bomDescription;
  };

  var defaultColumns: DefaultColumnTypes[] = [
    {
      title: "BOM",
      dataIndex: "bom",
      render: (_v:any, record:AssemblyCost) => (
        <span>
          {record.assembly.bom}
          {record.assembly.obsoletedAt != null ? " (Obsolete)" : ""}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_v:any, record:AssemblyCost) => (
        <div>
          {record.assembly.imported ? (
            <Link to={"/assemblies/" + encodeURIComponent(record.assembly.id)}>
              {getLabel(record.assembly)}
            </Link>
          ) : (
            getLabel(record.assembly)
          )}
        </div>
      ),
    },
    {
      title: "Source",
      dataIndex: "createdBy",
      render: (_v:any, record:AssemblyCost) => {

        const dealerName = record.user?.dealerName && `(${record.user.dealerName})`;
        const displayName = [ record.user?.name, dealerName ].join( " " );

        return <div>{displayName || record.createdBy || "Unknown"}</div>
      },
    },
    {
      title: "Material Cost",
      dataIndex: "assemblyStandardMaterialCost",
      render: (_v:any, record:AssemblyCost) => <span>{formatMoney(record.assembly.standardMaterialCost)}</span>,
    },
    {
      title: "New Material Cost",
      dataIndex: "standardMaterialCost",
      render: (_v:any, record:AssemblyCost) => <span>{formatMoney(record.standardMaterialCost)}</span>,
      editable: true,
    },
    {
      title: "Difference",
      dataIndex: "materialCostDifference",
      render: (_v:any, record:AssemblyCost) => {
        let v1 = record.assembly.standardMaterialCost || 0;
        let v2 = record.standardMaterialCost;
        const diff = v2 - v1;

        if (diff > 0) { 
          return ( <span>{formatMoney(diff)}<ArrowUpOutlined style={{ color: "green" }}  /></span> );
        }
        else if (diff < 0) {
          return ( <span>{formatMoney( -1 * diff)}<ArrowDownOutlined style={{ color: "red" }}  /></span> );
        }
        return "";
      },
    },
  ];

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });


  const handleSave = (row: DataType) => {
    const newData = [...( dataSource || [] )];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  return <Modal open={!!props.dataSource?.length}
    title="Update Assembly Costs"
    onOk={() => props.onOk( dataSource )}
    onCancel={props.onCancel}
    width="80%"
    style={{maxWidth: "60rem"}}
    afterOpenChange={(open) => {
      if(open) {
        setDataSource(props.dataSource);
      }
    }}
  >

    <Alert style={{marginBottom: "1.2rem" }} type="error" message="Please ensure you know the ramifications of updating older snapshots as already quoted prices may change." />

    <h3 style={{fontSize: ".9rem", fontWeight: "500", marginBottom: ".5rem"}}>
      To be rejected, these assemblies require a new material cost:
    </h3>



  <Table
    className="replaceCostsTable"
			components={{
				body: {
					row: EditableRow,
					cell: EditableCell,
			},
			}}
      rowClassName={() => 'editable-row'}
      bordered
      rowKey="id"
      columns={columns as ColumnTypes}
      dataSource={dataSource}
    />
    </Modal>
}

export default ReplaceCostsModal;
