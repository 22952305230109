import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useState } from "react";
import { Page, Permission, RuleSet } from "../../api/models";
import { ConfiguratorContext } from "../../context";
import { Button, Table, TablePaginationConfig, notification, Space } from "antd";
import { Link } from "react-router-dom";
import CreateRuleSetModal from "../../components/Rules/create_ruleset_modal";


const ListRuleSets = () => {
    const configurator = useContext(ConfiguratorContext);
    const [showCreateModal, setShowCreateModal] = useState(false);

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
    });
    const [ruleSets, setRuleSets] = useState<Page<RuleSet>>();

    useEffect(() => {
        loadRuleSets();
    }, [pagination.current, pagination.pageSize]);

    async function loadRuleSets() {
        try {
            setRuleSets(undefined);
            const results = await configurator.api.listRuleSets((pagination.current||1) - 1, pagination.pageSize||10);
            setRuleSets(results);
            setPagination({...pagination, total: results.totalElements});
        }
        catch(e) {
            notification.error({
                message: 'Failed to load rule sets at this time.'
            });
        }
    }

    async function confirmActivate(ruleSet: RuleSet) {
        if(window.confirm('Are you sure you want to activate the rule set ' + ruleSet.name + '?\n\nThis action will de-activate the current rule set and activate this as the new one.')) {
            try {
                await configurator.api.activateRuleSet(ruleSet.id);
                notification.success({message: 'Rule Set ' + ruleSet.name + ' activated.'});
                loadRuleSets();
            }
            catch(e) {
                notification.error({message: 'Failed to activate the rule set.'});
            }
        }
    }

    function onChange(pagination: TablePaginationConfig) {
        setPagination(pagination);
    }

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (ruleSet: RuleSet) => (
                <Link to={"/rule-sets/" + ruleSet.id}>{ruleSet.name}</Link>
            ),
        },
        {
            title: 'Active',
            render: (ruleSet: RuleSet) => 
              configurator.hasPermission(Permission.RULES_WRITE) 
                ? ruleSet.active ? 'Active' : <Button onClick={() => confirmActivate(ruleSet)}>Activate</Button>
                : ruleSet.active ? 'Active' : "",
        }
    ];

    return <div className="site-layout-background">
      <Space direction="vertical" style={{width:"100%"}}>
        <Title level={2}>Rule Sets</Title>
        {configurator.hasPermission(Permission.RULES_WRITE)  &&
          <Button type="primary" onClick={() => setShowCreateModal(true)}>Create Rule Set</Button> }
        <Table loading={!ruleSets} dataSource={ruleSets?.content}
          columns={columns} rowKey="id" pagination={pagination} onChange={onChange}/>
        {showCreateModal && <CreateRuleSetModal onCancel={() => setShowCreateModal(false)} onFinished={() => { setShowCreateModal(false); loadRuleSets(); }} />}
      </Space>
    </div>;
};

export default ListRuleSets;
