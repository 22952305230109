import {Modal, notification, Table} from "antd";
import {useContext,  useState} from "react";
import BMButton, {BMButtonProps} from "../BMButton";
import {ColumnType} from "antd/es/table";
import {Revision} from "../../api/models";
import Utils from "../../util/util";
import {ConfiguratorContext} from "../../context";


type RevisionSelectorProps =  Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  id?:string
  value?: number 
  onChange?: (v: number | undefined) => void
  currentRevisionId: number | undefined
  revisions: Revision[] | undefined
};

const RevisionSelector = (props: RevisionSelectorProps) => {

  const { id, value, onChange, revisions, currentRevisionId, ...btnProps } = props;

  const selectedRevisionId = props.value;
  const selectedRevision = props.revisions?.find( m => m.id === selectedRevisionId );

  const configurator = useContext(ConfiguratorContext);
  const [isOpen, setIsOpen] = useState<boolean>();


  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  /*
     const columns:ColumnType<Revision>[] = [{
render: (record) => {
return <Card title={record.name} style={{borderRadius: "15px"}} hoverable={true} size="small">
<div >{record.description}</div>
</Card>
}
}];
   */

  const columns:ColumnType<Revision>[] = [{
    render: r => Utils.formatRevisionLabel(r, currentRevisionId, configurator.isDealerSales() ),
    sorter: (a,b) => a.revision - b.revision,
    defaultSortOrder: "descend",
  }];

  const handleSelectRow = (record:Revision) => {

    setIsOpen(false);
    props.onChange?.(record.id);
  }

  const DEFAULT_PAGE_SIZE = 7;

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  const disabled = btnProps.disabled || (revisions?.length || 0 ) < 2;
  const handleDisabledClick = () => {
    if ( ( revisions?.length || 0 ) < 2 ) {
      notifyDisabled("There are no revisions.")
    }
    else {
      btnProps.onDisabledClick?.();
    }
  };

  const btnLbl =  ( ( revisions?.length || 0 ) > 1 )
    ? Utils.formatRevisionLabel(selectedRevision, currentRevisionId, configurator.isDealerSales() )
    : "NA";

  //hide border when disabled
  const btnStyle = disabled
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  return <>
    <BMButton type="text"
      className="ghostBmButton"
      disabled={disabled}
      onDisabledClick={handleDisabledClick}
      data-testid="revision-button"
      onClick={handleOpen}
      style={{padding: "0", ...btnProps.style}}
    ><span style={btnStyle}>{btnLbl}</span></BMButton>

    <Modal
      title={"Select Revision"}
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
      bodyStyle={{padding: "1rem"}}
      transitionName=""
    >
      <style>
        {`
          .revisionLst .ant-table-cell { border: none !important; } /* remove table cell borders */
            `}
      </style>

      <Table 
        className="revisionLst"
        showHeader={false}
        columns={columns}
        rowKey="id"
        dataSource={props.revisions}
        pagination={((props.revisions?.length || 0 ) > DEFAULT_PAGE_SIZE) ? { pageSize: DEFAULT_PAGE_SIZE } : false  } 
        onRow={(record, _rowIndex) => {
          return {
            onClick: () => handleSelectRow(record)
          };
        }}
        rowSelection={{
          type: "radio",
          onSelect: handleSelectRow,
          selectedRowKeys: [...(props.value ? [props.value] : [])],
        }}
      />

    </Modal>
  </>

}

export default RevisionSelector;
