import { Quote} from "../../../api/models";
import {ConfiguratorContext } from "../../../context";
import { notification } from "antd";
import {useContext, useEffect, useState} from "react";
import Utils from "../../../util/util";
import BMButton, {BMButtonProps} from "../../BMButton";

const ExportRevisionToEpicorButton = (props: BMButtonProps & {
  quote:Quote | undefined,
  onExporting?:(b:boolean)=>void
  pendingChanges?:boolean
}) => {
  const { quote, onExporting, pendingChanges, ...btnProps } = props;

  const configurator = useContext(ConfiguratorContext);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  useEffect(() => {
    onExporting?.( isExporting );
  }, [isExporting]);

  const handleDisabledClick = () => {

    const disabledMsg = !Utils.isOrder( quote ) ? 'Only orders can be exported.' 
      : pendingChanges ? "There are pending changes. Please save the quote first."
      : undefined;

    if ( disabledMsg ) {
      notification.warning({message:disabledMsg});
    }
  }

  const handleExportRevision = async () => {
    if ( !quote ) return;

    try {
      setIsExporting(true);
      await configurator.api.epicorExportRevision(quote.displayRevisionId);
      notification["success"]({
        message: "Revision is exporting to Epicor.",
      });
    }
    catch (e) {
      notification["error"]({
        message: "Failed to export at this time.",
      });
    }
    setIsExporting(false);
  };
  

  return <BMButton
    {...btnProps}
    disabled={pendingChanges || !quote || !Utils.isOrder(quote)} 
    onDisabledClick={handleDisabledClick} 
    onClick={handleExportRevision} 
    loading={isExporting}
  >Export To Epicor</BMButton>
}

export default ExportRevisionToEpicorButton;
