import 
{
  Card,
  Checkbox,
  Col,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Spin,
  Tabs,
  Upload,
  Image,
  UploadProps,
  Space,
  Divider,
} from "antd";
import { Form } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotFoundError } from "../api/errors";
import { ConfiguratorContext } from "../context";
import NotFoundPage from "./not_found";
import { PlusCircleOutlined, DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { Assembly, AssemblyMetadata, AssemblyOperation, Category, CategoryMetadata, Dealer, MISSING_IMAGE, Operation, Permission } from "../api/models";
import {useAsyncState} from "../hook/useAsyncState";
import ReadOnlyInput from "../components/ReadOnlyInput";
import {AssemblyRequest } from "../api";
import {StringParam, useQueryParam} from "use-query-params";
import { MetadataFieldList } from "../components/metadata_field";
import {useIntl} from "react-intl";
import BMButton from "../components/BMButton";
import Utils from "../util/util";

export interface NewAssemblyOperation {
  id?: number
  operationId?: string
  hours?:number
  group?:string
}

export interface AssemblyMetadataFormValues {
  id?:number
  categoryMetadata:CategoryMetadata
  valueText?:string
  valueBool?: boolean
  valueNumeric?: number
  valueDecimal?: number
}

interface FormAssembly extends Omit<Assembly, 'metadata'> {
  metadata: AssemblyMetadataFormValues[] | undefined
}

enum UPLOADING_STATUS {
  INIT = "init",
  ERROR = "error",
  DONE = "done",
  UPLOADING = "uploading"
}

const AssemblyImport = () => {
  const params = useParams<any>();
  const [tabKeyParam, setTabKey] = useQueryParam<string | undefined | null >("tab", StringParam);
  const configurator = useContext(ConfiguratorContext);
  const [assembly, assemblyAsync] = useAsyncState<Assembly>();
  const [dealerLst, dealerLstAsync] = useAsyncState<Dealer[]>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [categoryLst, categoryLstAsync] = useAsyncState<Category[]>([]);
  const [operations, operationsAsync] = useAsyncState<Operation[]>();
  const [formAssembly, setFormAssembly] = useState<FormAssembly>();
  const history = useHistory();
  const [form] = Form.useForm();
  const categoryId = Form.useWatch('categoryId', form);
  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);
  const intl = useIntl();

  const buildFormMetadata = (assembly:Assembly, category:Category) : AssemblyMetadata[] =>  {

    const assemblyMetadata:AssemblyMetadataFormValues[] = assembly?.metadata
      ?.filter( md => category?.metadata.some( cm => cm.id === md.categoryMetadata.id  )) || [];

    const categoryMetadata:AssemblyMetadataFormValues[] = category?.metadata
      .filter( cm => !assemblyMetadata.some( md  => cm.id === md.categoryMetadata.id  ) ) 
      .map( cm => ({categoryMetadata:cm, categoryMetadataId: cm.id}) ) || [];

    return [
    ...assemblyMetadata,
    ...categoryMetadata
    ];
  }

  const updateFormAssembly = (assembly:Assembly | undefined, category?:Category) => {
    if ( !assembly ) return;

    const metadata = buildFormMetadata( assembly, category || assembly?.category )

    setFormAssembly({ 
      ...assembly,
      metadata,
    });
  };

  useEffect(() => {
    Promise.all([
      loadAssembly(params.id),
      loadOperations(),
      loadCategories(),
      loadDealers(),
    ]).then( ([assembly]) => updateFormAssembly(assembly) );
  }, []);

  const isLoading = assemblyAsync.isLoading() || categoryLstAsync.isLoading() || operationsAsync.isLoading() || categoryLstAsync.isLoading() || dealerLstAsync.isLoading();
  useEffect(() => {
    form.resetFields();
  }, [formAssembly]);

  useEffect( () => {
    const category = categoryLst?.find( c => c.id === categoryId );
    if ( assembly && category ) {

      const metadata = buildFormMetadata( assembly, category || assembly?.category )


      setFormAssembly({ 
        ...assembly,
        metadata,
        categoryId: category.id,
      });
    }
  }, [categoryId])

  const isLoadingFail = assemblyAsync.isFail() || categoryLstAsync.isFail() || operationsAsync.isFail() || categoryLstAsync.isFail() || dealerLstAsync.isFail();
  const isImported = ( assemblyAsync.isDone() && assembly?.imported );

  const loadAssembly = (id:number) : Promise<Assembly | undefined> => {

    assemblyAsync.setLoading();
    return configurator.api.getAssembly(id)
      .then( resp => {
        assemblyAsync.setDone(resp.data)
        return resp.data;
      },
        (e) => {
          if (e instanceof NotFoundError) {
            setNotFound(true);
          } else {
            const errorMsg = intl.formatMessage({ id: e.message });
            notification.error( { message: "Failed load assembly. " + errorMsg });
            assemblyAsync.setFail(e.message);
          }

          return undefined;
        });
  };

  const loadCategories = () => {
    categoryLstAsync.setLoading()
    return configurator.api.getCategories()
      .then( resp => categoryLstAsync.setDone(resp.data.sort((a, b) => a.name.toLowerCase().localeCompare( b.name.toLowerCase() ) )),
        (e) =>  {
            const errorMsg = intl.formatMessage({ id: e.message });
            notification.error( { message: "Failed load categories. " + errorMsg });
            categoryLstAsync.setFail(e.message);
          });
  }

  const loadOperations = () => {
    operationsAsync.setLoading()
    return configurator.api.fetchAssemblyOperations()
      .then( resp => operationsAsync.setDone(resp.data?.sort((a, b) => a.operationId.localeCompare(b.operationId) )),
        (e) =>  {
            const errorMsg = intl.formatMessage({ id: e.message });
            notification.error( { message: "Failed load operations. " + errorMsg });
            operationsAsync.setFail(e.message);
          });
  }

  const loadDealers = () => {
    dealerLstAsync.setLoading();
    return configurator.api.getDealers()
      .then( resp => dealerLstAsync.setDone( resp ),
        (e) =>  {
            const errorMsg = intl.formatMessage({ id: e.message });
            notification.error( { message: "Failed load dealers. " + errorMsg });
            dealerLstAsync.setFail(e.message);
          });
  }

  const onSubmitForm = async (values:Record<string,any>) => {
    if ( !assembly ) return;

    try {
      assemblyAsync.setLoading();
      const resp = await configurator.api.importAssembly(
        {
          ...values,
        } as AssemblyRequest,
        assembly.id,
      );

      assemblyAsync.setDone( resp.data );
     
      history.push( "/assemblies/" + assembly.id );
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to import assembly. " + errorMsg });

      assemblyAsync.setDone( assembly );
    }

  };

  if ( notFound ) {
    return <div className="site-layout-background"><NotFoundPage /></div>
  }

  const isCurrentOrSelected = (ao:AssemblyOperation|NewAssemblyOperation|undefined, o:Operation) => o.current || ao?.operationId === o.operationId
  

  interface UploadImageResponse {
    status:string | undefined, 
    response?:{ 
      thumbnailUrl:string
      fullSizeUrl:string
    } 
  }
  const UploadImage = ( { assemblyId, thumbnailImageUrl }) => {
    const [uploadFile, setUploadFile] = useState<UploadImageResponse>({status: UPLOADING_STATUS.INIT});

    useEffect(()=> {
      if( uploadFile.status == UPLOADING_STATUS.DONE ) {
        notification.success( {message: "Upload complete."} );
      }
      else if( uploadFile.status == UPLOADING_STATUS.ERROR ) {
        notification.error(  {message: "There was an error uploading the image."});
      }

    }, [ uploadFile.status] )


    const isUploading = UPLOADING_STATUS.UPLOADING == uploadFile.status;
    const uploadImageUrl = assemblyId ? configurator.api.baseUrl + "/v1/assembly/" + encodeURIComponent(assemblyId) + "/image" : "";
    const onUploadChange: UploadProps['onChange'] = ({file}) => {
      setUploadFile( {status: file.status, response: file.response } );
    };

    return <Upload.Dragger
      name="image"
      action={uploadImageUrl}
      withCredentials={true}
      showUploadList={false}
      accept=".jpg,.jpeg,.png,.gif,.tiff"
      style={{ margin: "1rem", width: "240px"}}
      maxCount={1}
      onChange={onUploadChange}
      disabled={!canWrite}
    >
      <Spin spinning={isUploading}>
        {thumbnailImageUrl 
          ? <Image
            src={ uploadFile.response?.thumbnailUrl || thumbnailImageUrl || MISSING_IMAGE }
            fallback={MISSING_IMAGE}
            style={{paddingBottom: "20px"}}
            width={200}
            preview={false}
          />
          :<p className="ant-upload-drag-icon">
            <InboxOutlined  />
          </p>

        }
      </Spin>
      <p className="ant-upload-text">{!canWrite ? "You need permission to upload image" : "Click or drag file to upload"}</p>
    </Upload.Dragger>

  }

  const DetailsTabPane = () => <>
    <Form
      form={form}
      name="details"
      initialValues={formAssembly}
      labelCol={{span: 4}}
      labelAlign="right"
      disabled={!!getDisabledImportMsg()}
    >
      <Title level={4}>Assembly Details</Title>

      <Form.Item
        label="Name"
        name="label"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Dealer Exclusive"
        name="dealerExclusives"
      >
        <Select
          loading={dealerLstAsync.isLoading()}
          showSearch={true}
          mode="multiple"
          optionFilterProp="label"
          options={dealerLst?.map(d => ({ label: d.name, value:d.id }))}
        />
      </Form.Item>

      <Form.Item
        label="Material Cost"
        name="standardMaterialCost"
        rules={[
          {
            required: true,
            message: "Material Cost is required",
          },
        ]}
      >
        <InputNumber
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value!.replace(/\$\s?|(,*)/g, '')}
          placeholder="Type dollar amount"
          controls={false}
          style={{width: "100%"}}
        />
      </Form.Item>

      <Row gutter={[20,20]}>

        <Col offset={4} >
          <Form.Item
            name="noOption"
            valuePropName="checked"
          >
            <Checkbox >"NO OPTION"</Checkbox>
          </Form.Item>
        </Col>

        <Col >
          <Form.Item
            name="selectionRequiresUserInput"
            valuePropName="checked"
          >
            <Checkbox >Requires User Input</Checkbox>
          </Form.Item>
        </Col>

      </Row>

      <Row >
        <Col offset={4} >
          <UploadImage 
            assemblyId={assembly?.id} 
            thumbnailImageUrl={assembly?.thumbnailImageUrl} 
          />
        </Col>
      </Row>

      <Divider orientation="left" >Wizard</Divider>
      <Form.Item
        label="Name"
        name="labelWizard"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="descriptionWizard"
      >
        <Input />
      </Form.Item>
    </Form>
  </>

  const MetadataTabPane = () => <>
    <Form
      form={form}
      name="metadata"
      initialValues={formAssembly}
      labelCol={{span: 4}}
      labelAlign="right"
      disabled={!!getDisabledImportMsg()}
    >
      <MetadataFieldList />
    </Form>
  </>

  const OperationsTabPane = () => <>
    <Form
      form={form}
      name="operations"
      initialValues={formAssembly}
      disabled={!!getDisabledImportMsg()}
    >
      <Form.List name="operations" >
        {(fields, { add, remove }) => (
          <>
            <Title level={4}>
              Operations
              <PlusCircleOutlined onClick={(_e) => add()} style={{paddingLeft: ".5rem"}}  />
            </Title>
            {fields.map((ao, idx) => (
              <div key={idx}>
                <Form.Item
                  name={[ao.name, "id"]}
                  noStyle
                  hidden
                >
                  <Input hidden />
                </Form.Item>
                <Space.Compact>
                  <Form.Item
                    name={[ao.name, "operationId"]}
                    label="Operation"
                    rules={[
                      {
                        required: true,
                        message: "Operation is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: 300 }}
                    >
                      {operations
                        ?.filter( o => isCurrentOrSelected(formAssembly?.operations[ao.name], o) )
                        .map((o, idx) => 
                          <Select.Option value={o.operationId} key={o.operationId + "-" + idx}>
                            {!o.current 
                              ?  <span style={{color: "red"}}>{o.operationId}</span>
                              :  <>{o.operationId}</>
                            }
                          </Select.Option>
                            )}
                    </Select>
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    name={[ao.name, "group"]}
                    label="Group"
                    rules={[
                      {
                        required: true,
                        message: "Group is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: 150 }}
                      optionFilterProp="children"
                      options={[
                        {label:"CAB", value:"CAB" },
                        {label:"Chassis", value:"CHASSIS" }
                      ]}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    name={[ao.name, "hours"]}
                    label="Hours"
                    rules={[
                      {
                        required: true,
                        message: "Hours are required",
                      },
                    ]}
                  >
                    <Input style={{ width: 100 }} />
                  </Form.Item>
                  <DeleteOutlined onClick={() => remove(ao.name)} style={{paddingLeft: "1rem", marginTop: ".5rem", marginBottom: "24px" }} />
                </Space.Compact>
              </div>
            ))}
          </>)}
      </Form.List>
    </Form>
  </>

  const getDisabledImportMsg = () : string | undefined => {

    return !canWrite ? "You need permission to import assembly."
    : isImported ? "This assembly has been imported."
    : isLoadingFail ? "Data failed to load.  Please refresh."
    : undefined;
  }

  const formLabelStyle = {
    margin: 0,
    fontWeight: 600,
  }
 
  return <>
    <div className="site-layout-background">
      <Spin className="assemblyLoading" spinning={isLoading} >
        <React.Fragment>
          <Form.Provider
            onFormFinish={(_triggerFormName, { values }) => {
              onSubmitForm( values  );
            }}
          >
            <Title level={2}>Import Assembly</Title>
            <Row>
              <Col span={16}>
                <Form
                  form={form}
                  name="assembly"
                  initialValues={formAssembly}
                  labelCol={{span: 3}}
                  labelAlign="right"
                  disabled={!!getDisabledImportMsg()}
                >
                  <Row gutter={16}>
                      <BMButton
                        type="primary"
                        htmlType="submit"
                        loading={assemblyAsync.isLoading()}
                        disabled={!!getDisabledImportMsg()}
                        onDisabledClick={() => Utils.warning(getDisabledImportMsg())}
                      >
                        Import
                      </BMButton>
                  </Row>


                  <Card style={{margin: "1rem 0 1rem 0"}} bodyStyle={{padding: ".2rem .5rem .2rem .5rem"}}>
                    <Form.Item
                      style={formLabelStyle}
                      name="bom" 
                      label="BOM"
                    >
                      <ReadOnlyInput  />
                    </Form.Item>
                    <Form.Item
                      style={formLabelStyle}
                      name="bomDescription" 
                      label="Description"
                    >
                      <ReadOnlyInput  />
                    </Form.Item>

                    <Form.Item
                      style={formLabelStyle}
                      label="Category"
                      name="categoryId"
                      rules={[
                        {
                          required: true,
                          message: "Category is required",
                        },
                      ]}
                    >
                      <Select 
                        showSearch
                        optionFilterProp="label"
                        loading={categoryLstAsync.isLoading()}
                        options={categoryLst?.map( c => ({
                          label: c.name,
                          value: c.id
                        }))} 
                        />
                    </Form.Item>

                  </Card>
                </Form>

                <Tabs defaultActiveKey={tabKeyParam || undefined}
                  onChange={setTabKey}
                  items={[{
                    key:"1",
                    label:"Details",
                    children: <DetailsTabPane/>,
                    forceRender: true,
                  },
                  {
                    key:"2",
                    label:"Metadata",
                    children: <MetadataTabPane/>,
                    forceRender: true,
                  },
                  {
                    key:"4",
                    label:"Operations",
                    children: <OperationsTabPane/>,
                    forceRender: true,
                  }]}
                />

              </Col>
            </Row>
          </Form.Provider>
        </React.Fragment>

      </Spin>
    </div>
  </>
};

export default AssemblyImport;
