import { Checkbox, Input, Select } from "antd";
import React from "react";

const DependencyRule = (props) => {

  const numericOperators = ["gte", "lte", "gt", "lt"];

  var operators = [
    { name: "==", id: "eq" },
    { name: ">=", id: "gte" },
    { name: "<=", id: "gte" },
    { name: ">", id: "gt" },
    { name: "<", id: "lt" },
    { name: "!=", id: "neq" },
  ];

  if (props.otherCategory != null) {
    operators.push({ name: "MATCHES OTHER", id: "eqother" });
  }

  const selectedField = props.initialState.field != null
    ? props.category.metadata.find( (md) => md.id == props.initialState.field.id)
    : null;

  const filterOperators = (val) => {
    if (selectedField == null) return false;
    if (
      !(
        selectedField.fieldType == "numeric" ||
          selectedField.fieldType == "decimal"
      )
    ) {
      if (numericOperators.includes(val.id)) return false;
    }
    return true;
  };

  const selectedOperator =  props.initialState.operator != null
    ? operators.filter(filterOperators).find((o) => o.name == props.initialState.operator.name)
    : null

  const selectedValue = props.initialState.value != null ? props.initialState.value : ""
  const selectedOtherField = props.initialState.otherField != null ? props.initialState.otherField : ""
  const rule = {
    operator: selectedOperator,
    field: selectedField,
    value: selectedValue,
    otherField: selectedOtherField,
  }

  const defaultField =
    props.initialState != null && props.initialState.field != null
      ? props.category.metadata.indexOf(
        props.category.metadata.find(
          (md) => md.id == props.initialState.field.id
        )
      )
      : "";

  const NOT_FOUND = -1;
  const getInitialDefaultValue = () => {
    var initialDefaultValue = "";
    if (
      props.initialState.field != null &&
      props.initialState.value != null &&
    selectedField != null
    ) {
      if (selectedField.fieldType == "select") {
        const ndx = selectedField.selectOptions.indexOf(
          props.initialState.value
        );
        if ( ndx !== NOT_FOUND ) initialDefaultValue = ndx;
      } else if (selectedField.fieldType == "bool") {
        initialDefaultValue = props.initialState.value;
      } else {
        initialDefaultValue = "";
      }
      return initialDefaultValue;
    }
  }
  const defaultValue = getInitialDefaultValue();


  const onSelectField = (idx) => {
    props.onUpdateRule({
      operator:null,
      field:props.category.metadata[idx],
      value:null,
      otherField:null,
    });
  };

  const onSelectOperator = (op) => {
    const operator = operators.filter(filterOperators)[op];
    const otherField = (selectedOperator != operator) ? null : selectedOtherField;
    props.onUpdateRule({
      ...rule,
      operator,
      otherField,
    });
  };

  const onSelectOtherField = (otherField) => {
    props.onUpdateRule({
      ...rule,
      otherField,
    });
  };

  const onChangeValue = (value) => {
    props.onUpdateRule({
      ...rule,
      value,
    });
  };


  const defaultOtherFieldValue =
    props.initialState != null &&
    props.initialState.otherField != null &&
    props.initialState.otherField.length > 0 &&
    props.otherCategory != null
      ? props.otherCategory.metadata.findIndex(
        (md) => md.name == props.initialState.otherField
      )
        : null;

        return (
          <React.Fragment>
            <Select
              defaultValue={defaultField}
              onChange={onSelectField}
              style={{ width: "300px" }}
              placeholder="Field"
            >
              {props.category.metadata.map((md, idx) => (
                <Select.Option key={md.id} value={idx}>
                  {md.name}
                </Select.Option>
              ))}
            </Select>
            &nbsp;
            <Select
              defaultValue={
                selectedOperator 
                  ? operators.filter(filterOperators).indexOf(selectedOperator)
                  : ""
              }
              onSelect={onSelectOperator}
              style={{ width: "200px" }}
              placeholder="Operator"
            >
              {selectedField == null
                ? []
                : operators.filter(filterOperators).map((op, idx) => (
                  <Select.Option key={idx} value={idx}>
                    {op.name}
                  </Select.Option>
                ))}
            </Select>
            &nbsp;
            {selectedField != null && (
              <React.Fragment>
                {props.otherCategory != null &&
                  selectedOperator != null &&
                  selectedOperator.id == "eqother" && (
                    <React.Fragment>
                      <Select
                        defaultValue={defaultOtherFieldValue}
                        style={{ width: "325px" }}
                        onSelect={(i) =>
                          onSelectOtherField(props.otherCategory.metadata[i].name)
                        }
                      >
                        {props.otherCategory.metadata.map((field, i) => (
                          <Select.Option key={i} value={i}>
                            {props.otherCategory.name}.{field.name}
                          </Select.Option>
                        ))}
                      </Select>{" "}
                      &nbsp;
                    </React.Fragment>
                )}
                {selectedField.fieldType == "text" &&
                  selectedOperator != null &&
                  selectedOperator.id != "eqother" && (
                    <Input
                      defaultValue={defaultValue}
                      onChange={(e) => onChangeValue(e.target.value)}
                      style={{ width: "200px" }}
                      value={selectedValue}
                    />
                )}
                {selectedField.fieldType == "numeric" &&
                  selectedOperator != null &&
                  selectedOperator.id != "eqother" && (
                    <Input
                      defaultValue={defaultValue}
                      onChange={(e) => onChangeValue(e.target.value)}
                      style={{ width: "200px" }}
                      value={selectedValue}
                    />
                )}
                {selectedField.fieldType == "decimal" &&
                  selectedOperator != null &&
                  selectedOperator.id != "eqother" && (
                    <Input
                      defaultValue={defaultValue}
                      onChange={(e) => onChangeValue(e.target.value)}
                      style={{ width: "200px" }}
                      value={selectedValue}
                    />
                )}
                {selectedField.fieldType == "bool" &&
                  selectedOperator != null &&
                  selectedOperator.id != "eqother" && (
                    <Checkbox
                      defaultChecked={!!defaultValue}
                      onChange={(v) => onChangeValue(v.target.checked)}
                    />
                )}
                {selectedField.fieldType == "select" &&
                  selectedOperator != null &&
                  selectedOperator.id != "eqother" && (
                    <Select
                      defaultValue={defaultValue}
                      onSelect={(i) => onChangeValue(selectedField.selectOptions[i])}
                      style={{ width: "200px" }}
                    >
                      {selectedField.selectOptions.map((opt, i) => (
                        <Select.Option key={i} value={i}>
                          {opt}
                        </Select.Option>
                      ))}
                    </Select>
                )}{" "}
              </React.Fragment>
            )}
            <br />
            <br />
          </React.Fragment>
        );
};

export default DependencyRule;
