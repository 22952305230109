import { Button, ButtonProps, Input, Modal, notification, Table } from "antd"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { CategoryInfo, FilteredAssemblyDebug, RuleAction, RuleExpressionStub, RuleSetDefinition } from "../api/models"
import { ConfiguratorContext } from "../context"
import { useAsyncState } from "../hook/useAsyncState"
import Utils from "../util/util"
import RuleExpressionEditor from "./Rules/expression"
import { plainToClass } from "class-transformer"
import {
  InfoCircleTwoTone
} from '@ant-design/icons';
import ViewRuleModal from "./Rules/view_rule_modal"
import { SelectionInfo } from "./Quote/AssemblySelectionTable";
import { useQuoteContext } from "../contexts/QuoteContext"

const RuleDebugOutputModal = (props: ButtonProps & {
  category: CategoryInfo | undefined
  obsoletedOptions: SelectionInfo[]
}) => {
  const configurator = useContext(ConfiguratorContext);
  const quoteContext = useQuoteContext();
  const quote = quoteContext.quoteAsync?.val;
  const modelId = quoteContext.selectedModel?.modelInfo.id;
  
  const { category: b, obsoletedOptions, ...btnProps } = props;

  const [ruleDebugOutput, ruleDebugOutputAsync] = useAsyncState<FilteredAssemblyDebug[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>();
  const [filterSelections, setFilterSelections] = useState<string>();

  const [viewRule, setViewRule] = useState<RuleAction>();

  const obsoletedOptionBoms = obsoletedOptions.map(o => o.option['bom'] || "");

  useEffect(() => {
    if (isOpen) {
      setFilterSelections(undefined);
      const selectedOptions = Object.values(quoteContext.selectedOptions || {}).flat();
      const selectedCustomOptions = quoteContext.selectedCustomOptions?.map(co => co.id);
      fetchDebugOutput(modelId, props.category?.id, selectedOptions, selectedCustomOptions, quote?.displayRevisionId);
    }
  }, [isOpen, modelId, props.category, quoteContext.selectedOptions])

  async function onClickShowRule(ruleId: number) {
    try {
      const ruleSet = await configurator.api.getActiveRuleSet();
      const ruleSetDef = plainToClass(RuleSetDefinition, JSON.parse(ruleSet.ruleSet||''));
      const rule = ruleSetDef.rules.find(r => r.ruleId == ruleId);
      if(rule) {
        setViewRule(rule);
      }
      else {
        notification.error({message: 'Rule not found in rule set.'});
      }
    }
    catch(e) {
      notification.error({message: 'Failed to load rule details.'});
    }
  }

  const fetchDebugOutput = async (modelId: number | undefined, categoryId: number | undefined, selectedOptions: number[], selectedCustomOptions:number[] | undefined, revisionId:number | undefined): Promise<FilteredAssemblyDebug[] | undefined> => {
    if (!modelId) return;
    if (!categoryId) return;

    try {

      ruleDebugOutputAsync.setLoading();
      const resp = await configurator.api.fetchCategoryOptions(modelId, categoryId, { 
        selections:selectedOptions,
        customOptions: selectedCustomOptions,
        quoteRevisionId:revisionId 
      } );
      const filteredAssemblies = resp.data.ruleDebugOutput?.filteredAssemblies;
      ruleDebugOutputAsync.setDone(filteredAssemblies);
      return filteredAssemblies;
    }
    catch (e: any) {
      console.error(e.message);
      notification["error"]({
        message: "There was an error fetching the debug info.",
        description: e.message
      });
      ruleDebugOutputAsync.setFail(e.message);
      return ruleDebugOutput;
    }
  };

  const isReady = ruleDebugOutputAsync.isDone() || ruleDebugOutputAsync.isFail();

  const filterLower = filterSelections?.toLowerCase();
  const datasource = ruleDebugOutput?.filter(s => 
    !filterLower?.length || s.bom.toLowerCase().includes(filterLower) || s.label.toLowerCase().includes(filterLower) );

  const columns = [
    {
      title: 'BOM',
      dataIndex: 'bom',
      width: "10rem",
      render: (bom: string) => 
        <>
          <span>{bom}</span>
          <div style={{color: "red", fontWeight: 'bold'}} key={bom + "-obsolete"}>
            {obsoletedOptionBoms.includes(bom) && '(Obsolete)'}
          </div>
        </>
      
    },
    {
      title: 'Name',
      dataIndex: 'label',
    },
    {
      title: 'Rule Name',
      render: (debug: FilteredAssemblyDebug) => {
        if(debug.ruleName?.length > 0) {
          return debug.ruleName;
        }
        else {
          return 'Not Set';
        }
      }
    },
    {
      title: 'Rule',
      render: (debug: FilteredAssemblyDebug) => {
        if(debug.ruleId) {
          return <>
            {debug.ruleId} &nbsp;
            <InfoCircleTwoTone type="dashed" onClick={() => onClickShowRule(debug.ruleId)}  />
          </>
        }
        else {
          return (<>{debug.ruleCategory}</>);
        }
      }
    },
    {
      title: 'Triggered Expression',
      render: (debug: FilteredAssemblyDebug) => {
        if(debug.ruleExpression) {
          const exprStub = plainToClass(RuleExpressionStub, {expression: debug.ruleExpression});
          return <RuleExpressionEditor expression={exprStub.expression} readOnly={true} onUpdate={() => {}}/>
        }
        else {
          return (<>{debug.ruleBody}</>);
        }
      }
    },
  ];

  const handleChangeFilter = (e:ChangeEvent<HTMLInputElement>) => {
    setFilterSelections( e.target.value );
  }

  return <>
    <Button {...btnProps} onClick={() => setIsOpen(true)} >Debug</Button>
    <Modal
      title={`Rule Debug Output - ${Utils.stripSortingPrefix(props.category?.name)}`}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      open={isOpen}
      width="80%"
      style={{ minWidth: "20rem" }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      {(isReady && !ruleDebugOutput?.length) && <div> No debug output is available.</div>}
      <Input placeholder="Filter by BOM or Name" value={filterSelections} onChange={handleChangeFilter} allowClear/>

      <div style={{marginBottom: '1rem'}}>{datasource?.length} items</div>
      <Table loading={ruleDebugOutputAsync.isLoading()}
        dataSource={datasource}
        columns={columns}
        rowKey={d => d.bom + "-" +  d.ruleId + "-" +  d.ruleName}
      />
      {viewRule && <ViewRuleModal rule={viewRule} onClose={() => setViewRule(undefined)}/>}
    </Modal>
  </>
}

export default RuleDebugOutputModal



