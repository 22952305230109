import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Select, Spin, Table, Tooltip, notification } from "antd";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConfiguratorContext } from "../context";
import { DashComponent, MISSING_IMAGE } from "../api/models";
import { WarningFilled } from "@ant-design/icons";

const DashComponents = () => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DashComponent[]>();
  const configurator = useContext(ConfiguratorContext);
  const [componentTypes, setComponentTypes] = useState<string[]>([]);

  useEffect(() => {
    getAllDashComponents();
    getAllComponentTypes();
  }, []);

  const getAllDashComponents = async () => {
    try {
      setLoading(true);
      const resp = await configurator.api.getDashComponents(undefined);
      setData(resp.data.sort((a: DashComponent, b: DashComponent) => b.id - a.id));
    }
    catch (e) {
      notification.error( { message: "Failed to get dash components. " + e });
    }
    finally {
      setLoading(false);
    }
  };

  const getAllComponentTypes = async () => {
    try {
      setLoading(true);
      const resp = await configurator.api.getComponentTypes();
      setComponentTypes(resp.data);
    }
    catch (e) {
      console.log(e);
      notification.error( { message: "Failed to get dash components types. " + e });
    }
    finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Part Number",
      key: "partNumber",
      width: '30%',
      render: (com: DashComponent) => (
        <>
          <Link to={"/dash-component/" + encodeURIComponent(com.id)}>{com.partNumber}</Link>
          {!com.imageUrl && <Tooltip title={"Missing image"}><WarningFilled style={{marginLeft: ".5rem", fontSize: "16px",  color: "orange" }}/></Tooltip>}
        </>
      ),
    },
    {
      title: "Description",
      key: "description",
      width: '30%',
      render: (com: DashComponent) => (
        <span>{com.description}</span>
      ),
    },
    {
      title: "Image",
      key: "image",
      render: (componet: DashComponent) => (
        <Row gutter={22}>
          {!!componet?.imageUrl && <img
            id='fullImage'
            src={componet?.imageUrl || MISSING_IMAGE}
            alt=""
            style={{ width: '20%', height: '20%' }}
          />}
          {!!componet?.iconImageUrl && <img
            id='fullImage'
            src={componet?.iconImageUrl || MISSING_IMAGE}
            alt=""
            style={{ width: '5%', height: '5%' }}
          />}
        </Row>
      ),
    },
  ];

  return (
  <div key='dashComponent' className="site-layout-background">
    <Title level={2}>
      Dash Component List
    </Title>
    <Spin spinning={loading}>
      <Row>
        <CreateDashComponentModal
          componentTypes={componentTypes}
          getAllDashComponents={getAllDashComponents}
        />
      </Row>
      <Divider />
      <Table
        bordered
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{pageSize: 50}}
      />
    </Spin>
  </div>);
};

export default DashComponents;


const CreateDashComponentModal = (props: {componentTypes: string[], getAllDashComponents: () => void}) => {

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<undefined | string>();
  const [createDashComponentForm] = Form.useForm();
  const configurator = useContext(ConfiguratorContext);
  const [showNewModal, setShowNewModal] = useState(false);
  const {componentTypes, getAllDashComponents} = props;

  const onNewDashComponentSubmit = async (values: DashComponent) => {

    try {
      setSaving(true);
      const resp = await configurator.api.createDashComponent(
        {
          id: 0,
          partNumber: values.partNumber,
          description: values.description,
          type: values.type,
        }
      );

      if (resp) {
        setSaving(false);
        onCancelNewForm();
        getAllDashComponents();
        notification.success( { message: "Successfully created dash component. " });
      }
    }
    catch (e) {
      setError(`${e}`);
    }
    finally {
      setSaving(false);
    }
  }

  const onClickNew = () => {
    createDashComponentForm.resetFields();
    setShowNewModal(true);
  };

  const onCancelNewForm = () => {
    setShowNewModal(false);
    setError(undefined);
  }

  return (
    <>
      <Col>
        <Button type="primary" onClick={onClickNew}>Create Dash Component</Button>
      </Col>
      <Modal
        open={showNewModal}
        title="New Dash Component"
        onOk={() => createDashComponentForm.submit()}
        onCancel={onCancelNewForm}
        okText={"Submit"}
        width={"30%"}
        confirmLoading={saving}
        closable={!saving}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Form
          labelCol={{ span: 6 }}
          form={createDashComponentForm}
          name="newShippingDestination"
          onFinish={onNewDashComponentSubmit}
          labelAlign={"right"}
          layout="vertical"
        >
          <Form.Item
            label="Part Number"
            name="partNumber"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Description is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Type"
            name={'type'}
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Select
              options={componentTypes.map((value) => ({
                value: value,
                key: value,
              }))}
            >
            </Select>
          </Form.Item>

        </Form>
        {error && <Alert type="error" message={"Submission Failed"}></Alert>}
      </Modal>
  </>)
}

