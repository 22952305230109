import { Button, Col, Form, Input, message, notification, Row, Space, Spin, Table, Upload } from "antd";
import Title from "antd/lib/typography/Title";
import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ConfiguratorContext } from "../context";
import { AXIOS_CANCEL_MSG, ShippingDestination, SortRequestParam } from "../api/models";
import { UploadOutlined, DownloadOutlined} from "@ant-design/icons";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import {NumberParam, StringParam, useQueryParam} from "use-query-params";
import {debounce} from "lodash";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import axios, {CancelTokenSource} from "axios";

type  ShippingDestinationSort = SorterResult<ShippingDestination> | SorterResult<ShippingDestination>[]


enum UPLOADING_STATUS {
  INIT = "init",
  ERROR = "error",
  DONE = "done",
  UPLOADING = "uploading"
}

const DEFAULT_SORT = {
  field:  'name',
  order: 'ascend'
}

const ShippingDestinationListPage = () => {
  const configurator = useContext(ConfiguratorContext);
  const history = useHistory();
  const intl = useIntl();

  const [uploadStatus, setUploadStatus] = useState(UPLOADING_STATUS.INIT);
  const [isExporting, setIsExporting] = useState<boolean>();

  const [shippingDestinationLst, shippingDestinationLstAsync] = useAsyncState<ShippingDestination[]>();
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const [sort, setSort] = useState<ShippingDestinationSort>({
    field: sortFieldQueryParam || DEFAULT_SORT.field,
    order: sortDirectionQueryParam === 'descend' ? 'descend' : 'ascend' //for typescript
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 20 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
  });


  const isUploading = UPLOADING_STATUS.UPLOADING == uploadStatus;
  const importUrl = configurator.api.getShippingDestinationsImportUrl();

  useEffect(() => {
    reloadShippingDestinations();
  }, [pagination.pageSize, pagination.current, searchFilterParam, sortFieldQueryParam, sortDirectionQueryParam  ]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  useEffect(()=> {
    if( uploadStatus == UPLOADING_STATUS.DONE ) {
      reloadShippingDestinations();
    }
    else if( uploadStatus == UPLOADING_STATUS.ERROR ) {
          message.error(  "There was an error importing the file.");
    }
  }, [ uploadStatus] )

  const reloadShippingDestinations = () => {
    loadShippingDestinations(shippingDestinationLstAsync, pagination, sort, searchFilterParam || undefined );
  }
  const loadShippingDestinations = useCallback(debounce((shippingDestinationLstAsync:AsyncState<ShippingDestination[]>,  pagination?: TablePaginationConfig, sort?:ShippingDestinationSort, filter?: string ) => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    const sorter = [ sort ].flatMap(v => v)
    .filter( v => v )
    .map( v => ({
      field: v?.field?.toString() || DEFAULT_SORT.field,
      direction: v?.order === 'descend' ? 'desc' : 'asc',
    })) as SortRequestParam[];

    const page = ( pagination?.current || 1 ) - 1;
    const size = pagination?.pageSize || 20;

    shippingDestinationLstAsync.setLoading();
    configurator.api.getShippingDestinations( {
      page,
      size,
      sort: sorter,
      filter,
    })
      .then( resp => {
        cancelTokenSourceRef.current = undefined;
        shippingDestinationLstAsync.setDone( resp.data.content );
        setPagination({ ...pagination, total: resp.data.totalElements });
      },
        (e:any) => {
          const id = e.response?.data?.message || e.message ;
          if ( id !== AXIOS_CANCEL_MSG ) {
            const errorMsg = intl.formatMessage({ id });
            notification.error( { message: "Failed to get shipping destinations. " + errorMsg });
            shippingDestinationLstAsync.setFail(e.message);
          }
        });

  }, 800 ), [ ] );

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SorterResult<ShippingDestination> | SorterResult<ShippingDestination>[]) => {
    setPagination(pagination);

    setSort(sorter);

    const firstSort = [ sorter ].flat()[0];
    if ( firstSort.field ) {
      setSortFieldQueryParam( String( firstSort.field ) );
      setSortDirectionQueryParam( firstSort.order );
    }
  };

  const onFilterChange = ( e:ChangeEvent<HTMLInputElement>) => {
    setSearchFilterParam( e.target.value );
  };



  const onUploadChange = ({file}) => {
    setUploadStatus( file.status );
  };

  const onClickNew = () => {
      history.push( "/shipping-destination/0");
  }

  const onExport = async () => {
    const url = configurator.api.getShippingDestinationsExportUrl();
    setIsExporting( true );
    try {
      await configurator.api.downloadPdf( url )
    }
    catch(e:any) {
      message.error( e.message );
    }
    setIsExporting( false );

  };

  const columns:ColumnType<ShippingDestination>[] = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      render: (_name: string, sd:ShippingDestination) => (
        <Link to={"/shipping-destination/" + encodeURIComponent(sd.id)}>{sd.name}</Link>  //get individual shipping destination
      ),
    },
    {
      title: "Miles",
      dataIndex: "miles",
      sorter: true,
      render: (miles: number) => (
        <div>{miles}</div>
      ),
    },
    {
      title: "Cents Per Mile",
      dataIndex: "centsPerMile",
      sorter: true,
      render: (centsPerMile: number) => (
        <div>{centsPerMile}</div>
      ),
    }
  ];

  return (
    <div className="site-layout-background">
      <Title level={2}>Shipping Destinations</Title>

        <Space direction="vertical">
          <Row gutter={16} >
            <Col>
              <Form>
                <Form.Item
                  label="Filter"
                >
                  <Input
                    allowClear
                    onChange={onFilterChange}
                    value={searchFilterParam || undefined}
                    placeholder="Enter text to filter list"
                  />
                </Form.Item>
              </Form>
            </Col>

            <Col>
              <Button
                onClick={onExport}
                type="primary"
                icon={<DownloadOutlined />}
                loading={isExporting}
              >Export</Button>
            </Col>

            <Col>
              <Upload
                name="file"
                action={importUrl }
                withCredentials={true}
                onChange={onUploadChange}
                showUploadList={false}
                accept=".csv"
              >
                <Spin spinning={isUploading}>
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                  >Import</Button>
                </Spin>
              </Upload>
            </Col>

            <Col>
              <Button type="primary" onClick={onClickNew}>New</Button>
            </Col>

          </Row>

          <Table bordered
            loading={shippingDestinationLstAsync.isLoading()}
            onChange={tableOnChange}
            pagination={pagination}
            dataSource={shippingDestinationLst}
            columns={columns}
            rowKey={"id"}
          />
        </Space>
    </div>
  );
};

export default ShippingDestinationListPage;

