import "../util/mobile-table.css";
import styles from "./quotes.module.css";
import Title from "antd/lib/typography/Title";
import { Table, Button, Space, TablePaginationConfig, notification, Card, Col, Tooltip, Descriptions, Row, DatePicker } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Configurator, ConfiguratorContext } from "../context";
import { Link } from "react-router-dom";
import dayjs, { Dayjs } from 'dayjs'
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../util/util";
import { DateFilterType, Permission, QUOTE_TABLE, QuoteFilter, SortDirection, AXIOS_CANCEL_MSG } from "../api/models";
import { AsyncState, useAsyncState } from "../hook/useAsyncState";
import { debounce } from 'lodash';
import { ColumnType } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import useCheckMobileScreen from '../hook/useCheckMobileScreen';
import ExportQuotesModal from "../components/Quote/ExportQuotesModal";
import _ from "lodash";
import {useIntl} from "react-intl";
import DiffRevisionModalButton from "../components/Quote/DiffRevisionModalButton";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import NewQuoteWizard from "../components/Quote/NewQuoteWizard";
import { DescriptionsItemType } from "antd/es/descriptions";
import QuoteFilterControls from "../components/QuoteFilterControls";
import axios, {CancelTokenSource} from "axios";
import GeneralOrdersInfo from "../components/widgets/GeneralInfo";
import OrderProbability from "../components/Quote/OrderProbability";
import { ListQuotesResponse } from "../api";

export type ListQuotesResponseSort = SorterResult<ListQuotesResponse> | SorterResult<ListQuotesResponse>[]
type TableOnChangeFn = (p: TablePaginationConfig, f: Record<string, FilterValue | null>, s: ListQuotesResponseSort) => void

export const getMyQuotesPermission = (configurator:Configurator) :boolean => (configurator.hasGlobalViewAccess() || 
                                                                       ( !!configurator.userInfo?.dealerName && ( configurator.hasPermission(Permission.DEALER_ADMIN_READ) || configurator.hasPermission(Permission.DEALER_MANAGEMENT_READ) ) ) );

const Quotes = (props:{
  title?:string
  defaultFilter?:QuoteFilter
}) => {
  const intl = useIntl();
  const isMobile = useCheckMobileScreen();
  const configurator = useContext(ConfiguratorContext);
  const [_quoteList, quoteListAsync] = useAsyncState<ListQuotesResponse[]>([]);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);
  const [myQuotesParam, setMyQuotesParam] = useQueryParam<boolean | undefined | null>("my", BooleanParam);
  const [archivedParam, setArchivedParam] = useQueryParam<boolean | undefined | null>("includingArchived", BooleanParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [filterAssemblies, setAssembliesFilter] = useQueryParam("assemblies", NumericArrayParam);
  const [ordersOnlyParam, setOrdersOnlyParam] = useQueryParam<boolean|undefined|null>("ordersOnly", BooleanParam);
  const [hasCustomOptionsParam, setHasCustomOptionsParam] = useQueryParam<boolean|undefined|null>("hasCustomOptions", BooleanParam);
  const [incentiveProgramsParam, setIncentiveProgramsParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [quoteStatus, setQuoteStatus] = useQueryParam("status", ArrayParam);
  const [quoteSalespersonsParam, setQuoteSalespersonsParam] = useQueryParam("salespersons", ArrayParam);
  const [quoteEngineersParam, setQuoteEngineersParam] = useQueryParam("engineers", ArrayParam);
  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [modelsParam, setModelsParam] = useQueryParam("models", NumericArrayParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const [endCustomerParam, setEndCustomerParam] = useQueryParam("endCustomerId", NumericArrayParam);
  const [shippingDestinationParam, setShippingDestinationParam] = useQueryParam("shippingDestinationId", NumericArrayParam);
  const [includingCancelledParam, setIncludingCancelledParam] = useQueryParam<boolean | undefined | null>("includingCancelledOrder", BooleanParam);
  const [draftOnlyParam, setDraftOnlyParam] = useQueryParam<boolean | undefined | null>("hasDraftRevision", BooleanParam);
  const [stateRegistrationParam, setStateRegistrationParam] = useQueryParam<(string | null)[]|undefined|null>("stateRegistration", ArrayParam);
  const [sort, setSort] = useState<ListQuotesResponseSort>({
    columnKey: 'updatedAt',
    order: 'descend'
  });
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const [quoteQuantity, setQuoteQuantity] = useState(0);

  const [filter, setFilter] = useState<QuoteFilter>({
    myQuotes: myQuotesParam ?? !getMyQuotesPermission(configurator),
    includingArchived: archivedParam || undefined,
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    ordersOnly: ordersOnlyParam || false,
    includeReservations: ordersOnlyParam || false,
    hasCustomOptions: hasCustomOptionsParam || false,
    filterAssemblies: filterAssemblies as number[] || undefined,
    quoteStatus: [quoteStatus as (string|null) || []].flat() || undefined,
    engineers: [quoteEngineersParam as (string|null) || []].flat(),
    salespersons: [quoteSalespersonsParam as (string|null) || []].flat(),
    dateFilterType: dateFilterTypeParam as DateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : undefined,
    dateFilterEnd: dateFilterStartParam ? dayjs(dateFilterEndParam) : undefined,
    endCustomerId: endCustomerParam as number[] || undefined,
    shippingDestinationId: shippingDestinationParam as number[] || undefined,
    includingCancelledOrder: includingCancelledParam || false,
    hasDraftRevision: draftOnlyParam || false,
    models: [modelsParam as (number | null) || []].flat(),
    stateRegistration: stateRegistrationParam?.filter(v=>v) as (string[] | undefined),
    ...props.defaultFilter,
  });


  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 20 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
    showQuickJumper: true,
  });

  useEffect(() => {
    loadQuotes(quoteListAsync, pagination, filter, sort);
  }, [pagination.pageSize, pagination.current, filter, sort]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current]);


  const loadQuotes = useCallback(debounce( async (quoteListAsync:AsyncState<ListQuotesResponse[]>, pagination: TablePaginationConfig, filter: QuoteFilter, sorter:ListQuotesResponseSort) => {

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "updatedAt",
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    //hide stock unless searching
    const stock = filter.search?.length ? undefined : false;

    quoteListAsync.setLoading();
    try {
      const resp = await configurator.api.listQuotes({
          ...filter,
          stock,
          page: (pagination.current || 1) - 1,
          size: pagination.pageSize || 20,
          sort,
        },
        cancelSource.token,
      )
      cancelTokenSourceRef.current = undefined;
      quoteListAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });
      setQuoteQuantity(resp.data.totalElements || 0)
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Quotes failed to load. " + errorMsg });
        quoteListAsync.setFail(e.message);
      }
    }
  }, 600), []);


  const tableOnChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: ListQuotesResponseSort) => {
    setPagination(pagination);

    setSort(sorter);
  };

  const getFilterWithDateRange = (filter: QuoteFilter) => {
    return filter['dateRange'] ? {...filter, dateFilterStart: filter['dateRange'][0], dateFilterEnd: filter['dateRange'][1]} : filter;
  }

  const onFilterChange = debounce( (_values: Record<string, any>, filter:QuoteFilter) => {
    filter = getFilterWithDateRange(filter);
    setSearchFilterParam(filter.search);
    setArchivedParam(filter.includingArchived);
    setMyQuotesParam(filter.myQuotes);
    setDealerFilterParam(filter.dealerLst);
    setAssembliesFilter(filter.filterAssemblies);
    setQuoteStatus(filter.quoteStatus);
    setOrdersOnlyParam(filter.ordersOnly);
    setHasCustomOptionsParam(filter.hasCustomOptions);
    setIncentiveProgramsParam(filter.incentivePrograms?.join(","));
    setPagination({ ...pagination, current: 1 });
    setQuoteSalespersonsParam(filter.salespersons);
    setStateRegistrationParam(filter.stateRegistration);
    setQuoteEngineersParam(filter.engineers);
    filter.dateFilterType && setDateFilterTypeParam(filter.dateFilterType);
    filter['dateRange'] && setDateFilterStartParam((filter['dateRange']?.[0]).toString());
    filter['dateRange'] && setDateFilterEndParam((filter['dateRange']?.[1]).toString());
    !!filter.endCustomerId?.length && setEndCustomerParam(filter.endCustomerId);
    !!filter.shippingDestinationId?.length && setShippingDestinationParam(filter.shippingDestinationId);
    filter.includeReservations = filter.ordersOnly;
    setIncludingCancelledParam(filter.includingCancelledOrder);
    setDraftOnlyParam(filter.hasDraftRevision);
    setModelsParam(filter.models);
    setFilter(filter);
  }, 300);

  const resetQueryParam = () => {
    !!searchFilterParam && setSearchFilterParam(undefined);
    !!archivedParam && setArchivedParam(undefined);
    !!myQuotesParam && setMyQuotesParam(undefined);
    !!dealerFilterParam?.length && setDealerFilterParam( undefined );
    !!filterAssemblies?.length && setAssembliesFilter(undefined);
    !!quoteStatus?.length && setQuoteStatus(undefined);
    !!ordersOnlyParam && setOrdersOnlyParam(undefined);
    !!hasCustomOptionsParam && setHasCustomOptionsParam(undefined);
    !!incentiveProgramsParam && setIncentiveProgramsParam(undefined);
    !!quoteSalespersonsParam?.length && setQuoteSalespersonsParam(undefined);
    !!stateRegistrationParam?.length && setStateRegistrationParam(undefined);
    !!quoteEngineersParam?.length && setQuoteEngineersParam(undefined);
    !!dateFilterTypeParam && setDateFilterTypeParam(undefined);
    !!dateFilterStartParam && setDateFilterStartParam(undefined);
    !!dateFilterEndParam && setDateFilterEndParam(undefined);
    !!endCustomerParam?.length && setEndCustomerParam(undefined);
    !!shippingDestinationParam?.length && setShippingDestinationParam(undefined);
    !!draftOnlyParam && setDraftOnlyParam(undefined);
    !!modelsParam?.length && setModelsParam(undefined);
    setIncludingCancelledParam(undefined);
  }

  return (
    <div className="site-layout-background">
        <Row>
          <Col><Title level={2}>{props.title || "Quotes"}</Title></Col>
          <Col><GeneralOrdersInfo quoteListAsync={quoteListAsync} totalQuantity={quoteQuantity} filter={filter}/></Col>
        </Row>
        <QuoteFilterControls
          filter={filter}
          onFilterChange={onFilterChange}
          resetQueryParam={resetQueryParam}
          tableName={QUOTE_TABLE}
        />
        {isMobile
          ? <MobileTable
            quoteListAsync={quoteListAsync}
            tableOnChange={tableOnChange}
            pagination={pagination}
          />
          : <DesktopTable
            quoteListAsync={quoteListAsync}
            tableOnChange={tableOnChange}
            pagination={pagination}
            filter={filter}
            sort={sort}
          />
        }

    </div>
  );
};

const DesktopTable = (props: {
  quoteListAsync: AsyncState<ListQuotesResponse[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
  filter:QuoteFilter
  sort:ListQuotesResponseSort
}) => {
  const { quoteListAsync, tableOnChange, pagination } = props;
  const quoteList = quoteListAsync.val;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  const hasComparePermissions = configurator.isSalesDesk() || configurator.isAdmin() || configurator.isEngineering();
  const warningTextStyle = {color: "red", fontWeight: 'bold'};


  const updateOrderExpectation = async (quoteRevisionId:number, orderProbability:string | undefined, expectedOrderDate:Date | undefined ) => {

    quoteListAsync.setLoading()
    try {

      const resp = await configurator.api.updateOrderExpectation(quoteRevisionId, {
        orderProbability,
        expectedOrderDate,
      });
      const updatedQuote = resp.data;

      const ndx = quoteList?.findIndex( q => q.displayRevisionId === quoteRevisionId ) ?? -1;
      
      if ( ndx !== -1 ) {
        quoteList?.splice(ndx, 1, updatedQuote)
      }

      quoteListAsync.setDone(quoteList);

      notification.success({message: "Order expectation updated"})

    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg =  "Failed to update order expectation. " + errorMsg
      notification.error( { message: msg });
      quoteListAsync.setFail(msg);
    }
  }


  const handleChangeExpectedOrderDate = async (quote: ListQuotesResponse, expectedOrderDate: Dayjs | undefined ) => {
    updateOrderExpectation( quote.displayRevisionId, quote.orderProbability, expectedOrderDate?.toDate() );
  }

  const handleChangeProbability = async (quote: ListQuotesResponse, orderProbability: string | undefined ) => {
    updateOrderExpectation( quote.displayRevisionId, orderProbability, quote.expectedOrderDate );
  }

  const columns: ColumnType<ListQuotesResponse>[] = [
    {
      title: "Quote Details",
      key: "quoteId",
      render: (q) => <BaseQuoteDescription quote={q} />,
      sorter: true,
    },
    {
      title: "Model",
      dataIndex: "modelName",
      key: "latestRevision.model.name",
      sorter: true,
      render: (_, q) => <span>{q.model.name}</span>,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "latestRevision.quantity",
    },
    {
      title: "Status",
      key: "status",
      render: (q) => <>
        <div className={styles["quote-status"]} style={{ textTransform: "none" }}>
          {Utils.formatQuoteStatus(q)}
        </div>
        {!!q.archived && <div style={warningTextStyle}>(Archived)</div>}
      </>,
      sorter: true,
      width: "10rem"
    },
    {
      title: "Sales Person",
      key: "owner",
      render: (_owner, q) => Utils.formatUsername(q.owner),
      sorter: true,
      width: "10rem"
    }];

  if ( configurator.isInternalSales() ) {
    columns.push({
      title: "Engineer",
      key: "salesTeam.engineerMembers.user.name",
      render: (_owner, q) => q.salesTeam?.engineers?.map(u => u.name).join(", "),
      sorter: true,
      width: "10rem"
    })
  }

  columns.push({
    title: "Production Date",
    key: "latestRevision.summary.productionDate",
    sorter: true,
    render: (q) => q.productionDate ? dayjs(q.productionDate).format("MM/DD/YYYY") : undefined,
  })

  if ( configurator.isInternalSales() && !configurator.isEngineering() ) {
    columns.push({
      title: "Forecast",
      key: "expectedOrderDate",
      render: (q) => <>
        <DatePicker value={q.expectedOrderDate ? dayjs(q.expectedOrderDate) : undefined} onChange={(dt) => handleChangeExpectedOrderDate(q, dt)} bordered={false} />
        <OrderProbability value={q.orderProbability} onChange={(o) => handleChangeProbability( q, o )} bordered={false} />
      </>,
      sorter: true,
      width: "10rem"
    })
  }

  columns.push({
    title: "Last Updated",
    key: "updatedAt",
    render: (_updatedAt, q) => (
      <span>{dayjs(q.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}</span>
    ),
    sorter: true,
    defaultSortOrder: "descend",
    width: "9rem"
  });


  //quick fix to handle no elements
  const topButtonSection: any = pagination.total
    ? { position: "absolute", top: "1rem", zIndex: 1, right: "0px" }
    : { float: "right", marginBottom: "1rem", marginTop: "1rem" };

  return <>
    <div style={{ position: "relative" }}>
      <Col style={topButtonSection}>
        <Space direction="horizontal">
          <ExportQuotesModal sort={props.sort} filter={props.filter} modulename={QUOTE_TABLE}/>
          {hasComparePermissions && <DiffRevisionModalButton btnLabel="Compare" title="Quote Comparison" className="" type="primary"  /> }
          <NewQuoteWizard type="primary" >New</NewQuoteWizard>
        </Space>
      </Col>
      <Table
        data-testid="quoteListTable"
        loading={quoteListAsync.isLoading()}
        onChange={tableOnChange}
        bordered
        pagination={pagination}
        dataSource={quoteList}
        columns={columns}
        rowKey="quoteId"
      />
    </div>
  </>
}

const MobileTable = (props: {
  quoteListAsync: AsyncState<ListQuotesResponse[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
}) => {
  const { quoteListAsync, tableOnChange, pagination } = props;
  const quoteList = quoteListAsync.val;

  const gotoQuote = (q:ListQuotesResponse) => window.open("/inventory/" + encodeURI(q.quoteId), "_blank");

  const columns: ColumnType<ListQuotesResponse>[] = [
    {
      render: (_val, quote, _ndx) => {
        return <>
          <Card
            hoverable={true}
            onClick={() => gotoQuote(quote)}
            title={<>
              <div className={styles["quote-name"]} style={{whiteSpace: "normal"}}>{quote.name}</div>
              <div className={styles["quote-id"]}>({quote.quoteId})</div>
            </>
            }
          >
            <div className="section" style={{ maxWidth: "17rem" }}>
              <div className={styles["quote-status"]}>
                {Utils.formatQuoteStatus(quote)}
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "3rem" }}>
              <div className="section">
                <div>Salesperson:</div>
                <div><span>{quote?.owner?.name}</span></div>
                <div><span>{dayjs(quote.updatedAt).format("MMMM Do YYYY")}</span></div>
              </div>

              {quote?.vin &&
                <div className="section">
                  <div>VIN:</div>
                  <div><span>{quote?.vin}</span></div>
                </div>
              }

              {quote?.serialNumberStr &&
                <div className="section">
                  <div>Serial No:</div>
                  <div><span>{quote?.serialNumberStr}</span></div>
                </div>
              }

            </div>
          </Card>
        </>;
      },
    },

  ];

  //quick fix to handle no elements
  const newButtonStyle: any = pagination.total
    ? { position: "absolute", top: "1rem", zIndex: 1, right: "0px" }
    : { float: "right", marginBottom: "1rem" };


  return <>
    <div style={{ position: "relative" }}>
      <Link to="/configurator">
        <Button type='primary' style={newButtonStyle} >New</Button>
      </Link>
      <Table
        data-testid="quoteListTable"
        loading={quoteListAsync.isLoading()}
        onChange={tableOnChange}
        pagination={pagination}
        dataSource={quoteList}
        columns={columns}
        rowKey="id"
        className="mobile-table"
      />
    </div>
  </>


}

export const BaseQuoteDescription = (props:{
  quote:ListQuotesResponse
  hideTruckDescription?:boolean
}) => {

  const {quote:q} = props;

  const title = q.name || q.truckDescription || q.quoteId;
  const descriptionItems = [] as DescriptionsItemType[];
  if ( title !== q.quoteId) descriptionItems.push( { key: "quoteId", label:"Quote Id", children: <span style={{whiteSpace: "nowrap"}}>{q.quoteId}</span> });
  if ( q.partNumberString?.length ) descriptionItems.push( { key: "partNo", label:"Part No.", children: <span style={{whiteSpace: "nowrap"}}>{q.partNumberString}</span> });
  if ( q.serialNumberStr?.length ) descriptionItems.push( { key: "serialNo", label:"Serial No.", children: <span style={{whiteSpace: "nowrap"}}>{q.serialNumberStr}</span> });

  const isLastUpdateHalfYearAgo = (q: ListQuotesResponse) => {
    return dayjs(q.updatedAt).isBefore(dayjs(new Date()).subtract(6, 'month'));
  }

  return <>
    <div key={q.quoteId} style={{marginBottom: ".4rem"}}>
      <Link to={"/configurator/" + encodeURI(q.quoteId)}>{title}</Link>
      {isLastUpdateHalfYearAgo(q) && <Tooltip title={`Please archive the inactive quote. Last updated is on ${dayjs(q.updatedAt).format("MMM Do, YYYY")}`}>
        <ExclamationCircleOutlined style={{color: "orange", marginLeft: "5px"}}/>
      </Tooltip>}
    </div>
    <Descriptions 
      column={1}
      size="small"
      items={descriptionItems}
      style={{minWidth: "14rem"}}
    />
    {(!props.hideTruckDescription && !!q.truckDescription?.length && title !== q.truckDescription) &&
      <div>{q.truckDescription}</div> }
  </>;
}

export default Quotes;
