export class NotFoundError {
  message: string;
  code: string;

  constructor() {
    this.message = "error.notFound";
    this.code = "404";
  }
}

export class PermissionError {
  message: string;
  code: string;

  constructor() {
    this.message = "error.accessDenied";
    this.code = "403";
  }
}

export class BadRequestError {
  message?: string;
  code?: string;

  constructor(code: string, message: string) {
    this.message = message;
    this.code = code;
  }

  public toString(): string {
    var s = this.code ? this.code : "";

    if (this.message) {
      s += " " + this.message;
    }

    return s;
  }
}
