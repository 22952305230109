import { Col, Row, Tooltip } from "antd";
import { useState } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import { PhoneOutlined, MailOutlined, CheckOutlined } from "@ant-design/icons";

const CopyContact = (props: {emailToCopy?: string, phoneToCopy?: string}) => {

  const [copiedPhone, setCopiedPhone] = useState<string | undefined>();
  const [copiedEmail, setCopiedEmail] = useState<string | undefined>();

  const handleCopy = async (text: string | undefined, setCopyStatus: (val: string | undefined) => void) => {
    if (!text) return;
    try {
      await navigator.clipboard.writeText(text);
      setCopyStatus(text);
  
      setTimeout(() => {
        setCopyStatus(undefined);
      }, 2000);
    } catch (err) {
    }
  };

  const copyStyle = {
    style: { cursor: 'pointer', marginLeft: 8, color: "#1677ff" }
  }

  return (
  <Row>
    <Col>
      {props.emailToCopy ? 
          <Paragraph style={{marginTop: "6px"}}>
            <Tooltip title={copiedEmail ? "Copied" : "Copy"}>
              {!copiedEmail ? 
                <MailOutlined {...copyStyle} onClick={() => handleCopy(props.emailToCopy || "", setCopiedEmail)}/>
                :
                <CheckOutlined {...copyStyle}/>
              }
            </Tooltip>
          </Paragraph>
          :
          <></>
      }
    </Col>

    <Col>
      {props.phoneToCopy ? 
          <Paragraph style={{marginTop: "6px"}}>
            <Tooltip title={copiedPhone ? "Copied" : "Copy"}>
              {!copiedPhone ? 
                <PhoneOutlined {...copyStyle}  onClick={() => handleCopy(props.phoneToCopy || "", setCopiedPhone)}/>
                :
                <CheckOutlined {...copyStyle}/>
              }
            </Tooltip>
          </Paragraph>
          :
          <></>
      }
    </Col>
  </Row>
  );
}

export default CopyContact;
