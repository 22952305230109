import { Tabs, Typography } from "antd";
import PricingConfigView from "../components/PricingConfigForm";
import PricingHistory from "../components/PricingHistory";
const { Title } = Typography;

const PricingPage = () => {

  const panels = [
    { label: 'Active', key: 'item-1', children:<PricingConfigView/>  }, 
    { label: 'History', key: 'item-2', children: <PricingHistory/> },
  ];

  return <>
    <div className="site-layout-background">
      <Title level={2}>Pricing</Title>
      <Tabs 
        defaultActiveKey="item-1"
        items={panels} 
      />
    </div>
  </>
};

export default PricingPage;
