import React from "react";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {BaseCategory, CategoryRuleConfig, DependencyRule as CategoryDependencyRule} from "../api/models";
import DependencyRule from "./dependency_rule";

export interface EditCategoryRuleConfig extends CategoryRuleConfig {
  uuid: string
  rulesErrorLst?:string[]
  whenRulesErrorLst?:string[]
}

export interface NewCategoryRuleConfig {
  category?:number
  rules?:CategoryDependencyRule[]
  whenRules?: CategoryDependencyRule[]
  rulesErrorLst?:string[]
  whenRulesErrorLst?:string[]
}

const DependencyCriteria = (props:{
  whenCategory?:BaseCategory
  showWhenRules?:boolean
  initialState:EditCategoryRuleConfig | NewCategoryRuleConfig | undefined
  onUpdateCriteria: (cri:CategoryDependencyRule[], wcri?:CategoryDependencyRule[]) => void
  categories:BaseCategory[] | undefined
}) => {
  const categories = props.categories;
  const whenCriteria =  props.initialState?.whenRules || [];
  const criteria = props.initialState?.rules || [];
  const selectedCategory = categories?.find((c) => c.id == props.initialState?.category)

  const setWhenCriteria = ( wcri:CategoryDependencyRule[] ) => {
    props.onUpdateCriteria( criteria, wcri );
  }

  const setCriteria = ( cri:CategoryDependencyRule[] ) => {
    props.onUpdateCriteria( cri, whenCriteria );
  }

  const onUpdateWhenRule = (idx:number, rule:CategoryDependencyRule) => {
    const newCriteria = [...whenCriteria];
    newCriteria[idx] = rule;
    setWhenCriteria(newCriteria);
  };

  const onDeleteWhenCriteria = (idx:number) => {
    const newCriteria = [...whenCriteria];
    newCriteria.splice(idx, 1);
    setWhenCriteria(newCriteria);
  };

  const onWhenCriteriaNew = () => {
    setWhenCriteria(whenCriteria.concat([{}]));
  };

  const onUpdateRule = (idx:number, rule:CategoryDependencyRule) => {
    const newCriteria = [...criteria];
    newCriteria[idx] = rule;
    setCriteria(newCriteria);
  };

  const onDeleteCriteria = (idx:number) => {
    const newCriteria = [...criteria];
    newCriteria.splice(idx, 1);
    setCriteria(newCriteria);
  };

  const onCriteriaNew = () => {
    setCriteria(criteria.concat([{}]));
  };

  return (
    <div>
      {selectedCategory && (
        <React.Fragment>
          {props.showWhenRules && (
            <div>
              <strong>
                When items in {props.whenCategory?.name} match the following{" "}
                <PlusCircleOutlined onClick={onWhenCriteriaNew} />{" "}
              </strong>{" "}
              <br />
              <br />
              {whenCriteria.map((c, idx) => (
                <div key={idx}>
                  <DeleteOutlined onClick={() => onDeleteWhenCriteria(idx)} />{" "}
                  &nbsp;
                  <DependencyRule
                    initialState={c}
                    onUpdateRule={(rule:CategoryDependencyRule) => onUpdateWhenRule(idx, rule)}
                    category={props.whenCategory}
                  />
                </div>
              ))}
              {!!props.initialState?.whenRulesErrorLst?.length && <>
                <ul style={{color: "red", fontWeight: "600"}}>
                  {props.initialState?.whenRulesErrorLst?.map( (e,ndx) => <li key={ndx}>{e}</li> )}
                </ul>
              </>}
            </div>
          )}
          <div>
            <strong>
              {props.showWhenRules
                ? "Then include assemblies from " +
                  selectedCategory.name +
                  " that match: "
                : "Rules "}
              <PlusCircleOutlined onClick={onCriteriaNew} />{" "}
            </strong>{" "}
            <br />
            <br />
            {criteria.map((c, idx) => (
              <div key={idx}>
                <DeleteOutlined onClick={() => onDeleteCriteria(idx)} /> &nbsp;
                <DependencyRule
                  initialState={c}
                  onUpdateRule={(rule:CategoryDependencyRule) => onUpdateRule(idx, rule)}
                  category={selectedCategory}
                  otherCategory={props.whenCategory}
                />
              </div>
            ))}
            {!!props.initialState?.rulesErrorLst?.length && <>
              <ul style={{color: "red", fontWeight: "600"}}>
                {props.initialState?.rulesErrorLst?.map( (e,ndx) => <li key={ndx}>{e}</li> )}
              </ul>
            </>}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default DependencyCriteria;
