import React, { useContext, useEffect, useState } from "react";
import { Category } from "../api/models";
import { ConfiguratorContext } from "../context";
import { Form, Select, Spin } from "antd";
import { loadavg } from "os";
import RulesContext from "./Rules/context";

export interface Props {
  onSelectCategory?: (category: Category) => void;
  selectedCategory?: string;
  onChange?: (categoryOrCategoryIds: any) => void;
  style?: any;
  selectionMode?: "multiple"|"tags";
}

const CategorySelector2 = (props: Props) => {
  const rulesContext = useContext(RulesContext);

  return (
    <Select
      onChange={(c) => {
        const cat = rulesContext.categories.find(cat => cat.categoryId == c);
        if(props.onSelectCategory && cat) props.onSelectCategory(cat);
      }}
      defaultValue={props.selectedCategory}
      showSearch={true}
      style={props.style}
      mode={props.selectionMode}
      optionFilterProp="children"
      allowClear={true}
      filterOption={(input, option) => {
        if (option && option.children) {
          return option.children
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase());
        } else {
          return false;
        }
      }}
    >
      {rulesContext.categories.map((cat) => (
        <Select.Option key={cat.categoryId} value={cat.categoryId}>
          {cat.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CategorySelector2;
