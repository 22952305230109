import { useState } from "react";
import { Button, Upload, ButtonProps, Modal, Result } from "antd";
import {
  UploadOutlined,
} from "@ant-design/icons";
import {ProcessError} from "../api/models";

enum UPLOADING_STATUS {
  INIT = "init",
  ERROR = "error",
  DONE = "done",
  UPLOADING = "uploading"
}

export type UploadFailure = ProcessError;
interface UploadFile {
  name?: string
  status:string 
  response?:any
}
const UploadButton = (props:ButtonProps & {
  action: string
  showResults?:boolean
  onDone?: (v:UploadFile) => void
  onUploading?: (v:UploadFile) => void
  data?: Record<string, unknown>
}) => {

  const { onDone, onUploading, action, showResults, data, ...btnProps } = props;
  const [uploadFile, setUploadFile] = useState<UploadFile>({status: UPLOADING_STATUS.INIT});


  const handleUploadChange = ({file}) => {
    setUploadFile( file );

    if ( !showResults  &&
        ( new Array<string>(UPLOADING_STATUS.DONE, UPLOADING_STATUS.ERROR).includes(file.status) ) ) {

      handleUploadComplete(file);
    }
    if ( file.status === UPLOADING_STATUS.UPLOADING ) {
      onUploading?.(file); 
    }

    return file.response;
  };

  const handleUploadComplete = (fileParam?:UploadFile) => {

    var file = fileParam || uploadFile;
    setUploadFile({status: UPLOADING_STATUS.INIT});
    onDone?.(file);
  };

  const isUploading = UPLOADING_STATUS.UPLOADING == uploadFile.status;

  return <>
    <Upload
      name="file"
      action={action}
      onChange={handleUploadChange}
      withCredentials={true}
      showUploadList={false}
      data={data}
    >
      <Button {...btnProps}
        icon={<UploadOutlined />}
        loading={ btnProps.disabled || isUploading}
      >
        {props.children}
      </Button>
    </Upload>
    {showResults &&
    <UploadResultModal 
      status={uploadFile.status}
      onDone={handleUploadComplete}
      messages={uploadFile?.response}
    />
    }
  </>
}

const UploadResultModal  = (props: {
  status: string
  messages?: UploadFailure[]
  onDone: () => void
}) => {

    return <Modal 
      open={new Array<string>(UPLOADING_STATUS.DONE, UPLOADING_STATUS.ERROR).includes(props.status)}
      onOk={props.onDone}
      onCancel={props.onDone}
      cancelButtonProps={{style :{display : 'none'}}}
      style={{minWidth: "40rem"}}
    >
      <Result
        status={props.status === UPLOADING_STATUS.DONE ? "success" : "error"}
        title={props.status == UPLOADING_STATUS.DONE ? "Upload Successful" : "Error"}
        subTitle={ !!props.messages?.length && <div style={{textAlign: "left"}}>
          <div>The following records had errors:</div>
          <ul>
            {props.messages?.map( (err,ndx) => <li key={`upload-msg-${ndx}`}>{err.message}</li>)}
          </ul>
        </div> }
      />
    </Modal>;
}


export default UploadButton;

