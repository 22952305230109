import {useContext, useEffect, useState} from "react";
import Utils from "../../util/util";
import BMButton, {BMButtonProps} from "../BMButton";
import { Quote, SalesTeam, User } from "../../api/models"
import { Button, Form, notification, Row } from "antd";
import CopyContact from "../CopyContact";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { useIntl } from "react-intl";
import { ConfiguratorContext, UserListContext, UsersContext } from "../../context";
import { SalesTeamRequest } from "../../api";
import ModalWizard from "../ModalWizard";
import UserSelection from "../UserSelection";
import { SalesTeamDescription, SelectSalesTeam } from "../SelectSalesTeamButton";

type QuoteOwnerAssignmentProps =  Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  id?:string
  value?: User
  onChange?: (v: User | undefined) => void
};

const QuoteOwnerAssignment = (props: QuoteOwnerAssignmentProps) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const owner = props.value;

  //hide border when disabled
  const btnStyle =  ( props.disabled )
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  //remove other props for button props
  const { id, value, onChange, ...btnProps } = props;


  const btnLbl =  ( owner) ? Utils.formatUsername(owner)
    : props.disabled ? "None"
    : "Add Owner";

  return <>
    <Row>
      <BMButton type="text"
        className="ghostBmButton"
        {...btnProps}
        data-testid="owner-button"
        onClick={() => setIsOpen(true)} 
        style={{padding: "0"}}
      ><span style={btnStyle}>{btnLbl}</span></BMButton>
      <CopyContact emailToCopy={owner?.email} phoneToCopy={owner?.phoneNumber}/>
    </Row>

    <QuoteAssignModal
      open={isOpen}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
    />

  </>;

}

const QuoteAssignModal = (props: {
  open: boolean;
  onOk: (o?: User | undefined, salesTeam?: SalesTeam | undefined) => void;
  onCancel: () => void;
  skip?: boolean;
}) => {

  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const { userLstAsync, loadUserList } = useContext<UserListContext>(UsersContext);
  const userLst = userLstAsync?.val;
  const [owner, setOwner] = useState<User>();
  const [salesTeam, setSalesTeam] = useState<SalesTeam | undefined>();

  useEffect(()=> {
    if ( ( userLstAsync?.isInitial() || userLstAsync?.isFail() ) && !userLstAsync.isLoading() )  {
      loadUserList?.();
    }
  }, [userLstAsync?.state] );

  useEffect(() => {
    var quoteOwner = quote?.owner || userLst?.find( u => u.id === configurator.userInfo?.id );
    setOwner( quoteOwner );
    setSalesTeam(quote?.salesTeam);
  }, [props.open])

  const buildSalesTeamRequest = (salesTeam:SalesTeam | undefined) : SalesTeamRequest | undefined => {
    if ( !salesTeam ) return;

    return {
      id: salesTeam.id,
      name: salesTeam.name,
      sales: salesTeam.sales?.map( u => u.id ),
      engineers: salesTeam.engineers?.map( u => u.id ),
      support: salesTeam.support?.map( u => u.id ),
    };
  }


  const isDealer = !!owner?.dealerName;
  const ownerLst = userLst;

  const handleSave = async (ownerId: string, salesTeam:SalesTeam | undefined) : Promise<Quote|undefined> => {
    if ( !quote) return;

    const salesTeamDto = buildSalesTeamRequest(salesTeam)

    try {
      quoteAsync.setLoading();
      const resp = await configurator.api.reassignQuoteOwner(Number(quote?.id), ownerId, salesTeamDto)
      quoteAsync.setDone(resp.data);
      notification.success({ message: "Quote Reassigned." });

      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to assign quote. " + errorMsg });
      quoteAsync.setFail(e.message);
    }

    return;
  }

  const handleOk = async () => {

    if ( isDealer && !salesTeam ) {
      notification.error( { message: "A battle motors sales representative is required." });
      return;
    }

    if ( quote && owner ) {
      const resp = await handleSave( owner.id, salesTeam );
      if( resp ) {
        props.onOk( owner, salesTeam  );
      }
    }

  }

  const handleCancel = () => {
    props.onCancel();
  }

  return <ModalWizard
    open={props.open}
    width={"50rem"}
    onCancel={handleCancel}
    showSteps={false}
    steps={[
      {
        key: 1,
        title:(_nav) => "Assign Owner",
        hidden: props.skip,
        body:(_nav) => <div>
          <UserSelection
            value={owner?.id}
            options={ownerLst || []}
            loading={userLstAsync?.isLoading()}
            onChange={(user:User|undefined) => {
              if( user ) {
                setOwner( user );
                setSalesTeam(undefined);
              }
            }}
          />
        </div>,
        footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
          <Button key="next" disabled={!owner} type="primary" onClick={nav.nextStep}>Next</Button>
          <Button key="cancel" onClick={handleCancel}>Cancel</Button>
        </div>
      },
      {
        key: 2,
        title:(_nav) => "Assign Sales Team",
        hidden: !owner?.dealerId,
        body:(_nav) => <div>
          <SelectSalesTeam
            filter={{
              dealerId: owner?.dealerId ,
              isUserSalesTeam: owner?.dealerId ? undefined : true
            }}
            value={salesTeam}
            onChange={(st:SalesTeam|undefined) => {
              if( st ) {
                setSalesTeam(st );
              }
            }}

          />
        </div>,
        footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
          <Button key="next" type="primary" disabled={!salesTeam} onClick={nav.nextStep}>Next</Button>
          {props.skip 
            ? <Button key="cancel" onClick={handleCancel}>Cancel</Button>
            : <Button key="back" onClick={nav.prevStep}>Back</Button>}
        </div>
      },
      {
        key: 3,
        title:"Confirm Assignment",
        body: (_nav) => <div>
          <Form.Item label="Salesperson:"
            labelAlign="right" 
            labelCol={{span:8}}
            style={{marginTop: "3rem"}}
          >
            {Utils.formatUsername(owner)}
          </Form.Item >

          <Form.Item label="Sales Team:" 
            labelAlign="right" 
            labelCol={{span:8}}
            hidden={!owner?.dealerId}
          >
            <SalesTeamDescription salesTeam={salesTeam} /> 
          </Form.Item >
        </div>,
        footer:(nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
          <Button key="save" type="primary" loading={quoteAsync?.isLoading()} onClick={handleOk}>Save</Button>
          <Button key="back" onClick={nav.prevStep}>Back</Button>
        </div>
      }
    ] }
    />

};


export default QuoteOwnerAssignment
