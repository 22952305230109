import { Input, } from "antd";

const readOnlyInputStyle = {color: "black", background: "inherit"};

const ReadOnlyInput = (props:{
  value?:string|number|undefined,
  style?:any,
}) => {

  const style = {...readOnlyInputStyle, ...props.style };

  return <>
    <Input 
      disabled={true}
      value={props.value}
      style={style}
      bordered={false}
    />
  </>
}

export default ReadOnlyInput;

