import { Input, Modal, notification } from "antd"
import { useContext, useState } from "react";
import { ConfiguratorContext } from "../../context";
import RuleSetSelector from "./rule_set_selector";

export interface Props {
    onFinished: () => void;
    onCancel: () => void;
}

const CreateRuleSetModal = (props: Props) => {
    const configurator = useContext(ConfiguratorContext);
    const [isCreating, setIsCreating] = useState(false);
    const [name, setName] = useState('');
    const [selectedRuleSetId, setSelectedRuleSetId] = useState<number>();

    async function onOk() {
        if(!name || name.length == 0) {
            alert('Name is required.');
            return;
        }

        try {
            setIsCreating(true);
            await configurator.api.createRuleSet({
                name: name,
                copyFromRuleSetId: selectedRuleSetId,
            });
            notification.success({message: 'Rule set created.'});
            props.onFinished();
        }
        catch(e) {
            setIsCreating(false);
            notification.error({message: 'Failed to create rule set'});
        }
    }

    return (
        <Modal open onOk={onOk} onCancel={props.onCancel} title="Create Rule Set" confirmLoading={isCreating}>
            <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Rule Set Name" /> <br/><br/>
            Copy from Rule Set: <br/>
            <RuleSetSelector selectedRuleSetId={selectedRuleSetId} onSelect={(ruleSet) => setSelectedRuleSetId(ruleSet.id)}/>
        </Modal>
    )
};

export default CreateRuleSetModal;
