import {Button, Form, Input, notification, } from "antd";
import Table, {TablePaginationConfig} from "antd/es/table";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {ConfiguratorContext } from "../../context";
import BMButton, {BMButtonProps} from "../BMButton";
import ModalWizard from "../ModalWizard";
import Utils from "../../util/util";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import {AXIOS_CANCEL_MSG, CategoryInfo, EngineeringTeam, EngineeringTeamInfo } from "../../api/models";
import {debounce} from "lodash";
import {SortOrder, SorterResult, FilterValue} from "antd/es/table/interface";
import {EngineeringTeamFormValues, EngineeringTeamForm} from "./EditEngineeringTeamButtonModal";
import { WizardInstance } from "../Wizard";
import axios, {CancelTokenSource} from "axios";
import Paragraph from "antd/lib/typography/Paragraph";
import { NewEngineeringTeamRequest } from "../../api";
type  SortResult<T> = SorterResult<T> | SorterResult<T>[]
export type EngineeringTeamSort = SorterResult<EngineeringTeam> | SorterResult<EngineeringTeam>[]

interface EngineeringTeamFilter {
  search?: string
}

const DEFAULT_PAGE_SIZE = 5;

const defaultSort = {
  columnKey: 'name',
  order: 'ascend' as SortOrder
};

const SelectEngineeringTeamsButtonModal = (props: Omit<BMButtonProps, "id" | "value" | "onChange" > & {
  value?:EngineeringTeamInfo | undefined
  onChange?: (t:EngineeringTeam | undefined) => void
  category?: CategoryInfo
  hideRemove?:boolean
}) => {

  const {value:a, onChange:b, ...btnProps} = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [_selectedEngineeringTeam, selectedEngineeringTeamAsync] = useAsyncState<EngineeringTeam>();
  const [selectedRow, setSelectedRow] = useState<number | undefined>(props.value?.id);
  const [engineeringTeamLst, engineeringTeamLstAsync] = useAsyncState<EngineeringTeam[]>([]);
  const [filter, setFilter] = useState<EngineeringTeamFilter>({});
  const [sort, setSort] = useState<SortResult<EngineeringTeam>>(defaultSort);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["bottomLeft"],
    pageSize:  DEFAULT_PAGE_SIZE,
    current: 1,
  });

  useEffect(() => {
    if ( isOpen ) {
      reloadEngineeringTeams();
    }
  }, [pagination.pageSize, pagination.current, filter, sort]);


  const reloadEngineeringTeams = async () : Promise<EngineeringTeam[] | undefined> => {
    return loadEngineeringTeams( engineeringTeamLstAsync, pagination, filter , sort );
  }
  const loadEngineeringTeams = useCallback(debounce( async (engineeringTeamLstAsync:AsyncState<EngineeringTeam[]>, pagination: TablePaginationConfig, filter: EngineeringTeamFilter, sorter:SortResult<EngineeringTeam> ) : Promise<EngineeringTeam[] | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    var sort = [ sorter ].flat()[0];
    engineeringTeamLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchEngineeringTeamList({
        ...filter,
        page: ( pagination.current || 1 ) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort: {
          field: sort.columnKey?.toString() ||  defaultSort.columnKey,
          direction: sort.order == 'descend' ? 'desc' : 'asc',
        }
      })
      cancelTokenSourceRef.current = undefined;

      engineeringTeamLstAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
      return resp.data.content;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error({message:"Engineering Teams failed to load. " + errorMsg});
        engineeringTeamLstAsync.setFail( e.message );
      }
    }

  }, 800 ), [] );

  const createEngineeringTeam = async (engineeringTeam:NewEngineeringTeamRequest) : Promise<EngineeringTeam | undefined> => {

    selectedEngineeringTeamAsync.setLoading()
    try {
      const resp = await configurator.api.createEngineeringTeam(engineeringTeam)
      selectedEngineeringTeamAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to create engineering Team. " + errorMsg;
      notification.error( { message: msg });
      selectedEngineeringTeamAsync.setFail(msg);
    }

    return;
  }

  const handleChangeSearch = (search:string) => {
    const filter = {
      search
    };
    setFilter(filter);
    loadEngineeringTeams( engineeringTeamLstAsync, pagination, filter, sort );
  }

  const handleDone = () => {
    const selectedEngineeringTeam = engineeringTeamLst?.find(t => t.id === selectedRow );
    props.onChange?.(selectedEngineeringTeam);
    setIsOpen(false);
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  const handleNew = (nav:WizardInstance) => {

    //allow for render
    setTimeout(() => {
      form.resetFields();
      nav.nextStep();
    });
  }

  const handleRemove = () => {
      props.onChange?.(undefined);
      setIsOpen(false);
  }

  const handleSave = async () => {

    try {
      const values = await form.validateFields() as EngineeringTeamFormValues;
      const dto = {
        ...values,
        categories: values.categories?.map( c => c.id ),
        members: values.members?.map( u => u.id ),
      };

      const team = await createEngineeringTeam(dto);

      props.onChange?.(team);
      setIsOpen(false);
    }
    catch(e:any) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const handleSelectRow = (record:EngineeringTeam) => {
    setSelectedRow(record.id);
  }

  const tableOnChange = (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, sorter: EngineeringTeamSort) => {
    setPagination(pagination);

    setSort(sorter);
  };


  const buildTeamName = (t:EngineeringTeam) : string | undefined =>
    t.name || 
    t.categories?.map(c => Utils.stripSortingPrefix(c.name)).join(", ") ||
    t.members?.map(u => u.name ).join(", ");

  const btnTitle = props.children || props.value?.name || "None Assigned";

  const btnStyle = {borderBottom: "1px solid black"};

  return <>
    <BMButton type="text"
      className="ghostBmButton"
      style={{padding: 0}}
      {...btnProps}
      onClick={() => setIsOpen(true)} 
    ><span style={btnStyle}>{btnTitle}</span></BMButton>

      <ModalWizard
        open={isOpen}
        width={"60rem"}
        onCancel={handleCancel}
        showSteps={false}
        afterOpenChange={(open) => {
          if (open) {
            if (engineeringTeamLstAsync?.isInitial() && !engineeringTeamLstAsync.isLoading() ) {
              reloadEngineeringTeams();
            }
          }
        } }
        steps={[
          {
          key:1,
            title: "Select Engineering Team",
            body: (nav) => <div key="engineeringTeamSelection">
              <div style={{display: "flex", flexDirection: "row-reverse", marginBottom: "1rem", gap: ".4rem"}}>
                <Button type="primary" onClick={() => handleNew(nav)} >New</Button>
              </div>

              <div style={{width: "100%"}}>
                <Input onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search teams" />
              </div>

              <style>
                {`
                  .dialog-engineeringTeamLst .ant-table-content { padding: 5px; } /* don't clip corners */
                    .dialog-engineeringTeamLst .ant-table-cell { border: none !important; } /* remove table cell borders */
                    /* add error border to table */
                    .ant-form-item-has-error .dialog-engineeringTeamLst .ant-table-content {
                    border: solid 1px #ff4d4f;
                    border-radius: 15px;
                  }
                  `}
              </style>
              <Table 
                className="dialog-engineeringTeamLst"
                columns={ [ 
                  {
                    title: "Name",
                    key: "name",
                    render: (t:EngineeringTeam) => buildTeamName(t)
                  },
                  {
                    title: "Members",
                    key: "members",
                    render: (et:EngineeringTeam) => et.members?.map(u => u.name).join(", ")
                  },
                  {
                    title: "Categories",
                    key: "categories",
                    render: (et:EngineeringTeam) => 
                    <Paragraph style={{maxWidth:"18rem"}} ellipsis={{expandable: true, tooltip:true}}>{et.categories?.map(u => Utils.stripSortingPrefix(u.name)).join(", ")}</Paragraph>
                  },
                ]}
                rowKey="id"
                size="small"
                loading={engineeringTeamLstAsync?.isLoading()}
                dataSource={engineeringTeamLst}
                onChange={tableOnChange}
                pagination={pagination}
                rowSelection={{
                  type: "radio",
                  onSelect: handleSelectRow,
                  hideSelectAll: true,
                  selectedRowKeys: selectedRow ? [selectedRow] : [],
                }}
              />
            </div>,
            footer:() => <div style={{width: "100%", display: "flex", justifyContent:"space-between", padding: "0rem .3rem 0rem .3rem" }}>
            <div><Button onClick={handleRemove} danger >Remove</Button></div>
            <div><Button key="done" type="primary" onClick={handleDone}>Done</Button></div>
          </div>
          },
          {
          key:2,
            title: "Add Engineering Team",
            body: () => <div key="editStep">
            <EngineeringTeamForm form={form} category={props.category} initialValues={{ categories: props.category && [ props.category ] }}  />
            </div>,
            footer:(nav) => <div style={{width: "100%", display: "flex", flexDirection:"row-reverse", gap: ".3rem", padding: "0rem .3rem 0rem .3rem" }}>
              <div style={{display: "flex", gap: ".5rem" }}>
                <Button onClick={() => nav.prevStep()}>Back</Button>
                <Button key="save" type="primary" loading={selectedEngineeringTeamAsync.isLoading()} onClick={handleSave}>Done</Button>
              </div>
            </div>
          },
        ] }
      />
  </>;

}

export default SelectEngineeringTeamsButtonModal;
