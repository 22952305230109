import { Button, Checkbox, Col, Input, Modal, notification } from "antd";
import { Permission, RuleAction, RuleSet } from "../../api/models";
import { useContext, useEffect, useState } from "react";
import Rule from "./rule";
import { ConfiguratorContext } from "../../context";
import { useAsyncState } from "../../hook/useAsyncState";

const EditRuleModal = (props: {
    open: boolean | undefined,
    ruleSet: RuleSet | undefined,
    rule: RuleAction | undefined
    onFinished?: (rule: RuleAction | undefined) => void;
    onCancel?: () => void;
})=> {
    const [rule, ruleAsync] = useAsyncState<RuleAction>();
    const configurator = useContext(ConfiguratorContext);
    const [readOnly, setReadOnly] = useState(true);

    const [history, setHistory] = useState<RuleAction[]>([]);

    useEffect(() => {
      setHistory(props.rule ? [ props.rule ] : []);
      ruleAsync.setDone(props.rule);
    }, [props.rule]);

    const title = props.rule?.ruleId != -1 ? 'Edit Rule ' + props.rule?.ruleId : 'New Rule';

    function onUpdate(rule: RuleAction) {
        ruleAsync.setDone(rule);
        setHistory([...history, rule]);
    }

    function onUpdateName(name: string) {
        const newRule = {...rule} as RuleAction;
        newRule.name = name;
        Object.setPrototypeOf(newRule, Object.getPrototypeOf(props.rule));
        ruleAsync.setDone(newRule);
        setHistory([...history, newRule]);
    }

    function onUpdateEnabled(enabled: boolean) {
        const newRule = { ...props.rule } as RuleAction;
        newRule.disabled = !enabled;
        Object.setPrototypeOf(newRule, Object.getPrototypeOf(props.rule));
        ruleAsync.setDone(newRule);
        setHistory([...history, newRule]);
    }


    async function onOk() {
      if ( !rule ) return;

        try {

            ruleAsync.setLoading();

            if (rule.ruleId != -1) {
                await configurator.api.updateRule(props.ruleSet?.id || 0, {
                    rule,
                });
            }
            else {
                await configurator.api.createRule(props.ruleSet?.id || 0, {
                    rule,
                });
            }

            ruleAsync.setDone(rule);

            props.onFinished?.(rule);
        }
        catch (e:any) {
            const message = 'Rule failed to update: ' + e;
            ruleAsync.setFail(message);
            notification.error({ message });
        }
    }

    async function handleCopyRule() {
        if ( !rule ) return;

        try {
            ruleAsync.setLoading();

            const ruleId = props.ruleSet?.id || 0;
            if (ruleId) {
                await configurator.api.createRule(props.ruleSet?.id || 0, {
                    rule,
                });
            }
            ruleAsync.setDone(rule);
            if (props.onFinished) {
                props.onFinished(rule);
            }
        }
        catch (e:any) {
            const message = 'Rule failed to copy: ' + e;
            ruleAsync.setFail(message);
            notification.error({ message });
        }
    }

    function onUndo() {
        ruleAsync.setDone(history[history.length - 2]);
        setHistory(history.slice(0, history.length - 1));
    }

    return <Modal width="75%" 
      open={props.open} 
      confirmLoading={ruleAsync.isLoading()}
      okText="Save" 
      onOk={onOk} 
      onCancel={props.onCancel} 
      okButtonProps={{style:{ display: configurator.hasPermission(Permission.RULES_WRITE) ? "" : "none" }}}
      title={
        <div>
          <span>{title}</span> &nbsp;
          {configurator.hasPermission(Permission.RULES_WRITE) &&  <>
            <Button onClick={handleCopyRule} size="small" type="primary">Copy</Button>
            <Button onClick={onUndo} size="small" style={{ marginLeft: '5px', marginBottom: '5px' }} type="primary" disabled={history.length <= 1}>Undo</Button>
            <Button onClick={() => setReadOnly(!readOnly)} size="small" style={{ marginLeft: '5px', marginBottom: '5px' }} type="primary">{!readOnly ? 'View Mode' : 'Edit Mode'}</Button>
          </>}
        </div>
      } 
    >
            <strong>Name: </strong>
          <Col span={24}>
            {configurator.hasPermission(Permission.RULES_WRITE) 
              ? <Input value={rule?.name} style={{width: '100%'}} onChange={(e) => onUpdateName(e.target.value)}/>
              :<span>{rule?.name}</span> }
            </Col>
            <strong>Rule enabled:</strong> <Checkbox disabled={!configurator.hasPermission(Permission.RULES_WRITE)} checked={!rule?.disabled} onChange={(e) => onUpdateEnabled(e.target.checked)} /><br /><br />
            <Rule rule={rule} readOnly={readOnly} onUpdate={onUpdate} />
        </Modal>;
};

export default EditRuleModal;
