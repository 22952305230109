import styles from "./view_assembly.module.css";
import 
{
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Spin,
  Tabs,
  Upload,
  Image,
  UploadProps,
  Modal,
  Table,
  Space,
  Tooltip,
  Collapse,
  FormInstance,
  Result,
} from "antd";
import { Form } from "antd";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { NotFoundError } from "../api/errors";
import DependencyCriteria, {EditCategoryRuleConfig } from "../components/dependency_criteria";
import { MetadataFieldList } from "../components/metadata_field";
import { ConfiguratorContext } from "../context";
import NotFoundPage from "./not_found";
import { DeleteOutlined, InboxOutlined, InfoCircleTwoTone, WarningFilled, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import AssemblySelector from "../components/assembly_selector";
import { Assembly, AssemblyChangeType, AssemblyHistory, AssemblyInfo, AssemblyMetadata, AssemblyOperation, BaseModel, Category, CategoryMetadata, Dealer, 
  DependencyRule, MISSING_IMAGE, Operation, OPERATION_TYPE, PAGINATION_MAX_PAGE_SIZE, Permission, PricingConfig } from "../api/models";
import {useAsyncState} from "../hook/useAsyncState";
import {AssemblyRequest } from "../api";
import {StringParam, useQueryParam} from "use-query-params";
import AssemblyCostHistory from "../components/AssemblyCostHistory";
import BMButton from "../components/BMButton";
import Utils from "../util/util";
import {useIntl} from "react-intl";
import dayjs from "dayjs";
import {ValidateFields} from "rc-field-form/lib/interface";
import { ColumnType } from "antd/es/table";
import { useCategoryContext } from "../contexts/CategoryContext";
import { ProCard, ProDescriptions } from "@ant-design/pro-components";
import { CancelTokenSource } from "axios";
import NewAssemblyModal from "../components/new_assembly_modal";
import CategorySelector from "../components/category_selector";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import BMReadOnly from "../components/BMReadOnly";
import { useWatch } from "antd/es/form/Form";
import ModelMultipleSelector from "../components/ModelMultipleSelector";

export interface NewAssemblyOperation {
  id?: number
  operationId?: string
  hours?:number
  group?:string
}

enum UPLOADING_STATUS {
  INIT = "init",
  ERROR = "error",
  DONE = "done",
  UPLOADING = "uploading"
}

export interface AssemblyFormValues {
  id: number
  metadata: AssemblyMetadataFormValues[] | undefined
  restrictions: number[] | undefined
  operations: AssemblyOperation[] | undefined
  dealerExclusives: string[] | undefined
  label: string | undefined
  dependencyRules?: string | undefined
  standardMaterialCost?: number | undefined
  obsoleted: boolean | undefined
  noOption: boolean | undefined
  selectionRequiresUserInput: boolean
  labelWizard?: string | undefined
  descriptionWizard?: string | undefined
  assemblyDependencies: number[] | undefined
  notes?: string | undefined
  replacementAssemblyId:number | undefined
}
export interface AssemblyMetadataFormValues {
  id?:number
  categoryMetadata:CategoryMetadata
  valueText?:string
  valueBool?: boolean
  valueNumeric?: number
  valueDecimal?: number
}

type OperationMapType = {
  [key: string]: OPERATION_TYPE | undefined;
};

const gotoQuoteList = (assemblyId:number | undefined) => {
  if ( !assemblyId ) return;

  window.open(`/quotes?assemblies=${assemblyId}`, "_blank");
}

export const buildFormMetadata = (assembly:Assembly, category:Category) : AssemblyMetadata[] =>  {

  const assemblyMetadata:AssemblyMetadataFormValues[] = assembly?.metadata?.filter( md => category?.metadata.some( cm => cm.id === md.categoryMetadata.id  )) || [];

  const categoryMetadata:AssemblyMetadataFormValues[] = category?.metadata.filter( cm => !assemblyMetadata.some( md  => cm.id === md.categoryMetadata.id  ) ) 
    .map( cm => ({categoryMetadata:cm}) ) || [];

  return [
  ...assemblyMetadata,
  ...categoryMetadata
  ];
}

const ViewAssembly = () => {
  const params = useParams<any>();
  const [tabKeyParam, setTabKey] = useQueryParam<string | undefined | null >("tab", StringParam);
  const configurator = useContext(ConfiguratorContext);
  const [quoteCnt, quoteCntAsync] = useAsyncState<number>();
  const [assembly, assemblyAsync] = useAsyncState<Assembly>();
  const [dealerLst, dealerLstAsync] = useAsyncState<Dealer[]>();
  const [replacementAssemblyLst, replacementAssemblyLstAsync] = useAsyncState<AssemblyInfo[]>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [dependencyRules, setDependencyRules] = useState<Array<EditCategoryRuleConfig>>([]);
  const [operations, operationsAsync] = useAsyncState<Operation[]>();
  const [latestPricing, setLatestPricing] = useState<PricingConfig>();
  const [assemblyHistory, setAssemblyHistory] = useState<AssemblyHistory[]>([]);
  const [_groupOptions, setGroupOptions] = useState<{}>({});
  const [initialValues, setInitialValues] = useState<AssemblyFormValues>();
  const [form] = Form.useForm();
  const obsoleted = Form.useWatch("obsoleted", form);
  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);
  const intl = useIntl();
  const [operationMapping, setOperationMapping] = useState<OperationMapType>({});
  const [validationAlert, setValidationAlert] = useState<string[]>([]);
  const cancelReplacementAssembliesSourceRef = useRef<CancelTokenSource>();
  const cancelModelsRef = useRef<CancelTokenSource>();
  const categoryIdFormValue = useWatch( "categoryId", form);
  const [models, modelsAsync] = useAsyncState<BaseModel[]>();

  useEffect(() => {
    if ( !!assembly?.categoryId && categoryIdFormValue !== assembly?.categoryId ) {
      showCategoryChangeWarning();
    }

  }, [categoryIdFormValue]);

  const { categoriesAsync:categoryLstAsync, loadCategories:contextLoadCategories } = useCategoryContext();
  const categoryLst = categoryLstAsync?.val;

  useEffect(() => {
    Promise.all([
      loadAssembly(params.id),
      loadLatestPricing(),
      loadOperations(),
      loadModels(),
      loadCategories(),
      loadDealers(),
      loadHistory(params.id),
      loadAssemblyStatistics(params.id),
    ]).then( ([assembly]) => {
        if ( !assembly ) return;

        const metadata = buildFormMetadata( assembly, assembly?.category )
        setInitialValues({ 
          ...assembly,
          obsoleted: !!assembly.obsoletedAt,
          assemblyDependencies: assembly.assemblyDependencies.map(d => d.id),
          metadata,
        });
      });
  }, []);

  const isLoading = assemblyAsync.isLoading() || categoryLstAsync?.isLoading() || operationsAsync.isLoading() || dealerLstAsync.isLoading();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if ( obsoleted && assembly?.categoryId && ( replacementAssemblyLstAsync.isInitial() || replacementAssemblyLstAsync.isFail() ) ) {
      loadReplacementAssemblies( assembly?.categoryId );
    }
  }, [obsoleted]);

  const loadReplacementAssemblies = async (categoryId: number) => {
    replacementAssemblyLstAsync.setLoading();
    try {
      const resp = await Utils.executeWithCancelToken(cancelReplacementAssembliesSourceRef, (token) =>
        configurator.api.getAssemblyInCategory(categoryId, PAGINATION_MAX_PAGE_SIZE, token)
      );
      replacementAssemblyLstAsync.setDone( resp?.data?.content );
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to fetch replacement assemblies. " + errorMsg });
      replacementAssemblyLstAsync.setFail(e.message);
    }
  }

  const loadModels = async() => {
    modelsAsync.setLoading();
    try {
      const resp = await Utils.executeWithCancelToken(cancelModelsRef, (token) =>
        configurator.api.listModels( {
          inactive: true, 
          deleted: true, 
          size: PAGINATION_MAX_PAGE_SIZE,
        }, token )
      );
      modelsAsync.setDone(resp?.data?.content);
    }
    catch(e: any){
      const msg = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id: msg });
      console.log(errorMsg);
      notification.error({message: "Failed to load models in filter."})
    }

  }

  const isLoadingFail = !assemblyAsync.val || categoryLstAsync?.isFail() || operationsAsync.isFail() || dealerLstAsync.isFail();
  const isImported = ( assemblyAsync.isDone() && assembly?.imported );

  const loadLatestPricing = async () => {
    const pricing = (await configurator.api.getLatestPricing()).data;
    setLatestPricing(pricing);
  };

  const loadAssembly = (id:number) : Promise<Assembly | undefined> => {

    assemblyAsync.setLoading();
    return configurator.api.getAssembly(id)
    .then( resp => {
      assemblyAsync.setDone(resp.data)

      if (!resp.data.imported) return;

      if (resp.data.dependencyRules) {
        setDependencyRules(JSON.parse(resp.data.dependencyRules).map( (dr:EditCategoryRuleConfig) => ({...dr, uuid: crypto.randomUUID() }) ));
      }

      return resp.data;
    },
    (e) => {
      if (e instanceof NotFoundError) {
        setNotFound(true);
      } else {
        const errorMsg = intl.formatMessage({ id: e.message });
        notification.error( { message: "Failed load assembly. " + errorMsg });
        assemblyAsync.setFail(e.message);
      }

      return undefined;
    });
  };

  const loadCategories = () => {
    if ( categoryLstAsync?.isInitial() ) {
      contextLoadCategories?.();
    }
  }

  const loadOperations = () => {
    operationsAsync.setLoading()
    return configurator.api.fetchAssemblyOperations()
      .then( resp => {
        operationsAsync.setDone(resp.data?.sort((a, b) => a.operationId.localeCompare(b.operationId) ));

        const newMapping = resp.data.reduce((acc, op) => {
          acc[op.operationId] = op.defaultType;
          return acc;
        }, {});

        setOperationMapping(newMapping)
      },
        (e) =>  {
            const errorMsg = intl.formatMessage({ id: e.message });
            notification.error( { message: "Failed load operations. " + errorMsg });
            operationsAsync.setFail(e.message);
          });
  }

  const loadAssemblyStatistics = async (id:number) => {

    const filterAssemblies = [ id ];

    quoteCntAsync.setLoading();
    try {
      const resp = await configurator.api.listQuotes({
        filterAssemblies,
          page: 0,
          size: 1,
        },
      )
      quoteCntAsync.setDone(resp.data.totalElements);
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Failed to load quote statistics." + errorMsg });
        quoteCntAsync.setFail(e.message);
    }

  }

  const loadDealers = () => {
    dealerLstAsync.setLoading();
    return configurator.api.getDealers()
      .then( resp => dealerLstAsync.setDone( resp ),
        (e) =>  {
            const errorMsg = intl.formatMessage({ id: e.message });
            notification.error( { message: "Failed load dealers. " + errorMsg });
            dealerLstAsync.setFail(e.message);
          });
  }

  const dateDescendingSort = (a:AssemblyHistory, b:AssemblyHistory) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

  const loadHistory = async (id: number) => {
    try {
      const resp = await configurator.api.getAssemblyHistory(id);
      setAssemblyHistory(resp.data.sort(dateDescendingSort));
    }
    catch (e: any) {
      notification.error( { message: "Failed to load assembly history. " + e });
    }
  }

  const onSubmitForm = async (changeReason: string) => {
    try {
      const values = await form.validateFields() as AssemblyFormValues;

      const saveDependencyRules = dependencyRules.filter( dr => dr.rules.length || dr.whenRules.length );

      const metadata = values.metadata
      ?.map(md => ({
        ...md, 
        categoryMetadataId: md.categoryMetadata.id, 
        //remove junk
        updatedAt:undefined, createdAt: undefined, categoryMetadata: undefined, name: undefined
      }));

      const req: AssemblyRequest = {
        ...values,
        metadata,
        dependencyRules: JSON.stringify(saveDependencyRules),
        dependencies: values.assemblyDependencies,
        changeReason,
      };

      await saveAssembly( values.id, req );

      setValidationAlert([]);

      notification.success({ message:"Assembly successfully updated."});
    } catch (e:any) {
      notification.error({message: "Please fix validation errors." });
    }
  };

  const saveAssembly = async (assemblyId:number, req:AssemblyRequest) : Promise<Assembly | undefined> => {

    assemblyAsync.setLoading()
    try {
      const resp = await configurator.api.updateAssembly( assemblyId, req);
      assemblyAsync.setDone(resp.data);
      return resp.data;
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response?.data.message });
      const msg = "Failed to save assembly. " + errorMsg;
      notification.error( { message: msg });
      assemblyAsync.setFail(msg);
    }

    return;
  }

  const onAddNewDependency = () => {
    if ( !assembly ) return;

    const newRules = dependencyRules.concat( {
      uuid: crypto.randomUUID(),
      category: assembly.category.id,
      rules: [],
      whenRules: [],
    });
    setDependencyRules(newRules);
  };

  const NOT_FOUND = -1;
  const onUpdateCriteria = (cri:DependencyRule[], uuid:string) => {

    const idx = dependencyRules.findIndex( dr => dr.uuid === uuid );
    if ( idx === NOT_FOUND ) return;

    dependencyRules[idx] = {
      ...dependencyRules[ idx ],
      rules: cri,
    };
    setDependencyRules([...dependencyRules]);
  };

  async function moveToPending() {
    if ( !assembly ) return;

    try {
      assemblyAsync.setLoading();
      const resp = await configurator.api.resetAssemblyPending(assembly.id);
      assemblyAsync.setDone( resp.data );
    } catch (e) {
      assemblyAsync.setFail("Failed to move back to pending.");
    }
  }

  const handleChangeCategory = () => {
    const selectedCategory = categoryLst?.find( (c) =>  c.id === categoryIdFormValue );
    const metadata = selectedCategory?.metadata.map( cmd => ({categoryMetadata: cmd}) )

    form.setFieldValue('metadata', metadata );
  }

  const handleCancelChangeCategory = () => {
    form.setFieldValue( 'categoryId', assembly?.categoryId );
  }

  const showCategoryChangeWarning = () => {
          Modal.confirm({
            icon:<></>,
            content: <Result 
              status={"warning"}
              title={"Category has changed!"}
              subTitle={"This will affect existing quotes and clear all metadata."}
            />,
            onOk: handleChangeCategory,
            onCancel: handleCancelChangeCategory,
          });

  }

  if ( notFound ) {
    return <div className="site-layout-background"><NotFoundPage /></div>
  }

  const isCurrentOrSelected = (ao:AssemblyOperation|NewAssemblyOperation|undefined, o:Operation) => o.current || ao?.operationId === o.operationId;

  interface UploadImageResponse {
    status:string | undefined, 
    response?:{ 
      thumbnailUrl:string
      fullSizeUrl:string
    } 
  }
  const UploadImage = ( { assemblyId, thumbnailImageUrl }) => {
    const [uploadFile, setUploadFile] = useState<UploadImageResponse>({status: UPLOADING_STATUS.INIT});

    useEffect(()=> {
      if( uploadFile.status == UPLOADING_STATUS.DONE ) {
        notification.success( {message: "Upload complete."} );
      }
      else if( uploadFile.status == UPLOADING_STATUS.ERROR ) {
        notification.error(  {message: "There was an error uploading the image."});
      }

    }, [ uploadFile.status] )


    const isUploading = UPLOADING_STATUS.UPLOADING == uploadFile.status;
    const uploadImageUrl = assemblyId ? configurator.api.baseUrl + "/v1/assembly/" + encodeURIComponent(assemblyId) + "/image" : "";
    const onUploadChange: UploadProps['onChange'] = ({file}) => {
      setUploadFile( {status: file.status, response: file.response } );
    };

    return <Upload.Dragger
      name="image"
      action={uploadImageUrl}
      withCredentials={true}
      showUploadList={false}
      accept=".jpg,.jpeg,.png,.gif,.tiff"
      style={{ margin: "1rem", width: "450px"}}
      maxCount={1}
      onChange={onUploadChange}
      disabled={!canWrite}
    >
      <Spin spinning={isUploading}>
        {thumbnailImageUrl 
          ? <Image
            src={ uploadFile.response?.thumbnailUrl || thumbnailImageUrl || MISSING_IMAGE }
            fallback={MISSING_IMAGE}
            style={{paddingBottom: "20px"}}
            width={200}
            preview={false}
          />
          :<p className="ant-upload-drag-icon">
            <InboxOutlined  />
          </p>

        }
      </Spin>
      <p className="ant-upload-text">{!canWrite ? "You need permission to upload image" : "Click or drag file to upload image"}</p>
    </Upload.Dragger>

  }

  const CostHistoryTabPane = () => {

    var laborHours = assembly?.operations.map(o => o.hours).reduce((v, acc) => acc += v, 0) || 0;
    var laborHourlyCost = latestPricing?.laborHourlyCost || 0;
    var laborCost = laborHours * laborHourlyCost;

    return <>
      <Form
        form={form}
        name="costs"
        initialValues={initialValues}
        labelAlign="right"
        disabled={!!getDisabledUpdateMsg()}
      >
        <Title level={4}>Assembly Cost</Title>
        <Row gutter={16}>
          <Col>
            <Form.Item
              label="Next Snapshot Material Cost"
              name="standardMaterialCost"
            >
              <InputNumber
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder="Type dollar amount"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Next Snapshot Labor Cost" >
              {Utils.formatMoney( laborCost )}
              <Tooltip title={"Update Operations to change this value."}>
                <InfoCircleTwoTone style={{marginLeft: ".4rem"}} />
              </Tooltip>
            </Form.Item>
          </Col>

        </Row>
      </Form>

      <Title level={4}>History</Title>
      <AssemblyCostHistory assembly={assembly} latestPricing={latestPricing} onCostsUpdated={() => loadAssembly(params.id)}/>
    </>};

  const DetailsTabPane = () => <>
    <Form
      form={form}
      name="details"
      initialValues={initialValues}
      labelCol={{span: 4}}
      labelAlign="right"
      disabled={!!getDisabledUpdateMsg()}
    >
      <Title level={4}>Assembly Details</Title>

      <Form.Item
        label="Id"
        name="id"
        hidden={true}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="Name"
        name="label"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Dealer Exclusive"
        name="dealerExclusives"
      >
        <Select
          loading={dealerLstAsync.isLoading()}
          showSearch={true}
          mode="multiple"
          optionFilterProp="label"
          options={dealerLst?.map(d => ({ label: d.name, value:d.id }))}
        />
      </Form.Item>


      <Row gutter={[20,20]}>
        <Col offset={4} >
          <Form.Item
            name="noOption"
            valuePropName="checked"
          >
            <Checkbox >"NO OPTION"</Checkbox>
          </Form.Item>
        </Col>

        <Col >
          <Form.Item
            name="selectionRequiresUserInput"
            valuePropName="checked"
          >
            <Checkbox >Requires User Input</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row >
        <Col offset={4} >
          <Form.Item
            name="obsoleted"
            valuePropName="checked"
          >
            <Checkbox style={{marginLeft: "3px"}}>Obsolete</Checkbox>
          </Form.Item>
        </Col>
        <Col  >
          <Form.Item
            shouldUpdate
            labelCol={{span: 11}}
            name="replacementAssemblyId"
            label="Replacement Assembly"
            hidden={!obsoleted}
          >
            <Select
              allowClear
              showSearch
              style={{ width: "20rem" }}
              optionFilterProp="label"
              loading={replacementAssemblyLstAsync.isLoading()}
              options={replacementAssemblyLst?.map(a => ({value:a.id, label: Utils.getAssemblyInfoDisplayLabel(a) }))} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Notes"
        name="notes"
      >
        <Input.TextArea rows={4}/>
      </Form.Item>

      <Form.Item
        label="Models"
        name="modelIds"
      >
        <ModelMultipleSelector />
      </Form.Item>

      <Row >
        <Col offset={4} >
          <UploadImage 
            assemblyId={assembly?.id} 
            thumbnailImageUrl={assembly?.thumbnailImageUrl} 
          />
        </Col>
      </Row>

    </Form>
  </>

  const MetadataTabPane = () => <>
    <Form
      form={form}
      name="metadata"
      initialValues={initialValues}
      labelCol={{span: 4}}
      labelAlign="right"
      disabled={!!getDisabledUpdateMsg()}
    >
    <MetadataFieldList />
    </Form>
  </>

  const getGroupFromMapping = (idx: number) => {
    return operationMapping[form.getFieldValue("operations")[idx].operationId];
  }

  const existDefaultType = (operationId: string | undefined) => {
    return operationId && operationId in operationMapping && ((operationMapping[operationId] ?? OPERATION_TYPE.BOTH) !== OPERATION_TYPE.BOTH);
  }

  const onSetOperation = (idx: number) => {
    // reset group when operation is changed
    const currentOperations = [...form.getFieldValue("operations")];
    const newGroup = !!idx && existDefaultType(form.getFieldValue("operations")[idx]?.operationId) ? getGroupFromMapping(idx) : undefined;

    currentOperations[idx].group = newGroup;
    form.setFieldValue("operations", currentOperations);

    setGroupOptions({}); // force selection option to re-render
  }

  const assignDefaultGroup = (idx: number) => {
    const operationId = form.getFieldValue("operations")?.[idx]?.operationId || "";
    // console.log(operationId)   //debug re-render
    return existDefaultType(operationId);
  }

  const OperationsTabPane = () => <>
    <Form
      form={form}
      name="operations"
      initialValues={initialValues}
      disabled={!!getDisabledUpdateMsg()}
    >
      <Form.List name="operations" >
        {(fields, { add, remove }) => (
          <>
            <Title level={4}>
              Operations
              <Button onClick={(_e) => add()} icon={<PlusOutlined/>} style={{backgroundColor: "#1677ff", color: "white", marginLeft: ".5rem"}} shape="circle" size="small"/>
            </Title>
            {fields.map((ao, idx) => (
              <div key={idx}>
                <Form.Item
                  name={[ao.name, "id"]}
                  noStyle
                  hidden
                >
                  <Input hidden />
                </Form.Item>
                <Space>
                  <Form.Item
                    name={[ao.name, "operationId"]}
                    label="Operation"
                    rules={[
                      {
                        required: true,
                        message: "Operation is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      onChange={() => onSetOperation(idx)}
                    >
                      {operations
                        ?.filter( o => isCurrentOrSelected(initialValues?.operations?.[ao.name], o) )
                        .map((o, idx) => 
                          <Select.Option value={o.operationId} key={o.operationId + "-" + idx}>
                            {!o.current 
                              ?  <span style={{color: "red"}}>{o.operationId}</span>
                              :  <>{o.operationId}</>
                            }
                          </Select.Option>
                            )}
                    </Select>
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    name={[ao.name, "group"]}
                    label="Group"
                    rules={[
                      {
                        required: true,
                        message: "Group is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: 150 }}
                      optionFilterProp="children"
                      options={
                        assignDefaultGroup(idx) ? 
                        [{label: getGroupFromMapping(idx), 
                          key: getGroupFromMapping(idx),
                          value: getGroupFromMapping(idx),
                        }]
                        : 
                        [
                        {label:"CAB", value:"CAB", key: "CAB" },
                        {label:"CHASSIS", value:"CHASSIS", key: "CHASSIS" },
                        ]
                      }
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    name={[ao.name, "hours"]}
                    label="Hours"
                    rules={[
                      {
                        required: true,
                        message: "Hours are required",
                      },
                    ]}
                  >
                    <Input style={{ width: 100 }} />
                  </Form.Item>
                  <DeleteOutlined onClick={() => remove(ao.name)} style={{paddingLeft: "1rem", marginBottom: "24px", color: "red"}} />
                </Space>
              </div>
            ))}
          </>)}
      </Form.List>
    </Form>
  </>

  const DependenciesTabPane = () => <>
    <Title level={4}>Dependencies</Title>
    <Form
      form={form}
      name="dependencies"
      initialValues={initialValues}
      disabled={!!getDisabledUpdateMsg()}
    >
      <Form.Item
        label="Dependencies"
        name="assemblyDependencies"
      >
        <AssemblySelector />
      </Form.Item>
      <Title level={4}>
        Dependencies by Category
        <Button onClick={onAddNewDependency} icon={<PlusOutlined/>} style={{backgroundColor: "#1677ff", color: "white", marginLeft: ".5rem"}} shape="circle" size="small"/>
      </Title>
      {categoryLstAsync?.isDone() && dependencyRules?.map((dr) => (
        <div key={dr.uuid}>
          <DependencyCriteria
            initialState={dr}
            onUpdateCriteria={(cri:DependencyRule[] ) => {
              onUpdateCriteria(cri, dr.uuid);
            }}
            categories={categoryLst}
          />
        </div>
      ))}
    </Form>
  </>

  const getChangeType = (ah: AssemblyHistory): string => {
    switch (ah.changeType) {
      case AssemblyChangeType.CREATE_ASSEMBLY: return "Assembly Creation";
      case AssemblyChangeType.IMPORT_ASSEMBLY: return "Import Assembly";
      case AssemblyChangeType.UPDATE_ASSEMBLY: return "Assembly Update";
      case AssemblyChangeType.UPLOAD_ASSEMBLY_IMAGE: return "Upload Assembly Image";
    }
    return ah.changeType;
  }

  const getApprovedBy = (ah: AssemblyHistory): string => {
    return `${ah.createdBy} at ${dayjs(ah.createdAt).format("M/D/YY, h:mm a")}`;
  }

  const getChangeTitle = (change: AssemblyHistory) => {
    return (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", position: "relative"}}>
        <div style={{position: "absolute", right: 0}}>{getApprovedBy(change)}</div>
        <div>{getChangeType(change)}</div>
      </div>
    );
  }

  const HistoryTabPane = () => <>
      <style>
        {`
          .history-collapse {
            border: 2px solid #1890ff;
            background-color: white;
          }
          .history-collapse .ant-collapse-header .ant-collapse-arrow svg {
            color: darkgrey;
            fill: #1677ff;
          }
          .history-collapse .ant-collapse-header-text {
            color: #1677ff;
          }
        `}
      </style>
    <Title level={4}>History</Title>
    {assemblyHistory?.map((ah, idx) => 
       <div key={"div-" + idx}>
        <Collapse style={{marginBottom: ".6rem"}} key={`history-panel-${idx}`} defaultActiveKey={"change-" + ah.id + "-panel"} className="history-collapse" bordered={false}>
            <Collapse.Panel header={getChangeTitle(ah)} key={"change-" + ah.id + "-panel"}>
              {RenderHistory(ah, dealerLst, models)}
            </Collapse.Panel>
        </Collapse>
     </div>
    )}
  </>

  const getDisabledMovePendingMsg = () : string | undefined => {
    return !canWrite ? "You need permission to import assembly."
    : ((quoteCnt || 1) > 0 ) ? "Cannot move to pending while assembly is assigned to a quote."
    : !isImported ? "This assembly has not been imported."
    : isLoadingFail ? "Data failed to load.  Please refresh."
    : undefined;
  }

  const getDisabledUpdateMsg = () : string | undefined => {
    return !canWrite ? "You need permission to import assembly."
    : !isImported ? "This assembly has not been imported."
    : isLoadingFail ? "Data failed to load.  Please refresh."
    : undefined;
  }

  const onTabClick = (key: string) => {
    if(key === "history") {
      loadHistory(params.id);
    }
  }

  return <div className="site-layout-background">
      <Title level={2}>View Assembly</Title>
      <div className={styles.assemblyView}>
      <Spin className="assemblyLoading" spinning={isLoading} >
          <Form.Provider >
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  name="assembly"
                  initialValues={initialValues}
                  labelCol={{span: 3}}
                  labelAlign="right"
                >
                  <Row gutter={16}>
                    <Col>
                      <BMButton
                        type="primary"
                        onClick={moveToPending}
                        loading={assemblyAsync.isLoading() || quoteCntAsync.isLoading()}
                        disabled={!!getDisabledMovePendingMsg()}
                        onDisabledClick={() => Utils.warning(getDisabledMovePendingMsg())}
                      >
                        Move Pending
                      </BMButton>
                    </Col>
                    <Col>
                      <UpdateModal 
                        assembly={assembly}
                        loading={assemblyAsync.isLoading()}
                        disabled={!!getDisabledUpdateMsg()}
                        onDisabledClick={() => Utils.warning(getDisabledUpdateMsg())}
                        onSubmit={(reason) => onSubmitForm(reason)}
                        form={form}
                        setValidationAlert={setValidationAlert}
                      />
                    </Col>
                    <Col>
                      <NewAssemblyModal refAsm={assembly}/>
                    </Col>
                  </Row>
                  <ProCard bordered style={{marginTop: "1rem"}}>
                    <ProDescriptions
                      layout="horizontal"
                      labelStyle={{ width: '100px', textAlign: 'right', color: "black", fontWeight: "bold" }}
                      contentStyle={{ height: '30px' }}
                      column={1}
                      dataSource={initialValues}
                      columns={[
                          {
                            title: 'BOM',
                            key: 'bom',
                            dataIndex: 'bom',
                            copyable: true,
                          },
                          {
                            title: 'Description',
                            key: 'bomDescription',
                            dataIndex: 'bomDescription',
                            copyable: true,
                          },
                          {
                            title: 'Category',
                            key: 'category',
                            render: () => 
                              <Form.Item name="categoryId" style={{width: "25rem"}}>
                                <BMReadOnly readOnly={!configurator.isAdmin()} 
                                  renderValue={() => <Link style={{fontWeight: 400 }} to={"/categories/" + assembly?.category.id}>{assembly?.category.name}</Link> }>
                                  <CategorySelector allowClear={false} />
                                </BMReadOnly>
                              </Form.Item>
                          },
                          {
                            title: <>
                              <span>Quotes</span>
                              <Tooltip title="This is the number of quotes currently using this assembly."><QuestionCircleOutlined style={{marginLeft: "3px", color: "darkgrey"}}/></Tooltip>
                            </>,
                            key: 'quotes',
                            dataIndex: 'quotes',
                            render: () => <Button type="text"
                                            onClick={() => gotoQuoteList(assembly?.id)} 
                                            loading={quoteCntAsync.isLoading()}
                                            style={{marginTop: "-5px"}}
                                          >
                                            {quoteCnt}
                                          </Button>
                          },
                        ]}
                      >
                    </ProDescriptions>
                  </ProCard>
                </Form>

                <Tabs defaultActiveKey={tabKeyParam || undefined}
                  onChange={setTabKey}
                  onTabClick={(key) => onTabClick(key)}
                  items={[{
                    key:"1",
                    label:"Details",
                    children: <DetailsTabPane/>,
                    forceRender: true,
                  },
                  {
                    key:"2",
                    label:"Metadata",
                    children: <MetadataTabPane/>,
                    forceRender: true,
                  },
                  {
                    key:"3",
                    label:"Pricing",
                    children: <CostHistoryTabPane/>,
                    forceRender: true,
                  },
                  {
                    key:"4",
                    label:
                    <> 
                      <span>Operations</span>
                      {!!validationAlert?.length && <Tooltip title={validationAlert.map(a => <div key={a + "-alert"}>{a}</div>)}>
                        <WarningFilled style={{marginLeft: ".5rem", fontSize: "20px",  color: "orange" }} size={5}/>
                      </Tooltip>}
                    </>,
                    children: <OperationsTabPane/>,
                    forceRender: true,
                  },
                  {
                    key:"5",
                    label:"Dependencies",
                    children: <DependenciesTabPane/>,
                    forceRender: true,
                  },
                  {
                    key:"history",
                    label:"History",
                    children: <HistoryTabPane/>,
                    forceRender: true,
                  }
                ]}
                />

              </Col>
            </Row>
          </Form.Provider>

      </Spin>
    </div>
    </div>
};

export default ViewAssembly;


const UpdateModal = (props: {
  assembly: Assembly | undefined,
  loading: boolean,
  onDisabledClick: () => void,
  disabled: boolean,
  onSubmit: (val: string) => void,
  form: FormInstance,
  setValidationAlert: (val: string[]) => void
}) => {

  const [reasonForm] = Form.useForm();
  const {loading, disabled, onDisabledClick, onSubmit, form, setValidationAlert} = props;
  const [showReasonModal, setShowReasonModal] = useState(false);
  const reason = Form.useWatch<string>('reason', reasonForm);

  const onCancel = () => {
    setShowReasonModal(false);
    reasonForm.resetFields();
  }

  const validateForm = async () : Promise<ValidateFields | undefined> => {

    try {
      const values = await form.validateFields();
      setShowReasonModal(true);

      return values;
    }
    catch(e:any) {
      const validationErrors = e as ValidateErrorEntity;
      notification.error({message: "Please fix validation errors." });
      setValidationAlert(validationErrors?.errorFields?.map(f => f.errors.join(" ")));
    }

    return;
  }

  return (
    <>
      <BMButton
        type="primary"
        onClick={validateForm}
        loading={loading}
        disabled={disabled}
        onDisabledClick={onDisabledClick}
      >
        Update
      </BMButton>
      <Modal
        open={showReasonModal}
        okText={"Update"}
        closable={true}
        width={'50rem'}
        title="Reason for upate"
        onCancel={onCancel}
        footer={[
          <Button key="submit" type="primary"
            disabled={!reason}
            onClick={() => {onSubmit(reason); onCancel()}} >
            Update
          </Button>,
        ]}
      >
        <Form
          form={reasonForm}
        >
          <Form.Item
            name="reason"
            label="Reason"
            rules={[{ required: true, message: "Reason is required" }]}
          >
            <Input placeholder={'Please enter the reason here.'} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const RenderHistory = (ah: AssemblyHistory, dealerLst: Dealer[] | undefined, modelList: BaseModel[] | undefined) => {
  
  
  interface OperationChange {
    addedOperations: AssemblyOperation[],
    existingOperations: AssemblyOperation[],
    removedOperations: AssemblyOperation[],
    updatedOperations: AssemblyOperation[],
  }

  let operationChange: OperationChange = { 
    addedOperations: [],
    existingOperations: [],
    removedOperations: [],
    updatedOperations: []
  };


  const change = ah.assemblyChange;

  const columns: ColumnType<{ item: string, before: any, after: any; }>[] = [
    {
      dataIndex: "item",
      title: "Item",
      width: "20%"
    },
    {

      title: "Before",
      width: "40%",
      render: (change: {item: string, before: any, after: any}) => {
        if (change.item === "Image" && change.before !== "NA") {
          return <img src={change.before} alt="NA" style={{ width: 100, height: 100 }} />
        }
        return <span>{change.before}</span>
      }
    },
    {

      title: "After",
      width: "40%",
      render: (change: {item: string, before: any, after: any}) => {
        if (change.item === "Image" && change.after !== "NA") {
          return <img src={change.after} alt="NA" style={{ width: 100, height: 100 }} />
        }
        return <span>{change.after}</span>
      }
    },
  ];

  let data: {item: string, before: any, after: any}[] = [];

  if (change) {

    Object.keys(change)?.forEach(key => {
      if (String(key) === "label" || String(key) === "notes" || String(key) === "nonOption" || String(key) === "obsoleted" 
      || String(key) === "standardMaterialCost" || String(key) === "userRequireInput" || String(key) === "replacementAssembly" || String(key) === "image") {
        const item = change[key].item as string;
        let before = change[key].before == undefined ? "" : [].concat(change[key].before).join(", ");
        let after = change[key].after == undefined ? "" : [].concat(change[key].after).join(", ");
        if (String(key) === "standardMaterialCost") {
          before = change[key].before == undefined ? "" : Utils.formatMoney(change[key].before);
          after = change[key].after == undefined ? "" : Utils.formatMoney(change[key].after);
        }
        data.push({item, before: String(before) || "NA", after: String(after) || "NA"});
      }
      else if (String(key) === "dealerExclusives") {
        const item = change[key].item as string;
        const before = change[key].before == undefined ? "" : [].concat(change[key].before).map(dealerId => dealerLst?.find(d => d.id === dealerId)?.name).join(", ");
        const after = change[key].after == undefined ? "" : [].concat(change[key].after).map(dealerId => dealerLst?.find(d => d.id === dealerId)?.name).join(", ");
        data.push({item, before: String(before) || "NA", after: String(after) || "NA"});
      }
      else if (String(key) === "operations") {
        operationChange = change[key];
      }
      else if (String(key) === "modelChange") {
        const item = change[key].item as string;
        const before = change[key].before == undefined ? "" : [].concat(change[key].before).map(modelId => modelList?.find(d => d.id === modelId)?.name).sort().join(", ");
        const after = change[key].after == undefined ? "" : [].concat(change[key].after).map(modelId => modelList?.find(d => d.id === modelId)?.name).sort().join(", ");
        data.push({item, before: String(before) || "NA", after: String(after) || "NA"});
      }
      else if (String(key) === "metadata") {
        const updateMetadata = change[key].updatedMetadata.sort((a: AssemblyMetadata, b: AssemblyMetadata) => a.categoryMetadata.id - b.categoryMetadata.id);
        const existingMetadata = change[key].existingMetadata.sort((a: AssemblyMetadata, b: AssemblyMetadata) => a.categoryMetadata.id - b.categoryMetadata.id);
  
        (updateMetadata || []).forEach((u: AssemblyMetadata, uid: number) => {
          let item = u.categoryMetadata.name || "";
          let after = updateMetadata?.[uid] == undefined ? "" : updateMetadata?.[uid]; 
          let before = existingMetadata?.[uid] == undefined ? "" : existingMetadata?.[uid]; 
          if (Utils.getMetadataValue(before) || Utils.getMetadataValue(after)) {
            data.push({item, before: Utils.getMetadataValue(before) || "NA", after: Utils.getMetadataValue(after) || "NA"});
          }
        });
  
        (change[key].addedMetadata || []).forEach((u: AssemblyMetadata, uid: number) => {
          let item = u.categoryMetadata.name || "";
          let after = change[key].addedMetadata?.[uid] == undefined ? "" : change[key].addedMetadata?.[uid]; 
          if (Utils.getMetadataValue(after)) {
            data.push({item, before: "NA", after: Utils.getMetadataValue(after)});
          }
        });
  
        return data;
      }
  
    });
  }



  return (
    <>
      <style>
        {`
          .head-row-white .ant-table-thead > tr > th {
            background-color: white;
          }
        `}
      </style>

      {ah.initial && <div key={`${ah.id}-initial-record}`}>Initial Update Record</div>}

      {!ah.initial && <span key={`history reason - ${ah.id}`}><strong>Reason: </strong>{ah.reason}</span>}

      {!!data.length && <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        style={{marginTop: "1rem", marginRight: "1rem"}}
        className="head-row-white"
        rowKey={"item"}
      />}
      {
      !!operationChange?.addedOperations.length && 
      <div key={`${ah.id}-operation-change-added`} style={{marginTop: ".5rem"}}>
        <div key={`${ah.id}-operation-change-added-title`}>
          Added Operations:
        </div>
        {<ul>
        {operationChange?.addedOperations?.map((op: AssemblyOperation, idx: number) => <li key={`${ah.id}-operation-change-added-${idx}`}>
          {`Operation: ${op.operationId} Group: ${op.group}  Hours: ${op.hours}`}
        </li>)}
        </ul>}
      </div>
      }

      {!!operationChange?.removedOperations.length && 
      <div key={`${ah.id}-operation-change-removed`} style={{marginTop: ".5rem"}}>
        <div key={`${ah.id}-operation-change-removed-title`}>
          Removed Operations:
        </div>
        {<ul>
          {operationChange?.removedOperations?.map((op: AssemblyOperation, idx: number) => 
            <li key={`${ah.id}-operation-change-removed-${idx}`}>{`Operation: ${op.operationId} Group: ${op.group}  Hours: ${op.hours}`}</li>
          )}
        </ul>}
      </div>}

      {!!operationChange?.updatedOperations.length && 
      <div key={`${ah.id}-operation-change-update`} style={{marginTop: ".5rem"}}>
        <div key={`${ah.id}-operation-change-update-title`}>
          Updated Operations:
        </div>
        {<ul>
        {operationChange?.existingOperations?.map((op: AssemblyOperation, idx: number) => 
        <li key={`${ah.id}-operation-change-update-${idx}`}>
          <span key={`history-operation-update-from-${ah.id}`}>{`Operation: ${op.operationId} Group: ${op.group}  Hours: ${op.hours}`}</span>
          {"    ->    "}
          {operationChange.updatedOperations.filter(eop => eop.id === op.id).map(eop => <span key={`history-operation-update-to-${ah.id}`}>
            {` Operation: ${eop.operationId} Group: ${eop.group}  Hours: ${eop.hours}`}
            </span>)}
        </li>
        )}
        </ul>
        }
      </div>}
    </>)
}


