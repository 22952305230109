import {useContext, useEffect, useState} from "react";
import {PricingConfig} from "../api/models";
import {PricingSnapshotListContext, PricingSnapshotsContext} from "../context";
import BMButton, {BMButtonProps} from "./BMButton";
import PricingSnaphotSelectionModal from "./PricingSnapshotSelectionModal";

type SelectPricingSnapshotProps =Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  id?:string
  value?:number | undefined
  onChange?:(id:number|undefined)=>void
}

const SelectPricingSnapshot = (props:SelectPricingSnapshotProps) => {
  const { id:a, value:b, onChange:c, ...btnProps } = props;

  const { pricingSnapshotList, pricingSnapshotListAsync, loadPricingSnapshotList } = useContext<PricingSnapshotListContext>(PricingSnapshotsContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(()=> {
    if ( ( pricingSnapshotListAsync.isInitial() || pricingSnapshotListAsync.isFail() ) && !pricingSnapshotListAsync.isLoading() )  {
      loadPricingSnapshotList();
    }
  }, [ pricingSnapshotListAsync.state ] );

  const handleOk = (pc:PricingConfig) => {
    props.onChange?.(pc.pricingSnapshot?.id);
    setIsOpen(false);
  }
  const handleCancel = () => {
    setIsOpen(false);
  }
  const handleOpen = () => {
    setIsOpen(true);
  }

  const disabled = btnProps.disabled;
  const handleDisabledClick = () => {
    btnProps.onDisabledClick?.();
  };

  //hide border when disabled
  const btnStyle = disabled
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  const pricingSnapshot = pricingSnapshotList?.find( ps => ps.id === props.value)


  return <>
    <BMButton type="text"
      className="ghostBmButton"
      data-testid="pricingSnapshot-button"
      disabled={disabled}
      onDisabledClick={handleDisabledClick}
      onClick={handleOpen}
      style={{padding: "0", whiteSpace: "break-spaces",
      ...btnProps.style}}
    ><span style={btnStyle}>{pricingSnapshot?.name}</span></BMButton>

    <PricingSnaphotSelectionModal
      open={isOpen}
      value={props.value}
      onChange={handleOk}
      onCancel={handleCancel}
    />
  </>

}

export default SelectPricingSnapshot;


