import {useMemo, useRef, useState} from "react"
import {AsyncData, AsyncDataState} from "../api/async_data";


export interface AsyncState <T>{
    readonly val?:T
    readonly lastModified:number
    readonly state:AsyncDataState
    readonly err?:string
    setInit: (v?:T) => void
    setLoading: () => void
    setFail: (e?:string) => void
    setDone: (v?:T) => void
    setLoaded: (o:AsyncData<T>) => void
    isInitial: () => boolean
    isLoading: () => boolean
    isDone: () => boolean
    isFail: () => boolean
    getAsyncData: () => AsyncData<T>
}


export const useAsyncState = <T>( initialVal?:T ) : [ v:T | undefined, vAsync:AsyncState<T> ] => {

  const initialState = new AsyncData<T>(initialVal);
  const [state, setState] = useState<AsyncData<T>>( initialState );

  const asyncState = useMemo<AsyncState<T>>(():AsyncState<T> => ({
    val: state.val,
    lastModified: state.lastModified,
    state: state.state,
    err: state.err,
    setInit: (v?:T):void => { setState( state.init(v) ); },
    setLoading: ():void => { setState( state.loading() ); },
    setFail: (e?:string):void => { setState( state.fail(e) ); },
    setDone: (v?:T):void => { setState( state.done(v) ); },
    setLoaded: (o:AsyncData<T>):void => { setState( state.loaded(o) ); },
    isInitial: state.isInitial,
    isLoading: state.isLoading,
    isDone: state.isDone,
    isFail: state.isFail,
    getAsyncData: () => state
  }), [state] );

  return [ state.val, asyncState ];
}

export const useAsyncRef = <T>( initialVal?:T ) : AsyncState<T> => {

  const initialState = new AsyncData<T>(initialVal);
  const stateRef = useRef<AsyncData<T>>( initialState );

  return {
    val: stateRef.current.val,
    lastModified: stateRef.current.lastModified,
    state: stateRef.current.state,
    err: stateRef.current.err,
    setInit: (v?:T):void => { stateRef.current = stateRef.current.init(v); },
    setLoading: ():void => { stateRef.current = ( stateRef.current.loading() ); },
    setFail: (e?:string):void => { stateRef.current = ( stateRef.current.fail(e) ); },
    setDone: (v?:T):void => { stateRef.current = ( stateRef.current.done(v) ); },
    setLoaded: (o:AsyncData<T>):void => { stateRef.current = ( stateRef.current.loaded(o) ); },
    isInitial: stateRef.current.isInitial,
    isLoading: stateRef.current.isLoading,
    isDone: stateRef.current.isDone,
    isFail: stateRef.current.isFail,
    getAsyncData: () => stateRef.current,
    //getRef: () => stateRef
  }

}

