import { Button, Form, Input, Modal, notification, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import { Quote, SourcewellLetterParameters} from "../../api/models";
import {ConfiguratorContext } from "../../context";
import {useAsyncState} from "../../hook/useAsyncState";
import Utils from "../../util/util";
import BMButton from "../BMButton";

const SourcewellLetter = (props: {
  quote:Quote|undefined,
  pendingChanges:boolean,
  onExporting?:(e:boolean)=>void
}) => {

  const { quote, pendingChanges, onExporting } = props;

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [letterDefaults, letterDefaultsAsync] = useAsyncState<SourcewellLetterParameters>();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [letterDefaultsAsync, quote]);

  useEffect(() => {
    onExporting?.( isExporting );
  }, [isExporting]);

  const isDealer = configurator.isDealerSales();
  const isChangeableRevision = Utils.isChangeableRevision(quote);

  //bail conditions
  if ( isDealer ) return <></>;

  const loadLetterDefaults = async () => {
    if ( !quote ) return;

    try {
      letterDefaultsAsync.setLoading();
      var resp = await configurator.api.fetchSourcewellLetterDefaults( quote?.quoteId );
      letterDefaultsAsync.setDone( resp.data );

    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( {message: "Failed to load letter defaults. " + errorMsg} );
      letterDefaultsAsync.setFail( e.message );
    }
  }


  const generateSourcewellLetter = async (values:Record<string,any>) => {
    if ( !quote ) return;

    setIsExporting( true );
    try {
      const url = configurator.api.baseUrl + "/v1/quotes/" + encodeURI(quote.quoteId) + "/generateSourcewellLetter";
      await configurator.api.downloadPdfOpts( url, values );

      //close the Modal on success
      setShowModal(false);
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error({message:"There was an error exporting the sourcewell letter. " + errorMsg});
    }
    setIsExporting( false );

  };

  const handleDisabledClick = () => {

    const disabledMsg = !Utils.isOrder( quote ) ? 'Only orders can be exported.' 
      : !isChangeableRevision ? "Must be a changeable revision."
      : pendingChanges ? "There are pending changes. Please save the quote first."
      : undefined;

    if ( disabledMsg ) {
      notification.warning({message:disabledMsg});
    }
  }

  const handleShowModal = () => {
    loadLetterDefaults();
    setShowModal(true)
  }

  return <>
    <BMButton onClick={handleShowModal}
      type="text"
      disabled={!isChangeableRevision || pendingChanges}
      onDisabledClick={handleDisabledClick}
      loading={isExporting}
    >
      Sourcewell Letter
    </BMButton>
    <Modal
      title="Sourcewell Letter Generation"
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={ [
        <Button key="2" onClick={() => setShowModal(false)}>Cancel</Button>,
        <Button key="3" onClick={form.submit} type="primary" loading={isExporting}>Export</Button>
      ] }
      width="80%"
      style={{maxWidth: "60rem"}}
    >
      <Title level={5} style={{marginBottom: "2rem"}}>These values are for the letter only and will not be saved to the quote.  Adjust values as needed.</Title>
      <Spin spinning={letterDefaultsAsync.isLoading()}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          onFinish={generateSourcewellLetter}
          initialValues={letterDefaults}
        >
          <Form.Item
            label="Customer Greeting"
            name="customerGreeting"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Truck Name"
            name="truckName"
          >
            <Input />
          </Form.Item>


          <Form.Item
            label="Dealer Name"
            name="dealerName"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Dealer Contact"
            name="dealerContactName"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Dealer Address"
            name="dealerAddress"
          >
            <Input />
          </Form.Item>


          <Form.Item
            label="Destination Name"
            name="destinationName"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Destination Address"
            name="destinationAddress"
          >
            <Input />
          </Form.Item>

        </Form>
      </Spin>
    </Modal>
  </>

};

export default SourcewellLetter;
