import { Modal } from "antd";
import { RuleAction } from "../../api/models";
import Rule from "./rule";

export interface Props {
    rule: RuleAction;
    onClose: () => void;
}

const ViewRuleModal = (props: Props) => {
    return (
        <Modal style={{width: '90%'}} open title={"View Rule " + (props.rule.name||'') + " (" + props.rule.ruleId + ")"} cancelButtonProps={{hidden: true}} onOk={props.onClose} onCancel={props.onClose}>
            <Rule rule={props.rule} readOnly />
        </Modal>
    );
};

export default ViewRuleModal;
