import { useContext, useEffect, useState } from "react";
import { Quote, MISSING_IMAGE, UnassignedComponent, DashDrawingUrl, Assembly, CategoryIdAssembliesIdMap } from "../../api/models";
import { ConfiguratorContext } from "../../context";
import { Button, Modal, Row, notification, Alert, Spin, Tag } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

const QuoteDashTab = (props: {quote: Quote}) => {

  const configurator = useContext(ConfiguratorContext);
  const isAdminOrEngineering = configurator.isAdmin() || configurator.isEngineering();
  const {quote} = props;
  const [data, setData] = useState<DashDrawingUrl>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDrawing(false);
  }, [quote]);

  const getDrawing = async (regenerate: boolean) => {
    const action = regenerate ? "re-generate" : "get";
    setLoading(true);
    if (quote) {
      try {
        const resp = await configurator.api.getDashDrawing(quote.id, quote.displayRevisionId, regenerate);
        if (resp) {
          setData(resp.data);
          // setUploadSuccess(true);
          if (regenerate && resp.data.url) {
            notification.success({
              message: `Successfully ${action} drawing!`,
            });
          }
        }
      }
      catch (e) {
        console.log(e);
        // notification.error({
        //   message: `Failed to ${action} the drawing.`,
        // });
      }
      finally {
        setLoading(false);
      }
    }
  };

  return (<>
    {loading ? <Spin/> :
      (<div key={"dash-drawing"}>
        {data?.url ? 
          <div key={"alert-image"}>
            <MissingAssignmentAlert
              unassignedComponents={data?.unassignedComponents}
              uploadDashImage={data?.uploadDashImage || false}
              selections={quote.selections}
            />
            <br/>
            <img
              id='fullImage'
              src={ data?.url || MISSING_IMAGE }
              alt=""
              style={{ width: '100%', height: '100%', boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.25)" }}
            />
          </div> : 
          <p>{"No dash image has been assigned to this revision yet. Please check the template for the drawing assembly."}</p>
        }
        {isAdminOrEngineering &&
        <Row gutter={10} style={{marginTop: "1rem"}} justify={"center"}>
            <Button type="primary" onClick={() => getDrawing(true)}>Regenerate Drawing</Button>
        </Row>}
      </div>)
    }
  </>);
}

export default QuoteDashTab;

const MissingAssignmentAlert = (props: {unassignedComponents: UnassignedComponent[] | undefined, uploadDashImage: boolean, selections: CategoryIdAssembliesIdMap | undefined}) => {

  const {unassignedComponents, uploadDashImage, selections} = props;
  const [open, setOpen] = useState(false);
  const allSelections = Object.values(selections || {}).flat();

  return (<>
    <div  key="errorAlertButton">
      {
        (uploadDashImage ?? false) ?
        <Alert 
          type="info" 
          closable={true}
          message={"This is a custom dash layout."}
        />
        : 
        (!!unassignedComponents?.length && 
        <Alert 
          type="error" 
          closable={true}
          message={"Detected missing assignment, please review."}
          action={
            <Button size="small" onClick={() => {setOpen(true)}}>
              Detail
            </Button>
          }
        />)
      }
    </div>
    <Modal 
      title="Component to be assigned: "
      open={open}
      onCancel={() => {setOpen(false)}}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width="80%"
      centered
      style={{maxWidth: "70rem"}}
    >
      {unassignedComponents?.map((e, di) => 
      <Row style={{marginBottom: "5px"}} key={e.component + e.quantity}>
        <div style={{marginRight: "5px"}} >{e.component} - Quantity: {e.quantity},  Assemblies: </div>
        {Object.entries(e.assigningAssemblies).filter(v => allSelections.includes(v[1])).map((e, ei) =>
            <Paragraph key={`para-${di}-${ei}`} copyable={{text: e[0]}} style={{marginRight: "10px"}}>{e[0]}</Paragraph>
        )}
      </Row>
      )}
    </Modal>
  </>)
} 
