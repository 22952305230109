import { Alert, Col, Space, Table, notification } from "antd";
import Title from "antd/lib/typography/Title";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../context";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { Permission, UploadHistory } from "../../api/models";
import { useIntl } from "react-intl";
import dayjs from "dayjs";

const MasterSchedule = () => {
    const configurator = useContext(ConfiguratorContext);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [uploadHistory, setUploadHistory] = useState<UploadHistory[]>([]);
    const intl = useIntl();

    useEffect(() => {
      getUploadHistory();
    }, []);

    const sorting = (a: UploadHistory, b: UploadHistory) => {
      if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) return -1;
      if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return 1;
      return 0;
    }

    const getUploadHistory = async () => {
      try {
        const resp = await configurator.api.getMasterScheduleUploadHistory();
        setUploadHistory(resp.data.sort(sorting));
      }
      catch(e: any) {
        const errorMsg = intl.formatMessage({ id: e.message });
        notification.error( { message: "Failed to get upload history. " + errorMsg });
      }
    }

    const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        if(info.file) {
            if(info.file.error) {
                setErrorMessage('Failed to upload Master Schedule, please check the file and try again later.');
            }
            else {
                if(info.file.status == 'done') {
                    setSuccessMessage('Master Schedule is uploaded. Please come back and check status in the table.');
                    getUploadHistory();
                }
            }
        }

    };

    const columns = [
      {
        key: 'createdAt',
        title: 'Uploaded At',
        width: "20%",
        render: (h: UploadHistory) => <span>{dayjs(h.createdAt).format("MMM Do YYYY, h:mm:ss a")}</span>
      },
      {
        key: 'userName',
        title: 'Uploaded By',
        dataIndex: 'userName',
        width: "20%",
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        width: "60%",
      },
    ];

    return (
        <div className="site-layout-background">
            <Title level={2}>Master Schedule Import</Title>
            <Space direction="vertical">

            <div>This page is used to upload the master schedule and synchronize the data to orders.</div>
            {errorMessage && <Alert type="error" message={errorMessage}/>}
            {successMessage && <Alert type="success" message={successMessage}/>}
            <Col span={12}>
            {configurator.hasPermission(Permission.MASTER_SCHEDULE_WRITE) && 
            <div style={{height: "300px"}}>
              <Dragger name="file" multiple={false} showUploadList={true} onChange={onChange} style={{width: "50rem", marginTop: "2rem"}}
                withCredentials={true} action={configurator.api.baseUrl + '/v1/production/masterSchedule'}>

                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag the <strong>Master Schedule</strong> to this area to upload</p>
              </Dragger>
            </div>}
            </Col>
            </Space>
            {!!uploadHistory.length && 
            <Table
              bordered
              style={{width: "50rem", marginTop: "2rem"}}
              dataSource={uploadHistory}
              columns={columns}
              pagination={{pageSize: 10}}
              rowKey={"createdAt"}
              tableLayout="fixed"
            />}
        </div>
    )
};

export default MasterSchedule;
