import { useEffect } from "react";
import { Select, SelectProps, } from "antd";
import { CategoryInfo } from "../api/models";
import { useCategoryContext } from "../contexts/CategoryContext";
import Utils from "../util/util";

export interface CategoryMultiSelectorProps extends SelectProps {
  value?:CategoryInfo[]
  onChange?: (users: CategoryInfo[]) => void;
  categoryLst?:CategoryInfo[]
}

const CategoryMultiSelector = (props: CategoryMultiSelectorProps) => {
  const {categoriesAsync, loadCategories} = useCategoryContext();

  useEffect(() => {
    if ( ( categoriesAsync?.isInitial() || categoriesAsync?.isFail() ) && !categoriesAsync.isLoading() ) {
      loadCategories?.();
    }
  }, [])

  const { categoryLst:propCategoryLst, ...selectProps} = props; 
  const categoryLst = propCategoryLst || categoriesAsync?.val;
  const value = props.value?.map( u => u.id );
  const options = categoryLst?.sort((a,b) => a.name.localeCompare(b.name)).map(c => ({label: Utils.stripSortingPrefix( c.name ), value: c.id}));

  return <Select 
    {...selectProps}
    onChange={(idLst) => {
      const idSet = new Set(idLst);
      const selected = categoryLst?.filter( u => idSet.has(u.id)) || [];
      props.onChange?.( selected );
    }}
    showSearch
    optionFilterProp="label" 
    mode={"multiple"}
    value={value}
    allowClear
    options={options}
  />

};

export default CategoryMultiSelector;
