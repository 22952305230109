import {Steps, StepsProps} from "antd";
import {ReactNode, useEffect, useState } from "react";
import './transitions.css';


export interface WizardInstance {
  prevStep: () => void
  nextStep: () => void
  resetSteps: () => void
  getSteps: () => WizardStep[]
  getCurrentStep: () => WizardStep | undefined
  getLastStep: () => WizardStep | undefined
}

type WizardContent = ( (instance:WizardInstance) => ReactNode | string ) | string;
export interface WizardStep {
  key: React.Key
  title?: WizardContent
  body: WizardContent
  footer: WizardContent
  hidden?: boolean
}

const DefaultStepProps:StepsProps = {
  size: "small",
  direction: "horizontal",
  labelPlacement: "vertical",
}

export interface WizardProps  {
  initialStep?: React.Key
  steps: WizardStep[],
  showSteps?: boolean
  stepsProps?: StepsProps
  onStep?: (i:WizardInstance | undefined, s:WizardStep | undefined) => void
  viewHeight?: string
};

const getWizardContent = (content:WizardContent, instance:WizardInstance | undefined ) : string | ReactNode | undefined => {

  if ( typeof content == 'string'  ) {
    return content as string;
  }

  if (!instance) return;

  const contentFn = content as ((instance:WizardInstance | undefined) => ReactNode | string) ;
  return contentFn?.(instance);
};

export const getWizardFooter = (instance:WizardInstance | undefined, step: WizardStep | undefined) : string | ReactNode | undefined => {
  if ( !step ) return;

  return getWizardContent( step.footer, instance );
};

export const getWizardTitle = (instance:WizardInstance | undefined, step: WizardStep | undefined) : string | ReactNode | undefined => {
  if ( !step ) return;

  if ( !step.title ) return;

  return getWizardContent( step.title, instance );
};

const Wizard = (props: WizardProps ) => {

  const [activeKey, setActiveKey] = useState<React.Key | undefined>(props.initialStep);
  const [direction, setDirection] = useState<string>();

  useEffect(() => {
    const step = getCurrentStep() || steps?.[0];
    props.onStep?.( wizardInstance, step);
  }, [activeKey, props.steps.filter(s => s.hidden).map(s => s.key).join("")])

  const stepProps = props.stepsProps || DefaultStepProps;
  const steps = props.steps.filter( s => !s.hidden ) || [];

  const getStepIndex = (key:React.Key | undefined) : number | undefined => {
    if (key === undefined ) return;
    const ndx = steps.findIndex(s => s.key === key );
    if ( ndx === -1 ) return;
    return ndx;
  };
  const getStep = (key:React.Key | undefined) : WizardStep | undefined => {
    if (key === undefined ) return;
    return steps.find(s => s.key === key );
  };

  const initialStep = getStep(props.initialStep) || steps[0];

  const getLastStep = () : WizardStep | undefined => {
    return steps[ steps.length - 1 ];
  };
  const getCurrentStep = () : WizardStep | undefined => {
    return getStep(activeKey) || steps[0];
  };
  const getCurrentStepIndex = () : number | undefined => {
    return getStepIndex(activeKey);
  };
  const nextStep = () => {
    const ndx =  getCurrentStepIndex() || 0;
    const nextNdx = ( (ndx + 1) >= steps.length ) ? 0 : ndx + 1 //wrap on end
    const step = steps[nextNdx];
    setActiveKey( step.key );
    setDirection('right');
  }
  const prevStep = ()  =>{
    const ndx =  (getCurrentStepIndex() || 1) - 1;
    const step = steps[ndx];
    setActiveKey( step.key );
    setDirection('left');
  }

  const resetSteps = () => {
    const ndx = getStepIndex(initialStep?.key) || 0;
    gotoStep(ndx);
    setDirection('right');
  }
  const gotoStep = (ndx:number) => {
    const step = getStep(ndx);
    setActiveKey(step?.key)
  }

  const wizardInstance:WizardInstance = {
    nextStep,
    prevStep,
    resetSteps,
    getCurrentStep,
    getSteps: () => steps,
    getLastStep
  }

  const handleChangeStep = (stepNdx:number) => {
    gotoStep(stepNdx);
  }

  const currentNdx = getCurrentStepIndex() || 0;

  const showStepsStyle = props.showSteps === false ? { display: 'none' } : {};

  const getClassName = (ndx:number) => {
    const transitionClass = ( ndx === currentNdx ) ? 'step-enter-active'
      : direction === 'right'
        ? ndx === currentNdx - 1 ? 'step-exit step-exit-active-right'
          : 'step-enter-right'
      : ndx === currentNdx + 1 ? 'step-exit step-exit-active-left'
          : 'step-enter-left'
    return `step-content ${transitionClass}`

  }
  return <div>
    <Steps
      style={{...showStepsStyle, marginBottom: "1rem"}}
      {...stepProps}
      current={currentNdx}
      onChange={handleChangeStep}
      items={ steps.map((s, ndx) => {
        return {
          title: getWizardTitle(wizardInstance, s),
          disabled: ndx > currentNdx
        }
      })
      }
    />
    <div className="step-container" style={{height: props.viewHeight}}>
      {steps.map((item, index) => (
        <div
          key={index}
          className={getClassName(index)}
          style={{ display: index === currentNdx || index === currentNdx - 1 || index === currentNdx + 1 ? 'inline-block' : 'none' }}
        >
          {getWizardContent(item.body, wizardInstance)}
        </div>
      ))}
    </div>
  </div>

}

export default Wizard;
