import {CSSProperties, useCallback, useContext, useEffect, useRef, useState} from "react";
import {Assembly, Category, CategoryMetadata, AXIOS_CANCEL_MSG} from "../api/models";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import Utils from "../util/util";
import {ColumnType, TablePaginationConfig} from "antd/lib/table";
import { useIntl } from "react-intl";
import { debounce } from "lodash";
import { FilterValue, SorterResult, SortOrder } from "antd/es/table/interface";
import { ConfiguratorContext } from "../context";
import { Input, notification, Table } from "antd";
import axios, {CancelTokenSource} from "axios";

type  SortResult<T> = SorterResult<T> | SorterResult<T>[]

interface AssemblyFilter {
  filterQuery?: string
  filterMetadataQuery?: string
  categoryId?: number
}

const DEFAULT_PAGE_SIZE = 3;

const defaultSort = {
  columnKey: 'bom',
  order: 'ascend' as SortOrder
};

const AssemblySelectionTableWithMetadata = (props:{
  category: Category | undefined
  value?: Assembly
  onChange?: (a:Assembly | undefined) => void
}) => {

  const { category } = props;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const [filter, setFilter] = useState<AssemblyFilter>();
  const [assemblyLst, assemblyLstAsync] = useAsyncState<Assembly[]>();
  const [sort, setSort] = useState<SortResult<Assembly>>(defaultSort);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    pageSize:  DEFAULT_PAGE_SIZE,
    position: ["bottomLeft"],
    current: 1,
  });

  useEffect(() => {
    assemblyLstAsync.setInit();

    const filter = {categoryId:category?.id}
    setFilter(filter);
    loadAssemblyLst(assemblyLstAsync, pagination, filter, sort);
  },[category]);

  const loadAssemblyLst = useCallback(debounce( async (assemblyLstAsync:AsyncState<Assembly[]>, pagination: TablePaginationConfig, filter:AssemblyFilter | undefined, sorter:SortResult<Assembly>) : Promise<Assembly[] | undefined> => {

    const sort = {
      sortFields: [sorter].flat().map(s => s.field).join(","),
      sortDirections: [sorter].flat().map(s => s.order === 'descend' ? 'desc' : 'asc').join(",")
    }

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    assemblyLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchFilteredAssemblies({
          page: (pagination.current || 1) - 1,
          size: pagination.pageSize || 5,
          ...filter,
          ...sort,
        },
        cancelSource.token,
      );
      cancelTokenSourceRef.current = undefined;

      assemblyLstAsync.setDone(resp.data.content);
      setPagination({ ...pagination, current:resp.data.pageable.pageNumber + 1, pageSize:resp.data.pageable.pageSize, total: resp.data.totalElements });
      return resp.data.content;
    } catch (e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id: e.message });
        notification.error( { message: "Failed to get assemblies. " + errorMsg });
        assemblyLstAsync.setFail(e.message);
      }
    }

    return;
  }, 400), []);


  const datasource = assemblyLst;
  const selectedCategory = props.category;

  const getColumnForMetadata = ( md:CategoryMetadata ) : ColumnType<Assembly> => {

    return {
      key: md.id,
      title: md.name,
      width: 150,
      render: (s:Assembly) => 
        s.metadata.filter( md1 => md1.categoryMetadata.id == md.id)?.map(Utils.getMetadataValue).find(v=>v),
    };

  };

  const labelStyle =  { wordWrap: "break-word", wordBreak: "break-word", width: "20rem", } as CSSProperties;

  const metadataColumns = selectedCategory?.metadata?.sort((a,b) => (a.sortOrder || 0 ) - ( b.sortOrder || 0 ) )
    .map( md  => getColumnForMetadata( md ) ) || [];

  const columns:ColumnType<Assembly>[] = [
    {
      key: selectedCategory?.categoryId,
      title: "Name",
      fixed: "left",
      render: (a: Assembly) => <div style={labelStyle}>{!!a.label?.length ? a.label : a.bomDescription}, <span style={{whiteSpace:"nowrap"}}>{a.bom}</span></div>
    },
    ...metadataColumns
  ];

  const handleSelectRow = <T extends Assembly>(record: T, selected: boolean, selectedRows: T[], nativeEvent: Event) => {
    props.onChange?.(record);
  }

  const handleTableChange = async (pagination: TablePaginationConfig, _filters: Record<string, FilterValue | null>, _sorter: SorterResult<any> | SorterResult<any>[] | undefined) => {
    setPagination(pagination);
    loadAssemblyLst(assemblyLstAsync, pagination, filter, sort);
  }

  const handleChangeSearch = (filterQuery:string) => {
    const f = {...filter, filterQuery, filterMetadataQuery: filterQuery};
    const pg = {...pagination, current: 1, };
    setPagination(pg);
    setFilter(f);
    loadAssemblyLst(assemblyLstAsync, pg, f, sort);
  }
  return <>
    <div style={{width: "100%", margin: ".5rem 0 .5rem 0"}}>
      <Input onChange={(e) => handleChangeSearch( e.target.value)} placeholder="Search assemblies and metadata" />
    </div>
    <Table
      bordered
      size="small"
      rowKey="id"
      loading={assemblyLstAsync.isLoading()}
      columns={columns}
      dataSource={datasource}
      onChange={handleTableChange}
      pagination={pagination}
      rowSelection={{
        type: 'radio',
        onSelect: handleSelectRow,
        selectedRowKeys: props?.value?.id ? [props.value.id] : [],
        hideSelectAll: true,
      }}
      scroll={{ x: true }}
    />
  </>

}

export default AssemblySelectionTableWithMetadata;

