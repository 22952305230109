import './IncentiveProgramSelect.css';
import {Button, Card, Col, Modal, notification, Row, Spin, Table} from "antd";
import {ColumnType} from "antd/lib/table";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {IncentiveProgram, IncentiveProgramInfo} from "../../api/models";
import {ConfiguratorContext} from "../../context";
import { useAsyncState } from "../../hook/useAsyncState";
import BMButton, {BMButtonProps} from '../BMButton';
      
type IncentiveProgramSelectProps = Omit<BMButtonProps, "id" | "value" | "onChange"> & {
  id?:string
  value?: IncentiveProgramInfo[] 
  onChange?: (v: IncentiveProgramInfo[] | undefined) => void
  modelId:number | undefined
};

const IncentiveProgramSelect = (props:IncentiveProgramSelectProps) => {

  const { id:a, value:b, onChange:c, modelId:d, ...btnProps } = props;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>();
  const [incentiveProgramLst, incentiveProgramLstAsync] = useAsyncState<IncentiveProgram[]>();
  const [selectedIncentivePrograms, setSelectedIncentivePrograms] = useState<IncentiveProgramInfo[]>();

  const loadIncentivePrograms = async () => {
    if ( !props.modelId ) return;

    incentiveProgramLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchIncentivePrograms( props.modelId );
      incentiveProgramLstAsync.setDone(resp.data);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get incentive programs. " + errorMsg });
      incentiveProgramLstAsync.setFail(e.message);
    }
  };

  const handleDoneBtn = async () => {
    props.onChange?.(selectedIncentivePrograms);
    handleCancel();
  }

  const handleCancel = () => {
    setIsOpen(false);
    setSelectedIncentivePrograms(undefined);
    setErrorMessage(undefined);
  }

  const handleRemove = () => {
      setSelectedIncentivePrograms(undefined);
  }

  const handleOpen = () => {
    setSelectedIncentivePrograms(props.value);
    setIsOpen(true)
  }

  const handleSelectRow = (record:IncentiveProgram) => {
    
    //toggle previously selected
    if ( selectedIncentivePrograms?.includes( record ) ) {
      setSelectedIncentivePrograms(selectedIncentivePrograms.filter( i => i.id != record.id ) );
      return;
    }

    setSelectedIncentivePrograms([record]);
  }

  const errMsg = errorMessage && `An error occured: ${intl.formatMessage({ id: errorMessage })}`;

  var columns:ColumnType<IncentiveProgram>[] = [{
    render: (record) => {
      return <Card title={record.name} style={{borderRadius: "15px"}} hoverable={true} size="small">
        <div >{record.description}</div>
      </Card>
    }
  }];

  const datasource = incentiveProgramLst
    //hide disabled incentive program unless already selected
    ?.filter( ip => ip.enabled || props.value?.some(sip => sip.id === ip.id ) );

  const incentiveProgramsLbl = props.value?.map( i => i.name).join(", ") || ( props.disabled ? "None" : "Add Incentive Program" );

  //hide border when disabled
  const btnStyle =  ( props.disabled )
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

  return <>
    <BMButton type="text"
      className="ghostBmButton"
      {...btnProps}
      onClick={handleOpen}
      style={{padding: "0"}}
    ><span style={btnStyle}>{incentiveProgramsLbl}</span></BMButton>

    <Modal
      title="Select Incentive Program"
      open={isOpen}
      onCancel={handleCancel}
      onOk={handleDoneBtn}
      footer={
        <Row justify="space-between">
          <Col>
            <Button danger onClick={handleRemove}>Remove</Button>
          </Col>
          <Col>
            <Button  onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={handleDoneBtn}>Done</Button>
          </Col>
        </Row>
      }
      afterOpenChange={(open) => {
        if( open ) {
          loadIncentivePrograms();
        }
      }}
    >
      <Spin spinning={incentiveProgramLstAsync.isLoading()}>
          <div>
            <Table 
              className="incentiveProgramLst"
              showHeader={false}
              columns={columns}
              rowKey="id"
              dataSource={datasource}
              pagination={((incentiveProgramLst?.length || 0 ) > 3) ? { pageSize: 3 } : false  } 
              onRow={(record, _rowIndex) => {
                return {
                  onClick: () => handleSelectRow(record)
                };
              }}
              rowSelection={{
                type: "radio",
                onSelect: handleSelectRow,
                selectedRowKeys: [...(selectedIncentivePrograms?.map( ip => ip.id ) || [])],
                getCheckboxProps: (record) => {
                  return { 
                    disabled: !record.enabled
                  };
                }
              }}
            />

            <div style={{color: "red", maxHeight: "5.5rem", overflow:"auto", whiteSpace:"pre-line" }}>{errMsg}</div>
          </div>
      </Spin>
    </Modal>
  </>;

}

export default IncentiveProgramSelect;
