import "../../util/mobile-table.css";
import Title from "antd/lib/typography/Title";
import { Table, Input, Button, Space, notification, Row, Col, Form, Collapse, Select, Tooltip } from "antd";
import  { useCallback, useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../context";
import dayjs from 'dayjs'
import { ArrayParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../../util/util";
import { Customer, EntityType, PAGINATION_MAX_PAGE_SIZE, Permission, QuoteFilter, SALES_ORDER_REPORT, } from "../../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import {debounce} from "lodash";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import { getCSVRow } from "../../helpers/csv";
import {Link} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import IncentiveProgramSelector from "../../components/incentive_program_selector";
import DealerMultipleSelector from "../../components/DealerMultipleSelector";
import SaveFilter from "../../components/widgets/SaveFilter";
import { AdvancedSearchConfig } from "../../components/QuoteFilterControls";

type  SalesOrderQuoteSort = SorterResult<SalesOrderQuote> | SorterResult<SalesOrderQuote>[]
type  TableOnChangeFn = (p:TablePaginationConfig, f:Record<string, FilterValue | null>, s: SalesOrderQuoteSort) => void

export interface SalesOrderQuote {
quoteId                            : string
endCustomer                        : Customer
dealerName                         : string
status                             : string
quantity                           : number
lastApprovalDate?                  : Date
orderSubmitDate?                   : Date
quoteApprovalDate?                 : Date
totalPrice                         : number
}

const SalesOrdersReport = () => {
  const [_quoteList, quoteListAsync] = useAsyncState<SalesOrderQuote[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [incentiveProgramsParam, setIncentiveProgramParam] = useQueryParam<any>("incentivePrograms", ArrayParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [entityTypeFilterParam, setEntityTypeFilterParam] = useQueryParam<any>("entityTypes", ArrayParam);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [filter, setFilter] = useState<QuoteFilter>({
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    incentivePrograms: [incentiveProgramsParam as (string|null) || []].flat(),
    includingArchived: true,
    entityTypes: [entityTypeFilterParam as (string|null) || []].flat(),
    myQuotes: false,
  });

  const [sort, setSort] = useState<SalesOrderQuoteSort>({
    columnKey: sortFieldQueryParam || 'lastApprovalDate',
    order: sortDirectionQueryParam === 'ascend' ? 'ascend' : 'descend' //for typescript
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 20 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
  });


  useEffect(()=> {
    if ( quoteListAsync.isFail() )  {
      notification.error({ message:"Quotes failed to load. " + quoteListAsync.err });
    }
  }, [ quoteListAsync.state ] );

  useEffect(() => {
    loadQuotes(quoteListAsync, pagination, filter , sort );
  }, [pagination.pageSize, pagination.current, filter, sort]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  const loadQuotes = useCallback(debounce((quoteListAsync:AsyncState<SalesOrderQuote[]>,  pagination: TablePaginationConfig, filter: QuoteFilter, sorter:any ) => {

      quoteListAsync.setLoading();
      configurator.api.fetchSalesOrdersReport({
        ...filter,
        page: ( pagination.current || 1 ) - 1,
        size: pagination.pageSize || 20,
        sort: {
          field: sorter.columnKey?.toString(),
          direction: sorter.order === 'ascend' ? 'asc' : 'desc',
        }
      })
      .then( resp => {
      quoteListAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    },
    (e:any) => {
      console.log(e);
      quoteListAsync.setFail( e.message );
    });
  }, 400 ), [] );

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SorterResult<SalesOrderQuote> | SorterResult<SalesOrderQuote>[]) => {
    setPagination(pagination);

    const firstSort = [ sorter ].flatMap(v => v)[0];
    setSortFieldQueryParam( String( firstSort.columnKey ) );
    setSortDirectionQueryParam( firstSort.order );
    setSort(sorter);
  };

  const onFilterChange = (filter: QuoteFilter) => {
    setSearchFilterParam(filter.search);
    setDealerFilterParam( filter.dealerLst );
    setIncentiveProgramParam(filter.incentivePrograms);
    setPagination({ ...pagination, current: 1 });
    setEntityTypeFilterParam(filter.entityTypes);
    setFilter(filter);
  }

  const exportOrders = async () => {
    try {
      setIsExporting(true);
      const resp = (await configurator.api.fetchSalesOrdersReport({
        ...filter,
        ordersOnly: true,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE, 
        sort: {
          direction: 'desc',
          field: 'lastApprovalDate',
        }
      })).data;

      var csv = [['Quote Id', 'Customer', 'Dealer', 'Status', '# Trucks', 'Price', 'Quote Approval Date', 'Order Submit Date', 'Last Approval Date'].join(',')];
      resp.content.forEach((quote: any) => {
        csv.push(getCSVRow([
          quote.quoteId,
          quote.endCustomer?.name || "",
          quote.dealerName || "",
          quote.status,
          quote.quantity,
          Utils.formatMoney( quote.totalPrice ),
          quote?.quoteApprovalDate ? dayjs(quote?.quoteApprovalDate).format("MM/DD/YYYY") : "",
          quote?.orderSubmitDate ? dayjs(quote?.orderSubmitDate).format("MM/DD/YYYY") : "",
          quote?.lastApprovalDate ? dayjs(quote?.lastApprovalDate).format("MM/DD/YYYY") : "",
        ]));
      });
      var blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'orders-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="site-layout-background">
      <Title level={2}>Sales Orders Report</Title>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <FilterControls 
          filter={filter}
          onFilterChange={onFilterChange}
        />
        <DesktopTable
          quoteListAsync={quoteListAsync}
          tableOnChange={tableOnChange}
          pagination={pagination}
          isExporting={isExporting}
          exportOrders={exportOrders}
        />
      </Space>
    </div>
  );
};


const FilterControls = (props:{
  filter?: QuoteFilter
  onFilterChange: ( f: QuoteFilter ) => void
}) => {
  const { filter, onFilterChange } = props;

  const configurator = useContext(ConfiguratorContext);

  const [form] = useForm();

  const showClearFilter = !!(Object.values(form.getFieldsValue()).filter(v => v).flat().length);
  
  const clearFilter = () => {
    let setObject = {...form.getFieldsValue()};
    form.resetFields();

    Object.keys(setObject).forEach(key => {
      setObject[key] = undefined;
    });

    form.setFieldsValue(setObject);
    props.onFilterChange(setObject);
  }

  const onValuesChange = (_update:any, allValues: {search: string, incentivePrograms: string[], engineers: string[], dealerLst: string[], entityTypes: string[]}) => {
    onFilterChange( {
      ...filter,
      search: allValues.search,
      dealerLst: allValues.dealerLst,
      incentivePrograms: allValues.incentivePrograms,
      engineers: allValues.engineers,
      entityTypes: allValues.entityTypes,
      } );
  }
  const showAdvancedPanel = filter?.filterAssemblies?.length ||
    filter?.quoteStatus ||
    filter?.salespersons?.length ||
    filter?.engineers?.length ||
    filter?.ordersOnly ||
    filter?.incentivePrograms?.length ||
    filter?.entityTypes?.length;

  return <>
      <style>
      {`
        .clear-filter-button {
          margin-top: 3px;
          border: 1px solid red !important;
          color: red !important;
        }

        .clear-filter-button:hover {
          margin-top: 3px;
          border: 1px solid red !important;
          color: white !important;
          background-color: red !important;
        }
        
        .custom-form-item .ant-form-item {
          margin-bottom: 15px !important;
        }
      `}
    </style>
    <Form
      form={form}
      onValuesChange={onValuesChange}
      initialValues={filter}
      layout="vertical"
      className="custom-form-item"
    >
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <Row gutter={[32, 8]}>
          <Col xs={22} xxl={7}>
            <Form.Item name="search">
              <Input
                allowClear
                value={filter?.search}
                placeholder="Search by quote name, ID, and more."
              />
            </Form.Item>
          </Col>
          {(!configurator.isDealerSales() || configurator.hasPermission(Permission.DEALER_ADMIN_READ)) &&
          <Col xs={22} xxl={7}>
            <Form.Item name="dealerLst">
              <DealerMultipleSelector value={filter?.dealerLst}   style={{ width: "100%" }} placeholder="Filter by Dealer" />
            </Form.Item>
          </Col>}
          <div>
            <SaveFilter tableName={SALES_ORDER_REPORT} size="small" style={{marginTop: "3px", marginRight: "10px"}} />
          </div>
          <div>
            {showClearFilter && <Tooltip title="Reset Filter"><Button size="small" onClick={clearFilter} rootClassName="clear-filter-button">Reset</Button></Tooltip>}
          </div>
        </Row>

        <Collapse
          style={{ width: '100%', marginTop: "-1rem" }}
          defaultActiveKey={showAdvancedPanel ? "advanced-search" : undefined }
          {...AdvancedSearchConfig}
          items={
            [{
              key: "advanced-search",
              label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
              forceRender: true,
              children: 
              <Row gutter={[32, 8]}>
                <Col xs={22} xxl={7}>
                  <Form.Item
                    name="incentivePrograms"
                    label="Incentive Programs"
                  >
                    <IncentiveProgramSelector style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={22} xxl={7}>
                  <Form.Item
                    name="entityTypes"
                    label="Customer Entity Type"
                  >
                    <Select
                      options={Object.values(EntityType).map(val => {return {
                        label: Utils.snakeCaseToFirstLetterCapitalized(val),
                        value: String(val),
                      }})}
                      allowClear
                      optionFilterProp="label"
                      mode={"multiple"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            }]
        } 
        />
      </Space>
    </Form>
  </>
}

const DesktopTable = (props: {
  quoteListAsync: AsyncState<SalesOrderQuote[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
  isExporting: boolean
  exportOrders:()=>void
}) => {
  const { quoteListAsync, tableOnChange, pagination, isExporting, exportOrders, } = props;
  const quoteList = quoteListAsync.val;

  const columns:ColumnType<SalesOrderQuote>[] = [
    {
      title: "Quote",
      key: "quoteId",
      render: (q) => <>
        <Link to={"/configurator/" + encodeURI(q.quoteId)}>
          <div className="ellipsis" style={{maxWidth: "25rem"}}>{q.name}</div>
        </Link>
        <div>({q.quoteId})</div>
      </>
    },
    {
      title: "Customer",
      key: "customer",
      render: (q) => q.endCustomer?.name,
    },
    {
      title: "Dealer",
      dataIndex: "dealerName",
    },
    {
      title: "Status",
      key: "status",
      render: (q) => Utils.formatQuoteStatus(q),
    },
    {
      title: "# Trucks",
      key: "quantity",
      render: (q) => q.quantity,
    },
    {
      title: "Price",
      key: "price",
      render: (q) => Utils.formatMoney( q.totalPrice ),
    },
    {
      title: "Quote Approval Date",
      key: "quoteApprovalDate",
      sorter: true,
      render: (q) => (
        <span>
          {q?.quoteApprovalDate ? dayjs(q?.quoteApprovalDate).format("MM/DD/YYYY") : ""}
          </span>
      ),
    },
    {
      title: "Order Submit Date",
      key: "orderSubmitDate",
      sorter: true,
      render: (q) => (
        <span>
          {q?.orderSubmitDate ? dayjs(q?.orderSubmitDate).format("MM/DD/YYYY") : ""}
          </span>
      ),
    },
    {
      title: "Last Approval Date",
      key: "lastApprovalDate",
      sorter: true,
      render: (q) => (
        <span>
          {q?.lastApprovalDate ? dayjs(q?.lastApprovalDate).format("MM/DD/YYYY") : ""}
          </span>
      ),
    },
  ];

  //quick fix to handle no elements
  const newButtonStyle:any = pagination.total
    ? { position: "absolute", top: "1rem", zIndex: 1, right: "0px" }
    : { float: "right", marginBottom: "1rem" };


  return <>
        <div style={{ position: "relative" }}>
          <Row gutter={12} justify={"end"} style={newButtonStyle}>
            <Col>
              <Button type="primary" loading={isExporting} onClick={exportOrders}>Export</Button>
            </Col>
          </Row>
          <Table
            loading={quoteListAsync.isLoading()}
            onChange={tableOnChange}
            bordered
            pagination={pagination}
            dataSource={quoteList}
            columns={columns}
            rowKey="quoteId"
          />
        </div>
    </>
}



export default SalesOrdersReport;
