import { Row, Modal, Transfer, notification, Form, Select, ButtonProps } from "antd";
import { useState, useContext, useCallback } from "react";
import { TruckRecord, BaseQuote, SortDirection } from "../../api/models"
import { ConfiguratorContext } from "../../context";
import type { TransferDirection } from 'antd/es/transfer';
import BMButton from "../BMButton";
import {useIntl} from "react-intl";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import {debounce} from "lodash";
import { useQuoteContext } from "../../contexts/QuoteContext";

const MoveTrucksButton = (props:{
} & ButtonProps) => {

  const {quoteAsync, loadQuote} = useQuoteContext();
  const quote = quoteAsync?.val;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [transferTargetKeys, setTransferTargetKeys] = useState<string[]>([]);
  const [transferSelectedKeys, setTransferSelectedKeys] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>();
  const [quoteLst, quoteLstAsync] = useAsyncState<BaseQuote[]>();
  const [dstRevisionId, setDstRevisionId] = useState<number>();

  const truckLst:TruckRecord[] = quote?.trucks
    ?.map(truck => ({key: truck.truckSerialNumberStr, truckSerialNumberStr: truck.truckSerialNumberStr}))
    .sort((a, b) => (a.truckSerialNumberStr.localeCompare(b.truckSerialNumberStr))) || [] ;

    const loadQuotes = useCallback(debounce( async (quoteListAsync:AsyncState<BaseQuote[]>, search?:string) => {

      const sort = {
        field: 'updatedAt',
        direction: 'desc' as SortDirection
      };

      quoteListAsync.setLoading();
      try {
        const resp = await configurator.api.listQuotes({
          search,
          ordersOnly: true,
          includingArchived: false,
          page: 0,
          size: 5,
          sort,
        });

        quoteListAsync.setDone(resp.data.content);
        return resp.data.content;
      }
      catch(e: any) {
        const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
        notification.error( { message: "Quotes failed to load. " + errorMsg });
        quoteListAsync.setFail(e.message);
      }
      return;
    }, 400), []);


  const onTransferItemsChange = (nextTargetKeys: any[], _direction: TransferDirection, _moveKeys: any[]) => {
    setTransferTargetKeys(nextTargetKeys);
  };

  const onTransferSelectChange = (sourceSelectedKeys: any[], targetSelectedKeys: any[]) => {
    setTransferSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleOkBtn = async () => {
    await moveTruck();
    setIsOpen(false) ;

    //reload source quote
    loadQuote?.();
  }
  
  const moveTruck = async () => {
    if ( !dstRevisionId ) return;
    if ( !quote?.quoteId ) return;

    try {
      quoteAsync?.setLoading();
      const truckSnLst = transferTargetKeys.map( sn => Number(sn));
      await configurator.api.moveTrucks(truckSnLst, dstRevisionId);
      quoteAsync?.setDone(quote);
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to move trucks. " + errorMsg });
      quoteAsync?.setFail(e.message);
    }
  }

  return <>
    <BMButton {...props}
      onClick={() => {setIsOpen(true)}}
    >Move Trucks</BMButton>

    <Modal title="Move Trucks" 
      width={"40rem"}
      open={isOpen} 
      onOk={handleOkBtn}
      onCancel={() => setIsOpen(false)} 
      confirmLoading={quoteAsync?.isLoading()}
      afterOpenChange={(open) => {
        if ( open  ) {
          loadQuotes(quoteLstAsync);
        }
      } }
    >
      <Row>          
        <Transfer
          style={{margin: "0 auto", marginBottom: "3rem"}}
          dataSource={truckLst}
          titles={['Stay', 'Move']}
          targetKeys={transferTargetKeys}
          selectedKeys={transferSelectedKeys}
          onChange={onTransferItemsChange}
          onSelectChange={onTransferSelectChange}
          render={item => item.truckSerialNumberStr}
          pagination={{pageSize: 5}}
          locale={{itemUnit: 'Truck', itemsUnit: 'Trucks'}}
        />
      </Row>

      <Form.Item label="Destination Quote" >
        <Select 
          value={dstRevisionId}
          onChange={setDstRevisionId}
          allowClear
          showSearch
          style={{width:"100%", minWidth:"10rem"}} 
          optionFilterProp="label"
          onSearch={(s) => loadQuotes(quoteLstAsync, s )}
          loading={quoteLstAsync.isLoading()}
          options={quoteLst?.map(c => ({label: `${c.quoteId.split("-")[1]} - ${c.name}`, value: c.displayRevisionId}))} 
        />
      </Form.Item>
    </Modal>
  </>
}

export default MoveTrucksButton;
