import { useContext, useEffect } from "react";
import { ConfiguratorContext } from "../context";
import { notification, Select } from "antd";
import {useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import {Customer} from "../api/models";

const QuoteEndCustomerSelector = (props: {
  id?:string
  value?: number[] 
  onChange?: (endCustomers: number[]) => void;
  style?: any;
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [endCustomerLst, endCustomerLstAsync] = useAsyncState<Customer[] | undefined>();

  useEffect(() => {
    loadEndCustomers();
  }, []);


  const loadEndCustomers = async () => {
    try {
      const resp = await configurator.api.getCustomers();
      endCustomerLstAsync.setDone(resp.data);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get customers. " + errorMsg });
      endCustomerLstAsync.setFail(e.message);
    }
  };

  return (
    <Select
      onChange={props.onChange}
      defaultValue={props.value}
      allowClear
      showSearch={true}
      style={props.style}
      optionFilterProp="label"
      options={ endCustomerLst?.map(d => ({
        value: d.id, label:d.name
      }))}
      mode="multiple"
    >
    </Select>
  );
};

export default QuoteEndCustomerSelector;
