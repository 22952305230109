import "../../util/mobile-table.css";
import styles from "../orders.module.css";
import Title from "antd/lib/typography/Title";
import { Table, Input, Button, Space, notification, Checkbox, Row, Col, Select, Form, DatePicker, Tooltip } from "antd";
import  { useCallback, useContext, useEffect, useState } from "react";
import { ConfiguratorContext } from "../../context";
import { ArrayParam, BooleanParam, NumberParam, NumericArrayParam, StringParam, useQueryParam } from "use-query-params";
import { BaseQuote, DateFilterType, PAGINATION_MAX_PAGE_SIZE, PRICING_REPORT, Permission, QuoteFilter, SortDirection, } from "../../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import {debounce} from "lodash";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import { getCSVRow } from "../../helpers/csv";
import {Link} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import _ from "lodash";
import QuoteShippingDestinationSelector from "../../components/quote_shippingDestination_selector";
import QuoteEndCustomerSelector from "../../components/quote_endCustomer_selector";
import IncentiveProgramSelector from "../../components/incentive_program_selector";
import DealerMultipleSelector from "../../components/DealerMultipleSelector";
import Utils from "../../util/util";
import dayjs from "dayjs";
import SaveFilter from "../../components/widgets/SaveFilter";
import useDateTypeAndRangeHook from "../../hook/useDateTypeAndRangeHook";

const { RangePicker } = DatePicker;

type  PricingReportDataSort = SorterResult<PricingReportData> | SorterResult<PricingReportData>[]
type  TableOnChangeFn = (p:TablePaginationConfig, f:Record<string, FilterValue | null>, s: PricingReportDataSort) => void

export interface PricingReportData {
  quote: BaseQuote
  calculatedPrice?: number
  savedPrice?: number
  priceMatch?: number
  poUnitAmount?: number
}

const PricingReport = () => {
  const [reportLst, reportLstAsync] = useAsyncState<PricingReportData[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [incentiveProgramsParam, setIncentiveProgramsParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [ordersOnlyParam, setOrdersOnlyParam] = useQueryParam<boolean|undefined|null>("ordersOnly", BooleanParam);
  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const [endCustomerParam, setEndCustomerParam] = useQueryParam("endCustomerIds", NumericArrayParam);
  const [shippingDestinationParam, setShippingDestinationParam] = useQueryParam("shippingDestinationIds", NumericArrayParam);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [filter, setFilter] = useState<QuoteFilter>({
    myQuotes: false,
    includingArchived: false,
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    ordersOnly: ordersOnlyParam || false,
    dateFilterType: dateFilterTypeParam as DateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : undefined,
    dateFilterEnd: dateFilterStartParam ? dayjs(dateFilterEndParam) : undefined,
    endCustomerId: endCustomerParam as number[] || undefined,
    shippingDestinationId: shippingDestinationParam as number[] || undefined,
  });

  const [sort, setSort] = useState<PricingReportDataSort>({
    columnKey: sortFieldQueryParam || 'quoteId',
    order: sortDirectionQueryParam === 'ascend' ? 'ascend' : 'descend' //for typescript
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? 100 : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
  });

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
    loadQuotes( reportLstAsync, pagination, filter , sort );
  }, [pagination.pageSize, pagination.current, sort ]);

  const loadQuotes = useCallback(debounce(( reportLstAsync:AsyncState<PricingReportData[]>,  pagination: TablePaginationConfig, filter: QuoteFilter | undefined, sorter:PricingReportDataSort ) => {

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || "quoteId",
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));

    reportLstAsync.setLoading();
    configurator.api.fetchPricingReport({
      ...filter,
      page: ( pagination.current || 1 ) - 1,
      size: pagination.pageSize || 20,
      sort
    })
    .then( resp => {
      reportLstAsync.setDone( resp.data.content.filter(v=>v) as PricingReportData[]);
      setPagination({ ...pagination, total: resp.data.totalElements });
    },
    (e:any) => {
      console.log(e);
      reportLstAsync.setFail( e.message );
      notification.error({ message:"Quotes failed to load. " + e.message });
    });
  }, 400 ), [] );

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: SorterResult<PricingReportData> | SorterResult<PricingReportData>[]) => {
    setPagination(pagination);

    const firstSort = [ sorter ].flatMap(v => v)[0];
    setSortFieldQueryParam( String( firstSort.columnKey || "quoteId" ) );
    setSortDirectionQueryParam( firstSort.order );

    setSort(sorter);
  };

  const getFilterWithDateRange = (filter: QuoteFilter) => {
    return filter['dateRange'] ? {...filter, dateFilterStart: filter['dateRange'][0], dateFilterEnd: filter['dateRange'][1]} : filter;
  }

  const onFilterChange = debounce( (_values: Record<string, any>, filter:QuoteFilter) => {
    filter = getFilterWithDateRange(filter);
    setSearchFilterParam(filter.search);
    setDealerFilterParam( filter.dealerLst );
    setIncentiveProgramsParam(filter.incentivePrograms?.join(","));
    setOrdersOnlyParam(filter.ordersOnly);
    filter.dateFilterType && setDateFilterTypeParam(filter.dateFilterType);
    filter['dateRange'] && setDateFilterStartParam((filter['dateRange']?.[0]).toString());
    filter['dateRange'] && setDateFilterEndParam((filter['dateRange']?.[1]).toString());
    !!filter.endCustomerId?.length && setEndCustomerParam(filter.endCustomerId);
    !!filter.shippingDestinationId?.length && setShippingDestinationParam(filter.shippingDestinationId);
    setFilter( filter )
    setPagination({ ...pagination, current: 1 });
    loadQuotes( reportLstAsync, pagination, filter , sort );
  }, 300);

  const resetQueryParam = () => {
    !!searchFilterParam && setSearchFilterParam(undefined);
    !!dealerFilterParam?.length && setDealerFilterParam( undefined );
    !!ordersOnlyParam && setOrdersOnlyParam(undefined);
    !!incentiveProgramsParam && setIncentiveProgramsParam(undefined);
    !!dateFilterTypeParam && setDateFilterTypeParam(undefined);
    !!dateFilterStartParam && setDateFilterStartParam(undefined);
    !!dateFilterEndParam && setDateFilterEndParam(undefined);
    !!endCustomerParam?.length && setEndCustomerParam(undefined);
    !!shippingDestinationParam?.length && setShippingDestinationParam(undefined);
  }

  const exportReport = async (columns:ColumnType<PricingReportData>[]) => {
    try {

      const sorter = [sort].flat().map( sorter => ({
        field: sorter.columnKey?.toString() || "quoteId",
        direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
      }));

      setIsExporting(true);
      const resp = (await configurator.api.fetchPricingReport({
        ...filter,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE, 
        sort: sorter,
      })).data;

      var csv = [columns.map( c => c.title?.toString() || '' ).join(',')];

      (resp.content.filter(v=>v) as PricingReportData[]).forEach((q: PricingReportData) => {

        const baseRow = [
          q.quote.quoteId,
          q.quote.name,
          Utils.formatMoney( q.calculatedPrice ),
          Utils.formatMoney( q.savedPrice ),
          `${Utils.formatMoney( q.poUnitAmount )} (${q.quote?.quantity})`,
          Utils.formatMoney( q.priceMatch ),
        ]

        csv.push(getCSVRow(baseRow));

      });
      var blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'quote-pricing-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="site-layout-background">
      <Title level={2}>Pricing Report</Title>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <FilterControls 
          filter={filter}
          onFilterChange={onFilterChange}
          resetQueryParam={resetQueryParam}
        />
        <DesktopTable
          quoteListAsync={reportLstAsync}
          tableOnChange={tableOnChange}
          pagination={pagination}
          exportOrders={(c) => exportReport( c ) }
          isExporting={isExporting}
        />
      </Space>
    </div>
  );
};


const FilterControls = (props:{
  filter?: QuoteFilter
  onFilterChange: (_values: Record<string, any>, filter:QuoteFilter) => void
  resetQueryParam?: () => void
}) => {

  const [filterForm] = useForm();
  const configurator = useContext(ConfiguratorContext);

  const {validateDateFilterType, validateRangePicker, filterChange} = useDateTypeAndRangeHook(filterForm, "dateFilterType", "dateRange", props?.onFilterChange);

  const getDateRange = (filter: QuoteFilter | undefined) => {
    if (filter?.dateFilterStart && filter?.dateFilterEnd) {
      return [dayjs(filter.dateFilterStart), dayjs(filter.dateFilterEnd)];
    }
    return undefined;
  }

  const filterWithDateRange = {...props.filter, dateRange: getDateRange(props.filter)}

  const showClearFilter = !!(Object.values(filterForm.getFieldsValue()).filter(v => v).flat().length);

  const clearFilter = () => {
    let setObject = {...filterForm.getFieldsValue()};
    filterForm.resetFields();

    Object.keys(setObject).forEach(key => {
      setObject[key] = undefined;
    });

    filterForm.setFieldsValue(setObject);
    props.onFilterChange({}, setObject);
    props.resetQueryParam?.();
  }

  return <>
    <style>
      {`
        .clear-filter-button {
          margin-top: 3px;
          border: 1px solid red !important;
          color: red !important;
        }

        .clear-filter-button:hover {
          margin-top: 3px;
          border: 1px solid red !important;
          color: white !important;
          background-color: red !important;
        }

        .custom-form-item .ant-form-item {
          margin-bottom: 0px !important;
        }
      `}
    </style>
    <Form 
      initialValues={filterWithDateRange}
      form={filterForm} 
      onValuesChange={filterChange}
      layout="vertical"
      className="custom-form-item"
    >
    <div className={styles["filter-controls"]}>
      <div className={styles["line1"]}>
        <Form.Item name="search" >
          <Input
            allowClear
            placeholder="Search by quote name, ID, and more."
          />
        </Form.Item>
      </div>

      <Form.Item name="myQuotes" >
        <Input type="hidden"  />
      </Form.Item>
      <Form.Item name="archived" >
        <Input type="hidden"  />
      </Form.Item>

      {(!configurator.isDealerSales() || configurator.hasPermission(Permission.DEALER_ADMIN_READ)) && <>
        <div className={styles["line1"]} >
          <Form.Item name="dealerLst" >
            <DealerMultipleSelector style={{width: "100%"}} placeholder="Filter by Dealer" />
          </Form.Item>
        </div>
      </>}
      <div className={styles["line2"]}>
        <div>
          <Form.Item
            name="ordersOnly"
            valuePropName="checked"
          >
          <Checkbox>Orders Only</Checkbox>
          </Form.Item>
        </div>
        <div>
          <SaveFilter tableName={PRICING_REPORT} size="small" style={{marginTop: "3px"}} />
        </div>
        <div>
          {showClearFilter && <Tooltip title="Reset Filter"><Button size="small" onClick={clearFilter} rootClassName="clear-filter-button">Reset</Button></Tooltip>}
        </div>
      </div>
    </div>

      <Row gutter={12}>
        <Col xs={22} xxl={7}>
          <Form.Item
            name="shippingDestinationId"
            label="Shipping Destination"
          >
            <QuoteShippingDestinationSelector  style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={22} xxl={7}>
          <Form.Item
            name="endCustomerId"
            label="End Customer"
          >
            <QuoteEndCustomerSelector  style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={22} xxl={7}>
          <Form.Item
            name="incentivePrograms"
            label="Incentive Programs"
          >
            <IncentiveProgramSelector style={{ width: '100%' }} />
          </Form.Item>

        </Col>

      </Row>

      <Row gutter={12}>
        <Col xs={8} xxl={7}>
          <Form.Item
            name="dateFilterType"
            label="Date Filter"
            rules={[{ validator: validateDateFilterType }]}
          >
            <Select
              options={Object.keys(DateFilterType).map(e => ({ label: _.capitalize(e), value:e }))}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={14} xxl={14} >
          <Form.Item 
            name="dateRange"
            label="Date Filter Range"
            rules={[{ validator: validateRangePicker }]}
          >
            <RangePicker
              name="range"
              style={{width: "100%"}}
            />
          </Form.Item>
        </Col>

      </Row>
    </Form>
  </>
}

const DesktopTable = (props: {
  quoteListAsync: AsyncState<PricingReportData[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
  exportOrders:(columns:ColumnType<PricingReportData>[])=>void
  isExporting: boolean
}) => {
  const { quoteListAsync, tableOnChange, pagination, exportOrders, isExporting } = props;
  const quoteList = quoteListAsync.val;

  const columns:ColumnType<PricingReportData>[] = [
    {
      title: "Quote",
      key: "quoteId",
      sorter: true,
      render: (d) => <>
        <Link to={"/configurator/" + encodeURI(d.quote.quoteId)}>
          <div className="ellipsis" style={{maxWidth: "25rem"}}>{d.quote.quoteId}</div>
        </Link>
      </>
    },
    {
      title: "Name",
      key: "quoteName",
      render: (d) => d.quote.name
    },
    {
      title: "Calculated Price",
      key: "calculatedPrice",
      render: (d) => Utils.formatMoney( d.calculatedPrice )
    },
    {
      title: "Saved Price",
      key: "savedPrice",
      render: (d) => Utils.formatMoney( d.savedPrice )
    },
    {
      title: "PO Unit Amount",
      key: "poUnitAmount",
      render: (d) => `${Utils.formatMoney( d.poUnitAmount )} (${d.quote?.quantity})`
    },
    {
      title: "Price Match",
      key: "priceMatch",
      render: (d) => Utils.formatMoney( d.priceMatch )
    },
  ];

  //quick fix to handle no elements
  const newButtonStyle:any = pagination.total
    ? { position: "absolute", top: "1rem", zIndex: 1, right: "0px" }
    : { float: "right", marginBottom: "1rem" };


  return <>
        <div style={{ position: "relative" }}>
          <Row gutter={12} justify={"end"} style={newButtonStyle}>
            <Col>
              <Button type="primary" loading={isExporting} onClick={() => exportOrders(columns)}>Export</Button>
            </Col>
          </Row>
          <Table
            loading={quoteListAsync.isLoading()}
            onChange={tableOnChange}
            bordered
            pagination={pagination}
            dataSource={quoteList}
            columns={columns}
            rowKey="quoteId"
          />
        </div>
    </>
}



export default PricingReport;
