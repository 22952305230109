import "../util/mobile-table.css";
import {Button, ButtonProps, Form, Input, notification, Radio, Result} from "antd"
import {useForm, useWatch} from "antd/lib/form/Form"
import {useContext, useEffect, useState} from "react"
import {useIntl} from "react-intl"
import {ApprovalDiff, BaseCategory, Quote, QuoteReview} from "../api/models"
import {ConfiguratorContext} from "../context"
import {useAsyncState} from "../hook/useAsyncState"
import ModalWizard from "./ModalWizard"
import ApprovalDiffTable from "./Table/ApprovalDiffTable"
import Title from "antd/lib/typography/Title";
import Utils from "../util/util"
import { WizardInstance } from "./Wizard";
import { useQuoteContext } from "../contexts/QuoteContext";

interface CopyQuoteForm {
  name:string
  refresh:boolean
}

const QuoteCopyModal = (props: ButtonProps & {
  onFinished: (q:Quote) => void
}) => {

  const {onFinished:a, ...btnProps} = props;
  const configurator = useContext(ConfiguratorContext);
  const [form] = useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { quoteAsync, adminView } = useQuoteContext();
  const quote = quoteAsync?.val;
  const [quoteCopy, quoteCopyAsync] = useAsyncState<Quote>();
  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const [preview, previewAsync] = useAsyncState<ApprovalDiff>();
  const refresh = useWatch("refresh", form);

  const intl = useIntl();

  useEffect(() => {
    if ( isOpen ) {
      handleRefresh();
    }
  }, [refresh])


  const loadReview = async (quoteRevisionId:number) : Promise<QuoteReview | undefined> => {

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, {dealerView:!adminView});
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }

  const loadPreviewCopyQuote = async (quoteRevisionId:number) : Promise<ApprovalDiff | undefined> => {

    previewAsync.setLoading();
    try {
      const resp = await configurator.api.previewCopyQuote(quoteRevisionId);
      previewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch preview. " + errorMsg });
      previewAsync.setFail(e.message);
    }
    return;
  }

  const handleDone = () => {
    if ( !quoteCopy) return;

    setIsOpen(false);
    props.onFinished(quoteCopy);
  }

  const handleCopyStep = async (nav:WizardInstance) => {
    if ( !quote?.displayRevisionId ) return;

    const values = form.getFieldsValue(true);
    const copiedQuote = await saveQuoteCopy(quote.displayRevisionId, values);
    if ( copiedQuote ) {
      nav.nextStep();
    }
  }

  const saveQuoteCopy = async (quoteRevisionId:number, options:CopyQuoteForm) : Promise<Quote | undefined> => {

    quoteCopyAsync?.setLoading();

    try {
      const resp = await configurator.api.copyQuoteRevision(quoteRevisionId, options );
      quoteCopyAsync?.setDone(resp.data );
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message || e.response.data.message });
      notification.error( { message: "Failed to copy quote. " + errorMsg });
      quoteCopyAsync?.setFail(e.message);
    }
    return;
  };

  const handleRefresh = async () => {
    if ( !quote ) return;

    if ( refresh ) {
      await loadPreviewCopyQuote( quote.displayRevisionId );
    }
    else {
      await loadReview( quote.displayRevisionId );
    }
  }

  const showPreview = refresh && (preview?.assembliesDiff?.addedAssemblies?.length || preview?.assembliesDiff?.removedAssemblies?.length);
  const showQuoteReviewSummary = !refresh && ( review?.obsoleteAssemblyLst?.length || review?.invalidAssemblyLst?.length || review?.missingCategoryLst?.length || review?.customOptions.length );
  const canClone = configurator.isSalesDesk() || configurator.isEngineering() || configurator.isAdmin();

  const defaultRefresh = !canClone;

  return <>
    <Button
      {...btnProps}
      disabled={props.disabled || !quote}
      onClick={() => setIsOpen(true)}
    >
      Copy To New
    </Button>

    <Form
      form={form}
      initialValues={{
        refresh: defaultRefresh,
        name: "Copy of " + quote?.name,
      }}
      layout="vertical"
    >
    <ModalWizard
      open={isOpen}
      showSteps={false}
      bodyStyle={{maxHeight: "31rem"}}
      afterOpenChange={(open: boolean) => {
        if(open) {
          form.resetFields();
        }
      }}
      onCancel={() => setIsOpen(false)}
      steps={[
        {
            key:1,
          body: (nav) => <Result key="step0"
            status="info"
            title="Copy Quote"
            subTitle={"This will step through creating a duplicate quote with updated pricing."}
          />,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
            <Button key="back" onClick={() => setIsOpen(false)} >Cancel</Button>
          </div>,
        },
        {
            key:2,
          body: (nav) => <div key="step1">
            <div style={{display: "flex", justifyContent: "center", paddingTop: "3rem" }}>
              <Form.Item name="refresh" >
                <Radio.Group >
                  <div style={{display:"flex", flexDirection: "column", gap: "1rem" }}>
                    <div><Radio value={false}>Clone options</Radio></div>
                    <div><Radio value={true}>Update options</Radio> </div>
                  </div>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav.nextStep} loading={previewAsync.isLoading() || reviewAsync.isLoading()} >Next</Button>
            <Button key="back" onClick={nav.prevStep} >Back</Button>
          </div>,
          hidden: !canClone
        },
        {
            key:3,
          body: (nav) => <div key="step2">
            <Title level={5}>The quote will be updated with the following changes:</Title>
            <div style={{maxHeight: "30rem", overflow: "auto", }}>
              <ApprovalDiffTable diff={preview} hidePriceChange />
              </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
            <Button key="back" onClick={nav.prevStep} >Back</Button>
          </div>,
          hidden:!showPreview
        },
        {
            key:4,
          body: (nav) => <div key="step3">
            <div style={{maxHeight: "30rem", overflowY: "auto", paddingBottom: "1rem"}}>
                <QuoteReviewSummary review={review} /> 
              </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
            <Button key="back" onClick={nav.prevStep} >Back</Button>
          </div>,
          hidden: !showQuoteReviewSummary
        },
        {
            key:5,
          body: (nav) => <div key="step4">
            <div style={{display: "flex", justifyContent: "center", paddingTop: "3rem" }}>
              <Form.Item
                label="Copy Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Quote Name is required",
                  },
                ]}
                style={{width:"90%"}}
              >
                <Input/>
              </Form.Item>
            </div>
          </div>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={() => handleCopyStep(nav)} loading={quoteCopyAsync?.isLoading()}  >Next</Button>
            <Button key="back" onClick={nav.prevStep} disabled={quoteCopyAsync?.isLoading()} >Back</Button>
          </div>,
        },
        {
            key:6,
          body: (nav) => <Result key="step5"
            status="success"
            title="Copy Successful!"
            subTitle={`${quoteCopy?.name} ( ${quoteCopy?.quoteId} ) has been created.`}
          />,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={handleDone} >Done</Button>
          </div>,
        }
      ]}    
    />
    </Form>
  </>
}

const QuoteReviewSummary = (props:{
  review:QuoteReview | undefined
}) => {

  
  const sectionMap = props.review?.missingCategoryLst?.reduce( (acc, v ) => {
    acc[ v.configuratorSection ] = ( acc[ v.configuratorSection ] || [] ).concat(v);
    return acc;
  }, {}) as Record<string, Array<BaseCategory>>;

  const sections = sectionMap ? Object.entries(sectionMap) : [];
  //sort sections
  sections.sort( (a,b) => a[0].localeCompare(b[0]) );
  //sort categories
  sections.forEach(([k,v]) => v.sort( (a,b) => a.name.localeCompare(b.name) ) );

  return <>
    <Title level={5}>The new quote will be created with the following issues:</Title>
    {!!props.review?.obsoleteAssemblyLst.length && <div style={{marginBottom: "1rem"}}>
      <div style={{fontWeight: "500"}} >Obsolete assemblies:</div>
      <ul style={{marginBottom: "0.1rem"}}>
        {props.review.obsoleteAssemblyLst?.map((asm) => <li style={{wordBreak: "break-word"}} key={`asm-${asm.id}`}>{asm.label || asm.bomDescription}</li>)}
      </ul>
    </div>}

    {!!props.review?.invalidAssemblyLst.length && <div style={{marginBottom: "1rem"}}>
      <div style={{fontWeight: "500"}} >Incompatibile assemblies:</div>
      <ul style={{marginBottom: "0.1rem"}}>
        {props.review.invalidAssemblyLst?.map((asm) => <li style={{wordBreak: "break-word"}} key={`asm-${asm.id}`}>{asm.label || asm.bomDescription}</li>)}
      </ul>
    </div>}

    {(!!sections && !!sections.length) && <>
      <Title level={5}>Categories missing selections:</Title>
      {sections.map(([k,v]) => <div key={`section-${k}`}>
        <div style={{fontWeight: "500"}} >{k}</div>
        <ul style={{marginBottom: "0.1rem"}}>
          {v?.map((c:BaseCategory) => <li style={{wordBreak: "break-word"}} key={`category-${c.id}`}>{Utils.stripSortingPrefix(c.name)}</li>)}
        </ul>
      </div>)}
    </>}

    {!!props.review?.customOptions.length && <div style={{marginBottom: "1rem"}}>
      <div style={{fontWeight: "500"}} >Custom Option Issues:</div>
      <ul style={{marginBottom: "0.1rem"}}>
        {props.review.customOptions?.map((co) => <li style={{wordBreak: "break-word"}} key={`co-${co.id}`}>{co.content} - {co.approved === undefined ? "Pending" : "Rejected"}</li>)}
      </ul>
    </div>}

  </>;

}

export default QuoteCopyModal;



