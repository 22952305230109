import {
  Mentions,
  MentionProps,
} from "antd";
import { MentionsOptionProps } from "antd/lib/mentions";
import { useState } from "react";


const BmMentions = (props:MentionProps & {
  mentions: {
    [ prefix: string]: MentionsOptionProps[] | undefined
  }
}) => {
  const [prefix, setPrefix] = useState<string>();
  const prefixLst =  Object.keys( props.mentions );
  const options = prefix ? props.mentions?.[ prefix ] : [];
  return <Mentions 
    {...props}
    prefix={prefixLst}
    onSearch={ (_, newPrefix) => setPrefix(newPrefix) }
    options={options}
  /> 
}

export default BmMentions;


