import Title from "antd/lib/typography/Title";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Table, TablePaginationConfig, notification } from "antd";
import { ConfiguratorContext } from "../context";
import axios, {CancelTokenSource} from "axios";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { Assembly, DashComponent, AXIOS_CANCEL_MSG } from "../api/models";
import { useForm } from "antd/lib/form/Form";
import Utils from "../util/util";

const DEBOUNCE_MS = 500;

const AssignDashComponent = (props: any) => {
  const configurator = useContext(ConfiguratorContext);

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const [dataFilter, setDataFilter] = useQueryParam<any>("filter", StringParam);

  const [pageSizeQuery, setPageSizeQuery] = useQueryParam("nr", NumberParam);
  const [currentPage, setCurrentPage] = useQueryParam("p", NumberParam);

  const [assemblies, setAssemblies] = useState<Assembly[]>([]);
  const [pagination, setPagination ] = useState<any>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQuery == null || pageSizeQuery > 500 ? 50 : pageSizeQuery,
    current: currentPage == null || currentPage < 1 ? 1 : currentPage,
  });
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAssembly, setSelectedAssembly] = useState<{id: number}>({id: 0});
  const [allDashComponents, setAllDashComponents] = useState<DashComponent[]>([]);
  const [form] = useForm();
  const [selectedComponents, setSelectedComponents] = useState<DashComponent[]>([]);

  useEffect(() => {
    getAllDashComponents();
  }, []);

  const getAllDashComponents = async () => {
    try {
      setLoading(true);
      const resp = await configurator.api.getDashComponents(undefined);
      setAllDashComponents(resp.data);
    }
    catch(e) {
      console.log(e);
      notification.error({
        message: "Failed to get dash component.",
      });
    }
    finally{
      setLoading(false);
    }
  }

  useEffect(() => {
    clearDataSource();
    setPagination({ ...pagination, current: 1 });
  }, [dataFilter]);

  useEffect(() => {
    updateAssemblies(pagination, dataFilter);
  }, [pagination.pageSize, pagination.current, dataFilter]);

  const clearDataSource = () => {
    setAssemblies([]);
  }

  const updateAssemblies = useCallback(debounce((pagination, filterQuery) => {
    fetchAssemblies(pagination, filterQuery).then(data => {
      setAssemblies(
        data?.content || []
      );
      setPagination({ ...pagination, total: data?.totalElements || 0 });
    });
  }, DEBOUNCE_MS), []);

  const fetchAssemblies = async (pagination, dataFilter) => {
    try {
      setLoading(true);

      const resp = await Utils.executeWithCancelToken(cancelTokenSourceRef, (token) =>
        configurator.api.getFilteredAssemblies({
          page: pagination.current - 1,
          size: pagination.pageSize,
          categoryId: undefined,
          filterQuery: dataFilter,
          isDashAssembly: true,  // assembly with -CAB at bom
        },
        token)
      );
      return resp?.data;
    }
    catch (e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        notification.error({
          message: "Failed to get assemblies.",
        });
      }
    }
    finally {
      setLoading(false);
    }
  };

  const onChangeAssemblyFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataFilter(event.target.value);
  };
  const onChangePagination = (pagination: TablePaginationConfig) => {
    setPagination(pagination);

    setPageSizeQuery(pagination.pageSize);
    setCurrentPage(pagination.current);
  };

  const getLabel = (assembly: Assembly) => {
    return assembly.label != null && assembly.label.length > 0
      ? assembly.label
      : assembly.bomDescription;
  };

  const editAssemblyDashComponent = (assembly: Assembly) => {
    getAssemblyWithDashcomponent(assembly.id);
  };

  const saveAssembly = async (values: { dashComponentIds: number[]; }) => {
    const assignedComponents = (allDashComponents.filter(dc => (values.dashComponentIds || []).includes(dc.id)))
      .map(dc => { return ({ ...dc, quantity: values[dc.partNumber] }); });
    try {
      const resp = await configurator.api.saveAssemblyWithDashComponent(selectedAssembly.id, assignedComponents);
      if (resp) {
        setShowEditModal(false);
        // form.resetFields();
        updateAssemblies(pagination, dataFilter);
        notification.success({
          message: "Saved successfully.",
        });
      }
    }
    catch (e) {
      console.log(e);
      notification.error({
        message: "Saved failed.",
      });
    }
  }

  const getAssemblyWithDashcomponent = async (id: number) => {
    try {
      const resp = await configurator.api.getAssemblyWithDashComponent(id);
      if (resp) {
        setSelectedComponents(resp.data.dashComponents || []);
        setShowEditModal(true);
        setSelectedAssembly(resp.data);
        form.setFieldValue('dashComponentIds', resp.data.dashComponents?.map(dc => {return dc.id}));
        resp.data.dashComponents?.forEach(dc => {form.setFieldValue(String(dc.partNumber), dc.quantity)});
      }
    }
    catch(e) {
      console.log(e);
    }
  }

  const onChange = (_value, allValues: { dashComponentIds: number[]; }) => {
    const items: DashComponent[] = [];
    allValues.dashComponentIds.forEach(id => {
      const it = allDashComponents.find(dc => (dc.id === id));
      if (it) {
        items.push(it);
      }
    });
    setSelectedComponents(items);
  }

  var columns = [
    {
      title: "BOM",
      key: "bom",
      width: '20%',
      render: (asm: Assembly) => (
        <span>
          {asm.bom}
          {asm.obsoletedAt != null ? " (Obsolete)" : ""}
        </span>
      ),
    },
    {
      title: "Name",
      key: "name",
      width: '35%',
      render: (asm: Assembly) => (
        <Link to={"#"} onClick={() => editAssemblyDashComponent(asm)}>
          {getLabel(asm)}
        </Link>
      ),
    },
    {
      title: "Dash Components",
      key: "dashComponent",
      render: (asm: Assembly) => (
        asm?.dashComponents?.map((c, i) => <Row key={`components-${i}`} gutter={24}>
          <Col><div key={`component-${i}-${c.id}-pn`}>{"Part No.: " + c.partNumber}</div></Col>
          <Col><div key={`component-${i}-${c.id}-qty`}>{"Qty: " + c.quantity}</div></Col>
        </Row>)
      ),
    },
  ];

  return (
    <div className="site-layout-background">
      <Title level={2}>Assembly(CAB) List for Dash Component</Title>
      <div>
        <div style={{ marginTop: "12px" }}>
          <span>Filter: </span>
          <Input
            allowClear
            style={{ width: "300px" }}
            onChange={(e) => onChangeAssemblyFilter(e)}
            value={dataFilter}
            placeholder="Enter text to filter list"
          />
        </div>
        <div style={{ marginTop: "20px" }} >
          <Table
            loading={loading}
            dataSource={assemblies}
            onChange={(pagination) => { onChangePagination(pagination); }}
            bordered
            rowKey="id"
            columns={columns}
            pagination={pagination}
          />
        </div>
      </div>
      <Modal
        title={selectedAssembly['bom'] + ' - ' + selectedAssembly['bomDescription']}
        open={showEditModal}
        onCancel={() => { setShowEditModal(false); form.resetFields(); }}
        closable={true}
        maskClosable={false}
        footer={[]}
        width={'70rem'}
      >
        <Form
          labelCol={{ span: 4 }}
          labelAlign="left"
          form={form}
          onFinish={saveAssembly}
          onValuesChange={onChange}
        >
          <Form.Item
            label="Dash Component(s)"
            name="dashComponentIds"
          >
            <Select
              mode="tags"
              showSearch
              allowClear={true}
              optionFilterProp="children"
            >
              {allDashComponents?.map((dc, i) => (
                <Select.Option key={"components-" + i} value={dc.id}>
                  {dc.partNumber + ' - ' + dc.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {selectedComponents.map(
            (sdc, id) => {
              return (
                <Form.Item
                  label={sdc.partNumber}
                  key={id + '-' + sdc.partNumber}
                  name={sdc.partNumber}
                  rules={[{ required: true, message: "Quantity is required" }]}
                  initialValue={1}
                >
                  <InputNumber style={{ width: "16rem" }} placeholder="Quantity" min={1} />
                </Form.Item>
              );
            }
          )}

          <Form.Item wrapperCol={{ offset: 4 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>

        </Form>
      </Modal>
    </div>
  );
};

export default AssignDashComponent;
