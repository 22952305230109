import { Alert, Col, Form, Input, message, Row, Spin, Table, TablePaginationConfig } from "antd";
import Title from "antd/lib/typography/Title";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { NumberParam, useQueryParam } from "use-query-params";
import { BadRequestError } from "../../api/errors";
import { ExternalUser, UserType } from "../../api/models";
import DealerSelector from "../../components/dealer_selector";
import { ConfiguratorContext } from "../../context";
import EditUserModal from "./edit_user_modal";

const ListUsers = () => {
    const configurator = useContext(ConfiguratorContext);
    const [users, setUsers] = useState<ExternalUser[]>();
    const [dealerFilter, setDealerFilter] = useState<string>();
    const [searchFilter, setSearchFilter] = useState<string>();
    const [error, setError] = useState<string>();
    const [_messageApi, contextHolder] = message.useMessage();
    const [currentPage, setCurrentPage] = useQueryParam("page", NumberParam);
    const [pageSize, setPageSize] = useQueryParam("size", NumberParam);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        pageSize: pageSize||10,
        current: currentPage||1,
        showSizeChanger: true
    });

    useEffect(() => {
        loadUsers();
    }, [dealerFilter, searchFilter, pagination.pageSize, pagination.current])

    useEffect(() => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    }, [pagination.pageSize, pagination.current]);

    const onSelectDealer = (dealerId?: string) => {
        setDealerFilter(dealerId);
    };

    const onSearchChange = useCallback(debounce((e: any) => {
        if(e.target.value.length >= 3 || e.target.value.length == 0) {
            setSearchFilter(e.target.value);
        }
    }, 750), []);

    const loadUsers = async () => {
        try {
            setError(undefined);
            setUsers(undefined);
            const resp = await configurator.api.listUsers((pagination.current||0) - 1, pagination.pageSize||10, {
                dealerId: dealerFilter,
                filter: searchFilter,
            });
            setUsers(resp.content);
            setPagination({...pagination, total: resp.totalElements});
        }
        catch(e) {
            if(e instanceof BadRequestError) {
                setError('Failed to load users at this time: ' + e.message);
            }
            else {
                setError('Failed to load users at this time.');
            }
        }
    };

    const columns = [
        {
            key: "name",
            title: "Name",
            render: (u: ExternalUser) => {
                return (<p>{u.name}</p>);
            }
        },
        {
            key: "dealer",
            title: "Dealer",
            render: (u: ExternalUser) => {
                return (<p>{u.dealer?.name || 'N/A'}</p>);
            }
        },
        {
            key: "email",
            title: "Email",
            render: (u: ExternalUser) => {
                return (<p>{u.email}</p>);
            }
        },
        {
            key: "phoneNumber",
            title: "Phone",
            width: "15%",
            render: (u: ExternalUser) => <span>{u.phoneNumber}</span>
        },
        {
            key: "admin",
            title: "Admin",
            render: (u: ExternalUser) => {
                if(u.userType == UserType.Admin) return 'Yes';
                else return 'No';
            }
        },
        {
            key: "enabled",
            title: "Enabled",
            render: (u: ExternalUser) => {
                if(u.enabled) return 'Yes';
                else return 'No';
            }
        },
        {
            key: "actions",
            title: "Actions",
            render: (u: ExternalUser) => {
                if(!u.dealer) {
                    return;
                }
                return <EditUserModal loadUsers={loadUsers} user={u} edit={true}/>
            }
        }
    ];

    return (
        <div className="site-layout-background">
            {contextHolder}
            <Title level={2}>Dealer Users</Title>

            {error ? <div><br/><Alert type="error" message={error}/></div> : ''}
            <br /> <br/>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label="Filter by Dealer"
                        name="Dealer"
                        rules={[]}
                    >
                        <DealerSelector onChange={onSelectDealer} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Search"
                        name="Search"
                        rules={[]}
                    >
                        <Input onChange={onSearchChange} allowClear={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <EditUserModal loadUsers={loadUsers} user={undefined} edit={false}/>
                </Col>
            </Row>
            <Spin spinning={!users && !error}>
                <Table
                    bordered
                    rowKey="id"
                    columns={columns}
                    pagination={pagination}
                    onChange={(val) => setPagination(val)}
                    dataSource={users}
                />
            </Spin>
        </div>
    )
};

export default ListUsers;
