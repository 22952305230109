import { FilterAssembliesIfNoneMatchAction, RuleAction } from "../../api/models";
import FilterIfNoMatch from "./filter_if_no_match";

const Rule = (props: {
    rule?: RuleAction;
    onUpdate?: (arg0: RuleAction) => void;
    readOnly?: boolean;
}) => {
    return (props.rule instanceof FilterAssembliesIfNoneMatchAction) 
            ? <FilterIfNoMatch readOnly={props.readOnly} rule={props.rule} onUpdate={props.onUpdate||(() => {})}/>
            : <div>Rule Type not supported.</div>;
}

export default Rule;
