import { Button, Col, Input, notification, Row, Switch } from "antd";
import { useContext, useEffect, useState } from "react";
import { SystemConfig, SystemConfigPropertyType } from "../api/models";
import { ConfiguratorContext } from "../context";
import { BadRequestError } from "../api/errors";

export interface Props {
    config: SystemConfig;
    onConfigUpdated?: (SystemConfig) => void;
}

const SystemConfigValueEditor = (props: Props) => {
    const [modified, setModified] = useState(false);
    const [currentValue, setCurrentValue] = useState(props.config.value);
    const [saving, setSaving] = useState(false);
    const context = useContext(ConfiguratorContext);
    const isNumber = props.config.propertyType == SystemConfigPropertyType.number;
    const isBoolean = props.config.propertyType == SystemConfigPropertyType.bool;

    useEffect(() => {
        setModified(props.config.value != currentValue);
    }, [props.config]);

    function validateValue() {
        if(isNumber) {
            const valid = !isNaN(Number(currentValue).valueOf());
            if(!valid) alert('Value must be a number');
            return valid;
        }
        if (isBoolean) {
            const valid = currentValue === "true" || currentValue === "false";
            if(!valid) alert("Value must be true or false");
            return valid;
        }
        return true;
    }

    async function save() {
        if(!validateValue()) return;

        setSaving(true);
        try {
            const updatedConfig = await context.api.updateSystemConfig(props.config.property, { value: currentValue });
            setSaving(false);
            if (updatedConfig) {notification.success({message: "Successfully save system config."})}
            if (props.onConfigUpdated) props.onConfigUpdated(updatedConfig);
        }
        catch (e) {
            if (e instanceof BadRequestError) {
                const err: BadRequestError = e;
                notification['error']({
                    message: 'Failed to save',
                    description: e.message,
                });
            }
            else {
                notification['error']({
                    message: 'Failed to save',
                    description: 'Unable to update system configuration at this time.'
                });
            }
            setSaving(false);
        }
    }

    function onChange(e: any) {
        setModified(e.target.value != props.config.value)
        setCurrentValue(e.target.value);
    };

    const toggleBoolean = (checked: boolean) => {
        const value = checked? "true" : "false";
        setModified(value != props.config.value);
        setCurrentValue(value);
    }

    return (
        <Row gutter={6}>
            <Col span={18}>
                {isBoolean ?                         
                    <div style={{scale: "1.2", transform: "translate(8%, 0%)"}}>
                        <Switch
                            defaultChecked={currentValue === "true"}
                            checkedChildren="Enabled"
                            unCheckedChildren="Disabled"
                            onChange={(checked) => toggleBoolean(checked)}
                        />
                    </div> :
                    <Input onChange={onChange} value={currentValue} />
                }
                
            </Col>
            <Col span={6}>
                <Button type="primary" onClick={save} loading={saving} disabled={!modified}>Save</Button>
            </Col>
        </Row>
    );
};

export default SystemConfigValueEditor;
