import { Drawer,  DrawerProps,  Switch, Tooltip,  } from "antd";
import { PushpinOutlined } from "@ant-design/icons";
import {useState} from "react";

const PinnableDrawer = (props: DrawerProps & {
  modal?:boolean
  hidePin?:boolean
}) => {

  const {  modal, hidePin, ...drawerProps } = props;
  const [isPinned, setPinned] = useState<boolean>(false);

  const container = modal ? false : undefined;

  const handlePinDrawer = () => {
    setPinned( !isPinned );
  }

  const handleCloseDrawer = (e: React.MouseEvent | React.KeyboardEvent) => {
    setPinned(false);
    props.onClose?.(e);
  }

  const getMaskStyle = (isPinned:boolean):Record<string,string> => {
    if ( isPinned ) { 
      return {
      background: "rgb(0,0,0,0)", 
        position: 'relative',
      };
    }

    return { 
      background: "rgb(0,0,0,0.4)", 
    };
  }

  return <Drawer
    {...drawerProps}
    className="pinnedDrawer"
    title={<>
      <div className="pinnedDrawer-header" style={{ display: "flex", justifyContent: "space-between" }} >
        <div className="pinnedDrawer-title">{props.title || ""}</div>
        {!hidePin && (
        <Tooltip title="Pin Drawer">
          <Switch
            checkedChildren={<PushpinOutlined />}
            unCheckedChildren={<PushpinOutlined />}
            checked={isPinned}
            onChange={handlePinDrawer}
          />
        </Tooltip>
        )}
      </div>
    </>}
    closable={true}
    onClose={handleCloseDrawer}
    maskStyle={getMaskStyle(isPinned)}
    maskClosable={!isPinned}
    getContainer={container}
  />
}
export default PinnableDrawer;

