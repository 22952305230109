import { NotExpression, RuleExpression, RuleValueTypes } from "../../../api/models";
import RuleExpressionEditor from "../expression";

export interface Props {
    expression: NotExpression;
    onUpdate: (e:NotExpression) => void;
    disabledRuleValueTypes?: RuleValueTypes[] | undefined
}

const NotExpressionEditor = (props: Props) => {
    return (
        <div>
          <strong>NOT</strong><br/> <RuleExpressionEditor expression={props.expression.expression} 
            onUpdate={(expr) => {
              var newExpr = new NotExpression();
              newExpr.expression = expr;
              props.onUpdate(newExpr);
            }} 
            disabledRuleValueTypes={props.disabledRuleValueTypes} 
          />
        </div>
    );
};

export default NotExpressionEditor;
