import { useContext, useEffect } from "react";
import { ConfiguratorContext } from "../context";
import { notification, Select, SelectProps } from "antd";
import { IncentiveProgram } from "../api/models";
import {useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import _ from "lodash";

const IncentiveProgramSelector = (props: SelectProps<string>) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [incentiveProgramLst, incentiveProgramLstAsync] = useAsyncState<IncentiveProgram[]>();

  useEffect(() => {
    if ( !incentiveProgramLstAsync.isLoading() ) {
      loadIncentivePrograms();
    }
  }, [] );

  const loadIncentivePrograms = async () => {

    incentiveProgramLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchIncentivePrograms();
      incentiveProgramLstAsync.setDone(resp.data);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to get incentive programs. " + errorMsg });
      incentiveProgramLstAsync.setFail(e.message);
    }
  };

  var datasource = _.uniq( incentiveProgramLst?.map( ip => ip.name ) );

  return <Select
    {...props}
    mode="multiple"
    showSearch
    allowClear
    optionFilterProp="label"
    options={datasource?.map(ip => ({
      label: ip,
      value: ip
    }))}
  />

};

export default IncentiveProgramSelector;
