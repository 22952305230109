import Title from "antd/lib/typography/Title";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {  Alert, Button, Checkbox, Col, Form, Input, notification, Row, Space, TablePaginationConfig } from "antd";
import { ConfiguratorContext } from "../context";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import AssemblyCostsTable from "../components/Table/AssemblyCostsTable";
import axios, {CancelTokenSource} from "axios";
import { debounce } from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {AssemblyCost, AXIOS_CANCEL_MSG, Permission} from "../api/models";
import ReplaceCostsModal from "../components/Table/ReplaceCostsModal";
import {AssemblyCostFilter} from "../api";
import { useIntl } from "react-intl";
import CategorySelector from "../components/category_selector";
import { useForm } from "antd/es/form/Form";

const AssemblyCostsView = () => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);

  //parameters from the query string
  const [queryParam, setQueryParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [pageSizeQuery, setPageSizeQuery] = useQueryParam<number | null | undefined>("nr", NumberParam);
  const [currentPage, setCurrentPage] = useQueryParam<number | null | undefined>("p", NumberParam);
  const [dataFilter, setDataFilter ] = useState<AssemblyCostFilter>({ query: queryParam || undefined });
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const [replacementImports, setReplacementImports] = useState<AssemblyCost[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();
  const [pending, pendingAsync] = useAsyncState<AssemblyCost[]>();
  const [pagination, setPagination ] = useState<TablePaginationConfig>({
    total: 0,
    position: ["bottomLeft"],
    pageSize: pageSizeQuery == null || pageSizeQuery > 500 ? 50 : pageSizeQuery,
    current: currentPage == null || currentPage < 1 ? 1 : currentPage,
  });

  const [filterForm] = useForm();

  useEffect(() => {
    setPagination({ ...pagination, current: 1 } );
  }, [dataFilter]);

  useEffect(() => {
    reloadPending();
  }, [pagination.pageSize, pagination.current, dataFilter ]);

  const canWriteCost = configurator.hasPermission(Permission.ASSEMBLY_COST_WRITE);
  const canWrite = configurator.hasPermission(Permission.ENGINEERING_WRITE);


  const reloadPending = () : Promise<AssemblyCost[] | undefined> | undefined => {
    return loadPending(pendingAsync, pagination, dataFilter || undefined );
  }
  const loadPending = useCallback( debounce( async (pendingAsync:AsyncState<AssemblyCost[]>, pagination:TablePaginationConfig, filter:AssemblyCostFilter | undefined) : Promise<AssemblyCost[] | undefined> => {
    try {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    pendingAsync.setLoading();
      const resp = await configurator.api.getAssemblyCosts( {
          ...filter,
          page: (pagination.current || 1) - 1,
          size: pagination.pageSize || 20,
        },
        cancelSource.token,
      );
      cancelTokenSourceRef.current = undefined;

      pendingAsync.setDone(resp.data.content);
      setPagination({ ...pagination, total: resp.data.totalElements });

      return resp.data.content;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Quotes failed to load. " + errorMsg });
        pendingAsync.setFail(e.message);
      }
    }

    return;

  }, 800), [] );

  const handleFilterChange = (_changedValues: Record<string, any>, values: Record<string, any>) => {
    setDataFilter({ ...values });
    setQueryParam( values.search );
  }

  const onChangePagination = ( pagination:TablePaginationConfig  ) => {

    setPagination(pagination );

    //save to the query string
    setPageSizeQuery( pagination.pageSize );
    setCurrentPage( pagination.current );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAcceptCosts = async () => {
    if ( !selectedRowKeys?.length ) return;

    pendingAsync.setLoading();
    const idList = selectedRowKeys.map( v => Number(v));

    const lst = (await configurator.api.acceptAssemblyCosts( idList )).data;

    //unselected updated ids
    const updatedIdSet = new Set( lst.map( p => p.id ) );
    setSelectedRowKeys( Array.from( idList ).filter( id => !updatedIdSet.has( id ) ) );

    reloadPending();
  }

  const isMaterialCostReplacement = ( c:AssemblyCost ) : boolean => {
    return c.standardMaterialCost === c.assembly.standardMaterialCost
  }

  const handleRejectCosts = async () => {
    if ( !selectedRowKeys?.length ) return;

    //bisect rejection list by rejections and replacements
    const idList = new Set( selectedRowKeys.map( v => Number(v)) );
    const { rejects, replacements } = pending
      ?.filter( c => idList.has(c.id) )
      .reduce( (acc, c ) => {

      if ( isMaterialCostReplacement(c) ) {
        acc.replacements.push( c );
      }
      else {
        acc.rejects.push( c );
      }

      return acc;
    }, {rejects:new Array<AssemblyCost>(), replacements:new Array<AssemblyCost>() } ) || {};

    //handle rejections first
    if ( rejects?.length ) {

      pendingAsync.setLoading();
      const rejectIdLst = rejects.map( c =>  c.id );
      const lst = (await configurator.api.rejectAssemblyCosts( rejectIdLst )).data;

      //unselected updated ids
      const updatedIdSet = new Set( lst.map( p => p.id ) );
      setSelectedRowKeys( Array.from( idList ).filter( id => !updatedIdSet.has( id ) ) );

      reloadPending();
    }

    //if rejected imports, prompt for new price
    if ( replacements?.length ) {
      setReplacementImports( replacements );
    }
  }

  const handleReplaceCosts = async ( replacementImports:AssemblyCost[] | undefined ) => {
    if ( !replacementImports?.length ) return;

    const replacementLst = replacementImports.map( ac => ({
      id: ac.id,
      replacementCost: ac.standardMaterialCost
    }));

    pendingAsync.setLoading();
    const lst = (await configurator.api.replaceAssemblyCosts( replacementLst ) ).data;

    //hide replacements dialog
    setReplacementImports([]);

    //unselected updated ids
    const idList = new Set( selectedRowKeys?.map( v => Number(v)) );
    const updatedIdSet = new Set( lst.map( p => p.id ) );
    setSelectedRowKeys( Array.from( idList ).filter( id => !updatedIdSet.has( id ) ) );

    reloadPending();
  }

  return <div className="site-layout-background">

    <Space direction="vertical" style={{width: "100%"}}>

      <div style={{display: "flex", justifyContent: "space-between"}}>
        <Title level={2}>Assembly Costs</Title>
        <Space>
          <Button disabled={pendingAsync.isLoading() || !canWrite || !selectedRowKeys?.length}
            type="primary"
            title={canWrite ? "" : "You need permission to this."}
            onClick={handleAcceptCosts}>
            Accept
          </Button>
          <Button disabled={pendingAsync.isLoading() || !canWriteCost || !selectedRowKeys?.length}
            type="primary"
            title={canWriteCost ? "" : "You need permission to this."}
            onClick={handleRejectCosts}>
            Reject
          </Button>
        </Space>
      </div>

      <Form
        form={filterForm} 
        onValuesChange={handleFilterChange}
      >
      <Row gutter={20}>
        <Col span={10}>
          <Form.Item 
                name="query"
            >
              <Input
                allowClear
                value={dataFilter.query}
                placeholder="Search list"
          />
          </Form.Item>
        </Col>
        <Col span={10}>
            <Form.Item
              name="categoryId"
            >
            <CategorySelector 
                placeholder="Filter by category"
              />
          </Form.Item>
        </Col>
          <Col span={4}>
            <Form.Item
              name="showCfOnly"
              valuePropName="checked"
            >
              <Checkbox
                checked={dataFilter.showCfOnly}>
                Show Configurator-only
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {!canWriteCost &&
        <Alert 
          type="info"
          style={{marginTop: "1rem"}}
          message={"Replacement Import needs permission."}
        />}

      <AssemblyCostsTable
        dataSource={pendingAsync.val}
        onChange={onChangePagination}
        pagination={pagination}
        loading={pendingAsync.isLoading()}
        rowSelection={{
          type:  "checkbox",
          onChange: onSelectChange,
          hideSelectAll: false,
          selectedRowKeys: selectedRowKeys,
        }}
      />

      <ReplaceCostsModal 
        dataSource={replacementImports} 
        onOk={handleReplaceCosts}
        onCancel={() => setReplacementImports([])}
      />
    </Space>
  </div>;
};

export default AssemblyCostsView;
