import { Quote } from "../../api/models"
import {useContext, useState} from "react";
import { ConfiguratorContext } from "../../context";
import {Button, Checkbox, Col, Form, Modal, Radio, Row, message, notification, ButtonProps } from "antd";
import {useForm} from "antd/lib/form/Form";
import _ from "lodash";
import { useQuoteContext } from "../../contexts/QuoteContext";
import FormItem from "antd/es/form/FormItem";

export interface PdfOptions {
  inventory?: boolean;
  technicalSpecification?: boolean;
  technicalSpecificationExcludeNoOption?: boolean;
  technicalSpecificationExcludeEngineeringOption?: boolean;
  pricingSummary?: boolean;
  priceForDealer?: boolean;
  priceForCustomer?: boolean;
  priceForMsrp?: boolean;
  gvwr?: boolean;
  componentLocation?: boolean;
  performance?: boolean;
  paint?: boolean;
  cancellationPolicy?: boolean;
  notes?: boolean;
  pricingIncludeDealerAdjustment?: boolean;
};

const ExportQuotePdf = (props: ButtonProps & {
  pendingChanges?: boolean
  onExporting?: (b: boolean) => void
  inventory?: boolean
}) => {
  const { pendingChanges, onExporting, inventory, ...btnProps } = props;

  const { quoteAsync } = useQuoteContext();
  const quote = quoteAsync?.val;

  const configurator = useContext(ConfiguratorContext);
  const [showModal, setShowExportContentsModal] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [form] = useForm();
  const showIncludeAdjustments = Form.useWatch('priceForCustomer', form);
  const isMsrp = Form.useWatch('priceForMsrp', form);
  const withTechnicalSpec = Form.useWatch('technicalSpecification', form);
  const includePricing = Form.useWatch('pricingSummary', form);

  const initialValues = {
    technicalSpecification: true,
    pricingSummary: true,
    priceForDealer: true,
    priceForCustomer: false,
    priceForMsrp: false,
    gvwr: true,
    componentLocation: true,
    performance: true,
    paint: true,
    cancellationPolicy: true,
    notes: true,
    pricingIncludeDealerAdjustment: true,
    technicalSpecificationExcludeNoOption: false,
    technicalSpecificationExcludeEngineeringOption: false,
  }

  const setExporting = (isExporting: boolean) => {
    setIsExporting(isExporting);
    onExporting?.(isExporting);
  }

  if (!quote) return <></>;

  const showPendingChangesAlert = () => {
    notification.warning({ message: "There are pending changes. Please save the quote first." });
  };

  const handleShowModal = () => {
    if (pendingChanges) {
      showPendingChangesAlert();
      return;
    }
    if ( inventory ) {
      const options = {...msrpExcludePages, ...initialValues, inventory}
      exportPDF(quote, options);
      return;
    }
    setShowExportContentsModal(true);
  }

  const msrpExcludePages = {
    gvwr: false,
    componentLocation: false,
    performance: false,
    paint: false,
    cancellationPolicy: false,
    notes: false,
    technicalSpecificationExcludeEngineeringOption: false,
    technicalSpecificationExcludeNoOption: false
  }

  const handleExport = () => {
    const options = form.getFieldsValue();
    const newOptions = isMsrp ? {...options, ...msrpExcludePages} : options;
    exportPDF(quote, { ...newOptions, inventory: inventory || false });
    setShowExportContentsModal(false);
  }
  const exportPDF = async (quote: Quote,  options: { inventory: boolean }) => {
    if (!quote) return;

    const url = configurator.api.getExportQuotePdfUrl(quote.displayRevisionId, options )
    const quoteRev = quote.revisions.find(rev => rev.id === quote.displayRevisionId)?.revision || 0
    const filename = `${quote.quoteId}_REV_${quoteRev}.pdf`;

    setExporting(true);
    try {
      await configurator.api.downloadPdf(url, filename)
    }
    catch (e: any) {
      message.error(e.message);
    }
    setExporting(false);
  }

  return <>
    <style>
      {`
        .pdf-option-form .ant-form-item {
          margin-bottom: 5px;
        }
      `}
    </style>
    <Button
      type="text"
      loading={isExporting}
      {...btnProps}
      onClick={handleShowModal}
    >
      PDF
    </Button>
    <Modal
      title="Export Quote"
      open={showModal}
      okText="Export"
      onOk={handleExport}
      onCancel={() => setShowExportContentsModal(false)}
    >
      <p style={{ fontSize: "10pt", fontWeight: "600" }}>Include the following sections in the quote:</p>
      <Form
        form={form}
        initialValues={ initialValues }
        className="pdf-option-form">

        <FormItem name="technicalSpecification" valuePropName="checked" wrapperCol={{ span: 24 }}>
          <PdfOptions label="Technical Specifications"/>
        </FormItem>
        {withTechnicalSpec && <FormItem name="technicalSpecificationExcludeNoOption" valuePropName="checked" wrapperCol={{ span: 24 }} style={{marginLeft: "3rem"}}>
          <PdfOptions label="Exclude NO OPTION selections" disabled={!withTechnicalSpec}/>
        </FormItem>}
        {withTechnicalSpec && !isMsrp && <FormItem name="technicalSpecificationExcludeEngineeringOption" valuePropName="checked" wrapperCol={{ span: 24 }} style={{marginLeft: "3rem"}}>
          <PdfOptions label="Exclude Engineering selections" disabled={!withTechnicalSpec || isMsrp}/>
        </FormItem>}

        <FormItem name="pricingSummary" valuePropName="checked" wrapperCol={{ span: 24 }}>
          <PdfOptions label="Pricing Summary"/>
        </FormItem>

        {includePricing && <Row gutter={24}  style={{marginLeft: "3rem"}}>
          <Form.Item name="priceForDealer" valuePropName="checked">
            <Radio onClick={() => {form.setFieldsValue({...form.getFieldsValue(), priceForDealer: true, priceForMsrp: false, priceForCustomer: false, pricingIncludeDealerAdjustment: false})}}>Dealer Pricing</Radio>
          </Form.Item>
          <Form.Item name="priceForCustomer" valuePropName="checked">
            <Radio onClick={() => {form.setFieldsValue({...form.getFieldsValue(), priceForDealer: false, priceForMsrp: false, priceForCustomer: true, pricingIncludeDealerAdjustment: true})}}>Customer Pricing</Radio>
          </Form.Item>
          <Form.Item name="priceForMsrp" valuePropName="checked">
            <Radio onClick={() => {form.setFieldsValue({...form.getFieldsValue(), priceForDealer: false, priceForMsrp: true, priceForCustomer: false, pricingIncludeDealerAdjustment: false})}}>MSRP</Radio>
          </Form.Item>
        </Row>}

        {showIncludeAdjustments && <FormItem name="pricingIncludeDealerAdjustment" valuePropName="checked" wrapperCol={{ span: 24 }} style={{marginLeft: "3rem"}}>
          <PdfOptions label="Pricing - Include dealer Adjustments" disabled={isMsrp}/>
        </FormItem>}

        <FormItem name="gvwr" valuePropName="checked" wrapperCol={{ span: 24 }}>
          <PdfOptions label="GVWR" disabled={isMsrp}/>
        </FormItem>
        <FormItem  name="componentLocation"  valuePropName="checked" wrapperCol={{ span: 24 }}>
          <PdfOptions label="Component Locations" disabled={isMsrp}/>
        </FormItem>
        <FormItem  name="performance"  valuePropName="checked" wrapperCol={{ span: 24 }}>
          <PdfOptions label="Performance" disabled={isMsrp}/>
        </FormItem>
        <FormItem  name="paint" valuePropName="checked" wrapperCol={{ span: 24 }}>
          <PdfOptions label="Paint Specification" disabled={isMsrp}/>
        </FormItem>
        <FormItem  name="cancellationPolicy" valuePropName="checked" wrapperCol={{ span: 24 }}>
          <PdfOptions label="Change and Cancellation Policy" disabled={isMsrp}/>
        </FormItem>
        <FormItem  name="notes" valuePropName="checked" wrapperCol={{ span: 24 }}>
          <PdfOptions label="Notes" disabled={isMsrp}/>
        </FormItem> 
      </Form>
    </Modal>
  </>
}

export default ExportQuotePdf;

const PdfOptions = (props: {label: string, disabled?: boolean}) => {
  const {label, ...checkBoxProps} = props;
  return (
    <Row gutter={12}>
      <Col>
        <Checkbox {...checkBoxProps}/>
      </Col>
      <Col>
        <span>{label}</span>
      </Col>
    </Row>);
}

