import { useContext, useEffect, useState } from "react";
import { Table, Modal, Button, Input, Form, Select, notification, Row, Col } from "antd";
import { ConfiguratorContext } from "../context";
import { ConcessionCriteria, ConcessionCriteriaOption } from "../api/models";
import {EditOutlined} from "@ant-design/icons";
import { useIntl } from "react-intl";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";

const ConcessionCriteriaPage = () => {
  const configurator = useContext(ConfiguratorContext);
  const [allCriteria, setAllCriteria] = useState<ConcessionCriteria[]>([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ConcessionCriteriaOption | undefined>();
  const intl = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    getAllConcessionCriteria();
    getConcessionCriteriaOptions();
  }, []);

  const getAllConcessionCriteria = async () => {
    try {
      const resp = await configurator.api.getAllConcessionCriteria();
      setAllCriteria(resp.data);
    } catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to fetch concession criteria. " + errorMsg });
    }
  };

  const getConcessionCriteriaOptions = async () => {
    try {
      const resp = await configurator.api.getConcessionCriteriaOptions();
      setOptions(resp.data);
    } catch (e) {
      console.error(e);
    }
  }

  const showModal = (record: ConcessionCriteria) => {
    form.setFieldsValue(record);
    setOpen(true);
  };

  const onAdd = () => {
    form.resetFields();
    setOpen(true);
  }

  const handleSave = () => {
    const id = form.getFieldValue("id");
    !!id ? updateCriteria() : createCriteria();
  }

  const updateCriteria = async () => {
    const obj = await form.validateFields();
    try {
      await configurator.api.updateConcessionCriteria(obj);
      notification.success({ message: "Concession criteria saved." });
      setOpen(false);
      getAllConcessionCriteria();
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to save criteria. " + errorMsg });
    }
  };

  const createCriteria = async () => {
    const obj = await form.validateFields();
    try {
      await configurator.api.createConcessionCriteria(obj);
      notification.success({ message: "Concession criteria created." });
      setOpen(false);
      getAllConcessionCriteria();
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to create criteria. " + errorMsg });
    }
  };

  const deleteCriteria = async () => {
    const obj = await form.validateFields();

    if (!obj.id) return;

    try {
      await configurator.api.deleteConcessionCriteria(obj.id);
      notification.success({ message: "Concession criteria deleted." });
      setOpen(false);
      getAllConcessionCriteria();
    }
    catch (e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Failed to delete criteria. " + errorMsg });
    }
  };

  const requireOneOfTwoFields = async () => {
    const engineModel = form.getFieldValue("engineModel");
    const transmissionModel = form.getFieldValue("transmissionModel");
  
    if (!engineModel && !transmissionModel) {
      throw new Error("Either Engine Model or Transmission Model is required.");
    }
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const getUpdateInfo = (criteria: ConcessionCriteria): React.ReactNode => {
    if (!criteria?.updatedAt || !criteria?.updatedBy) {
      return <span>{`${criteria?.createdBy} on ${dayjs(criteria?.createdAt).format('M/D/YYYY')}`}</span>
    }
    return <span>{`${criteria?.updatedBy} on ${dayjs(criteria?.updatedAt).format('M/D/YYYY')}`}</span>
  }

  const columns = [
    {
      title: "Engine Model",
      dataIndex: "engineModel",
    },
    {
      title: "Transmission Model",
      dataIndex: "transmissionModel",
    },
    {
      title: "Sales Manager Concession",
      dataIndex: "salesManagerConcession",
    },
    {
      title: "Finance Concession",
      dataIndex: "financeConcession",
    },
    {
      title: "Update",
      dataIndex: "updated",
      render: (_, criteria: ConcessionCriteria) => getUpdateInfo(criteria),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record: ConcessionCriteria) => (
        <Button type="link" onClick={() => showModal(record)} icon={<EditOutlined style={{color: "#1677FF"}} />}>
        </Button>
      ),
    },
  ];

  return (
    <div className="site-layout-background">

      <Title level={2}>Concession Criteria</Title>

      <Row justify={"end"} style={{marginBottom: "10px"}}>
        <Button type="primary" onClick={onAdd}>Add</Button>
      </Row>

      <Table
        dataSource={allCriteria}
        columns={columns}
        rowKey="id"
        pagination={false}
      />

      <Modal
        title={!form.getFieldValue("id") ? "Add Concession Criteria" : "Edit Concession Criteria"}
        open={open}
        okText="Save"
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        footer={[
          <Row justify={"space-between"}>
            <Col>
              <Button key="delete" hidden={!form.getFieldValue("id")} danger onClick={deleteCriteria}>
                Delete
              </Button>
            </Col>
            <Col>
              <Button key="submit" type="primary" onClick={handleSave}>
                Save
              </Button>
            </Col>
          </Row>
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="id"
            hidden
          >
          </Form.Item>
          <Form.Item
            name="engineModel"
            label="Engine Model"
            rules={[{ validator: requireOneOfTwoFields }]}
          >
            <Select
              placeholder="Select Engine Model"
              options={options?.engineModel.map((model) => ({
                label: model,
                value: model,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="transmissionModel"
            label="Transmission Model"
            rules={[{ validator: requireOneOfTwoFields }]}
          >
            <Select
              placeholder="Select Transmission Model"
              options={options?.transmissionModel.map((model) => ({
                label: model,
                value: model,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="salesManagerConcession"
            label="Sales Manager Concession"
            rules={[{ required: true, message: "Please input the sales manager concession!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="financeConcession"
            label="Finance Concession"
            rules={[{ required: true, message: "Please input the finance concession!" }]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ConcessionCriteriaPage;
