import { FormattedMessage, IntlShape } from "react-intl";
import _ from "lodash";
import {AssemblyMetadata, Quote, RevisionType, Revision, RevisionStatus, User, Customer, CustomOptionType, ApproverRole, ChangeOrderStep, QuoteStatus, BaseCategory, AssemblyBase, Performance, Permission, QuoteReview, BaseQuote, AXIOS_CANCEL_MSG, AssemblyInfo, RevisionApprovalStatus} from "../api/models";
import {ReactNode} from "react";
import React from "react";
import {notification} from "antd";
import dayjs from "dayjs";
import {ColumnType} from "antd/es/table";
import {Configurator} from "../context";
import {AsyncState} from "../hook/useAsyncState";
import axios, { CancelTokenSource } from "axios";

interface QuoteStatusProps {
  status: string, 
  approvalStatus: string | undefined, 
  isPricingValid?:boolean | undefined, 
  reservation?: boolean | undefined  
}

export interface ExportableColumn<T> extends ColumnType<T> {
  renderCSV: ( rec:T ) => string
}

export interface SearchTerm {
  value:string, not:boolean
}

export interface HSLColor {
  h:number
  s:number
  l:number
}
export class Utils {
  static getAssemblyLabel(asm:AssemblyBase) : string | undefined {
    if (asm.label && asm.label.length > 0) {
      return asm.label;
    }
    return asm.bomDescription;
  }

  static formatUsername(u:User|undefined):string|undefined {  
    return u?.dealerName ? `${u?.name} (${u?.dealerName})` : u?.name; 
  }

  static getMetadataValue(md:AssemblyMetadata | undefined) : string | number | undefined {
    if (!md) return;
    if (md.valueText != null) return md.valueText;
    if (md.valueNumeric != null) return md.valueNumeric;
    if (md.valueDecimal != null) return md.valueDecimal;
    if (md.valueBool) return md.valueBool ? "True" : "False";
    return;
  }

  static getQuoteStatusId(quote:QuoteStatusProps | undefined) : string | undefined {
    if ( !quote ) return;
 
    const { status, approvalStatus, isPricingValid } = quote;
    if ( !status ) return;

    if (status === QuoteStatus.QUOTE) {
      if (isPricingValid === false) {
        return 'status.expiredQuote';
      }

      if (approvalStatus === RevisionApprovalStatus.APPROVED )  {

        if (quote.reservation) {
          return 'status.QUOTE.reservation';
        }
        return 'status.approvedQuote'; 
      }
    }

    return "status." + status;
  }
  static formatQuoteStatus(quote:QuoteStatusProps | undefined) : React.ReactNode {
    if ( !quote ) return <></>

    const id = this.getQuoteStatusId(quote);
    if ( !id ) return <></>

    return <FormattedMessage
      id={id}
      defaultMessage={quote.status}
    />
  }
  static formatQuoteStatusStr(intl:IntlShape, quote:QuoteStatusProps | undefined) : string | undefined {
    if ( !quote ) return;

    const id = this.getQuoteStatusId(quote);
    if ( !id ) return;
    
    return intl.formatMessage({id, defaultMessage:quote.status})
  }

  static formatApprovalType(approvalType: string | undefined, reservation?: boolean | undefined): React.ReactNode {
    if (!approvalType) return <></>;

    if (!!reservation) {
      approvalType = "RESERVATION";
    }

    return <FormattedMessage
      id={"approvalType." + approvalType}
      defaultMessage={approvalType}
    />;
  }

  static snakeCaseToFirstLetterCapitalized = (str: string | undefined) => {
    if (!str) return '';
    if (str === ApproverRole.ENGINEERING) return "Application Engineering";
    if (str === ChangeOrderStep.SALES_DESK_CHANGE_ORDER_PO_REQUEST || str === ChangeOrderStep.OLD_SALES_DESK_CHANGE_ORDER_PO_REQUEST) return "Sales Desk Change Order PO Request";
    const ret = str.split('_').map(str => 
      str[0].charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    ).join(' ');
    return ret;
  }

  static formatPercent = (() => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    });

    return (val:number | undefined) :string => {
      if ( val === undefined || isNaN(val) ) return 'N/A';
      return formatter.format( val );
    }
  })();

  static formatRevisionLabel = (rev: Revision | undefined, currentRevisionId: number | undefined, forDealer: boolean) : string => {
    if ( !rev ) return '';

    let status = '';  // Initial status pending approval according to the 1st release of change order
    const hasEpicorRev = rev.revisionString && rev.revisionString.length != 0 && 
      (rev.approvalStatus == RevisionApprovalStatus.APPROVED || rev.approvalStatus == null);

    const revision = forDealer ? rev.revision : (rev.revision + (hasEpicorRev ? (' ( Epicor Rev ' + rev.revisionString + ' )') : ''));

    const pendingStatus = [RevisionApprovalStatus.DRAFT, RevisionApprovalStatus.PENDING_APPROVAL];
    if (rev.id === currentRevisionId && ( !rev.approvalStatus || !pendingStatus.includes(rev?.approvalStatus) ) ) {
      return revision + ' - ' + RevisionStatus.CURRENT + status;
    }

    let revisionType = Utils.snakeCaseToFirstLetterCapitalized(rev?.revisionType) || '';

    status = rev.approvalStatus?.replace('_', ' ') || '';
    if (rev?.revisionType === RevisionType.SPLIT_ORDER && 'approvalStatus' in rev) {
      revisionType = "SPLIT";
    }

    if (revisionType === '') {
      revisionType = (rev?.id === currentRevisionId) ? RevisionStatus.CURRENT : RevisionStatus.PREVIOUS;
    }
    return revision + ' - ' + revisionType + (status === '' ? '' : ' ( ' + status + ' )');
  };

  static formatMoney = (() => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });


    return (val:number | undefined, defaultValue?: string) :string => {
      if ( val === undefined || isNaN(val) ) return defaultValue ?? 'N/A';
      const factor = Math.pow(10, 4);
      const roundedVal = Math.ceil(val * factor) / factor;
      return formatter.format( roundedVal );
    }
  })();

  static formatMoneyWithSign = (val: number | undefined): string => {
    const formattedValue = Utils.formatMoney(val);
    
    if (formattedValue === 'N/A') return formattedValue;

    if (!val || (val && val < 0)) {
      return formattedValue;
    } else {
      return '+' + formattedValue;
    }
  }

  static bisectList<T>( lst:Array<T>, pred:(x:T)=>boolean ):Array<T[]> {

    return lst.reduce( this.bisect(pred), [ new Array<T>(), new Array<T>() ] );
  }

  static bisect<T>(pred:(x:T)=>boolean) {
    return (acc:Array<T[]>, v:T ) : Array<T[]> => {
          const [ s, r ] =  acc;

          const cond = pred( v )

          if ( cond ) {
            s.push( v );
          }
          else {
            r.push( v );
          }

          return acc;

      } ;
  }

  static diff( a:any, b:any ) {
      return _.differenceWith(_.toPairs(a), _.toPairs(b), _.isEqual)
  }

  static saveNavigation() {
        window.sessionStorage.setItem("auth0-redirect", window.location.href );
  }

  static restoreNavigation() {
    const nav = window.sessionStorage.getItem("auth0-redirect");
    if ( nav ) {
      window.sessionStorage.removeItem("auth0-redirect");
      window.location.href = nav;
    }
  }

  static isOrder(quote: Quote | undefined | null): boolean {
    return !!quote?.partNumberString?.length;
  }

  static isChangeableRevision(quote:Quote | undefined, rev?:Revision) : boolean | undefined {
     if ( !quote ) return;

     const revision = rev || quote?.revisions.find(r => r.id === quote?.displayRevisionId);

     if ( !revision ) return;

    const isLatest = revision.latestApproved || quote.revisions.length == 1;

    const isChangeableRevision = isLatest ||
      (revision.revisionType == RevisionType.CHANGE_ORDER && !isLatest) ||
      (revision.revisionType == RevisionType.ENGINEERING_CHANGE && !isLatest);

    return isChangeableRevision;

  }

  static stripSortingPrefix(name: string | undefined | null) : string {
    return Utils.stripSortingPrefixOpt(name) || "";
  }
  static stripSortingPrefixOpt(name: string | undefined | null) : undefined | string {
    if (!name) return;

    const match = name.match(/[ ]*[0-9]+[ ]*\-(.*)/);
    if (match) {
      return match[1];
    }

    return name;
  };

  static joinNodes(data:ReactNode[], delimiter: ReactNode) : ReactNode {

    return <>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && delimiter}
            {item}
          </React.Fragment>
        ))}
      </>
  };

  static warning(msg:string | undefined) : void {
    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

  static getEngineeringLeadTime = (customOption: CustomOptionType, categories: BaseCategory[]): string => {
    const category = categories?.find(cat => cat.id === customOption.category?.id);
    if (category?.designAndProcurementWeeks || category?.designAndProcurementWeeks === 0) {
      return String(category.designAndProcurementWeeks);
    }
    return '';
  }

  static getAddress(customer: Customer) : string {
    const address = [customer.addressLine1, customer.addressLine2].filter(v => v).map(str => str?.trim()).join(" ");
    const city = [customer.city || undefined].filter(v => v).map(str => str?.trim()).join(" ");
    const zip = [customer.stateProvince, customer.postalCode].filter(v => v).map(str => str?.trim()).join(" ");
    return [address, city, zip].filter(v => v).join(", ");
  }

  static exportDataAsCSV<T>(filename:string, data:T[], columns:ExportableColumn<T>[]) : void {
    if ( data.length === 0 ) return;

    const csvHeader = [ 
      columns
      .map( c => c.title )
      .join(",") 
    ];
    const csvData = data.map( d => columns
                             .map( c => c.renderCSV( d ) ) //get the column from the data
                             .map( c => String( c ) )
                             .map( c => c.replaceAll( '"', '""' ) ) //escape double quotes
                             .map( c => `"${c}"` ) //wrap in double quotes
                             .join(",") 
                            );

    const CSV = csvHeader.concat( csvData ).join('\n');

    window.URL = window.webkitURL || window.URL;

    const contentType = 'text/csv';

    const csvFile = new Blob([CSV], {type: contentType});

    const csvFileNameComponents = [ filename ];

    const ts = dayjs().format( "YYYY-MM-DD" );
    csvFileNameComponents.push( ts );

    const csvFileName = csvFileNameComponents.join( "_" ) + ".csv";

    var a = document.createElement('a');
    a.download = csvFileName;
    a.href = window.URL.createObjectURL(csvFile);
    a.click()

  }

  static isWithinLeadTime(productionDate: Date | undefined, leadTime: number | undefined): boolean {
    if (!productionDate || !leadTime) return false;
    return dayjs(productionDate).isBefore(dayjs().add(leadTime, 'day'));
  }

  static getQuoteReadOnlyMsg(conditions: {
    isEffectiveRevision:boolean | undefined
    isRevisionApproved:boolean | undefined
    isInitialLoading:boolean | undefined
    isLocked:boolean | undefined
    hasWritePermission:boolean | undefined
    isEngineeringLocked:boolean | undefined
    isReservation :boolean | undefined
    isOrder: boolean | undefined
    isOrderCancelled:boolean | undefined
    isDraft:boolean | undefined
    isPendingApproval:boolean | undefined
    isEngineering:boolean | undefined
    isAdmin:boolean | undefined
    isEngineeringChangeOrder:boolean | undefined
    isWritable: boolean | undefined
    existPendingSplitChange: boolean | undefined
    isArchived: boolean | undefined
  }) : string | undefined {

    const {
      isEffectiveRevision,
      isRevisionApproved,
      isInitialLoading,
      isLocked,
      hasWritePermission,
      isEngineeringLocked,
      isReservation ,
      isOrder,
      isDraft,
      isPendingApproval,
      isOrderCancelled,
      isEngineering,
      isAdmin,
      isEngineeringChangeOrder,
      isWritable,
      existPendingSplitChange,
      isArchived,
    } = conditions;

    return isInitialLoading ? "Please wait for the quote to finish loading."
      : isArchived  ? "This quote has been archived.  No changes are permitted."
      : isOrder && isPendingApproval && !existPendingSplitChange ? "Orders cannot be changed while pending approval."
      : isOrder && !isDraft ? "Orders can only be changed with a change order."
      : isLocked ? "This quote is locked by another user."
      : !hasWritePermission ? "Additional permissions are needed to edit this quote."
      : isEngineeringLocked ? "This quote is locked by engineering."
      : !isEffectiveRevision ? "Only the latest effective revision be modified."
      : isRevisionApproved ? "A quote cannot be changed once approved.  Please create a change order or revise the quote."
      : isReservation ? "This is a reservation and cannot be modified."
      : isOrderCancelled ? "This order has been canceled."
      : (isEngineeringChangeOrder && isDraft && !( isEngineering || isAdmin) ) ? "This is an engineering change and can only be changed by an engineer."
      : !isWritable ? "Additional permissions are needed to edit this quote."
      : undefined ;

  }
  static getQuoteState(configurator:Configurator, quoteAsync:AsyncState<Quote> | undefined, isLocked?:boolean ) {

    const quote = quoteAsync?.val;

    const isEngineering = configurator.isEngineering();
    const isAdmin = configurator.isAdmin();
    const isSalesDesk = configurator.isSalesDesk();

    const isEngineeringLocked = !( isEngineering || isAdmin ) && !!quote?.lockedByEngineer;

    const hasShareWritePermission = !!quote?.quoteShares?.filter( s => s.writePermission ).find( s => s.user.id === configurator.userInfo?.id );
    const hasDealerWritePermission = configurator.hasAnyPermission([Permission.DEALER_ADMIN_WRITE, Permission.DEALER_MANAGEMENT_WRITE]);
    const isQuoteOwner = quote?.owner?.id === configurator.userInfo?.id;
    const isQuoteSales = quote?.salesTeam?.sales?.some(rep => rep.id === configurator.userInfo?.id);

    const hasWritePermission = isQuoteOwner || isEngineering || isAdmin || isSalesDesk || hasDealerWritePermission || isQuoteSales || hasShareWritePermission;

    const isInitialLoading = quoteAsync?.isLoading() && !quoteAsync.val;

    const isLatestRevision = quote?.displayRevisionId === quote?.latestRevisionId;
    const isEffectiveRevision = quote?.displayRevisionId === quote?.effectiveRevisionId;
    const effectiveRevision = quote?.revisions.find( r => r.id === quote.effectiveRevisionId );

    const existPendingSplitChange = quote?.pendingSplitChange != undefined;
    const splitChangeFullySubmitted = quote?.pendingSplitChange?.partners.every(s => s.partnerSubmitted === true) && quote?.pendingSplitChange?.selfSubmitted;
    const beforeSplitChangeSelfSubmitted = existPendingSplitChange && !quote?.pendingSplitChange?.selfSubmitted;

    const isPendingApproval = effectiveRevision?.approvalStatus === RevisionApprovalStatus.PENDING_APPROVAL;
    const isOrder = Utils.isOrder(quote);
    const isDraft =  effectiveRevision?.approvalStatus === RevisionApprovalStatus.DRAFT;
    const isPending = isDraft || isPendingApproval || splitChangeFullySubmitted;

    const isReleaseEngineeringApprovalEdit = isPendingApproval && configurator.isReleaseEngineering() && !quote?.latestApproval?.action && quote?.latestApproval?.approverRole === ApproverRole.RELEASE_ENGINEERING;
    const isApplicationEngineeringApprovalEdit =  isPendingApproval && configurator.isEngineering() && !quote?.latestApproval?.action && quote?.latestApproval?.approverRole === ApproverRole.ENGINEERING;
    const isSalesDeskApprovalEdit =  isPendingApproval && configurator.isSalesDesk() && !quote?.latestApproval?.action && quote?.latestApproval?.approverRole === ApproverRole.SALES_DESK;
    const isApprovalEdit = !quote?.priceProtected && ( isReleaseEngineeringApprovalEdit || isApplicationEngineeringApprovalEdit || isSalesDeskApprovalEdit );

    // revision will become draft after change order is created in split change, button should be disabled at the moment
    const canAddChangeOrderToSplit = beforeSplitChangeSelfSubmitted && !isDraft && effectiveRevision?.approvalStatus !== RevisionApprovalStatus.PENDING_APPROVAL;

    const isSplitOrder = effectiveRevision?.revisionType === RevisionType.SPLIT_ORDER;
    const isSalesChangeOrder = isEffectiveRevision && (effectiveRevision?.revisionType == RevisionType.CHANGE_ORDER || effectiveRevision?.revisionType == RevisionType.PRICE_PROTECTED_CHANGE);
    const isEngineeringChangeOrder = isEffectiveRevision && effectiveRevision?.revisionType == RevisionType.ENGINEERING_CHANGE;
    const isReviseQuote = !isLatestRevision && effectiveRevision?.revisionType == RevisionType.QUOTE_UPDATE;

    const isChangeOrderSalesDeskPoStep = (isSalesChangeOrder && 
      (quote?.status === ChangeOrderStep.SALES_DESK_CHANGE_ORDER_PO_REQUEST || quote?.status === ChangeOrderStep.OLD_SALES_DESK_CHANGE_ORDER_PO_REQUEST));
    const isChangeOrderSalesDeskReviewStep = (isSalesChangeOrder && 
      (quote?.status === ChangeOrderStep.SALES_DESK_CHANGE_ORDER_REVIEW || quote?.status === ChangeOrderStep.OLD_SALES_DESK_CHANGE_ORDER_REVIEW));

    const isOrderShipped = quote?.status === QuoteStatus.SHIPPED
    const isOrderCancelled = quote?.status === QuoteStatus.CANCELLED
    const isRevisionApproved = quote?.approvalStatus === RevisionApprovalStatus.APPROVED;
    const isArchived = quote?.archived;
    const canChangeReadOnly = ( isSalesDesk || isEngineering || isAdmin ) && !isEngineeringLocked && !isArchived;

    const isNewQuote = !quote && quoteAsync?.isDone();

    const writeable = 
      isNewQuote ||
      isApprovalEdit ||  //is role approval step
      (isDraft && isEffectiveRevision) || 
      (isDraft && isSalesChangeOrder ) //is a sales change order
    ;

    const readOnlyMsg = this.getQuoteReadOnlyMsg({
      isEffectiveRevision,
      isRevisionApproved,
      isInitialLoading,
      isLocked,
      hasWritePermission,
      isEngineeringLocked,
      isReservation: !!quote?.reservation,
      isOrderCancelled,
      isOrder,
      isDraft,
      isPendingApproval,
      isEngineering,
      isAdmin,
      isEngineeringChangeOrder,
      isWritable: writeable,
      existPendingSplitChange,
      isArchived,
    });

    const isReadOnly = !!readOnlyMsg;

    const rslt = {
      isInitialLoading,
      isEngineeringLocked,
      isLatestRevision,
      isEffectiveRevision,
      effectiveRevision,
      existPendingSplitChange,
      splitChangeFullySubmitted,
      beforeSplitChangeSelfSubmitted,
      isOrder,
      isPendingApproval,
      isDraft,
      isPending,
      canAddChangeOrderToSplit,
      isSplitOrder,
      isSalesChangeOrder,
      isEngineeringChangeOrder,
      isOrderShipped,
      isOrderCancelled,
      isRevisionApproved,
      isReadOnly,
      isApprovalEdit,
      readOnlyMsg,
      isReviseQuote,
      hasWritePermission,
      isArchived,
      canChangeReadOnly,
      isChangeOrderSalesDeskPoStep,
      isChangeOrderSalesDeskReviewStep,
    }
    //console.log(rslt);
    return rslt;

  }

  static getPerformanceErrors(perf:Performance | undefined) : Array<string> | undefined {

    if ( !perf ) return undefined;

    const data = perf.performanceData;
    const errs = [
      data?.gradeabilityAlert && "The gradeability needs review.",
      data?.wheelslipAlert && "The wheelslip needs review.",
      data?.accelerationRateAlert && "The acceleration rate needs review.",
      data?.startabilityAlert && "The startability needs review.",
      data?.gearedSpeedAlert && "The gearing speed needs review."
    ].filter(v=>v) as Array<string>;

    if ( perf?.performanceWeight?.weightsMissing.length 
        || perf?.performanceWeight?.tareMissing.length 
      || perf?.performanceWeight?.gvwrMissing.length 
      || perf?.performanceData?.performanceMissing.length 
      || perf.performanceDimension?.dimensionMissing.length ) {
        errs.push( "There are missing selections causing calculation errors.");
      }

      return errs;

  }

  static reviewHasErrors(review:QuoteReview | undefined) : boolean {
    if( !review ) return false;

    if ( review.customOptions.length ) return true;
    if ( review.invalidAssemblyLst.length ) return true;
    if ( review.obsoleteAssemblyLst.length ) return true;
    if ( review.missingCategoryLst.length ) return true;
    if ( Utils.getPerformanceErrors(review.performance)?.length ) return true;


    return false;

  }

 static searchValue =  (searchFilter:SearchTerm[] | undefined, value:string | undefined) => {
    if ( !searchFilter?.length ) return true;

    return searchFilter.every(s => {
      const rslt = value?.toLowerCase().includes( s.value.toLowerCase() );
      return s.not ? !rslt : rslt;
    });
  }
  static splitByWhitespacePreservingQuotes(input:string | undefined) : SearchTerm[] | undefined {
    if ( !input ) return;

    const regex = /(!?"[^"]*"|!?[^\s"]+)/g;
    let matches = new Array<SearchTerm>();
    let match:RegExpExecArray | null;

    while ((match = regex.exec(input)) !== null) {
        let value = match[0];
        let not = value.startsWith('!');
        
        if (not) {
            value = value.substring(1);
        }

        // Remove surrounding quotes if they exist
        if (value.startsWith('"') && value.endsWith('"')) {
            value = value.substring(1, value.length - 1);
        }

        matches.push({ value: value, not: not });
    }

    return matches;
  }

  static buildSerialNumberStr(snLst:string[]):string {
    return [...snLst]
      .map( sn => Number.parseInt(sn) )
      .filter( sn => !isNaN(sn) )
      .sort( )
      .reduce( ( acc, sn ) => {

        const lastSet = acc[ acc.length - 1 ] || [];
        const lastSn = lastSet[ lastSet.length - 1 ];

        //if no set, it's the first.
        //create first set
        if ( !lastSn ) {
          lastSet.push( sn );
          acc.push( lastSet );
          return acc;
        }

        //if contiguous with last serial
        //add to the current set
        if ( sn - 1 === lastSn ) {
          lastSet.push( sn );
          return acc;
        }

        //otherwise, create new set
        acc.push( [ sn ] ); 
        return acc;

      }, new Array<Array<number>>() )
      .reduce( (acc, snRange ) => {

        //if single set, leave solo
        if ( snRange.length === 1 ) {
          acc.push( String( snRange.pop() ) )
          return acc;
        }

        //if only two, join them
        if ( snRange.length === 2 ) {
          acc.push( snRange.join(", ") )
          return acc;
        }

        //if multiple serials, create range
        acc.push( snRange.shift() + "-" + snRange.pop() )
        return acc;

      }, new Array<string>() )
      .join( "," );
  }


  static expandTruckSerialNumberStr( snStr:string ):number[] {
    //use Set to remove duplicates
    return [...(new Set( snStr.split( "," )
      .flatMap( v => {

        const arr = v.split( "-" ); //split ranges

        const r1 = Number.parseInt( arr[0] );
        if ( isNaN(r1) ) throw new Error(`Invalid number. (${arr[0]})` );

        if ( arr.length === 1 ) return [ r1 ]; //if only one value, not a range, return

        const r2 = Number.parseInt( arr[1] );
        if ( isNaN(r2) ) throw new Error(`Invalid number. (${arr[1]})` );

        const rangeSize = ( r2 + 1 ) - r1; //inclusive
        if ( rangeSize < 1 ) throw new Error(`Invalid range size. The range ending number must be larger than the starting number. ${r1} - ${r2}.  size = (${rangeSize})` );
        if ( rangeSize > 10000 ) throw new Error(`Invalid range size. It's too big. ${r1} - ${r2}.  size = (${rangeSize})` );

        return new Array<number>( rangeSize ).fill( r1 ).map( ( v, ndx ) => v + ndx ); //create an full array from the range

      })
    ))];


  }


  static executeWithCancelToken = async <T extends Object> (ref: React.MutableRefObject<any>, request: (token: any) => Promise<T | undefined>) => {
    const cancelSource = Utils.getCancelTokenSource(ref);
    try {
      const resp = await request(cancelSource.token);
      ref.current = undefined;
      return resp;
    } catch (e: any) {
      const msg = e.response?.data?.message || e.message;
      if (msg !== AXIOS_CANCEL_MSG) {
        throw e;
      }
    }
  };

  static getCancelTokenSource = (cancelTokenRef: React.MutableRefObject<CancelTokenSource | undefined>): CancelTokenSource => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel(AXIOS_CANCEL_MSG);
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenRef.current = cancelSource;
    return cancelSource;
  };

  static getAssemblyInfoDisplayLabel = (asm:AssemblyInfo) : string => {
    const lbl = ( asm.label?.length )
      ? asm.label
      : asm.bomDescription 

      return [asm.bom, lbl].filter(v=>v).join( " - " );
  }

  static isEmptyDeep = (obj:any) => {
    if ( obj === undefined || obj === null ) {
      return true;
    }
    else if(_.isObject(obj)) {
        if(Object.keys(obj).length === 0) return true
        else return _.every(_.map(obj, v => Utils.isEmptyDeep(v)))
    }
    else if(_.isString(obj)) {
      return !obj.length
    }

    return false
  }

  static notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }

}

export default Utils;
