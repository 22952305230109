import "./ColorPicker.css";
import { HexColorPicker, HexColorInput } from "react-colorful";
import {FC, useCallback, useRef, useState} from "react";
import useClickOutside from "./useClickOutside";

interface Props {
  placeholder:string
  value: string;
  onChange: (e:any) => void
}
const ColorPicker: FC<Props> = ({value: color, onChange, placeholder}: Props) => {
  const popover = useRef(null);

  const [isColorPickerOpen, setColorPickerOpen] = useState<boolean>(false);

  const close = useCallback(() => setColorPickerOpen(false), []);
  useClickOutside(popover, close);

  return (
    <>
      <HexColorInput onChange={onChange} 
        color={color}
        placeholder={placeholder}
        className="ant-input colorTextInput"
        prefixed={!!color} //hide prefix # when value is empty
      />
      <div
        className="swatch"
        style={{ backgroundColor: color }}
        onClick={() => setColorPickerOpen(true)}
      />

      {isColorPickerOpen && (
        <div className="popover" ref={popover} >
          <HexColorPicker 
            onChange={onChange}
          />
        </div>
      )}
    </>
  );
}

export default ColorPicker

