import { Drawer } from "antd";
import SiteNav from "./SiteNav";
import {
  MenuOutlined
} from "@ant-design/icons";
import {useState} from "react";
import {Header} from "antd/lib/layout/layout";

const DrawerSiteNav = () => {

  const [ showDrawer, setShowDrawer ] = useState<boolean>(false);

  const handleClickHeader = () => {
    setShowDrawer(!showDrawer);
  }

  return <>
    <Header style={{color: "white"}} onClick={handleClickHeader}>
      <div style={{marginLeft: "-20px", float:"left"}}><MenuOutlined /></div>
      <span >Battle Motors - Configurator</span>
    </Header>

    <Drawer
      open={showDrawer}
      placement="left"
      width="275px"
      onClose={handleClickHeader}
      bodyStyle={{padding: 0, backgroundColor: "#001529"}}
    >
      <SiteNav handleSelect={handleClickHeader} />
    </Drawer>
  </>
}
export default DrawerSiteNav;


