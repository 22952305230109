import Title from "antd/lib/typography/Title";
import { Table, Button, Space, notification, Col, Drawer, Spin, Skeleton, DrawerProps, Badge } from "antd";
import  { useCallback, useContext, useEffect, useRef, useState } from "react";
import { InfoCircleTwoTone, ExclamationOutlined } from "@ant-design/icons";
import { ConfiguratorContext, } from "../context";
import { Link } from "react-router-dom";
import dayjs from 'dayjs'
import { ArrayParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import Utils from "../util/util";
import { AXIOS_CANCEL_MSG, BaseQuote, CommentStats, CommentTopic, PAGINATION_MAX_PAGE_SIZE, QuoteComment, QuoteFilter, QuoteReview } from "../api/models";
import { ColumnType, FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import useCheckMobileScreen from "../hook/useCheckMobileScreen";
import {debounce} from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import { getCSVRow } from "../helpers/csv";
import {useIntl} from "react-intl";
import {SortOrder} from "antd/es/table/interface";
import DiffRevisionModalButton from "../components/Quote/DiffRevisionModalButton";
import QuoteFilterControls from "../components/QuoteFilterControls";
import QuoteReviewDetail from "../components/QuoteReviewDetail";
import CommentActivityList from "../components/CommentActivityList";
import axios, {CancelTokenSource} from "axios";

type  BaseQuoteSort = SorterResult<BaseQuote> | SorterResult<BaseQuote>[]
type  TableOnChangeFn = (p:TablePaginationConfig, f:Record<string, FilterValue | null>, s: BaseQuoteSort) => void

const getProductionDateStr = (date?: Date | undefined ) => {
  const days = date ? dayjs(date).diff(dayjs(), 'day') : undefined
  return days !== undefined ? days + " days" : 'Not Available';
};

const reviewFilterDefaults = {
  ordersOnly: true,
  review: true,
}

const CommentList = (props: {quoteId:string | undefined}) => <CommentActivityList {...props} topic={CommentTopic.VfdReview} />

const gotoQuote = (q:BaseQuote) => window.open("/configurator/" + encodeURI(q.quoteId), "_blank");

const DEFAULT_PAGE_SIZE = 20;
const VFDReviewPage = () => {
  const isMobile = useCheckMobileScreen();
  const intl = useIntl();

  const [_quoteList, quoteListAsync] = useAsyncState<BaseQuote[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [incentiveProgramsParam, setIncentiveProgramParam] = useQueryParam<string|undefined|null>("incentivePrograms", StringParam);
  const [quoteEngineersParam, setQuoteEngineersParam] = useQueryParam("engineers", StringParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string|undefined|null>("filter", StringParam);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number|undefined|null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number|undefined|null>("p", NumberParam);
  const [dealerFilterParam, setDealerFilterParam] = useQueryParam<any>("dealer", ArrayParam);
  const [sortFieldQueryParam, setSortFieldQueryParam] = useQueryParam<string|undefined|null>("sf", StringParam);
  const [sortDirectionQueryParam, setSortDirectionQueryParam] = useQueryParam<string|undefined|null>("sd", StringParam);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [filter, setFilter] = useState<QuoteFilter>({
    search: searchFilterParam || undefined, //silly fix for null
    dealerLst: dealerFilterParam || undefined, //silly fix for null
    incentivePrograms: incentiveProgramsParam?.split(",") || undefined, //silly fix for null
    engineers: [quoteEngineersParam as (string|null) || []].flat(),
    myQuotes: false,
  });
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const defaultSort = {
    columnKey: 'latestRevision.summary.productionDate',
    order: 'ascend' as SortOrder
  };
  const [sort, setSort] = useState<BaseQuoteSort>({
    columnKey: sortFieldQueryParam || defaultSort.columnKey,
    order: sortDirectionQueryParam as ( SortOrder | undefined ) || defaultSort.order
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    total: 0,
    position: ["topLeft", "bottomLeft"],
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
    showLessItems: isMobile,
  });

  useEffect(() => {
    loadEngineeringReviewOrders( quoteListAsync, pagination, filter , sort );
  }, [ filter, pagination.pageSize, pagination.current, sort ]);

  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);
  }, [pagination.pageSize, pagination.current ]);

  const loadEngineeringReviewOrders = useCallback(debounce( async (quoteListAsync:AsyncState<BaseQuote[]>, pagination: TablePaginationConfig, filter: QuoteFilter, sorter:BaseQuoteSort ) : Promise<BaseQuote[] | undefined> => {

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    const sort = [ sorter ].flat()[0];
    quoteListAsync.setLoading();
    try {
      const resp = await configurator.api.listQuotes({
          ...filter,
          ...reviewFilterDefaults,
          page: ( pagination.current || 1 ) - 1,
          size: pagination.pageSize || DEFAULT_PAGE_SIZE,
          sort: {
            field: sort.columnKey?.toString() ||  defaultSort.columnKey,
            direction: sort.order == 'ascend' ? 'asc' : 'desc',
          }
        },
        cancelSource.token,
      )
      cancelTokenSourceRef.current = undefined;

      const reviewLst = resp.data.content.filter(v=>v);
      quoteListAsync.setDone( reviewLst );
      setPagination({ ...pagination, total: resp.data.totalElements });

      return reviewLst;
    }
    catch(e:any) {
      const errorMsg = intl.formatMessage({ id: e.message })
      notification.error({message:"Quotes failed to load. " + errorMsg});
      quoteListAsync.setFail( e.message );
    }

    return;
  }, 400 ), [ ] );



  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: BaseQuoteSort) => {
    setPagination(pagination);

    const firstSort = [ sort ].flat()[0];
    setSortFieldQueryParam( firstSort.columnKey?.toString() );
    setSortDirectionQueryParam( firstSort.order );
    setSort(sorter);
  };

  const onFilterChange = ( filter:QuoteFilter ) => {
    setSearchFilterParam(filter.search);
    setDealerFilterParam( filter.dealerLst );
    setIncentiveProgramParam(filter.incentivePrograms?.join(","));
    setQuoteEngineersParam(filter.engineers?.join(","));
    setPagination({ ...pagination, current: 1 });

    setFilter(filter);
  };

  const exportOrders = async () => {
    try {
      setIsExporting(true);
      const resp = (await configurator.api.listQuotes({
        ...filter,
        ...reviewFilterDefaults,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE, 
        sort: {
          direction: 'desc',
          field: 'latestRevision.summary.productionDate',
        }
      })).data;

      var csv = [[
        'Quote',
        'PartNo',
        'Model',
        'Engineer',
        'Status',
        'DueDate',
      ].join(',')];

      resp.content.forEach((quote: BaseQuote) => {
        csv.push(getCSVRow([
          quote.quoteId,
          quote.partNumberString || '',
          quote.status,
          quote.model.name,
          quote.salesTeam?.engineers?.map(u => u.name).join(" + ") || '',
          getProductionDateStr(quote.productionDate),
        ]));
      });
      var blob = new Blob([csv.join('\n')], {type: 'text/csv;charset=utf-8'});
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'orders-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="site-layout-background">
      <Title level={2}>VFD Review</Title>
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>
        <QuoteFilterControls 
          filter={filter}
          onFilterChange={onFilterChange}
        />

        <DesktopTable
          quoteListAsync={quoteListAsync}
          tableOnChange={tableOnChange}
          pagination={pagination}
          sort={sort}
          reviewParam={true}
          isExporting={isExporting}
          exportOrders={exportOrders}
        />

      </Space>
    </div>
  );
};

const ReviewDrawer = (props:DrawerProps & { quote:BaseQuote | undefined }) => {

  const { quote, ...drawerProps } = props;
  
  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const loadReview = async (quoteRevisionId:number | undefined) : Promise<QuoteReview | undefined> => {
    if ( !quoteRevisionId ) return;

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, {includeAllCustomOptions:true});
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }

  return <>
    <Drawer
      {...drawerProps}
      title={<>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
          <div>{quote?.partNumberString} Issue(s)</div>
          {!!quote &&
          <Button type="primary" onClick={() => gotoQuote(quote) }>Edit</Button>
          }
        </div>
      </>}
      afterOpenChange={(open) => {
        if (open) {
          reviewAsync.setInit();
          loadReview(quote?.displayRevisionId);
        }
      }}
    >

      <Spin spinning={reviewAsync.isLoading()}>
        {!review ? <Skeleton />
        :<QuoteReviewDetail review={review} />}
      </Spin>
    </Drawer>
  </>
}

const CommentsDrawer = (props:DrawerProps & { quote:BaseQuote | undefined }) => {

  const { quote, ...drawerProps } = props;
  
  return <>
    <Drawer
      {...drawerProps}
      title={<>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}} >
          <div>{quote?.partNumberString} Comment(s)</div>
        </div>
      </>}
    >
        <CommentList quoteId={quote?.quoteId} />
    </Drawer>
  </>
}


const DesktopTable = (props: {
  quoteListAsync: AsyncState<BaseQuote[]>
  tableOnChange: TableOnChangeFn
  pagination: TablePaginationConfig
  sort:BaseQuoteSort
  reviewParam:boolean
  isExporting: boolean
  exportOrders:()=>void
}) => {
  const { quoteListAsync, tableOnChange, pagination, sort, isExporting, exportOrders  } = props;
  const quoteList = quoteListAsync.val;

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const hasComparePermissions = configurator.isSalesDesk() || configurator.isAdmin() || configurator.isEngineering();
  const [quote, setQuote] = useState<BaseQuote | undefined>();
  const [isReviewOpen, setIsReviewOpen] = useState<boolean>(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(false);

  const [quoteCommentStats, setQuoteCommentStats] = useState<Record<string,CommentStats>>();

  const getCommentCount = async (quoteId:string) : Promise<Record<string, CommentStats>> => {
    const commentLst = await loadQuoteComments(quoteId);
    const total = commentLst?.length || 0;
    const unread = commentLst?.filter(c => !c.lastViewedAt).length || 0;
    return { 
      [quoteId]: { total, unread }
    };
  }

  const updateQuoteCommmentCnt = async (quoteId:string | undefined) => {
    if ( !quoteId ) return;

    const commentLst = await getCommentCount(quoteId);

    if ( commentLst ) {
      setQuoteCommentStats( { 
        ...quoteCommentStats, 
        ...commentLst
      } );
    }
  }

  useEffect(() => {
    if ( quoteListAsync.isDone() ) {
      Promise.all( quoteList?.map(q => q.quoteId ).map( getCommentCount ) || [] )
        .then( qc => {
          const stats = qc.reduce( ( acc, v ) => ({...acc, ...v}), {}) ;
          setQuoteCommentStats( stats );
        });
    }
  }, [ quoteListAsync ]);

  const handleShowReview = (quote:BaseQuote) => {
    setQuote( quote );
    setIsReviewOpen(true);
  }
  const handleShowComments = (quote:BaseQuote) => {
    setQuote( quote );
    setIsCommentsOpen(true);
  }
  const handleHideComments = () => {
    const quoteId = quote?.quoteId;
    updateQuoteCommmentCnt(quoteId);

    setIsCommentsOpen(false)
  }

  const loadQuoteComments = async (quoteId:string | undefined) : Promise<QuoteComment[] | undefined> => {
    if ( !quoteId ) return;

    try {
      const topic = [ CommentTopic.VfdReview ]
      const resp = await configurator.api.fetchQuoteComments(quoteId, {topic});
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch comments " + errorMsg });
    }
    return;
  }


  const firstSort = Array.isArray(sort) ? sort[0] : sort;

  let columns:ColumnType<BaseQuote>[] = [
    {
      title: "Quote",
      key: "name",
      render: (q:BaseQuote) => <><Link to={"/configurator/" + encodeURI(q.quoteId)}
        onClick={(e) => {
          gotoQuote(q);
          e.preventDefault();
        }}
      >{q.quoteId} rev. {q.revision} ({q.epicorRevision})</Link></>,
    },
    {
      title: "Part No.",
      key: "partNumberString",
      width:  "10rem", //fixed size to prevent wrapping
      render: (q:BaseQuote) => <>
        <span style={{whiteSpace: "nowrap"}}>{q.partNumberString}</span>
        <Button onClick={() => handleShowComments(q)} 
          shape="circle" 
          type="text"
          icon={<Badge count={quoteCommentStats?.[q.quoteId]?.total} size="small" color={quoteCommentStats?.[q.quoteId]?.unread ? "red" : "grey" } >
          <InfoCircleTwoTone />
        </Badge>} />
      </>
    },
    {
      title: "Model",
      key: "model",
      render: (q:BaseQuote) => q.model.name
    },
    {
      title: "Status",
      key: "status",
      render: (q:BaseQuote) => Utils.formatQuoteStatus(q),
    },
    {
      title: "Due Date",
      key: "latestRevision.summary.productionDate",
      sorter: true,
      defaultSortOrder: firstSort.order,
      render: (q:BaseQuote) => (
        <span>{getProductionDateStr(q.productionDate)}</span>
      ),
    },
  ];

  if ( configurator.isInternalSales() ) {
    const SALES_COL_NDX = 3;
    columns = columns.slice(0,SALES_COL_NDX)
    .concat( {
      title: "Engineer",
      key: "salesTeam.engineerMembers.user.name",
      render: (_owner, q) => q.salesTeam?.engineers?.map(u => Utils.formatUsername(u)).join(", "),
      sorter: true,
    })
    .concat( columns.slice(SALES_COL_NDX) );
  }

  columns.unshift( {
    key: "review",
    onCell: _record => {
      return {
        onClick: event => {
          event.stopPropagation(); // this will avoid onRow being called
        }
      }
    },
    render: (q:BaseQuote) => <ExclamationOutlined style={{color: "red"}} onClick={() => handleShowReview(q)} />
  });

  //quick fix to handle no elements
  const topButtonSection: any = pagination.total
    ? { position: "absolute", top: "1rem", zIndex: 1, right: "0px" }
    : { float: "right", marginBottom: "1rem", marginTop: "1rem" };

  return <>
        <div style={{ position: "relative" }}>
          <Col style={topButtonSection}>
            <Space direction="horizontal">
              <Button type="primary" loading={isExporting} onClick={exportOrders}>Export</Button>
              {hasComparePermissions && <DiffRevisionModalButton btnLabel="Compare" title="Quote Comparison" className="" type="primary"  /> }
            </Space>
          </Col>

          <Table
            loading={quoteListAsync.isLoading()}
            onChange={tableOnChange}
            bordered
            pagination={pagination}
            dataSource={quoteList}
            columns={columns}
            rowKey="quoteId"
          />
          <ReviewDrawer quote={quote} 
            open={isReviewOpen && !!quote}
            onClose={() => setIsReviewOpen(false)}
          />
          <CommentsDrawer quote={quote} 
            open={isCommentsOpen && !!quote}
            onClose={handleHideComments}
          />
        </div>
    </>
}

export default VFDReviewPage;
