import { Select, SelectProps } from "antd";
import { useEffect, useMemo } from "react";
import { CANADA_PROVINCES, CountryCode, US_STATES } from "../api/models";


const StatesSelector = ( props: Omit<SelectProps, 'value' | 'onChange'> & {
    value?: string;
    onChange?: (state?: string ) => void;
    country?: string
    includeTbd?: boolean
  }) => {

  const {country:a, includeTbd:b, ...selectProps} = props;

  useEffect(() => {
    if (props.country && props.value)  {
      const states = getStates(props.country).map(s => s.value );
      const selected = states.includes(props.value);
      if ( !selected ) {
        props.onChange?.(undefined);
      }
    }
  }, [props.country]);

  const getStates = (country:string | undefined) => {
    switch (country) {
      case CountryCode.CA: 
      {
        return CANADA_PROVINCES;
      }
      case CountryCode.US:
      default:
      {
        return US_STATES;
      }
    }
  }

  const options = useMemo(() => {

    const tbd =  {
      value: "TBD",
      label: "TBD"
    };

    const states = getStates(props.country).map(state => ({
        value:state.value,
        label:state.name
      })) 

    return props.includeTbd 
      ? [tbd, ...states]
      : states;

  }, [props.country, props.includeTbd] );

  return (
    <Select
      showSearch={true}
      allowClear={true}
      {...selectProps}
      optionFilterProp="value"
      options={options}
      />
  );
}

export default StatesSelector;

