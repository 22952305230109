import {Button, Col, Form, Modal, notification, Row, Select, Spin} from "antd";
import {useCallback, useContext, useRef, useState} from "react";
import BMButton, {BMButtonProps} from "../BMButton";
import {useIntl} from "react-intl";
import {ConfiguratorContext} from "../../context";
import ApprovalDiffTable from "../Table/ApprovalDiffTable";
import {AsyncState, useAsyncState} from "../../hook/useAsyncState";
import {ApprovalDiff, Quote, QuoteInfo, SortDirection} from "../../api/models";
import jsPDF from "jspdf";
import dayjs from "dayjs";
import {debounce} from "lodash";
import { useQuoteContext } from "../../contexts/QuoteContext";

const DiffRevisionModalButton = (props: BMButtonProps & {
  title?: string
  btnLabel?: string
  quoteIdA?: string
  revisionA?: number
  quoteIdB?: string
  revisionB?: number
}) => {

  const [isOpen, setIsOpen] = useState<boolean>();

  const [form] = Form.useForm();
  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const [approvalDiff, approvalDiffAsync] = useAsyncState<ApprovalDiff>();
  const {quoteAsync} = useQuoteContext();
  const [quoteAList, quoteAListAsync] = useAsyncState<QuoteInfo[]>([]);
  const [quoteBList, quoteBListAsync] = useAsyncState<QuoteInfo[]>([]);
  const [quoteA, quoteAAsync] = useAsyncState<Quote>();
  const [quoteB, quoteBAsync] = useAsyncState<Quote>();
  const specComparisonBodyRef = useRef(null);

  const defaultQuoteId = quoteAsync?.val?.quoteId;
  const defaultMinQuoteRevision =  quoteAsync?.val?.revisions.find(r => r.id === quoteAsync.val?.displayRevisionId)?.revision || 1;
  const defaultMaxQuoteRevision =  quoteAsync?.val?.revisions.sort((a,b) => b.revision - a.revision ).find(v=>v)?.revision || 1;

  const handleChangeQuoteIdA = async (quoteId: string | undefined) => {
    return await handleChangeQuoteId( quoteAAsync, quoteId, 'A' );
  }
  const handleChangeQuoteIdB= async (quoteId: string | undefined) => {
    return await handleChangeQuoteId( quoteBAsync, quoteId, 'B' );
  }
  const handleChangeQuoteId = async (quoteAsync:AsyncState<Quote>, quoteId: string | undefined, suffix:'A' | 'B') => {

    if( !quoteId ) {
      form.setFieldValue( 'revision' + suffix, undefined );
      return;
    }

    return loadQuote( quoteAsync, quoteId )?.then(q => {
      quoteAsync.setDone(q);

      const rev = q?.revisions.sort((a,b) => b.revision - a.revision ).find(v=>v)?.revision || 1;
      form.setFieldValue( 'revision' + suffix, rev );
    });
  }

  const handleExportPdf = () => {

    const element = specComparisonBodyRef.current;
    if (!element ) return;

    const values = form.getFieldsValue(true);
    const outputName = [
      [values.quoteIdA, values.revisionA].join("r"),
      [values.quoteIdB, values.revisionB].join("r") 
    ].join("-");
    const ts = dayjs().format('YYYY-MM-DD.SSS');

    const doc = new jsPDF();
    doc.html(element, {
      callback: (doc) => {
        doc.save( `${outputName}-${ts}.pdf` );
      },
      html2canvas:  { scale: .12 },
      margin: 10,
    });
  }
  
  const handleCancel = async () => {
    setIsOpen(false)
  }

  const handleCompare = async () => {
    
    try {
      var values = await form.validateFields();
      loadApprovalDiff( values.quoteIdA, values.revisionA, values.quoteIdB, values.revisionB );
    }
    catch(validationErrors) {
      notification.error({message: "Please fix validation errors." });
    }
  }

  const handleFilterChange = async (changedValues: Record<string, any>, _values: Record<string, any>) => {

    if ( 'quoteIdA' in changedValues ) {

      await handleChangeQuoteIdA(changedValues.quoteIdA );
    }

    if ( 'quoteIdB' in changedValues ) {
      await handleChangeQuoteIdB(changedValues.quoteIdB );
    }

    if ( 'revisionA' in changedValues || 'revisionB' in changedValues ) {
      await handleCompare();
    }

  }

  const loadApprovalDiff = async (
    quoteIdA:string,
    revisionA:number,
    quoteIdB:string,
    revisionB:number,
  ) => {

    approvalDiffAsync.setLoading();
    try {
      const resp = await configurator.api.diffArbitraryRevisions(quoteIdB, revisionB, quoteIdA, revisionA);
      approvalDiffAsync.setDone(resp.data);
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch approval differences. " + errorMsg });
      approvalDiffAsync.setFail(e.message);
    }
  }

  const resetTargetValue = async () => {

    const quoteId = props.quoteIdA || defaultQuoteId;

    await handleChangeQuoteIdA( quoteId );
    await handleChangeQuoteIdB( quoteId );

    form.resetFields();

    approvalDiffAsync.setInit();
  }

  const loadQuoteList = useCallback(debounce( async (quoteListAsync:AsyncState<QuoteInfo[]>, search:string) : Promise<QuoteInfo[] | undefined> => {

    const sort = {
      field: 'quoteId',
      direction: 'asc' as SortDirection
    };

    quoteListAsync.setLoading();
    try {
      const resp = await configurator.api.listQuoteInfo({
        search,
        page: 0,
        size: 5,
        sort,
      });

      quoteListAsync.setDone(resp.data.content);
      return resp.data.content;
    }
    catch(e: any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Quotes failed to load. " + errorMsg });
      quoteListAsync.setFail(e.message);
    }
    return;
  }, 400), [] );

  const loadQuoteListA = (s:string) => loadQuoteList(quoteAListAsync, s ); 
  const loadQuoteListB = (s:string) => loadQuoteList(quoteBListAsync, s ); 

  const loadQuote = useCallback(async (quoteAsync:AsyncState<Quote>, quoteId:string) :Promise<Quote | undefined> => {
    if ( !quoteId ) return;

    try {
      quoteAsync.setLoading();

      const resp = await configurator.api.getQuote(quoteId);
      const quote = resp.data;

      quoteAsync.setDone(quote);

      return quote;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Quote failed to load. " + errorMsg });
      quoteAsync.setFail(e.message);
    }

    return;
  }, []);

  const fieldsetStyle = {
    border: "3px solid #1890ff",
    borderRadius: "10px",
    padding: "0 .6rem 0 .6rem"
  };
  const legendStyle = {
    fontSize: "12px",
    color: "#1890ff",
    background: "transparent",
    width: "max-content",
    marginLeft: "13px",
    padding: "0 3px 0 2px",
    border: "none",
  };

  const modalButtonStyle = approvalDiffAsync.isDone() ? {style: {display: "none"}} : {};
  const { btnLabel:z, quoteIdA:a, revisionA:b, quoteIdB:c, revisionB:d, ...btnProps } = props;
  const btnLabel = props.btnLabel || "Spec. Comparison";
  const modalTitle = props.title || btnLabel;
  const compareLbl = "Compare";

  return <>
    <BMButton
      className="ghostBmButton"
      type="text"
      {...btnProps}
      onClick={() => setIsOpen(true)}
    >{btnLabel}</BMButton>
    <Modal 
      title={modalTitle}
      open={isOpen}
      okButtonProps={modalButtonStyle}
      okText={compareLbl}
      onOk={handleCompare}
      onCancel={handleCancel}
      cancelButtonProps={modalButtonStyle}
      afterOpenChange={() => isOpen && resetTargetValue()}
      width={"80%"}
    >

      <div style={{marginBottom: "1rem", marginTop: "1rem"}} >
        <Form form={form} 
          onValuesChange={handleFilterChange}
          initialValues={{
            quoteIdA: props.quoteIdA || defaultQuoteId,
            revisionA: props.revisionA ||  ( defaultMinQuoteRevision === defaultMaxQuoteRevision ) ? 1 : defaultMinQuoteRevision,
            quoteIdB:  props.quoteIdA || defaultQuoteId,
            revisionB: props.revisionB || defaultMaxQuoteRevision,
          }}
        >
          <Row justify={"space-between"} align="bottom" >
            <Col>
              <Row gutter={16} align="bottom">
                <fieldset style={fieldsetStyle} >
                  <legend style={legendStyle}>Spec A</legend>

                  <Row gutter={16}>
                    <Col>
                      <Form.Item label="Quote" name="quoteIdA" >
                        <Select 
                          allowClear
                          showSearch
                          style={{width:"100%", minWidth:"10rem"}} 
                          placeholder="Type quote Id" 
                          optionFilterProp="label"
                          onSearch={loadQuoteListA}
                          loading={quoteAListAsync.isLoading()}
                          options={quoteAList?.map((q) => ({label: q.quoteId, value:q.quoteId}))}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label="Revision" name="revisionA" >
                        <Select 
                          style={{width:"100%", minWidth:"4rem"}} 
                          optionFilterProp="label"
                          loading={quoteAAsync.isLoading()}
                          options={quoteA?.revisions.sort((a,b) => b.revision - a.revision ).map((r) => ({label: r.revision, value:r.revision}))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </fieldset>

                <fieldset style={{...fieldsetStyle, marginLeft: "1rem" }}>
                  <legend style={legendStyle}>Spec B</legend>

                  <Row gutter={16}>
                    <Col>
                      <Form.Item label="Quote" name="quoteIdB" >
                        <Select 
                          allowClear
                          showSearch
                          style={{width:"100%", minWidth:"10rem"}} 
                          placeholder="Type quote Id" 
                          onSearch={loadQuoteListB}
                          loading={quoteBListAsync.isLoading()}
                          options={quoteBList?.map((q) => ({label: q.quoteId, value:q.quoteId}))}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label="Revision" name="revisionB" >
                        <Select 
                          style={{width:"100%", minWidth:"4rem"}} 
                          loading={quoteBAsync.isLoading()}
                          options={quoteB?.revisions.sort((a,b) => b.revision - a.revision ).map((r) => ({label: r.revision, value:r.revision}))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </fieldset>
              </Row>
            </Col>

            <Col id="specComparisonBtnLst" >
              <Row gutter={12}>
                <Col>
                  <Button onClick={handleExportPdf} hidden={!approvalDiffAsync.isDone()}>Export PDF</Button>
                </Col>
                <Col>
                  <Button onClick={handleCancel} hidden={!approvalDiffAsync.isDone()} >Cancel</Button>
                </Col>
              </Row>
            </Col>
          </Row >
        </Form>
      </div>

      <div ref={specComparisonBodyRef}>
        <ApprovalDiffTable 
          diff={approvalDiff}
          loading={approvalDiffAsync.isLoading()}
          columnTitles={[
            `${form.getFieldValue('quoteIdA')} rev ${form.getFieldValue('revisionA')}`,
            `${form.getFieldValue('quoteIdB')} rev ${form.getFieldValue('revisionB')}`,
          ]}
        />
      </div>

    </Modal>
  </>
}

export default DiffRevisionModalButton
